import React from 'react';
import { DialogTitle, Grid, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';

import { StyledDialogActions, StyledDialogContent } from '../Dialog';
import { RoundButton } from '../Button/RoundButton';
import { PayoutHandler } from 'components/payouts/payoutHandler';

const StyledTypography = styled(Typography)(() => ({
  marginLeft: '20px',
  alignSelf: 'center',
  paddingBottom: '0px',
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: '16px 8px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    padding: '16px 0px',
  },
}));

export default function AddPayoutDetails({
  handleClose,
  isPayoutAdded,
  returnUrl,
  title,
  message,
}: {
  handleClose: () => void;
  isPayoutAdded: boolean;
  returnUrl?: string;
  title?: string;
  message?: string;
}) {
  const theme = useTheme();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <React.Fragment>
      <StyledDialogTitle>
        <Stack direction="row">
          <StyledTypography variant="h6" color={theme.palette.grey[900]}>
            {title ? title : 'New Contract'}
          </StyledTypography>
        </Stack>
      </StyledDialogTitle>

      <StyledDialogContent dividers>
        <Grid container item xs={12} justifyContent="center">
          <Grid item>
            <Typography variant="body2">
              {message
                ? message
                : 'Please add your payout details via our partner Stripe prior to creating contracts as a seller.'}
            </Typography>
          </Grid>
        </Grid>
      </StyledDialogContent>
      <StyledDialogActions>
        <Grid container justifyContent="flex-end" gap={2}>
          <RoundButton fullWidth={isMobileScreen} variant="outlined" onClick={handleClose}>
            Cancel
          </RoundButton>

          <PayoutHandler
            btnText={`${isPayoutAdded ? 'Update' : 'Add'} Payout Details`}
            onClose={() => handleClose()}
            returnUrl={returnUrl}
          />
        </Grid>
      </StyledDialogActions>
    </React.Fragment>
  );
}
