import { CircularProgress, Grid, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import RatingComponent from 'components/common/RatingComponent';
import { EllipsisTypography } from 'components/common/StyledWorkroom/WorkroomStyles';
import { UserAvatar } from 'components/common/UserAvatar';
import { formatDistanceStrict } from 'date-fns';
import { IFrontUserReview } from 'global/interfaces/userReview';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUserReviews } from 'services/contractService';
import { showError } from 'utils/errorHandler';
import { BorderedBox } from '../common/BorderedBox';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';

const StyledRateReviewOutlinedIcon = styled(RateReviewOutlinedIcon)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fill: theme.palette.grey[600],
    width: '32px',
    height: '32px',
    marginRight: '16px',
    marginLeft: '-8px',
  },
}));

export default function UserRatingSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { id } = useParams();
  const [reviews, setReviews] = useState<IFrontUserReview[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id) {
      getUserReviews(id)
        .then(res => {
          setReviews(res);
          setLoading(false);
        })
        .catch(showError);
    }
  }, []);

  return (
    <BorderedBox showBorder={!isMobile}>
      {!loading ? (
        <>
          <Stack ml={isMobile ? 'unset' : '-4px'} alignItems="center" gap={1} flexDirection="row">
            {!isMobile && <StyledRateReviewOutlinedIcon />}
            <Typography variant="h6">Reviews</Typography>
          </Stack>
          <Grid container spacing={4} mt={0.5}>
            {reviews &&
              reviews?.map((review, index) => (
                <Grid container item key={index} xs={12} alignItems="flex-start">
                  <Grid item xs={1.7} sm={0.9} md={2} lg={1.8}>
                    <UserAvatar userId={review.reviewSentByUserId} noLink />
                  </Grid>
                  <Grid item xs={7.3} sm={7.1} md={7} lg={6.2}>
                    <EllipsisTypography variant="subtitle2" fontWeight={700}>
                      {review.displayName}
                    </EllipsisTypography>
                    <RatingComponent rating={review.rating} />
                  </Grid>
                  <Grid container item xs={3} lg={4} alignItems="flex-start" justifyContent="flex-end">
                    <Typography variant="caption" color="grey.500">
                      {formatDistanceStrict(new Date(review.createdDate), new Date(), { addSuffix: true })}
                    </Typography>
                  </Grid>
                  <Grid container justifyContent="flex-end" sx={{ marginTop: '10px' }}>
                    <Grid item xs={10.3} sm={11.1} md={10} lg={10.2}>
                      <Typography variant="body2">{review.comment}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </>
      ) : (
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      )}
    </BorderedBox>
  );
}
