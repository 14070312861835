import {
  Avatar,
  FormHelperText,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FormikProps } from 'formik';
import { ChatType, IChatUserThread } from 'global/interfaces/chatThread';
import { ContractViewType, ICreateContractForm } from 'global/interfaces/contract';
import { IUser } from 'global/interfaces/user';
import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { nameof } from 'ts-simple-nameof';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle, StyledGrow } from 'components/common/Dialog';
import { RoundButton } from '../Button/RoundButton';

import { ArrowBlockLeftIcon } from 'components/icon/ArrowBlockLeftIcon';
import { ArrowBlockRightIcon } from 'components/icon/ArrowBlockRightIcon';
import { StyledListItemButton } from '../Button/StyledListItemButton';

export const StyledList = styled(List)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  marginTop: '30px',
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  [theme.breakpoints.down('sm')]: {
    height: '34px',
    width: '34px',
  },
}));

const StyledListItemAvatar = styled(ListItemAvatar)(({ theme }) => ({
  minWidth: theme.spacing(7),

  [theme.breakpoints.down('sm')]: {
    minWidth: theme.spacing(5.5),
  },
}));

export const ChooseBuySell: React.FC<{
  form: FormikProps<ICreateContractForm>;
  next: () => void;
  user: IUser | null;
  selectedThread: IChatUserThread | undefined;
  handleCancel: () => void;
}> = ({ next, form, selectedThread, handleCancel }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [error, setError] = useState<boolean>(false);
  const isOneToOneChat = selectedThread?.type == ChatType.OneToOne;

  return (
    <>
      <StyledDialogTitle>
        <Typography variant="h6" color={theme.palette.grey[900]}>
          New Contract {isOneToOneChat && `for ${selectedThread.displayName}`}
        </Typography>
        <IconButton onClick={() => handleCancel()} sx={{ marginRight: '-12px' }}>
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>

      <StyledDialogContent dividers>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color={theme.palette.grey[900]} mt={isMobileScreen ? 2 : 0} fontWeight={600}>
            Are you the buyer or seller for this contract?
          </Typography>

          <StyledGrow in={true} timeout={1000}>
            <StyledList disablePadding>
              <ListItem disablePadding>
                <StyledListItemButton
                  selected={form.values.buySell === ContractViewType.Buy}
                  onClick={() => {
                    setError(false);
                    form.setFieldValue(
                      nameof<ICreateContractForm>(x => x.buySell),
                      ContractViewType.Buy,
                    );
                  }}
                >
                  <StyledListItemAvatar>
                    <StyledAvatar>
                      <ArrowBlockRightIcon style={{ color: theme.palette.common.white }} />
                    </StyledAvatar>
                  </StyledListItemAvatar>
                  <ListItemText primary={<Typography variant="subtitle1">Buyer</Typography>} />
                </StyledListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <StyledListItemButton
                  selected={form.values.buySell === ContractViewType.Sell}
                  onClick={() => {
                    setError(false);
                    form.setFieldValue(
                      nameof<ICreateContractForm>(x => x.buySell),
                      ContractViewType.Sell,
                    );
                  }}
                >
                  <StyledListItemAvatar>
                    <StyledAvatar>
                      <ArrowBlockLeftIcon style={{ color: theme.palette.common.white }} />
                    </StyledAvatar>
                  </StyledListItemAvatar>
                  <ListItemText primary={<Typography variant="subtitle1">Seller</Typography>} />
                </StyledListItemButton>
              </ListItem>
              <FormHelperText sx={{ color: '#F04438', mt: '8px' }}>
                {error ? 'Choose whether you are the buyer or seller' : ''}
              </FormHelperText>
            </StyledList>
          </StyledGrow>
        </Grid>
      </StyledDialogContent>

      <StyledDialogActions>
        <RoundButton fullWidth={isMobileScreen} variant="outlined" onClick={() => handleCancel()}>
          Cancel
        </RoundButton>
        <RoundButton
          fullWidth={isMobileScreen}
          variant="contained"
          onClick={() => {
            if (form.values.buySell === null) {
              setError(true);
              return;
            }
            next();
          }}
        >
          Next
        </RoundButton>
      </StyledDialogActions>
    </>
  );
};
