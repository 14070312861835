import { Box, IconButton, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FileType } from 'global/enums/fileTypes';
import BlobVideo from '../BlobFiles/BlobVideo';
import PdfViewer from '../PdfViewer/PdfViewer';
import { memo } from 'react';
import BlobAudio from '../BlobFiles/BlobAudio';
import MediaProgress, { audioText, videoText } from '../MediaProgress';
import { ICarouselFile } from './ICarouselFile';
import ImageViewer from '../ImageViewer/ImageViewer';
import { Close, Download } from '@mui/icons-material';
import { triggerFileDownload } from 'utils/file';

const ParentBox = styled(Box)(() => ({
  position: 'relative',
  height: '100%',
  width: '100%',
}));

const StyledBox = styled(Box)(() => ({
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}));

const RightButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '10px',
  right: '10px',
  width: '36px',
  height: '36px',
  backgroundColor: theme.palette.common.white,
  boxShadow: '0 3px 9px rgba(0,0,0,0.3)',
  color: 'rgba(0, 0, 0, 0.54)',
  pointer: 'cursor',
  zIndex: 1,

  '&:hover': {
    backgroundColor: theme.palette.grey[300],
  },
}));

const CloseButton = styled(RightButton)(() => ({
  top: '10px',
  right: '10px',
}));

const DownloadButton = styled(RightButton)(() => ({
  top: '10px',
  right: '60px',
}));

const renderWithTitle = (children: JSX.Element, title: string) => (
  <>
    {children}
    <Box
      sx={{
        textAlign: 'center',
        pt: 3,
      }}
    >
      <Typography color={'grey.500'} sx={{ fontSize: '0.8rem' }}>
        {title}
      </Typography>
    </Box>
  </>
);

interface ICarouselSlideProps extends ICarouselFile {
  isActive: boolean;
  onClose: () => void;
}

function CarouselSlide({ url, name, type, isLoading, isActive, onClose }: ICarouselSlideProps) {
  const theme = useTheme();
  const pdfPaddingY = useMediaQuery(theme.breakpoints.up('lg')) ? 80 : 25;

  return (
    <ParentBox>
      <CloseButton size="small" onClick={onClose}>
        <Close />
      </CloseButton>
      <DownloadButton size="small" onClick={() => triggerFileDownload(url, name)}>
        <Download />
      </DownloadButton>
      {type == FileType.Pdf && (
        <PdfViewer url={url} enabled={isActive} showOpenInNewTab={false} paddingY={pdfPaddingY} />
      )}
      {type == FileType.Image && <ImageViewer url={url} name={name} />}
      {type === FileType.Video && (
        <StyledBox>
          {isLoading ? <BlobVideo url={url} /> : renderWithTitle(<MediaProgress message={videoText} />, name)}
        </StyledBox>
      )}
      {type === FileType.Audio && (
        <StyledBox>
          {isLoading ? (
            <>
              <BlobAudio url={url} isActive={isActive} />
            </>
          ) : (
            renderWithTitle(<MediaProgress message={audioText} />, name)
          )}
        </StyledBox>
      )}
    </ParentBox>
  );
}

export default memo(CarouselSlide);
