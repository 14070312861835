import { Stack, styled, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import FormSelect from 'components/common/Select/FormSelect';
import { useFormik } from 'formik';
import { IOpportunity } from 'global/interfaces/opportunity';
import { SelectItem } from 'global/interfaces/selects';
import { Dispatch, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RoundButton } from 'components/common/Button/RoundButton';

interface IOppFilterProps {
  categories: SelectItem[];
  setFilteredOpps: Dispatch<React.SetStateAction<IOpportunity[]>>;
  opportunities: IOpportunity[];
  children?: React.ReactNode;
  setLoading: Dispatch<boolean>;
}

interface ISearchForm {
  category?: string;
  search?: string;
}

const StyledTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    paddingRight: 0,
    borderColor: '#10182833',
  },
}));

const StyledSelectField = styled(FormSelect)(() => ({
  borderRadius: '12px',
  borderColor: '#10182833',
}));

export default function OppFilters({
  categories,
  setFilteredOpps,
  opportunities,
  children,
  setLoading,
}: IOppFilterProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [searchParams, setSearchParams] = useSearchParams();

  const form = useFormik<ISearchForm>({
    initialValues: {
      category: searchParams.get('category') ?? 'All',
      search: searchParams.get('oppsearch') ?? '',
    },
    onSubmit: values => {
      setLoading(true);

      const params = new URLSearchParams(searchParams);
      if (values.category !== 'All') {
        params.set('category', values.category ?? 'All');
      } else {
        params.delete('category');
      }
      if (values.search) {
        params.set('oppsearch', values.search);
      } else {
        params.delete('oppsearch');
      }

      setTimeout(() => {
        setSearchParams(params);
        setLoading(false);
      }, 300);
    },
  });

  useEffect(() => {
    if (!searchParams.has('category') && !searchParams.has('oppsearch')) {
      setFilteredOpps(opportunities);
      return;
    }

    let filt: IOpportunity[] = opportunities;
    if (searchParams.has('category')) {
      filt = opportunities.filter(opp => opp.categories.includes(searchParams.get('category') ?? ''));
    }
    if (searchParams.has('oppsearch')) {
      const oppsearch = searchParams.get('oppsearch') ?? '';
      filt = filt.filter(
        f =>
          f.description.toLowerCase().includes(oppsearch.toLowerCase()) ||
          f.title.toLowerCase().includes(oppsearch.toLowerCase()) ||
          (f.location ? f.location.toLowerCase().includes(oppsearch.toLowerCase()) : false),
      );
    }
    setFilteredOpps(filt);
  }, [searchParams, opportunities]);

  return (
    <Stack
      sx={{ mb: !isMobile ? 5 : 0, mt: !isMobile ? 5 : 4 }}
      spacing={2}
      direction={'column'}
      component="form"
      onSubmit={form.handleSubmit}
    >
      <Typography variant="subtitle1" mb={0.25}>
        Filter opportunities
      </Typography>
      <StyledSelectField
        label="Category"
        value={form.values.category}
        name="category"
        size={'small'}
        items={categories}
        onChange={form.handleChange}
        fullWidth
      />
      <StyledTextField
        name="search"
        label="Keywords"
        value={form.values.search}
        onChange={form.handleChange}
        autoComplete="off"
        placeholder="Enter Keywords"
        size={'small'}
        fullWidth
        InputProps={{
          autoComplete: 'off',
        }}
      />
      <RoundButton variant="contained" type="submit">
        Update Results
      </RoundButton>
      {children}
    </Stack>
  );
}
