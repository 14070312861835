import { Box, Stack, Typography, styled, useTheme } from '@mui/material';

import { IWorkHistory } from 'global/interfaces/user';
import { getHistoryDateString } from 'utils/workHistoryHelper';
import { StyledCarousel } from 'components/common/Carousel/StyledCarousel';
import { compareWorkHistories } from 'utils/compareWorkHistoryUtil';

const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
`;

const PaddedBox = styled(FlexBox)(({ theme }) => ({
  marginTop: '32px',
  paddingRight: '24px',

  [theme.breakpoints.down('md')]: {
    paddingLeft: '16px',
  },
}));

const StyledWorkHistoryBox = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  padding: '16px 24px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderColor: theme.palette.grey[200],
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '8px',
  marginInline: '10px',

  [theme.breakpoints.down('md')]: {
    marginInline: '12px',
  },
}));

const CarouselWrapper = styled(Box)(({ theme }) => ({
  padding: '24px',
  paddingInline: '12px',
  marginInline: '-24px',

  [theme.breakpoints.down('md')]: {
    paddingInline: '8px',
    marginInline: 0,
    paddingBottom: 0,
  },
}));

interface IUserWorkHistorySectionProps {
  workHistories: IWorkHistory[];
}

export default function UserWorkHistorySection(props: IUserWorkHistorySectionProps) {
  const theme = useTheme();

  const responsiveProps = {
    desktop: {
      breakpoint: { max: window.screen.width, min: 1440 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: theme.breakpoints.values.lg, min: theme.breakpoints.values.sm },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: theme.breakpoints.values.sm, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <>
      <PaddedBox>
        <Typography variant="h6">Work History</Typography>
      </PaddedBox>

      <CarouselWrapper>
        <StyledCarousel className="work-history-react-multiple-carousel" responsive={responsiveProps}>
          {props.workHistories.sort(compareWorkHistories).map((p: IWorkHistory, index: number) => [
            <StyledWorkHistoryBox key={index}>
              <Stack direction="column" spacing={1} alignItems="left">
                <Typography variant="subtitle1">{p.title}</Typography>
                <Typography variant="body2">{getHistoryDateString(p)}</Typography>
                <Typography variant="body2">{p.company}</Typography>
              </Stack>
            </StyledWorkHistoryBox>,
          ])}
        </StyledCarousel>
      </CarouselWrapper>
    </>
  );
}
