import { EditUserProfile } from 'components/userProfileEdits/EditUserProfile';
import { IUser } from 'global/interfaces/user';
import { useEffect, useState } from 'react';
import { getUser } from 'services/userService';

import { showError } from 'utils/errorHandler';
import { useTitle } from 'utils/router';
import { useSearchParams } from 'react-router-dom';
import { ApplyParam } from 'global/Constants/SearchParamConstants';
import { VettingStatus } from 'global/enums/vettingStatus';

const EditUser = () => {
  useTitle('Edit profile');
  const [user, setUser] = useState<IUser | null>(null);
  const [searchParams] = useSearchParams();
  const [isApply] = useState<boolean>(Boolean(searchParams.get(ApplyParam)));

  useEffect(() => {
    getUser()
      .then((res: IUser) => {
        if (isApply) {
          res.isSellingServices = true;
        }
        res.needsVettingValidation = isApply || res.vetting.status != VettingStatus.NotSubmitted;
        setUser(res);
      })
      .catch(showError);
  }, []);

  return <> {user !== null && <EditUserProfile user={user} isApplyPhase={isApply} />} </>;
};

export default EditUser;
