import { Box, styled } from '@mui/material';
import { useRef, useState } from 'react';
import useCalculatedHeight, { maxMediaHeight } from './useCalculatedHeight';

const StyledBox = styled(Box)(() => ({
  position: 'relative',
}));

const StyledVideo = styled('video')(({ theme }) => ({
  objectFit: 'cover',
  maxWidth: '100%',
  maxHeight: `${maxMediaHeight}px`,
  display: 'block',
  backgroundColor: theme.palette.common.black,
}));

const MessageContainer = styled('div')(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: 'white',
}));

const DownloadLink = styled('a')(() => ({
  color: 'white',
  textDecoration: 'underline',
}));

export interface IChatMessageMediaVideoProps {
  url?: string;
  fileName: string;
  extension: string;
  onDownload?: () => void;
}

export default function ChatMessageMediaVideo({ url, onDownload }: IChatMessageMediaVideoProps): JSX.Element {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoError, setVideoError] = useState<boolean>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const handleVideoError = () => {
    if (videoRef.current && videoRef.current.networkState === HTMLMediaElement.NETWORK_NO_SOURCE) {
      setVideoError(true);
    }
  };

  const handleLoadedData = () => {
    setVideoError(false);
    setLoaded(true);
    if (videoRef.current != null) {
      const element = videoRef.current;
      element.style.height = '';
    }
  };

  useCalculatedHeight(videoRef.current, loaded);

  return (
    <StyledBox>
      <StyledVideo
        controls
        muted
        playsInline
        loop
        onError={handleVideoError}
        onLoadedData={handleLoadedData}
        ref={videoRef}
        preload="metadata"
      >
        <source src={`${process.env.REACT_APP_CDN_URL ?? ''}${url}`} />
        Your browser does not currently recognize this video format.
      </StyledVideo>

      {videoError && (
        <MessageContainer>
          Your browser can&apos;t play this video. <DownloadLink onClick={onDownload}>Click here</DownloadLink> to
          download it.
        </MessageContainer>
      )}
    </StyledBox>
  );
}
