import { DrawerBody, DrawerFooter, DrawerHeader } from '../StyledDrawer/DrawerStyles';
import { IconButton, Stack, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { RoundButton } from '../Button/RoundButton';
import useProgressBar from 'global/hooks/useProgressBar';
import { cancelContract } from 'services/contractService';
import { showError } from 'utils/errorHandler';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ContractStatus, ContractType, IContract } from 'global/interfaces/contract';
import { dateStringFromDate } from 'utils/dateFormat';

const StyledTypography = styled(Typography)(() => ({
  overflowWrap: 'break-word',
}));

export default function CancelContract({
  setCancelOption,
  setDrawer,
  contract,
}: {
  setCancelOption: React.Dispatch<React.SetStateAction<boolean>>;
  contract: IContract;
  setDrawer?: React.Dispatch<boolean>;
}) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [progress, showProgress] = useProgressBar();

  const handleCancelContract = () => {
    showProgress(true);
    cancelContract(contract.contractId)
      .then(() => {
        showProgress(false);
        if (setDrawer !== undefined) {
          setCancelOption(false);
          setDrawer(false);
        }
      })
      .catch(e => {
        showError(e);
        showProgress(false);
      });
  };
  return (
    <>
      <DrawerHeader container>
        <Stack justifyContent="center" gap={2} width="100%">
          <Stack direction="row" width="100%" gap={1} justifyContent="space-between" alignItems="center">
            <StyledTypography variant="h6">Cancel Contract </StyledTypography>

            <IconButton onClick={() => setDrawer && setDrawer(false)} sx={{ mr: '-12px' }}>
              <CloseOutlinedIcon />
            </IconButton>
          </Stack>
        </Stack>
      </DrawerHeader>
      {(contract.contractType == ContractType.FixedPrice ||
        (contract.contractType == ContractType.Retainer &&
          (!contract.isEnding ||
            contract.status === ContractStatus.Created ||
            contract.status === ContractStatus.Offered))) && (
        <>
          <DrawerBody item container>
            <Typography variant="body1" pb={0.5}>
              {contract.contractType == ContractType.Retainer && contract.status === ContractStatus.InProgress
                ? 'Cancellation will take effect at the end of the current billing period on ' +
                  dateStringFromDate(contract.currentPeriodEndDate) +
                  '. Are you sure you want to cancel this contract?'
                : 'Are you sure you want to cancel this contract?'}
            </Typography>
          </DrawerBody>
          <DrawerFooter container justifyContent="flex-end" gap={1}>
            <RoundButton fullWidth={isMobileScreen} variant="outlined" onClick={() => setCancelOption(false)}>
              No
            </RoundButton>
            <RoundButton fullWidth={isMobileScreen} variant="contained" onClick={() => handleCancelContract()}>
              Yes
            </RoundButton>
          </DrawerFooter>
        </>
      )}
      {contract.contractType == ContractType.Retainer &&
        contract.isEnding &&
        contract.status === ContractStatus.InProgress && (
          <>
            <DrawerBody item container>
              <Typography variant="body1" pb={0.5}>
                This contract is already scheduled for termination on{' '}
                {dateStringFromDate(contract.currentPeriodEndDate)}.
              </Typography>
            </DrawerBody>
            <DrawerFooter container justifyContent="flex-end" gap={1}>
              <RoundButton fullWidth={isMobileScreen} variant="outlined" onClick={() => setCancelOption(false)}>
                Ok
              </RoundButton>
            </DrawerFooter>
          </>
        )}

      {progress}
    </>
  );
}
