import { Outlet, useSearchParams } from 'react-router-dom';
import { ProtectedRoute } from './ProtectedRoute';

export const ForcedLoginRoute = () => {
  const [searchParams] = useSearchParams();

  if (searchParams && searchParams.get('fl') === '1') {
    return <ProtectedRoute />;
  }

  return <Outlet />;
};
