import { Box, IconButton, styled, useTheme } from '@mui/material';
import { FileType } from 'global/enums/fileTypes';
import Carousel from 'react-material-ui-carousel';
import { isTouchScreen } from 'utils/device';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import React, { useState } from 'react';
import { CarouselRenderer } from './CarouselRender';
import { IFileMetadata } from 'global/interfaces/file';

interface IHomeCarouselProps {
  portfolioFiles: IFileMetadata[];
  muted: boolean;
  setMuted: React.Dispatch<React.SetStateAction<boolean>>;
  inView: boolean;
  paused: boolean;
  pausePlay(paused: boolean): void;
  isActiveUser: boolean;
  profileImage: string;
}

const StyledCarousel = styled(Carousel)(() => ({
  '&:hover button': {
    opacity: '1',
  },
  '& .custom-carousel-arrow:hover': {
    opacity: '1',
  },
  '& .indicator-btn svg': {
    fontSize: '11px',
  },
}));

const customCarouselArrow = 'custom-carousel-arrow';
const carouselBaseClass = 'carousel-elem';

export const UserCarousel = React.memo(function UserCarousel({
  portfolioFiles,
  muted,
  setMuted,
  paused,
  pausePlay,
  isActiveUser,
  profileImage,
}: IHomeCarouselProps) {
  const theme = useTheme();
  const [activeIndex, setActiveIndex] = useState(0);

  const className = `${carouselBaseClass}`;

  const setActiveItem = (now?: number) => {
    if (now !== undefined) {
      setActiveIndex(now);
    }
  };

  const filteredFiles: IFileMetadata[] = portfolioFiles
    .filter(
      x =>
        x.isProcessed === true && (x.type === FileType.Image || x.type === FileType.Video || x.type === FileType.Audio),
    )
    .slice(0, 10);

  if (filteredFiles.length === 0) {
    const fileParts: string[] = profileImage.split(/[/.]/);
    const profileImageDefault: IFileMetadata = {
      id: fileParts[1] ?? '',
      directory: fileParts[0] ?? '',
      extension: `.${fileParts[2]}` ?? '',
      name: fileParts[1],
      type: FileType.Image,
      isProcessed: true,
      path: profileImage,
    };
    filteredFiles.push(profileImageDefault);
  }

  return (
    <StyledCarousel
      autoPlay={false}
      indicators
      indicatorContainerProps={{
        style: {
          position: 'absolute',
          bottom: '7px',
          zIndex: 1,
        },
        className: 'indicator-btn',
      }}
      activeIndicatorIconButtonProps={{
        style: {
          color: theme.palette.grey[50],
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        },
      }}
      fullHeightHover={false}
      cycleNavigation={true}
      duration={300}
      animation="slide"
      navButtonsProps={{
        style: {
          background: theme.palette.common.white,
          color: theme.palette.grey[900],
          margin: 0,
          padding: '0.2rem',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        },
        className: customCarouselArrow,
      }}
      navButtonsWrapperProps={{
        style: {
          bottom: '65%',
          top: 'unset',
          height: '0',
          paddingRight: '10px',
          paddingLeft: '9px',
          paddingTop: 0,
          paddingBottom: 0,
        },
      }}
      navButtonsAlwaysVisible={(isActiveUser || isTouchScreen()) && filteredFiles.length > 1}
      navButtonsAlwaysInvisible={filteredFiles.length <= 1}
      onChange={setActiveItem}
      changeOnFirstRender={true}
      swipe={false}
      NavButton={({ onClick, className, style, next, prev }) => (
        <Box
          sx={{
            display: 'flex',
            width: '50px',
            height: '80px',
            alignItems: 'center',
            '&:hover': {
              opacity: '1',
            },
          }}
          justifyContent={next ? 'flex-end' : 'flex-start'}
          onClick={e => {
            e.stopPropagation();
            onClick();
          }}
        >
          <IconButton className={className} style={style}>
            {next && <NavigateNextIcon />}
            {prev && <NavigateBeforeIcon />}
          </IconButton>
        </Box>
      )}
    >
      {filteredFiles.map((x, index) => (
        <CarouselRenderer
          key={index}
          portfolioFile={x}
          className={className}
          index={index}
          activeIndex={activeIndex}
          muted={muted}
          setMuted={setMuted}
          paused={paused}
          pausePlay={pausePlay}
          carouselListLength={filteredFiles.length}
          isActiveUser={isActiveUser}
        />
      ))}
    </StyledCarousel>
  );
});
