import { Container, Grid, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { ContractHistory } from 'components/viewContracts/contractHistory';
import { AuthContext } from 'contexts/AuthContext';
import { IConnection } from 'global/interfaces/connection';
import { IContract, IContractFilters } from 'global/interfaces/contract';
import { useContext, useEffect, useMemo, useState } from 'react';
import { getConnections } from 'services/connectionService';
import { getContracts } from 'services/contractService';
import { showError } from 'utils/errorHandler';
import { useCustomEventListener } from 'react-custom-events';
import { MessagePublisherEventType } from 'global/enums/messagePublisherEventType';
import { useTitle } from 'utils/router';
import { useSearchParams } from 'react-router-dom';
import { FilterIconButton } from 'components/common/Button/FilterIconButton';

const pageSize = 15;

const StyledMainContainer = styled(Container)(() => ({
  marginBottom: '20vh',
}));

const StyledHeadingGrid = styled(Grid)(({ theme }) => ({
  marginTop: '36px',

  [theme.breakpoints.down('md')]: {
    marginTop: '32px',
    marginBottom: '28px',
  },
}));

const ViewContracts: React.FC = () => {
  const authContext = useContext(AuthContext);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [contracts, setContracts] = useState<IContract[]>([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState<IContractFilters | null>(null);
  const [connections, setConnections] = useState<IConnection[]>([]);
  const isMobileScreen = useMediaQuery(useTheme().breakpoints.down('md'));
  const [mobileDrawer, setMobileDrawer] = useState(false);
  const [searchParams] = useSearchParams();
  const [fullContractList, setFullContractList] = useState<IContract[]>([]);
  useTitle('Contract history');

  useEffect(() => {
    loadConnections();
  }, [refresh, authContext]);

  const loadContracts = async (queryParams: IContractFilters | null): Promise<IContract[]> => {
    setLoading(true);
    const filters = queryParams ? { ...queryParams, pageSize } : { pageSize };

    return await getContracts({ ...filters, pageSize })
      .then((res: IContract[]) => {
        setLoading(false);
        return res;
      })
      .catch(error => {
        showError(error);
        throw error;
      });
  };

  useEffect(() => {
    if (searchParams.keys().next().value !== undefined) {
      const params: IContractFilters = {
        userId: searchParams.get('userId') ?? '',
        status: searchParams.get('status') ?? '',
        ...filters,
      };
      loadContracts(filters ?? params).then(res => setContracts(res));
    } else {
      loadContracts(null).then(res => {
        setContracts(res);
        setFullContractList(res);
      });
    }
  }, [searchParams]);

  useEffect(() => {
    if (searchParams.keys().next().value !== undefined) {
      if (contracts.length < 1) {
        loadContracts(null).then(res => {
          setFullContractList(res);
        });
      }
    }
  }, []);

  const loadConnections = () => {
    getConnections({})
      .then(res => {
        setConnections(res);
        setRefresh(false);
      })
      .catch(showError);
  };

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.ContractChange],
    () => {
      loadContracts(filters).then(res => setContracts(res));
    },
    [authContext],
  );

  const handleFilterChange = (filters: IContractFilters) => {
    setFilters({ ...filters, pageNumber: 1 });
  };

  const handleNextPage = () => {
    const pageNumber = filters?.pageNumber ?? 1;
    if (contracts.length === pageSize * pageNumber) {
      setFilters(currentFilters => ({ ...currentFilters, pageNumber: pageNumber + 1 }));
    }
  };

  const contractUserIds = fullContractList.map(c =>
    c.buyerAdditionalDetails?.sellerId ? c.buyerAdditionalDetails.sellerId : c.sellerAdditionalDetails?.buyerId,
  );
  const uniqContractUserIds = [...new Set(contractUserIds)];
  const relevantConnections = uniqContractUserIds
    .map(userId => connections.find(c => c.otherUser?.userId == userId))
    .filter((conn): conn is IConnection => conn !== undefined);

  const isFilterApplied = useMemo(() => !!filters?.status || !!filters?.userId, [filters?.status, filters?.userId]);

  return (
    <StyledMainContainer>
      <Grid container>
        <StyledHeadingGrid container item xs={12} gap="8px" alignItems="center">
          <Typography variant={isMobileScreen ? 'h5' : 'h4'}>Contracts</Typography>

          {isMobileScreen && (
            <FilterIconButton isFilterApplied={isFilterApplied} onClick={() => setMobileDrawer(true)} />
          )}
        </StyledHeadingGrid>

        <Grid item xs={12}>
          <ContractHistory
            contracts={contracts}
            onNextPage={handleNextPage}
            onFilterChange={handleFilterChange}
            loading={loading}
            connections={relevantConnections}
            mobileDrawer={mobileDrawer}
            setMobileDrawer={setMobileDrawer}
          />
        </Grid>
      </Grid>
    </StyledMainContainer>
  );
};

export default ViewContracts;
