import { Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { PackageType } from 'global/enums/packageType';
import { FormikProps } from 'formik';
import { ICreateContractForm } from 'global/interfaces/contract';
import { useEffect, useState } from 'react';
import { nameof } from 'ts-simple-nameof';
import { IChatRecipient } from 'global/interfaces/chatThread';
import FormSelect from '../Select/FormSelect';
import { SelectItem } from 'global/interfaces/selects';
import { RoundButton } from '../Button/RoundButton';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { StyledLoadingButton } from 'components/payouts/payoutHandler';

export interface IContractFixedProps extends FormikProps<ICreateContractForm> {
  next: () => void;
  loading: boolean;
  handleCancel: () => void;
  recipients: IChatRecipient[];
}

export const TeamChatSelectUser: React.FC<IContractFixedProps> = ({
  next,
  loading,
  handleCancel,
  recipients,
  ...form
}) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    form.setFieldValue(
      nameof<ICreateContractForm>(x => x.name),
      PackageType[0],
    );
  }, []);

  const items: SelectItem[] = [
    { id: '', label: 'None' },
    ...recipients.map(r => ({ id: r.userId, label: r.displayName })),
  ];

  const handleOnNext = () => {
    if (form.values.otherUserId) {
      setShowError(false);
      next();
    } else {
      setShowError(true);
    }
  };

  return (
    <>
      <StyledDialogTitle>
        <Stack direction="row" gap={1.5} alignItems="center">
          <Typography variant="h6" color={theme.palette.grey[900]}>
            Recipient
          </Typography>
        </Stack>
      </StyledDialogTitle>

      <StyledDialogContent dividers>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <FormSelect
            items={items}
            value={form.values.otherUserId ?? ''}
            onChange={form.handleChange}
            label="Recipient"
            name="otherUserId"
            error={showError}
            helpertext={showError ? 'Contract recipient is required.' : ''}
            sx={{ mb: 2 }}
          />
        </Grid>
      </StyledDialogContent>

      <StyledDialogActions>
        <RoundButton fullWidth={isMobileScreen} variant="outlined" onClick={() => handleCancel()}>
          Cancel
        </RoundButton>
        <StyledLoadingButton fullWidth={isMobileScreen} loading={loading} variant="contained" onClick={handleOnNext}>
          Next
        </StyledLoadingButton>
      </StyledDialogActions>
    </>
  );
};
