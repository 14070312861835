import { styled, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import blackShoutt from 'assets/images/blackShoutt.svg';
import { CenteredBox } from './Containers';

interface StyledBoxProps {
  backgroundColor?: string;
  backgroundImage?: string;
}

const StyledBannerBox = styled(Box, {
  shouldForwardProp: prop => !['backgroundColor', 'backgroundImage'].includes(prop as string),
})<StyledBoxProps>(({ backgroundColor, backgroundImage, theme }) => ({
  background: backgroundImage
    ? `url(${backgroundImage}) no-repeat center center / cover`
    : backgroundColor
    ? backgroundColor
    : theme.palette.blue[200],
}));

const ShouttImage = styled('img')(({ theme }) => ({
  width: '450px',
  objectFit: 'contain',
  marginBottom: '16px',

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const BannerStack = styled(Stack)(({ theme }) => ({
  paddingTop: '48px',
  paddingBottom: '48px',

  [theme.breakpoints.up('md')]: {
    paddingTop: '80px',
    paddingBottom: '80px',
  },
}));

interface TemplateProps {
  children: React.ReactNode;
  bannerContent: JSX.Element;
  backgroundColor?: string;
  backgroundImage?: string;
}

export default function Template(props: TemplateProps): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <StyledBannerBox backgroundColor={props.backgroundColor} backgroundImage={props.backgroundImage}>
        <Container maxWidth="lg">
          <BannerStack direction={'column'}>
            <CenteredBox>
              <ShouttImage alt="Shoutt" src={blackShoutt} />
            </CenteredBox>
            {props.bannerContent}
          </BannerStack>
        </Container>
      </StyledBannerBox>

      <Box pb={isMobile ? '60px' : '120px'}>
        <Container maxWidth="lg">
          <Stack
            alignItems="flex-start"
            justifyContent={'center'}
            direction={isMobile ? 'column' : 'row'}
            pt={isMobile ? '46px' : '56px'}
          >
            <Box>{props.children}</Box>
          </Stack>
        </Container>
      </Box>
    </>
  );
}
