import { MainSectionBox } from './MainSectionBox';
import { Box, Button, CircularProgress, Grow, IconButton, Stack, styled, Typography, useTheme } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ChatType, IChatUserThread } from 'global/interfaces/chatThread';
import { getThreads } from 'services/chatThreadService';
import { showError } from 'utils/errorHandler';
import { UserAvatar } from 'components/common/UserAvatar';
import { MessageIcon } from 'components/icon/MessageIcon';
import { useIsComponentMounted } from 'global/hooks/useIsComponentMounted';

const JustifiedBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

export default function ItemConnections() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [chatThreads, setChatThreads] = useState<IChatUserThread[]>([]);
  const isComponentMounted = useIsComponentMounted();

  useEffect(() => {
    loadConnections();
  }, []);

  const loadConnections = () => {
    if (isComponentMounted.current) {
      setLoading(true);
      setChatThreads([]);
    }

    getThreads()
      .then((res: IChatUserThread[]) => {
        res = res.filter(p => p.type == ChatType.OneToOne && p.active);
        if (res.length > 5) res = res.slice(0, 5);
        if (isComponentMounted.current) {
          setChatThreads(res);
          setLoading(false);
        }
      })
      .catch(showError);
  };
  return (
    <MainSectionBox>
      <JustifiedBox>
        <Stack direction="row" spacing={1.5} mb={1.0}>
          <Typography variant="subtitle1" fontWeight={600}>
            Recent Connections
          </Typography>
        </Stack>
        {!loading && chatThreads.length > 0 && (
          <Stack direction="row" spacing={2} mb={1.0}>
            <Button
              variant="text"
              sx={{ color: theme.palette.primary.dark, mr: '-6px' }}
              endIcon={<ArrowForward />}
              onClick={() => navigate(`/network/connections`)}
            >
              View all
            </Button>
          </Stack>
        )}
      </JustifiedBox>
      {loading && (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <CircularProgress />
        </Box>
      )}
      {!loading && chatThreads.length === 0 && (
        <Box display={'flex'}>
          <Stack direction="column" spacing={2} mb={2} mt={1}>
            <Typography variant="body2">You don&apos;t have any connections!</Typography>
          </Stack>
        </Box>
      )}
      {!loading && chatThreads.length > 0 && (
        <Grow in={true} timeout={500}>
          <Box>
            {chatThreads.map((chatThread, index: number) => (
              <Stack key={index} direction="column" spacing={1.5} mt={0.5} mb={1}>
                <JustifiedBox>
                  <FlexBox>
                    <UserAvatar
                      sx={{ width: 30, height: 30, fontSize: '0.75rem' }}
                      userId={chatThread.otherUserId}
                      displayName={chatThread.displayName}
                    />
                    <Typography
                      variant="subtitle2"
                      title={chatThread.displayName}
                      ml={1.5}
                      style={{ cursor: 'pointer' }}
                    >
                      {chatThread.displayName}
                    </Typography>
                  </FlexBox>
                  <FlexBox>
                    <IconButton onClick={() => navigate(`/workroom/${chatThread.chatThreadId}`)}>
                      <MessageIcon />
                    </IconButton>
                  </FlexBox>
                </JustifiedBox>
              </Stack>
            ))}
          </Box>
        </Grow>
      )}
    </MainSectionBox>
  );
}
