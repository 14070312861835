import { useTheme } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

interface IStripeElementsProps {
  children: React.ReactNode;
}

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripe = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

function StripeElements(props: IStripeElementsProps): JSX.Element {
  const theme = useTheme();

  const options = {
    // Fully customizable with appearance API.
    appearance: {
      variables: {
        fontSizeBase: '16px',
        borderRadius: '12px',
        fontFamily: 'sans-serif',
        // See all possible variables below
      },
      rules: {
        '.Input': {
          boxShadow: 'none',
          color: theme.palette.grey[900],
        },
      },
    },
  };

  return (
    <Elements stripe={stripe} options={options}>
      {props.children}
    </Elements>
  );
}

export default StripeElements;
