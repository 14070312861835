import { Box, Grid, Stack, Typography, styled } from '@mui/material';
import { PaddedBox } from './UserProfileHeadSection/UserProfileHeadSection';

import { getNameFromUrl, parseAsRelativeUrl } from 'utils/url';
import { Link } from 'react-router-dom';
import { LogoAdornmentHandler } from 'components/common/LogoAdornmentHandler';

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.grey[600],
  textDecoration: 'none',
}));

const StackContainer = styled(Stack)(({ theme }) => ({
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(3),
  gap: theme.spacing(2),

  svg: {
    marginBottom: '-4px',
  },

  [theme.breakpoints.down('md')]: {
    paddingInline: '16px',
  },
}));

export function UserPersonalLinks({ links }: { links: string[] }) {
  return (
    <Box mt={4}>
      <PaddedBox>
        <Typography variant="h6">Links</Typography>{' '}
      </PaddedBox>
      <StackContainer>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" rowSpacing={1} gap={3}>
          {links.map((link, index) => (
            <StyledLink key={index} to={parseAsRelativeUrl(link)} target="_blank">
              <Stack direction="row" gap={1.25}>
                <LogoAdornmentHandler url={parseAsRelativeUrl(link)} /> {getNameFromUrl(link)}
              </Stack>
            </StyledLink>
          ))}
        </Grid>
      </StackContainer>
    </Box>
  );
}
