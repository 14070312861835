import { styled, useTheme } from '@mui/material/styles';

import { useLocation } from 'react-router-dom';
import { Box, Container, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

import { BannerStack, FeatureSubText, FeatureText, MultiLineFeatureContainer } from 'components/info/Hero';
import { desktopHeaderHeight, mobileHeaderHeight } from 'global/Constants/HeaderFooterConstants';
import UserSearch from 'components/common/Search/UserSearch';
import { ClockIcon } from 'components/icon/ClockIcon';

import im from 'assets/images/lps/Hero_talent-min.webp';

import vettedtal from 'assets/images/lps/vettedtalent.webp';
import { ExclusiveIcon } from 'components/icon/ExclusiveIcon';
import { BriefIcon } from 'components/icon/BriefIcon';

const StyledBannerBox = styled(Box)(() => ({
  backgroundImage: `url(${im})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

const StyledImage = styled('img')(({ theme }) => ({
  width: '100%',
  maxWidth: '490px',
  [theme.breakpoints.down('md')]: {
    marginTop: '20px',
  },
}));

const HeaderBanner = () => {
  const location = useLocation();
  const showHeaderGraphic = location.pathname.toLowerCase() === '/talent';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      {showHeaderGraphic && (
        <>
          {isMobile && (
            <Box mb="12px" mt={`${mobileHeaderHeight + 12}px`} paddingX="16px">
              <UserSearch />
            </Box>
          )}
          <StyledBannerBox marginTop={!isMobile ? `${desktopHeaderHeight}px` : '0px'}>
            <Container maxWidth="lg">
              <BannerStack direction={'column'} rowGap={2}>
                <Grid container columnSpacing={6}>
                  <Grid item xs={12} md={7}>
                    <Typography
                      variant="h3"
                      fontWeight={700}
                      fontSize={{ xs: '26px', md: '44px', lg: '55px' }}
                      color={'#101828'}
                      maxWidth={'800px'}
                    >
                      Quality freelance talent for you. Vetted by us.
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize={{ xs: '16px', lg: '20px' }}
                      color={'#344054'}
                      maxWidth={'800px'}
                      mt={isMobile ? 2 : 3}
                    >
                      We know that serious businesses have no time waste on sifting through profiles and boilerplate
                      pitches. Browse, chat and find talent with ease.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <StyledImage src={vettedtal} />
                  </Grid>
                </Grid>
                <Grid container mt={{ xs: '0px', md: '0px' }} spacing={isMobile ? '14px' : '22px'}>
                  <Grid item xs={12} md={4}>
                    <MultiLineFeatureContainer direction={'row'}>
                      <Stack direction={'column'} gap={'4px'} spacing={1}>
                        <Stack direction={'row'} spacing={1}>
                          <ClockIcon
                            sx={{
                              width: !isMobile ? '24px' : '20px',
                              height: !isMobile ? '24px' : '20px',
                            }}
                          />
                          <FeatureText>10x Faster Hiring</FeatureText>
                        </Stack>

                        <FeatureSubText>
                          Post an opportunity or browse quality talent that has been pre-screened by us. No messing
                          about!
                        </FeatureSubText>
                      </Stack>
                    </MultiLineFeatureContainer>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MultiLineFeatureContainer direction={'row'}>
                      <Stack direction={'column'} gap={'4px'} spacing={1}>
                        <Stack direction={'row'} spacing={1}>
                          <BriefIcon
                            sx={{
                              width: !isMobile ? '24px' : '20px',
                              height: !isMobile ? '24px' : '20px',
                            }}
                          />
                          <FeatureText>100% Commission Free</FeatureText>
                        </Stack>

                        <FeatureSubText>
                          No more large commissions! All transactions are 100% commission free for both parties.
                        </FeatureSubText>
                      </Stack>
                    </MultiLineFeatureContainer>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <MultiLineFeatureContainer direction={'row'} height={'100%'}>
                      <Stack direction={'column'} gap={'4px'} spacing={1}>
                        <Stack direction={'row'} spacing={1}>
                          <ExclusiveIcon
                            sx={{
                              width: !isMobile ? '24px' : '20px',
                              height: !isMobile ? '24px' : '20px',
                            }}
                          />
                          <FeatureText>Payment Protection</FeatureText>
                        </Stack>

                        <FeatureSubText>
                          Take advantage of our invoice, payment protection and contract tools. Or deal direct. It’s
                          your choice.
                        </FeatureSubText>
                      </Stack>
                    </MultiLineFeatureContainer>
                  </Grid>
                </Grid>
              </BannerStack>
            </Container>
          </StyledBannerBox>
        </>
      )}
    </>
  );
};

export default HeaderBanner;
