import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IChatUserThread } from 'global/interfaces/chatThread';
import { getDisputesMessages, getMessages, markMessagesAsRead, newTextMessage } from 'services/chatMessageService';
import * as React from 'react';
import { ChatMessageType, IChatMessageDisplay, IChatTyping } from 'global/interfaces/chatMessage';
import { showError, showErrorMessage } from 'utils/errorHandler';
import List from '@mui/material/List';
import { AuthContext } from 'contexts/AuthContext';
import { Editor as TinyMCEEditor } from 'tinymce';
import { InView } from 'react-intersection-observer';
import { useCustomEventListener } from 'react-custom-events';
import { ConnectionRestarted, MessagePublisherEventType } from 'global/enums/messagePublisherEventType';
import ChatMessageText from 'components/workRoom/chatMessageText';
import ChatMessageMedia from 'components/workRoom/media/ChatMessageMedia';
import ChatMessagePayment from 'components/workRoom/chatMessagePayment';
import ChatMessagePaymentSuccessful from 'components/workRoom/chatMessagePaymentSuccessful';
import UploadFilesDialog from 'components/workRoom/UploadFilesDialog';
import ChatMessagReview from 'components/workRoom/chatMessageReview';
import ChatMessage, {
  FlexBox,
  MessageTiteTypography,
  StyledAvatar,
  StyledListItem,
  StyledListItemAvatar,
  TimeTypography,
} from 'components/workRoom/chatMessage';
import ChatMessageReviewComplete from 'components/workRoom/chatMessageReviewComplete';
import ChatMessageContractComplete from 'components/workRoom/chatMessageContractComplete';
import SendDeliverablesDrawer from 'components/workRoom/SendDeliverablesDrawer';
import ChatMessageContractCancelled from 'components/workRoom/chatMessageContractCancelled';
import { Chip, CircularProgress, Divider, ListItemText, styled } from '@mui/material';
import MessagesSectionHeader from './messagesSectionHeader';
import MessagesSectionFooter from './messagesSectionFooter';
import { updateMessage } from './updateMessageDisplay';
import { BouncingLoaderIcon } from 'components/icon/BouncingLoaderIcon';
import ChatMessageConnectionAccepted from './chatMessageConnectionAccepted';
import ChatMessageConnectionRemoved from './chatMessageConnectionRemoved';
import { useContext, useRef, useState } from 'react';
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import { IContract } from 'global/interfaces/contract';
import ChatMessageConnectionAutoAccepted from './chatMessageConnectionAutoAccepted';
import ChatMessageFeedback from './chatMessageFeedback';
import ChatMessageContractRefunded from './chatMessageContractRefunded';
import ChatMessagePaymentFailed from './chatMessagePaymentFailed';
import ChatMessagAcceptContract from './chatMessageAcceptContract';
import ChatMessageContractAccepted from './chatMessageContractAccepted';
import ChatMessageContractDeclined from './chatMessageContractDeclined';
import ChatMessageConnectionInvitationAccepted from './chatMessageConnectionInvitationAccepted';

const MessageContentBox = styled(Box)(({ theme }) => ({
  height: '100%',
  overflowY: 'auto',
  borderLeft: `1px solid ${theme.palette.grey[200]}`,
  borderRight: `1px solid ${theme.palette.grey[200]}`,
  overflowWrap: 'break-word',
  p: {
    marginBlockStart: '0px',
    marginBlockEnd: '0px',
  },
  backgroundColor: theme.palette.common.white,
  '&::-webkit-scrollbar': {
    height: '1px',
    width: '4px',
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#8f8f8f',
    '-webkit-border-radius': '1ex',
  },
  '&::-webkit-scrollbar-corner': {
    background: 'inherit',
  },

  [theme.breakpoints.down('md')]: {
    border: 'none',
  },
}));

const DateDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.grey[200],
  margin: '8px 12px 6px 12px',
}));

const StyledList = styled(List)(() => ({
  paddingBottom: '0px',
}));

interface IMessagesSectionProps {
  selectedThread: IChatUserThread | undefined;
  isRightSectionCollapsed: boolean;
  handleLeftClick: () => void;
  handleRightClick: () => void;
  handleRightCollapseClick: () => void;
  teams: IChatUserThread[];
  isTyping: boolean;
  setIsTyping: (isTyping: boolean) => void;
  hasActiveSellingContract: boolean;
  isAdminScreen?: boolean;
  onCreateContractButtonClick: (isCreating: boolean) => void;
  isCreatingContract: boolean;
  haveContracts: boolean;
  addMemberDialogOpen?: boolean;
  setAddMemberDialogOpen?: React.Dispatch<boolean>;
  setSelectedThread?: React.Dispatch<React.SetStateAction<IChatUserThread | undefined>>;
  contracts: IContract[];
}

export default function MessagesSection(props: IMessagesSectionProps): JSX.Element {
  const [initialValue, setInitialValue] = useState<string | undefined>('');
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const messagesEndRef = useRef<Element | null>();
  const uploadRef = useRef<HTMLInputElement>(null);
  const authContext = useContext(AuthContext);
  const [messages, setMessages] = useState<IChatMessageDisplay[]>([]);
  const [loadingCompleted, setLoadingCompleted] = useState<boolean>(false);

  const [openSendDeliverablesForApproval, setOpenSendDeliverablesForApproval] = useState<boolean>(false);

  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);

  const [typingRecipient, setTypingRecipient] = useState<IChatTyping>();
  const [isFirstTyping, setIsFirstTyping] = useState<boolean>(true);
  const [typingThreadId, setTypingThreadId] = useState<string | undefined>(undefined);

  const { isAway } = useContext(GlobalStateContext);
  const [messagesAway, setMessagesAway] = useState<string[]>([]);

  // Start loading the previous page 10 items before we hit the top
  const infiniteItemsOffset = 10;
  // Page Size of 50 messages
  const numberOfItemsPerPage = 50;
  const [hasMorePages, setHasMorePages] = useState<boolean>(false);

  const [lastIndexOfNewPage, setLastIndexOfNewPage] = useState<number>(-1);
  const lastElementOfNewPageRef = useRef<Element | null>();

  const addTemporaryTextMessage = (tempId: string, content: string, chatThreadId: string): void => {
    const tempMessage: IChatMessageDisplay = {
      id: tempId,
      userId: authContext.user?.id ?? '',
      chatThreadId,
      userDisplayName: `${authContext.user?.firstName ?? ''} ${authContext.user?.lastName ?? ''}`,
      createdDateTime: new Date().toUTCString(),
      content,
      messageType: ChatMessageType.Text,
      files: [],
      actioned: false,
      isTemporary: true,
    };

    setMessages(current => {
      const lastDate = current.length > 0 ? current[current.length - 1].date : '';
      updateMessage(tempMessage, lastDate ?? '', authContext, props.selectedThread);
      return [...current, tempMessage];
    });

    // Scroll to bottom to see new message and set focus again for the next message
    setInitialValue('');
    editorRef.current?.focus();
    scrollToBottom();
    if (uploadRef.current) {
      uploadRef.current.value = '';
    }
    setSelectedFiles(null);
  };

  const onSendClick = (): void => {
    const content = editorRef.current?.getContent();

    if (props.selectedThread?.chatThreadId !== undefined && content !== undefined && content !== '') {
      setInitialValue(content);
      const tempId = new Date().getTime().toString(36);
      const newMessage = content ?? '';
      const chatThreadId = props.selectedThread?.chatThreadId ?? '';

      // Add temporary message immediately to the message window.
      // This is for a good UX, immediate feedback to the user!
      addTemporaryTextMessage(tempId, newMessage, chatThreadId);

      // Submit message to server and replace temporary message
      newTextMessage(chatThreadId, newMessage, props.isAdminScreen ? props.selectedThread.contractId : undefined)
        .then((newMessage: IChatMessageDisplay) => {
          // Update temporary message
          setMessages(currentMessages => {
            const nonTempMessages = currentMessages.filter(x => !x.isTemporary);
            const lastDate = nonTempMessages.length > 0 ? nonTempMessages[nonTempMessages.length - 1].date : '';
            updateMessage(newMessage, lastDate ?? '', authContext, props.selectedThread);
            return currentMessages.map(msg => (msg.id == tempId ? newMessage : msg));
          });

          //Set typing back to being the first time typing
          setIsFirstTyping(true);
        })
        .catch(() => {
          setMessages(currentMessages =>
            currentMessages.map(msg => (msg.id == tempId ? { ...msg, error: 'Failed to send.' } : msg)),
          );
        });
    } else {
      if (props.selectedThread?.chatThreadId === undefined) {
        showErrorMessage('No Chat Thread Id. Please contact support');
      }
      editorRef.current?.focus();
    }
  };

  const updateMessages = (newMessages: IChatMessageDisplay[]): void => {
    let lastDate = '';
    for (const m of newMessages) {
      lastDate = updateMessage(m, lastDate, authContext, props.selectedThread);
    }
    setMessages(prevMessages => {
      const lastMsgFromPrev = prevMessages[prevMessages.length - 1];
      const lastMsgIndexFromNew = newMessages.findIndex(m => m.id == lastMsgFromPrev?.id);
      const messagesDiff =
        lastMsgIndexFromNew < 0 ? newMessages : newMessages.slice(lastMsgIndexFromNew + 1, newMessages.length);
      return [...prevMessages, ...messagesDiff];
    });
    setHasMorePages(newMessages.length === numberOfItemsPerPage);
    scrollToBottom();
    setLoadingCompleted(true);
  };

  const loadMoreMessages = (inView: boolean): void => {
    if (inView && hasMorePages) {
      setLoadingCompleted(false);
      const skip = messages.length;
      const chatThreadId: string = props.selectedThread?.chatThreadId ?? '';
      getMessages(chatThreadId, skip, numberOfItemsPerPage)
        .then((res: IChatMessageDisplay[]) => {
          setMessages(current => {
            const newArray = [...res, ...current];
            let lastDate = '';
            for (const m of newArray) {
              lastDate = updateMessage(m, lastDate, authContext, props.selectedThread);
            }
            return newArray;
          });
          setHasMorePages(res.length === numberOfItemsPerPage);
          setLoadingCompleted(true);
          // Need to set the scroll position so the content doesn't jump
          setLastIndexOfNewPage(res.length - 1 + infiniteItemsOffset);
          lastElementOfNewPageRef?.current?.scrollIntoView(true);
        })
        .catch(showError);
    }
  };

  const scrollToBottom = (): void => {
    setTimeout(() => {
      messagesEndRef?.current?.scrollIntoView();
    }, 200);
  };

  const setEditorRef = (editor: TinyMCEEditor): void => {
    editorRef.current = editor;
  };

  const handleUploadFilesClose = (): void => {
    setTimeout(() => {
      editorRef.current?.focus();
    }, 200);
    if (uploadRef.current) {
      uploadRef.current.value = '';
    }
    setSelectedFiles(null);
  };

  const onUploadClick = (): void => {
    uploadRef.current?.click();
  };

  const onOpenDeliverablesForApprovalClick = (): void => {
    setOpenSendDeliverablesForApproval(true);
  };

  const onCloseDeliverablesForApproval = (): void => {
    setOpenSendDeliverablesForApproval(false);
    setTimeout(() => {
      editorRef.current?.focus();
    }, 200);
  };

  const onSelectFiles = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const files: FileList | null = e.target.files;
    setSelectedFiles(files);
  };

  const loadMessages = (reset = true) => {
    const chatThreadId: string = props.selectedThread?.chatThreadId ?? '';
    if (reset) {
      if (uploadRef.current) {
        uploadRef.current.value = '';
      }
      setSelectedFiles(null);
      setLastIndexOfNewPage(-1);
      setHasMorePages(false);
      setMessages([]);
      setLoadingCompleted(false);
    }
    if (chatThreadId !== '') {
      if (!props.isAdminScreen) {
        getMessages(chatThreadId, 0, numberOfItemsPerPage)
          .then((newMessages: IChatMessageDisplay[]) => {
            updateMessages(newMessages);
            markMessagesAsRead(props.selectedThread?.chatThreadId ?? '');
          })
          .catch(showError);
      } else {
        if (props.selectedThread?.disputeRaisedById === undefined) {
          showErrorMessage('Something went wrong');
          throw 'Dispute raised by user id cannot be empty';
        }

        getDisputesMessages(chatThreadId, 0, numberOfItemsPerPage, props.selectedThread!.disputeRaisedById)
          .then((res: IChatMessageDisplay[]) => {
            updateMessages(res);
          })
          .catch(showError);
      }
    }
  };

  React.useEffect(() => {
    loadMessages();
  }, [props.selectedThread, authContext.user]);

  useCustomEventListener(
    ConnectionRestarted,
    () => {
      loadMessages(false);
    },
    [props.selectedThread, authContext.user],
  );

  React.useEffect(() => {
    if (!isAway && messagesAway.length > 0) {
      messagesAway.forEach(x => markMessagesAsRead(x));
      setMessagesAway([]);
    }
  }, [isAway]);

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.NewMessage],
    (newMsg: IChatMessageDisplay) => {
      if (newMsg.chatThreadId === props.selectedThread?.chatThreadId) {
        setMessages(currentMessages => {
          const messageExists =
            (newMsg.userId == authContext.user?.id &&
              currentMessages.some(msg => msg.isTemporary && msg.content == newMsg.content)) ||
            currentMessages.some(msg => msg.id == newMsg.id);
          if (messageExists) {
            return currentMessages;
          } else {
            const lastDate = currentMessages.length > 0 ? currentMessages[currentMessages.length - 1].date : '';
            updateMessage(newMsg, lastDate ?? '', authContext, props.selectedThread);
            return [...currentMessages, newMsg];
          }
        });
        scrollToBottom();
        if (newMsg.userId !== authContext.user?.id) {
          if (!isAway) {
            setTimeout(() => {
              markMessagesAsRead(newMsg.chatThreadId);
            }, 500);
          } else {
            setMessagesAway(messages => [...messages, newMsg.chatThreadId]);
          }
        }
      }
    },
    [props.selectedThread, authContext.user, isAway],
  );

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.UpdateMessage],
    (res: IChatMessageDisplay) => {
      if (res.chatThreadId === props.selectedThread?.chatThreadId) {
        setMessages(current => {
          const lastDateIndex = current.findIndex(p => p.id === res.id) - 1;
          const lastDate = lastDateIndex > 0 ? current[lastDateIndex].date : '';
          updateMessage(res, lastDate ?? '', authContext, props.selectedThread);
          return current.map(item => (item.id === res.id ? res : item));
        });
      }
    },
    [props.selectedThread, authContext.user],
  );

  useCustomEventListener(MessagePublisherEventType[MessagePublisherEventType.UserTyping], (res: IChatTyping) => {
    scrollToBottom();
    setTypingRecipient(res);
    props.setIsTyping(true);
    setTypingThreadId(res.id);

    setTimeout(() => {
      setTypingRecipient(res);
      props.setIsTyping(false);
      setTypingRecipient(undefined);
      setTypingThreadId(undefined);
    }, 30000);
  });

  const typingDisplayName = typingRecipient?.typingDisplayName ?? '';

  return (
    <Stack direction="column" spacing={0} height="100%">
      <MessagesSectionHeader
        selectedThread={props.selectedThread}
        handleLeftClick={props.handleLeftClick}
        handleRightClick={props.handleRightClick}
        handleRightCollapseClick={props.handleRightCollapseClick}
        isRightSectionCollapsed={props.isRightSectionCollapsed}
        teams={props.teams}
        isCreatingContract={props.isCreatingContract}
        onCreateContractButtonClick={props.onCreateContractButtonClick}
        haveContracts={props.haveContracts}
        addMemberDialogOpen={props.addMemberDialogOpen as boolean}
        setAddMemberDialogOpen={props.setAddMemberDialogOpen as React.Dispatch<boolean>}
        setSelectedThread={props.setSelectedThread as React.Dispatch<React.SetStateAction<IChatUserThread | undefined>>}
      />
      <MessageContentBox>
        {props.selectedThread !== undefined && (
          <>
            {!loadingCompleted && messages.length === 0 && (
              <Box display={'flex'} width={'100%'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
                <CircularProgress />
              </Box>
            )}

            {messages.length !== 0 && (
              <StyledList>
                {messages.map((item: IChatMessageDisplay, i: number) => (
                  <React.Fragment key={i}>
                    {(item.firstDate ?? false) && (
                      <DateDivider>
                        <Chip size="small" label={<Typography variant="caption">{item.date}</Typography>}></Chip>
                      </DateDivider>
                    )}
                    {/* This next list item is to control the infinite scrolling
                     When this comes into view we will load another page
                */}
                    {loadingCompleted && i === 10 && (
                      <InView as="div" onChange={inView => loadMoreMessages(inView)}></InView>
                    )}
                    {loadingCompleted && i === lastIndexOfNewPage && <Box ref={lastElementOfNewPageRef} />}
                    <ChatMessage {...item}>
                      {item.messageType === ChatMessageType.Text && <ChatMessageText {...item} />}
                      {item.messageType === ChatMessageType.Payment && (
                        <ChatMessagePayment {...item} currentUserId={authContext.user?.id} />
                      )}
                      {item.messageType === ChatMessageType.PaymentSuccessful && (
                        <ChatMessagePaymentSuccessful {...item} currentUserId={authContext.user?.id} />
                      )}
                      {item.messageType === ChatMessageType.Upload && (
                        <ChatMessageMedia {...item} isRightSectionCollapsed={props.isRightSectionCollapsed} />
                      )}
                      {item.messageType === ChatMessageType.Review && (
                        <ChatMessagReview {...item} user={authContext.user} />
                      )}
                      {item.messageType === ChatMessageType.ReviewComplete && (
                        <ChatMessageReviewComplete {...item} currentUserId={authContext.user?.id} />
                      )}
                      {item.messageType === ChatMessageType.ContractComplete && (
                        <ChatMessageContractComplete {...item} />
                      )}
                      {item.messageType === ChatMessageType.ContractCancelled && (
                        <ChatMessageContractCancelled {...item} />
                      )}
                      {item.messageType === ChatMessageType.ConnectionAccepted && (
                        <ChatMessageConnectionAccepted {...item} currentUserId={authContext.user?.id} />
                      )}
                      {item.messageType === ChatMessageType.ConnectionRemoved && (
                        <ChatMessageConnectionRemoved {...item} currentUserId={authContext.user?.id} />
                      )}
                      {item.messageType === ChatMessageType.ConnectionAutoAccepted && (
                        <ChatMessageConnectionAutoAccepted {...item} currentUserId={authContext.user?.id} />
                      )}
                      {item.messageType === ChatMessageType.NewReviewAdded && <ChatMessageFeedback {...item} />}
                      {item.messageType === ChatMessageType.ContractRefunded && (
                        <ChatMessageContractRefunded {...item} currentUserId={authContext.user?.id} />
                      )}
                      {item.messageType === ChatMessageType.PaymentFailed && (
                        <ChatMessagePaymentFailed {...item} currentUserId={authContext.user?.id} />
                      )}
                      {item.messageType === ChatMessageType.AcceptContract && (
                        <ChatMessagAcceptContract {...item} user={authContext.user} />
                      )}
                      {item.messageType === ChatMessageType.ContractAccepted && (
                        <ChatMessageContractAccepted {...item} />
                      )}
                      {item.messageType === ChatMessageType.ContractDeclined && (
                        <ChatMessageContractDeclined {...item} />
                      )}
                      {item.messageType === ChatMessageType.InvitationAccepted && (
                        <ChatMessageConnectionInvitationAccepted {...item} currentUserId={authContext.user?.id} />
                      )}
                    </ChatMessage>
                  </React.Fragment>
                ))}
                {props.isTyping && props.selectedThread.chatThreadId === typingThreadId && (
                  <StyledListItem alignItems="flex-start" isSystemMessage={false}>
                    <StyledListItemAvatar>
                      <StyledAvatar displayName={typingDisplayName} userId={typingRecipient?.typingUserId} />
                    </StyledListItemAvatar>
                    <ListItemText
                      primary={
                        <React.Fragment>
                          <FlexBox>
                            <MessageTiteTypography variant="subtitle2" isSystemMessage={false}>
                              {typingDisplayName}
                            </MessageTiteTypography>
                            <TimeTypography variant="caption">
                              {new Date().toLocaleTimeString('en-Us', {
                                hour12: true,
                                hour: 'numeric',
                                minute: 'numeric',
                              })}
                            </TimeTypography>
                          </FlexBox>
                        </React.Fragment>
                      }
                      secondary={<BouncingLoaderIcon width={80} height={80} />}
                      secondaryTypographyProps={{ component: 'div' }}
                    />
                  </StyledListItem>
                )}
              </StyledList>
            )}
          </>
        )}
        <Box ref={messagesEndRef} />
        <input ref={uploadRef} onChange={onSelectFiles} hidden accept="*" multiple type="file" />
      </MessageContentBox>

      <MessagesSectionFooter
        selectedThread={props.selectedThread}
        initialValue={initialValue}
        scrollToBottom={scrollToBottom}
        setEditorRef={setEditorRef}
        onSendClick={onSendClick}
        onUploadClick={onUploadClick}
        onOpenDeliverablesForApprovalClick={onOpenDeliverablesForApprovalClick}
        setIsFirstTyping={setIsFirstTyping}
        isFirstTyping={isFirstTyping}
        hasActiveSellingContract={props.hasActiveSellingContract}
        isAdminScreen={props.isAdminScreen}
      />

      <UploadFilesDialog
        selectedThread={props.selectedThread}
        user={authContext.user}
        selectedFiles={selectedFiles}
        handleUploadFilesClose={handleUploadFilesClose}
      />

      <SendDeliverablesDrawer
        open={openSendDeliverablesForApproval}
        selectedThread={props.selectedThread}
        user={authContext.user}
        handleClose={onCloseDeliverablesForApproval}
      />
    </Stack>
  );
}
