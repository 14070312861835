import { Button, debounce, Grid, styled, SwipeableDrawer, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import { IContractFilters } from 'global/interfaces/contract';
import { IModel } from 'global/interfaces/model';
import { nameof } from 'ts-simple-nameof';
import { mapToSearchParams } from 'utils/search';
import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import FormSelect from 'components/common/Select/FormSelect';
import { IConnection } from 'global/interfaces/connection';
import { RoundButton } from 'components/common/Button/RoundButton';
import { mapConnectionsToSelect, mapContractStatusesToJson, mapContractTypesToSelect } from './contractFilterMappers';

export interface IContractFilterValue extends IModel<string> {
  userId?: string;
  status?: string;
  type?: string;
}

interface IContractFilterProps {
  onFilterChange: (filters: IContractFilters) => void;
  connections: IConnection[];
  mobileDrawer: boolean;
  setMobileDrawer: (value: boolean) => void;
}

const mapToFormProps = (searchParams: URLSearchParams): IContractFilterValue | undefined => {
  const obj = {
    userId: searchParams.get(nameof<IContractFilterValue>(p => p.userId)) ?? undefined,
    status: searchParams.get(nameof<IContractFilterValue>(p => p.status)) ?? undefined,
    type: searchParams.get(nameof<IContractFilterValue>(p => p.type)) ?? undefined,
  };
  if (obj.userId === undefined && obj.status === undefined) {
    return undefined;
  }
  return obj;
};

export const defaultFilterSelectValue = -1;

const StyledMobileGridContent = styled(Grid)(() => ({
  marginTop: 0,
  padding: '24px',
}));
const StyledMobileDrawer = styled(SwipeableDrawer)(() => ({
  '& .MuiPaper-root': {
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
  },
}));

const FilterSelect = styled(FormSelect)(({ theme }) => ({
  '&.MuiOutlinedInput-root': {
    borderRadius: '12px !important',
  },

  '& fieldset': {
    borderColor: theme.palette.grey[200],
  },
}));

export const ContractFilters: React.FC<IContractFilterProps> = ({ onFilterChange, connections, ...props }) => {
  const isMobileScreen = useMediaQuery(useTheme().breakpoints.down('md'));
  const [searchParams, setSearchParams] = useSearchParams();
  const formProps = mapToFormProps(searchParams);

  const form = useFormik<IContractFilterValue>({
    initialValues: { userId: formProps?.userId ?? '', status: formProps?.status ?? '', type: formProps?.type ?? '' },
    onSubmit: values => {
      const newSearchParams = mapToSearchParams(values);
      onFilterChange({ ...values });
      setSearchParams(newSearchParams);
    },
  });

  return (
    <>
      {isMobileScreen ? (
        <>
          <MobileContractFilters
            {...props}
            onFilterChange={onFilterChange}
            connections={connections}
            form={form}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        </>
      ) : (
        <DesktopContractFilters
          {...props}
          onFilterChange={onFilterChange}
          connections={connections}
          form={form}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      )}
    </>
  );
};

export const DesktopContractFilters: React.FC<FilterWithFormProps> = ({ connections, form }) => {
  const debouncedSubmit = useCallback(
    debounce(() => {
      form.submitForm();
    }, 200),
    [form.submitForm],
  );

  useEffect(() => {
    if (form.initialValues != form.values) {
      debouncedSubmit();
    }
  }, [debouncedSubmit, form.values]);

  return (
    <Grid container spacing={1} alignItems="center" mt={2}>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <FilterSelect
          size="small"
          name="userId"
          defaultValue={defaultFilterSelectValue}
          value={form.values.userId ?? ''}
          onChange={form.handleChange}
          label="Connection..."
          items={mapConnectionsToSelect(connections)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <FilterSelect
          size="small"
          name="status"
          defaultValue={defaultFilterSelectValue}
          value={form.values.status ?? ''}
          onChange={form.handleChange}
          label="Status"
          items={mapContractStatusesToJson()}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <FilterSelect
          size="small"
          name="type"
          defaultValue={defaultFilterSelectValue}
          value={form.values.type ?? ''}
          onChange={form.handleChange}
          label="Type"
          items={mapContractTypesToSelect()}
        />
      </Grid>
      <Grid item xs={6} sm={4} md={3} lg={3}>
        <RoundButton
          color="primary"
          variant="text"
          onClick={() => {
            form.setValues({
              userId: '',
              status: '',
            });
            form.submitForm();
          }}
          sx={{
            padding: { xs: '16px 20px', lg: '18px 36px' },
          }}
        >
          <Typography variant="subtitle2" color={'primary'}>
            Reset
          </Typography>
        </RoundButton>
      </Grid>
    </Grid>
  );
};

interface FilterWithFormProps extends IContractFilterProps {
  form: ReturnType<typeof useFormik>;
  searchParams: URLSearchParams;
  setSearchParams: (searchParams: URLSearchParams | undefined) => void;
}

const MobileContractFilters: React.FC<FilterWithFormProps> = ({ form, connections, mobileDrawer, setMobileDrawer }) => (
  <StyledMobileDrawer
    anchor="bottom"
    open={mobileDrawer}
    onClose={() => setMobileDrawer(false)}
    onOpen={() => console.log('open')}
  >
    <Grid item xs={12}>
      <StyledMobileGridContent>
        <Grid container item xs={12} justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item xs={12} container justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Filter By</Typography>
            <Button
              color="primary"
              onClick={() => {
                form.setValues({
                  userId: '',
                  status: '',
                });
                form.submitForm();
              }}
            >
              <Typography variant="subtitle2" color="primary">
                Clear filters
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <FormSelect
              name="userId"
              defaultValue={defaultFilterSelectValue}
              value={form.values.userId ?? ''}
              onChange={form.handleChange}
              label="Connection..."
              fullWidth
              items={mapConnectionsToSelect(connections)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormSelect
              name="status"
              defaultValue={defaultFilterSelectValue}
              value={form.values.status ?? ''}
              onChange={form.handleChange}
              label="Status"
              fullWidth
              items={mapContractStatusesToJson()}
            />
          </Grid>
          <Grid item xs={12}>
            <FormSelect
              name="type"
              defaultValue={defaultFilterSelectValue}
              value={form.values.type ?? ''}
              onChange={form.handleChange}
              label="Type"
              fullWidth
              items={mapContractTypesToSelect()}
            />
          </Grid>
          <Grid item xs={12} spacing={2} container justifyContent="space-between">
            <Grid item xs={6}>
              <RoundButton variant="outlined" fullWidth onClick={() => setMobileDrawer(false)}>
                Cancel
              </RoundButton>
            </Grid>
            <Grid item xs={6}>
              <RoundButton
                variant="contained"
                fullWidth
                onClick={() => {
                  form.submitForm();
                  setMobileDrawer(false);
                }}
              >
                Apply
              </RoundButton>
            </Grid>
          </Grid>
        </Grid>
      </StyledMobileGridContent>
    </Grid>
  </StyledMobileDrawer>
);
