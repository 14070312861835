import { Drawer, Grid, Typography, styled, useTheme } from '@mui/material';
import { StyledDrawerGrid } from 'components/common/StyledDrawerGrid';
import { IContractDisplay } from 'global/interfaces/contract';
import { RoundButton } from 'components/common/Button/RoundButton';
import { LoadingButton } from '@mui/lab';
import { completeContract } from 'services/contractService';
import IApiError from 'global/interfaces/api';
import { showErrorMessage } from 'utils/errorHandler';
import useProgressBar from 'global/hooks/useProgressBar';

export const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  padding: '18px 36px',
  borderRadius: theme.spacing(6),

  [theme.breakpoints.down('sm')]: {
    padding: '12px 22px',
  },
}));

export const CompleteContractDrawer: React.FC<{
  contract: IContractDisplay;
  setCompleteContractDrawer: (value: boolean) => void;
  setDrawer?: (value: boolean) => void;
  open: boolean;
}> = ({ contract, setCompleteContractDrawer, setDrawer, open }) => {
  const theme = useTheme();
  const [progress, showProgress] = useProgressBar();

  const handleCompleteContract = () => {
    setCompleteContractDrawer(false);
    showProgress(true);
    completeContract(contract.contractId)
      .then(() => {
        setDrawer && setDrawer(false);
        showProgress(false);
      })
      .catch((err: IApiError) => {
        showErrorMessage(`Could not complete contract. Please refresh the page: ${err.message}`);
      });
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={setCompleteContractDrawer}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDrawerGrid container>
          <Grid item xs={12} mb={3}>
            <Typography variant="h6" color={theme.palette.grey[900]}>
              Complete contract
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Completing the contract will release payment to the seller. Would you like to complete the contract?
            </Typography>
          </Grid>
          <Grid container mt={6} gap={2} flexWrap="nowrap">
            <RoundButton fullWidth variant="outlined" onClick={() => setCompleteContractDrawer(false)}>
              No
            </RoundButton>
            <StyledLoadingButton fullWidth variant="contained" onClick={() => handleCompleteContract()}>
              Yes
            </StyledLoadingButton>
          </Grid>
        </StyledDrawerGrid>
      </Drawer>
      {progress}
    </>
  );
};
