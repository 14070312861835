import { Container, FormControl, InputAdornment, InputLabel, OutlinedInput, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import CopyToClipboardButton from 'components/common/Button/CopyToClipboardButton';
import { useState } from 'react';
import { getSourceUrl } from 'utils/user';

export default function SourceTracking() {
  const [source, setSource] = useState<string>('');
  return (
    <Container maxWidth="sm">
      <Stack sx={{ width: '100%' }} direction="column" gap={3} marginTop={10} marginBottom={2}>
        <Typography variant="h4">Source Tracking</Typography>

        <FormControl>
          <InputLabel>Source</InputLabel>
          <OutlinedInput
            type="text"
            label="Source"
            fullWidth
            value={source}
            onChange={e => setSource(e.target.value)}
          />
        </FormControl>

        <FormControl>
          <InputLabel>Source Tracking Link</InputLabel>
          <OutlinedInput
            type="text"
            label="Source Tracking Link<"
            readOnly
            fullWidth
            value={getSourceUrl(source)}
            endAdornment={
              <InputAdornment position="end">
                <CopyToClipboardButton
                  textToCopyToClipboard={getSourceUrl(source)}
                  buttonLabel="Copy"
                  roundButton={true}
                  sx={{ maxWidth: '80px' }}
                />
              </InputAdornment>
            }
          />
        </FormControl>
      </Stack>
    </Container>
  );
}
