import { SvgIcon, SvgIconProps } from '@mui/material';

export const ReviewCompletedIcon = (props: SvgIconProps) => (
  <SvgIcon width="17" height="20" viewBox="0 0 17 20" fill="none" {...props}>
    <path
      d="M14.6667 10.4167V5.66667C14.6667 4.26654 14.6667 3.56647 14.3942 3.0317C14.1545 2.56129 13.7721 2.17884 13.3017 1.93916C12.7669 1.66667 12.0668 1.66667 10.6667 1.66667H5.33334C3.93321 1.66667 3.23315 1.66667 2.69837 1.93916C2.22796 2.17884 1.84551 2.56129 1.60583 3.0317C1.33334 3.56647 1.33334 4.26654 1.33334 5.66667V14.3333C1.33334 15.7335 1.33334 16.4335 1.60583 16.9683C1.84551 17.4387 2.22796 17.8212 2.69837 18.0609C3.23315 18.3333 3.93321 18.3333 5.33334 18.3333H8.00001M9.66668 9.16667H4.66668M6.33334 12.5H4.66668M11.3333 5.83334H4.66668M10.0833 15.8333L11.75 17.5L15.5 13.75"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
