import { Autocomplete, Box, Grid, InputAdornment, styled, TextField, Typography } from '@mui/material';
import { IClearbitResponse } from 'global/interfaces/clearbitResponse';
import { useState } from 'react';
import { getClearbitLogo } from 'services/clearbitService';

const StyledImg = styled('img')({
  width: '20px',
  height: '20px',
});

interface ICompanyAutoCompleteProps {
  onCompanyChanged: (companyResponse: IClearbitResponse) => void;
  error: boolean;
  helperText?: string;
  value: string;
  logo: string | undefined;
}

export const CompanyAutoComplete = ({
  onCompanyChanged,
  error,
  helperText,
  value,
  logo,
}: ICompanyAutoCompleteProps) => {
  const [options, setOptions] = useState<IClearbitResponse[]>([]);
  const [selectedLogo, setSelectedLogo] = useState<string | undefined>(() => logo);

  const getLogo = (inputText: string) => {
    if (!inputText) {
      return;
    }

    getClearbitLogo(inputText)
      .then(res => {
        const mappedOptions = res || [];
        const updatedOptions = mappedOptions.map((op, index) => ({
          ...op,
          id: index,
        }));
        setOptions(updatedOptions);
      })
      .catch(() => {
        setOptions([]);
      });
  };

  const handleChange = (e: React.SyntheticEvent<Element, Event>, newOption: IClearbitResponse) => {
    setSelectedLogo(newOption.logo);
    onCompanyChanged(newOption);
  };

  return (
    <Autocomplete
      freeSolo
      disableClearable
      options={options}
      value={value}
      filterOptions={filterByProperties}
      onChange={(e, option) => (typeof option === 'string' ? undefined : handleChange(e, option))}
      onInputChange={(e, value) => getLogo(value)}
      getOptionLabel={option => (typeof option === 'string' ? option : option.name!)}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
              <StyledImg src={option.logo} sx={{ marginRight: '8px' }} /> {option.name}
            </Box>
            <Box>
              <Typography variant="caption" color="grey.600" fontWeight={400}>
                {option.domain}
              </Typography>
            </Box>
          </Grid>
        </li>
      )}
      renderInput={params => (
        <TextField
          {...params}
          name="company"
          label="Company *"
          variant="outlined"
          size="medium"
          fullWidth
          error={error}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            startAdornment: selectedLogo ? (
              <InputAdornment position="start" sx={{ marginRight: '2px' }}>
                <StyledImg src={selectedLogo} />
              </InputAdornment>
            ) : undefined,
          }}
        />
      )}
    />
  );
};

function filterByProperties(options: IClearbitResponse[], { inputValue }: { inputValue: string }): IClearbitResponse[] {
  return options.filter(
    options =>
      options.name?.toLowerCase().includes(inputValue.toLocaleLowerCase()) ||
      options.domain?.toLowerCase().includes(inputValue.toLocaleLowerCase()),
  );
}
