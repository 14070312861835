import { Grid } from '@mui/material';
import { FormikProps } from 'formik';
import { IEditableUser } from 'global/interfaces/user';

import { PricingEditor } from './PricingEditor';

interface IEditSellServicesSectionProps {
  form: FormikProps<IEditableUser>;
}

export const EditSellServicesSection = ({ form }: IEditSellServicesSectionProps) => (
  <Grid container spacing={1}>
    <Grid item xs={12}>
      <PricingEditor form={form} />
    </Grid>
  </Grid>
);
