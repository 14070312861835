import { Button, styled } from '@mui/material';
import { ContractStatus, ContractType, IContractDisplay } from 'global/interfaces/contract';
import { useState } from 'react';
import { CompleteContractDrawer } from './CompleteContractDrawer';

const LinkButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.dark,
}));

interface ICompleteContractProps {
  contract: IContractDisplay;
  drawer?: boolean;
  setDrawer?: React.Dispatch<boolean>;
}

export default function CompleteContract({ contract, setDrawer }: ICompleteContractProps) {
  const [completeContractDrawer, setCompleteContractDrawer] = useState<boolean>(false);
  return (
    <>
      {contract.contractType == ContractType.FixedPrice && contract.status == ContractStatus.InProgress && (
        <LinkButton variant="text" onClick={() => setCompleteContractDrawer(true)}>
          Complete Contract
        </LinkButton>
      )}
      <CompleteContractDrawer
        contract={contract}
        open={completeContractDrawer}
        setCompleteContractDrawer={setCompleteContractDrawer}
        setDrawer={setDrawer}
      />
    </>
  );
}
