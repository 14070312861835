import {
  DialogContent,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  Stack,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { IOpportunity } from 'global/interfaces/opportunity';
import { RoundButton } from 'components/common/Button/RoundButton';

import { AuthContext } from 'contexts/AuthContext';
import { useContext, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { applyToShoutt, messagePoster, profileNotApproved } from 'services/opportunityService';
import { showError } from 'utils/errorHandler';
import { useNavigate } from 'react-router-dom';
import { VettingStatus } from 'global/enums/vettingStatus';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { pushToDataLayer } from 'utils/tagHelper';
import { appliedOpportunity } from 'global/constants';
import { UserAvatar } from 'components/common/UserAvatar';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

interface IApplyShouttOpportunity {
  onClose: () => void;
  open: boolean;
  opportunity: IOpportunity | null;
  updateApplyOnClose: () => void;
}
const ApplicationDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '30px 24px 10px !important',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  borderBottomStyle: 'none',

  [theme.breakpoints.down('sm')]: {
    padding: '20px 16px !important',
  },
}));

const StyledUserAvatar = styled(
  UserAvatar,
  {},
)(() => ({
  height: '60px',
  width: '60px',
  borderRadius: '12px',
}));

export default function ApplyShouttOpportunity(props: IApplyShouttOpportunity): JSX.Element {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (props.open && props.opportunity != null && authContext.user?.vettingStatus != VettingStatus.Approved) {
      profileNotApproved(props.opportunity.id);
    } else if (props.open && props.opportunity != null && authContext.user?.vettingStatus == VettingStatus.Approved) {
      messagePoster(props.opportunity.id);
    }
    setSuccess(false);
    form.setFieldValue('message', '');
  }, [props.opportunity, props.open]);

  const form = useFormik<{ message: string }>({
    initialValues: {
      message: '',
    },
    validationSchema: object({
      message: string().max(2000, 'Message must be at most 2000 characters').required('You must enter a message'),
    }),
    onSubmit: values => {
      setLoading(true);

      applyToShoutt({ opportunityId: props.opportunity?.id ?? '', message: values.message })
        .then(() => {
          setLoading(false);
          setSuccess(true);
          pushToDataLayer(appliedOpportunity, {
            transaction_id: props.opportunity?.id,
          });
        })
        .catch(e => {
          setLoading(false);
          showError(e);
        });
    },
  });

  return (
    <>
      {authContext.user?.vettingStatus != VettingStatus.Approved && (
        <>
          <StyledDialogTitle>
            <Typography variant="h6" color={theme.palette.grey[900]}>
              {authContext.user?.vettingStatus != VettingStatus.Rejected ? 'Get listed' : 'Profile rejected'}
            </Typography>
            <IconButton onClick={props.onClose} sx={{ marginRight: '-12px' }}>
              <CloseIcon />
            </IconButton>
          </StyledDialogTitle>
          {authContext.user?.vettingStatus === VettingStatus.NotSubmitted && (
            <>
              <StyledDialogContent dividers>
                <Typography variant="body1">
                  To apply for this opportunity you must be listed on our marketplace. This ensures that only quality
                  vetted freelancers can respond.
                </Typography>
              </StyledDialogContent>
              <StyledDialogActions>
                <RoundButton variant="outlined" fullWidth={isMobileScreen} onClick={props.onClose}>
                  Cancel
                </RoundButton>
                <RoundButton
                  variant="contained"
                  fullWidth={isMobileScreen}
                  onClick={() => navigate(`/users/${authContext.user?.id}/edit?apply=true`)}
                >
                  Get Listed
                </RoundButton>
              </StyledDialogActions>
            </>
          )}
          {authContext.user?.vettingStatus === VettingStatus.Rejected && (
            <>
              <StyledDialogContent dividers>
                <Typography variant="body1">
                  Your profile was rejected so you are not able to apply for this opportunity.
                </Typography>
              </StyledDialogContent>
              <StyledDialogActions>
                <RoundButton variant="contained" fullWidth={isMobileScreen} onClick={props.onClose}>
                  Close
                </RoundButton>
              </StyledDialogActions>
            </>
          )}
          {authContext.user?.vettingStatus === VettingStatus.InProgress && (
            <>
              <StyledDialogContent dividers>
                <Typography variant="body1">
                  You have submitted your profile for approval and it is currently being reviewed. You will be able to
                  apply for this opportunity when your profile is approved.
                </Typography>
              </StyledDialogContent>
              <StyledDialogActions>
                <RoundButton variant="contained" fullWidth={isMobileScreen} onClick={props.onClose}>
                  Close
                </RoundButton>
              </StyledDialogActions>
            </>
          )}
          {authContext.user?.vettingStatus === VettingStatus.UpdatesRequired && (
            <>
              <StyledDialogContent dividers>
                <Typography variant="body1">
                  To apply for this opportunity you must be listed on our marketplace. This ensures that only quality
                  vetted freelancers can respond to these opportunities. You have submitted for approval and have been
                  requested to make the following updates:
                </Typography>
                <Typography variant="body1" mt={2} fontStyle={'italic'}>
                  {authContext.user?.feedback}
                </Typography>
              </StyledDialogContent>
              <StyledDialogActions>
                <RoundButton variant="outlined" fullWidth={isMobileScreen} onClick={props.onClose}>
                  Cancel
                </RoundButton>
                <RoundButton
                  variant="contained"
                  fullWidth={isMobileScreen}
                  onClick={() => navigate(`/users/${authContext.user?.id}/edit`)}
                >
                  Update Profile
                </RoundButton>
              </StyledDialogActions>
            </>
          )}
        </>
      )}
      {props.opportunity != null &&
        authContext.user?.vettingStatus == VettingStatus.Approved &&
        !props.opportunity.applied &&
        !success && (
          <>
            <StyledDialogTitle>
              <Typography variant="h6" color={theme.palette.grey[900]}>
                {props.opportunity.title}
              </Typography>
              <IconButton onClick={props.onClose} sx={{ marginRight: '-12px' }}>
                <CloseIcon />
              </IconButton>
            </StyledDialogTitle>
            <ApplicationDialogContent dividers>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12}>
                  <Stack direction="row" spacing={1.5} alignItems="center">
                    <StyledUserAvatar
                      userId={authContext.user?.id}
                      displayName={authContext.user.firstName + ' ' + authContext.user.lastName}
                    />
                    <Stack
                      direction="column"
                      spacing={0.5}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => window.open(`/users/${authContext.user?.id}/`, '_blank')}
                    >
                      <Typography
                        variant={'subtitle1'}
                        fontWeight={600}
                        fontSize={'18px'}
                        alignItems={'center'}
                        component={'div'}
                        display={'flex'}
                      >
                        <span>{authContext.user.firstName + ' ' + authContext.user.lastName}</span>
                        <OpenInNewOutlinedIcon sx={{ width: '18px', height: '18px', ml: 0.75 }} />
                      </Typography>

                      <Typography variant="body1" color={'#4e61d9'}>
                        {authContext.user.title}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant={'subtitle1'} fontWeight={700} mb={0.5} fontSize={'18px'}>
                    Your cover note
                  </Typography>
                  <Typography variant="body2" mb={2.5}>
                    Please include a message to the job poster - this is the first thing they will see along with your
                    Shoutt profile. Use this to catch their attention and highlight why you’re a great fit!
                  </Typography>
                  <FormGroup>
                    <FormControl>
                      <TextField
                        name="message"
                        multiline
                        rows={isMobileScreen ? 10 : 6}
                        fullWidth
                        value={form.values.message}
                        onChange={form.handleChange}
                        error={form.touched.message !== undefined && Boolean(form.errors.message)}
                        helperText={form.touched.message !== undefined ? form.errors.message : ''}
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>
              </Grid>
            </ApplicationDialogContent>
            <StyledDialogActions>
              <RoundButton variant="outlined" fullWidth={isMobileScreen} onClick={props.onClose}>
                Cancel
              </RoundButton>
              <RoundButton
                loading={loading}
                disabled={loading}
                variant="contained"
                type="button"
                fullWidth={isMobileScreen}
                onClick={() => form.handleSubmit()}
              >
                Submit
              </RoundButton>
            </StyledDialogActions>
          </>
        )}
      {props.opportunity != null &&
        authContext.user?.vettingStatus == VettingStatus.Approved &&
        !props.opportunity.applied &&
        success && (
          <>
            <StyledDialogTitle>
              <Typography variant="h6" color={theme.palette.grey[900]}>
                Application Successful
              </Typography>
              <IconButton onClick={props.updateApplyOnClose} sx={{ marginRight: '-12px' }}>
                <CloseIcon />
              </IconButton>
            </StyledDialogTitle>
            <StyledDialogContent dividers>
              <Typography variant="body1">
                Your application has been successfully submitted. The job poster will be in touch if they want to
                discuss further.
              </Typography>
            </StyledDialogContent>
            <StyledDialogActions>
              <RoundButton variant="contained" fullWidth={isMobileScreen} onClick={props.updateApplyOnClose}>
                Close
              </RoundButton>
            </StyledDialogActions>
          </>
        )}
      {props.opportunity != null && props.opportunity.applied && (
        <>
          <StyledDialogTitle>
            <Typography variant="h6" color={theme.palette.grey[900]}>
              Already Applied
            </Typography>
            <IconButton onClick={props.onClose} sx={{ marginRight: '-12px' }}>
              <CloseIcon />
            </IconButton>
          </StyledDialogTitle>
          <StyledDialogContent dividers>
            <Typography variant="body1">
              You have already applied for the opportunity &quot;{props.opportunity.title}&quot;. The job poster will be
              in touch if they want to progress your application.
            </Typography>
          </StyledDialogContent>
          <StyledDialogActions>
            <RoundButton variant="contained" fullWidth={isMobileScreen} onClick={props.onClose}>
              Close
            </RoundButton>
          </StyledDialogActions>
        </>
      )}
    </>
  );
}
