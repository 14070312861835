import { IChatDeliverable } from 'global/interfaces/chatMessage';
import * as React from 'react';
import { Divider, Drawer, Grid, IconButton, Typography, styled, useTheme } from '@mui/material';
import { getContract, reviewDeliverables } from 'services/contractService';
import { ContractDeliverableStatus, IContract, IReviewedDeliverable } from 'global/interfaces/contract';
import IApiError from 'global/interfaces/api';
import { showError, showErrorMessage } from 'utils/errorHandler';
import useProgressBar from 'global/hooks/useProgressBar';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { StyledDrawerGrid } from 'components/common/StyledDrawerGrid';
import CloseIcon from '@mui/icons-material/Close';
import { RoundButton } from 'components/common/Button/RoundButton';

export enum ReviewResult {
  AlreadyApproved,
  AlreadyRevisionRequested,
  AlreadyDisputed,
  ConfirmApproval,
  ConfirmRaiseDispute,
  ExecuteApprovalRevision,
}

interface IReviewSingleProps {
  deliverable: IChatDeliverable;
  messageId: string;
}

const StyledGrid = styled(Grid)(() => ({
  marginTop: '24px',
}));

const StyledDivider = styled(Divider)(() => ({
  width: '100%',
}));

export default function ReviewSingle(props: IReviewSingleProps): JSX.Element {
  const [open, setOpen] = React.useState<boolean>(false);
  const [reviewResult, setReviewResult] = React.useState<ReviewResult | undefined>(undefined);
  const [progress, showProgress] = useProgressBar();
  const theme = useTheme();

  const handleReview = (newStatus: ContractDeliverableStatus): void => {
    getContract(props.deliverable.contractId)
      .then((res: IContract) => {
        const deliverable = res.deliverables.find(p => p.name == props.deliverable.name);
        const isLastDeliverableToApprove =
          res.deliverables.filter(p => p.status != ContractDeliverableStatus.Approved).length === 1;

        let newReviewResult: ReviewResult | undefined = undefined;

        if (deliverable === undefined || deliverable === null) {
          showErrorMessage('Cannot find deliverable');
        } else if (deliverable.status === ContractDeliverableStatus.Approved) {
          newReviewResult = ReviewResult.AlreadyApproved;
        } else if (deliverable.status === ContractDeliverableStatus.RevisionRequired) {
          newReviewResult = ReviewResult.AlreadyRevisionRequested;
        } else if (deliverable.status === ContractDeliverableStatus.Disputed) {
          newReviewResult = ReviewResult.AlreadyDisputed;
        } else if (
          deliverable.status === ContractDeliverableStatus.WaitingApproval &&
          isLastDeliverableToApprove &&
          newStatus === ContractDeliverableStatus.Approved
        ) {
          newReviewResult = ReviewResult.ConfirmApproval;
        } else if (
          deliverable.status === ContractDeliverableStatus.WaitingApproval &&
          deliverable.revisionsRequested === res.revisions &&
          newStatus === ContractDeliverableStatus.RevisionRequired
        ) {
          newReviewResult = ReviewResult.ConfirmRaiseDispute;
        } else if (deliverable.status === ContractDeliverableStatus.WaitingApproval) {
          newReviewResult = ReviewResult.ExecuteApprovalRevision;
        }

        setReviewResult(newReviewResult);
        if (newReviewResult !== ReviewResult.ExecuteApprovalRevision) {
          setOpen(true);
        } else {
          updateDeliverableStatus(newStatus);
        }
      })
      .catch(showError);
  };

  const handleApprove = (): void => {
    handleReview(ContractDeliverableStatus.Approved);
  };

  const handleRequestRevision = (): void => {
    handleReview(ContractDeliverableStatus.RevisionRequired);
  };

  const updateDeliverableStatus = (newStatus: ContractDeliverableStatus): void => {
    setOpen(false);
    showProgress(true);
    const deliverablesToSend: IReviewedDeliverable[] = [
      {
        contractId: props.deliverable.contractId,
        contractName: props.deliverable.contractName,
        name: props.deliverable.name,
        newStatus: newStatus,
      },
    ];
    reviewDeliverables(deliverablesToSend, props.messageId)
      .then(() => {
        showProgress(false);
      })
      .catch((err: IApiError) => {
        showErrorMessage(`Could not send message. Please refresh the page: ${err.message}`);
      });
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
        <Grid item xs={12} sm={6} md={12} lg={4.5}>
          <RoundButton
            variant="contained"
            fullWidth
            type="button"
            onClick={handleApprove}
            startIcon={<DoneAllOutlinedIcon />}
          >
            Approve
          </RoundButton>
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={4.5}>
          <RoundButton
            variant="outlined"
            type="button"
            fullWidth
            onClick={handleRequestRevision}
            startIcon={<ClearOutlinedIcon />}
          >
            Request Revision
          </RoundButton>
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <>
          {reviewResult === ReviewResult.AlreadyApproved && (
            <StyledDrawerGrid container>
              <Grid item xs={10}>
                <Typography variant="h6" color={theme.palette.grey[900]}>
                  Already approved
                </Typography>
              </Grid>
              <Grid item xs={2} container justifyContent="flex-end">
                <IconButton onClick={() => handleClose()}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">{`Deliverable ${props.deliverable.contractName}: ${props.deliverable.name} has already been approved.`}</Typography>
              </Grid>
              <StyledGrid item container>
                <RoundButton variant="contained" onClick={() => handleClose()}>
                  Ok
                </RoundButton>
              </StyledGrid>
            </StyledDrawerGrid>
          )}

          {reviewResult === ReviewResult.AlreadyRevisionRequested && (
            <StyledDrawerGrid container>
              <Grid item xs={10}>
                <Typography variant="h6" color={theme.palette.grey[900]}>
                  Already Requested Revision
                </Typography>
              </Grid>
              <Grid item xs={2} container justifyContent="flex-end">
                <IconButton onClick={() => handleClose()}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">{`You have already requested a revision of deliverable ${props.deliverable.contractName}: ${props.deliverable.name}.`}</Typography>
              </Grid>
              <StyledGrid item container>
                <RoundButton variant="contained" onClick={() => handleClose()}>
                  Ok
                </RoundButton>
              </StyledGrid>
            </StyledDrawerGrid>
          )}

          {reviewResult === ReviewResult.AlreadyDisputed && (
            <StyledDrawerGrid container>
              <Grid item xs={10}>
                <Typography variant="h6" color={theme.palette.grey[900]}>
                  Already disputed
                </Typography>
              </Grid>
              <Grid item xs={2} container justifyContent="flex-end">
                <IconButton onClick={() => handleClose()}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">{`You have already raised a dispute for deliverable ${props.deliverable.contractName}: ${props.deliverable.name}.`}</Typography>
              </Grid>
              <StyledGrid item container>
                <RoundButton variant="contained" onClick={() => handleClose()}>
                  Ok
                </RoundButton>
              </StyledGrid>
            </StyledDrawerGrid>
          )}

          {reviewResult === ReviewResult.ConfirmApproval && (
            <>
              <StyledDrawerGrid container alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="h6">Confirm approval</Typography>
                </Grid>
                <Grid item xs={2} container justifyContent="flex-end">
                  <IconButton onClick={() => handleClose()}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </StyledDrawerGrid>

              <StyledDivider />

              <StyledDrawerGrid container>
                <Grid item xs={12}>
                  <Typography variant="body1" sx={{ ml: 0, mt: 1.5 }}>
                    This is the final deliverable for this contract. Approving it will complete the contract and release
                    any funds which remain in escrow. Please confirm if you approve the following deliverable:
                  </Typography>
                  <br />
                  <Grid xs={12} container alignItems="center">
                    <Typography variant="subtitle1">Deliverable: &nbsp;</Typography>
                    <Typography variant="body1">{props.deliverable.contractName}</Typography>
                  </Grid>
                </Grid>
                <StyledGrid item container gap={1}>
                  <RoundButton variant="outlined" onClick={handleClose}>
                    No
                  </RoundButton>
                  <RoundButton
                    variant="contained"
                    onClick={() => updateDeliverableStatus(ContractDeliverableStatus.Approved)}
                  >
                    Yes
                  </RoundButton>
                </StyledGrid>
              </StyledDrawerGrid>
            </>
          )}

          {reviewResult === ReviewResult.ConfirmRaiseDispute && (
            <StyledDrawerGrid container>
              <Grid item xs={10}>
                <Typography variant="h6" color={theme.palette.grey[900]}>
                  Raise dispute
                </Typography>
              </Grid>
              <Grid item xs={2} container justifyContent="flex-end">
                <IconButton onClick={() => handleClose()}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <StyledGrid item xs={12}>
                <Typography variant="body1">{`There are no revisions left in contract ${props.deliverable.contractName}. Please attempt to resolve any issues with the deliverable with the seller. A dispute should only be raised as a last resort. Would you like to raise a dispute now?`}</Typography>
              </StyledGrid>
              <StyledGrid item container gap={1}>
                <RoundButton variant="outlined" onClick={handleClose}>
                  No
                </RoundButton>
                <RoundButton
                  variant="contained"
                  onClick={() => updateDeliverableStatus(ContractDeliverableStatus.Disputed)}
                >
                  Yes
                </RoundButton>
              </StyledGrid>
            </StyledDrawerGrid>
          )}
        </>
      </Drawer>
      {progress}
    </>
  );
}
