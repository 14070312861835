import { Box, Stack, styled } from '@mui/material';

export const DetailContainer = styled(Stack)(({ theme }) => ({
  maxWidth: '75%',
  gap: '18px',
  margin: 'auto',

  [theme.breakpoints.down('md')]: {
    gap: '12px',
    maxWidth: 'unset',
  },
}));

export const CenteredBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignContent: 'center',
  flexDirection: 'column',
  [theme.breakpoints.down('md')]: {
    alignContent: 'start',
  },
}));
