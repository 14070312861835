import { Chip, Grid, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import CategoryLookups from 'json/CategoryLookups.json';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

const StyledChip = styled(Chip)(({ theme }) => ({
  height: '28px',

  backgroundColor: theme.palette.black[200],
  borderRadius: '16px',
  color: theme.palette.black[500],
  cursor: 'pointer',
  padding: '4px 12px',
}));

const CategoryGrid = styled(Grid)(({ theme }) => ({
  paddingTop: '33px',
  paddingBottom: '40px',
  alignItems: 'center',
  display: 'flex',
  width: '100%',
  overflow: 'auto',

  '::-webkit-scrollbar': {
    width: 0,
  },

  [theme.breakpoints.down('md')]: {
    paddingBottom: '36px',
  },
}));

export default function UserCategories() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { updateScrollY } = useContext(GlobalStateContext);

  const handleClick = (category: string) => {
    updateScrollY(0);
    if (isMobile) {
      navigate(`/users?search=${encodeURIComponent(category)}`);
    } else {
      navigate(`/users?category=${encodeURIComponent(category)}`);
    }
  };

  return (
    <>
      <CategoryGrid container direction="row" justifyContent="flex-start" gap="10px" flexWrap="nowrap">
        {CategoryLookups.filter(c => !!c.home).map((p, index: number) => [
          <Grid item key={index}>
            <StyledChip
              label={<Typography variant={isMobile ? 'caption' : 'subtitle2'}>{p.label}</Typography>}
              size="small"
              onClick={() => handleClick(p.id)}
            />
          </Grid>,
        ])}
      </CategoryGrid>
    </>
  );
}
