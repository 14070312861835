import { SvgIcon, SvgIconProps } from '@mui/material';

export const FixedPriceIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M6 16.5C6 18.7091 7.79086 20.5 10 20.5H14C16.2091 20.5 18 18.7091 18 16.5C18 14.2909 16.2091 12.5 14 12.5H10C7.79086 12.5 6 10.7091 6 8.5C6 6.29086 7.79086 4.5 10 4.5H14C16.2091 4.5 18 6.29086 18 8.5M12 2.5V22.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
