import { Box, IconButton, IconButtonProps, styled } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Dispatch } from 'react';
import { BiteSizeContentLength } from './BiteSizeContent';

interface IndicatorButtonProps extends IconButtonProps {
  active: boolean;
}

const IndicatorContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(1),
}));

const IndicatorButton = styled(IconButton, {
  shouldForwardProp: prop => prop !== 'active',
})<IndicatorButtonProps>(({ theme, active }) => ({
  padding: '2px',
  color: active ? theme.palette.grey[600] : theme.palette.grey[400],
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

export default function BiteSizeIndicator({
  activeIndex,
  setActiveIndex,
}: {
  activeIndex: number;
  setActiveIndex: Dispatch<number>;
}) {
  return (
    <IndicatorContainer>
      {[...Array(BiteSizeContentLength)].map((_, index) => (
        <IndicatorButton
          key={index}
          onClick={() => setActiveIndex(index)}
          active={index === activeIndex ? true : false}
        >
          <FiberManualRecordIcon sx={{ fontSize: '11px' }} />
        </IndicatorButton>
      ))}
    </IndicatorContainer>
  );
}
