import React, { useState } from 'react';
import { Button, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { RoundButton } from './RoundButton';

const StyledCopyButton = styled(Button)(() => ({
  maxWidth: '80px',
}));

/**
 * Returns a button component that copies the specified text to the clipboard when clicked.
 *
 * @param {string} textToCopyToClipboard - The text to be copied to the clipboard
 * @param {string} buttonLabel - The label to be displayed on the button
 * @param {object} sx - (Optional) Custom styles to apply to the button
 * @param {boolean} roundButton - (Optional) Indicates if the button should be round
 * @param {React.ReactNode} icon - (Optional) The icon to be displayed on the button, only for round button
 * @return {JSX.Element} The CopyToClipboardButton component
 */
const CopyToClipboardButton = ({
  textToCopyToClipboard,
  buttonLabel,
  sx = {},
  roundButton = false,
  icon,
}: {
  textToCopyToClipboard: string;
  buttonLabel: string;
  sx?: object;
  roundButton?: boolean;
  icon?: React.ReactNode;
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const copyToClipboard = async (text: string): Promise<boolean> => {
    try {
      await navigator.clipboard.writeText(text);
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleCopy = async () => {
    setShowTooltip(false);
    const success = await copyToClipboard(textToCopyToClipboard);
    if (success) {
      setShowTooltip(true);
    }
  };

  return (
    <Tooltip open={showTooltip} onClose={() => setShowTooltip(false)} title="Copied to clipboard">
      {roundButton ? (
        <RoundButton type="button" variant="contained" startIcon={!isMobile ? icon : null} onClick={handleCopy} sx={sx}>
          {buttonLabel}
        </RoundButton>
      ) : (
        <StyledCopyButton type="button" variant="contained" onClick={handleCopy} sx={sx}>
          {buttonLabel}
        </StyledCopyButton>
      )}
    </Tooltip>
  );
};

export default CopyToClipboardButton;
