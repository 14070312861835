import { Box, Fab, styled, useMediaQuery, useTheme } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ChatMessageMediaImage from './ChatMessageMediaImage';
import { OpenInNew } from '@mui/icons-material';

const StyledBox = styled(Box)(() => ({
  position: 'relative',
}));

const ThumbTRContainer = styled('div')(() => ({
  position: 'absolute',
  top: '0',
  right: '0',
  padding: '5px',
}));

const MobileCenterContainer = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
}));

export interface IChatMessageMediaPdfProps {
  thumbnailUrl?: string;
  fileName: string;
  extension: string;
  onClick?: () => void;
}

export default function ChatMessageMediaPdf(props: IChatMessageMediaPdfProps): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <StyledBox>
      <ChatMessageMediaImage {...props} />
      <ThumbTRContainer>
        <DescriptionOutlinedIcon style={{ fontSize: '25px' }} />
      </ThumbTRContainer>
      {isMobile && (
        <MobileCenterContainer>
          <Fab color="primary" onClick={props.onClick}>
            <OpenInNew />
          </Fab>
        </MobileCenterContainer>
      )}
    </StyledBox>
  );
}
