interface EventData {
  [key: string]: any;
}

export function pushToDataLayer(eventName: string, eventData?: EventData): void {
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push({
    event: eventName,
    ...eventData, // Additional data if needed
  });
}
