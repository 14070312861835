import React, { useState } from 'react';
import { FormikProps } from 'formik';
import { IEmailInvitationForm } from 'global/interfaces/emailInvitation';
import { Typography } from '@mui/material';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { useTheme } from '@mui/material/styles';
import { IUser } from 'global/interfaces/user';
import EmailInviteFromContent from './EmailInviteFormContent';
import EmailInviteFromButton from './EmailInviteFormButtons';
import { EmailInviteStep } from 'global/enums/networkEnums';

interface EmailInviteFormProps {
  user: IUser | null | undefined;
  emailInviteStep: EmailInviteStep;
  setEmailInviteStep: (step: EmailInviteStep) => void;
  handleClose: () => void;
  setLoading: (loading: boolean) => void;
  loading: boolean;
}

const EmailInviteForm: React.FC<EmailInviteFormProps> = ({
  user,
  emailInviteStep,
  setEmailInviteStep,
  handleClose,
  setLoading,
  loading,
}) => {
  const theme = useTheme();
  const [otherUserId, setOtherUserId] = useState<string | undefined>(undefined);
  const [form, setInviteForm] = useState<FormikProps<IEmailInvitationForm>>();

  const handleFormClose = () => {
    setOtherUserId(undefined);
    setEmailInviteStep(EmailInviteStep.Form);
    handleClose();
  };

  return (
    <>
      <StyledDialogTitle>
        <Typography variant="h6" color={theme.palette.grey[900]}>
          Send Invite
        </Typography>
      </StyledDialogTitle>
      <StyledDialogContent dividers>
        <EmailInviteFromContent
          emailInviteStep={emailInviteStep}
          user={user}
          setInviteForm={setInviteForm}
          setEmailInviteStep={setEmailInviteStep}
          setLoading={setLoading}
          setOtherUserId={setOtherUserId}
        />
      </StyledDialogContent>
      <StyledDialogActions>
        <EmailInviteFromButton
          loading={loading}
          emailInviteStep={emailInviteStep}
          otherUserId={otherUserId}
          form={form}
          handleFormClose={handleFormClose}
          setLoading={setLoading}
        />
      </StyledDialogActions>
    </>
  );
};

export default EmailInviteForm;
