import { createContractSearchParam } from 'global/constants';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useCreateContractDialog = (): { openContractDialog: boolean } => {
  const [searchParams, setSearchParams] = useSearchParams();

  const openDialog: boolean = searchParams.has(createContractSearchParam);

  useEffect(() => {
    //remove param from url
    if (openDialog) {
      const newParams = searchParams;
      newParams.delete(createContractSearchParam);
      setSearchParams(newParams);
    }
  }, []);
  return { openContractDialog: openDialog };
};
