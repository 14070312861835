import { IContactUsForm } from 'global/interfaces/IContactUsForm';
import { authorizedPostForm } from './baseApiService';
import { nameof } from 'ts-simple-nameof';

const resource = 'ContactUs';

export async function sendContactDetails(contactDetails: IContactUsForm) {
  const formData = new FormData();
  formData.append(
    nameof<IContactUsForm>(x => x.name),
    contactDetails.name,
  );
  formData.append(
    nameof<IContactUsForm>(x => x.email),
    contactDetails.email,
  );
  formData.append(
    nameof<IContactUsForm>(x => x.description),
    contactDetails.description,
  );
  formData.append(
    nameof<IContactUsForm>(x => x.subject),
    contactDetails.subject,
  );
  if (contactDetails.token) {
    formData.append(
      nameof<IContactUsForm>(x => x.token),
      contactDetails.token,
    );
  }

  const fileBlob = contactDetails.file ? new Blob([contactDetails.file], { type: contactDetails.file.type }) : null;

  if (fileBlob && contactDetails?.file !== null && contactDetails.file !== undefined) {
    formData.append(
      nameof<IContactUsForm>(x => x.file),
      fileBlob,
      contactDetails.file.name,
    );
  } else {
    formData.append(
      nameof<IContactUsForm>(x => x.file),
      '',
    );
  }

  return await authorizedPostForm(`${resource}`, formData);
}
