import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import OpportunityAccordion from 'components/opportunities/OpportunityAccordion';
import { IApplicant, IOpportunity } from 'global/interfaces/opportunity';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getApplicants } from 'services/opportunityService';
import { showError } from 'utils/errorHandler';
import OppNotFound from './OppNotFound';
import { UserAvatar } from 'components/common/UserAvatar';
import React, { useContext, useEffect, useState } from 'react';
import { VerifiedTickIcon } from 'components/icon/VerifiedTickIcon';
import { RoundButton } from 'components/common/Button/RoundButton';
import OppNoAccess from './OppNoAccess';

import { AuthContext } from 'contexts/AuthContext';
import { UserRole } from 'global/enums/userRole';
import ShareButton from 'components/ShareButton';

const StyledListItemAvatar = styled(ListItemAvatar)(({ theme }) => ({
  paddingTop: '6px',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    paddingTop: '2px',
  },
}));

const StyledVerifiedTickIcon = styled(VerifiedTickIcon)(({ theme }) => ({
  position: 'absolute',
  top: '-2px',
  right: '-2px',
  [theme.breakpoints.down('sm')]: {
    width: '18px',
    height: '18px',
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  alignItems: 'flex-start',
  gap: '18px',
  marginBottom: '24px',
  [theme.breakpoints.down('sm')]: {
    gap: '8px',
    marginBottom: '18px',
  },
}));

const StyledAvatar = styled(UserAvatar)(({ theme }) => ({
  width: '96px',
  height: '96px',
  borderRadius: '8px',

  [theme.breakpoints.down('sm')]: {
    width: '50px',
    height: '50px',
  },
}));

export default function ViewApplicants() {
  const [opp, setOpp] = useState<IOpportunity>();
  const [loading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [noAcess, setNoAccess] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const authContext = useContext(AuthContext);
  const [privateUrl, setPrivateUrl] = useState<string>('');

  const hasAdminRole = authContext.user && authContext.user.roles.indexOf(UserRole.Administrator) > -1;
  const baseUrl = hasAdminRole
    ? process.env.REACT_APP_ADMIN_SHARE_OPPORTUNITY_URL
    : process.env.REACT_APP_SHARE_OPPORTUNITY_URL;
  let shareUrl = `${baseUrl}?opportunityId=${opp?.id}`;

  if (!hasAdminRole && authContext.user?.id) {
    shareUrl = shareUrl + `&source=${authContext.user.id}`;
  } else if (!hasAdminRole) {
    shareUrl = shareUrl + `&source=nou`;
  }

  const { id } = useParams();

  useEffect(() => {
    if (!id) return;

    getApplicants(id, searchParams.get('accessToken'))
      .then(res => {
        setOpp(res);
        setPrivateUrl(
          window.location.protocol +
            '//' +
            window.location.host +
            '/applicants/' +
            res.id +
            '?accessToken=' +
            res.accessToken,
        );
      })
      .catch(e => {
        if (e.status === 404) {
          setNotFound(true);
          return;
        }
        if (e.status === 401) {
          setNoAccess(true);
          return;
        }
        showError(e);
      })
      .finally(() => setLoading(false));
  }, [id]);

  const onViewProfile = (userId: string): void => {
    navigate(`/users/${userId}?opportunityId=${opp?.id}`);
  };

  if (notFound) {
    return <OppNotFound />;
  }

  if (noAcess) {
    return <OppNoAccess />;
  }

  return (
    <>
      {opp && !loading && (
        <Container maxWidth="lg" sx={{ mt: isMobile ? 3 : 6, mb: isMobile ? 3 : 6 }}>
          <Grid container direction={isMobile ? 'column-reverse' : 'row'} spacing={isMobile ? 2 : 4}>
            <Grid item xs={12} md={8}>
              <Typography variant={isMobile ? 'h6' : 'h5'}>Applicants</Typography>
              {opp.applicants && opp.applicants?.length > 0 && (
                <List sx={{ mt: '16px' }} dense={true}>
                  {opp.applicants.map((applicant: IApplicant, index: number) => (
                    <React.Fragment key={index}>
                      <StyledListItem disableGutters disablePadding>
                        <StyledListItemAvatar>
                          <StyledAvatar userId={applicant.userId} displayName={applicant.name} variant="square" />
                          <StyledVerifiedTickIcon />
                        </StyledListItemAvatar>
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Stack direction={'column'} spacing={0.5}>
                                <Typography
                                  variant="subtitle1"
                                  fontSize={'18px'}
                                  fontWeight={700}
                                  color={theme.palette.grey[900]}
                                >
                                  {applicant.name}
                                </Typography>
                                <Typography
                                  variant={isMobile ? 'subtitle2' : 'subtitle1'}
                                  color={theme.palette.grey[900]}
                                >
                                  {applicant.title}
                                </Typography>
                              </Stack>
                            </React.Fragment>
                          }
                          secondary={
                            <>
                              {!isMobile && (
                                <Stack direction={'column'} spacing={2}>
                                  <Typography
                                    variant="body1"
                                    mt={2}
                                    whiteSpace={'break-spaces'}
                                    sx={{ wordWrap: 'break-word' }}
                                  >
                                    {applicant.message}
                                  </Typography>
                                  <Box>
                                    <RoundButton
                                      variant="outlined"
                                      onClick={() => {
                                        onViewProfile(applicant.userId);
                                      }}
                                    >
                                      View Profile
                                    </RoundButton>
                                  </Box>
                                </Stack>
                              )}
                            </>
                          }
                          secondaryTypographyProps={{ component: 'div' }}
                        ></ListItemText>
                      </StyledListItem>
                      {isMobile && (
                        <Stack direction={'column'} spacing={2} marginBottom={'32px'} width={'calc(100vw - 32px)'}>
                          <Typography
                            variant={isMobile ? 'body2' : 'body1'}
                            whiteSpace={'break-spaces'}
                            sx={{ wordWrap: 'break-word' }}
                          >
                            {applicant.message}
                          </Typography>
                          <Box>
                            <RoundButton
                              variant="outlined"
                              onClick={() => {
                                onViewProfile(applicant.userId);
                              }}
                            >
                              View Profile
                            </RoundButton>
                          </Box>
                        </Stack>
                      )}
                    </React.Fragment>
                  ))}
                </List>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant={isMobile ? 'h6' : 'h5'}>Your opportunity</Typography>
              <Box pb={1} mt={'24px'}>
                <OpportunityAccordion opportunity={opp} expanded={isMobile ? undefined : true} forceMobile={true}>
                  <>
                    <Box>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle1" fontSize={{ xs: '16px', md: '18px' }}>
                          Share opportunity publicly
                        </Typography>
                        <Stack mt="-2px">
                          <ShareButton url={shareUrl} />
                        </Stack>
                      </Stack>
                      <Typography variant="body2" mt={1} mb={3}>
                        Share your job link anywhere to receive vetted applicants here.
                      </Typography>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="subtitle1" fontSize={{ xs: '16px', md: '18px' }}>
                          Share applicants privately
                        </Typography>
                        <Stack mt="-2px">
                          <ShareButton url={privateUrl} />
                        </Stack>
                      </Stack>
                      <Typography variant="body2" mt={1} mb={2}>
                        Securely share your list of applicants with your colleagues.
                      </Typography>
                    </Box>
                  </>
                </OpportunityAccordion>
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}
