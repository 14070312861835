import { RefundType } from 'global/enums/RefundType';
import { IPagingFilter } from './ordering';

export enum ContractType {
  FixedPrice = 'FixedPrice',
  Retainer = 'Retainer',
}

export enum RecurrenceType {
  Monthly = 'Monthly',
  Weekly = 'Weekly',
}

export interface ICreateContractForm {
  name: string;
  buySell: ContractViewType | null;
  contractType: ContractType | null;
  deliverables: string[];
  price?: number | null;
  revisions?: number | null;
  delivery?: number | null;
  otherUserId?: string | null;
  termsAccepted: boolean;
  currency: string;
  recurrence?: RecurrenceType | null;
  duration?: number | null;
  description?: string | null;
  release?: number | null;
}

export enum ContractPaymentSecretType {
  AlreadyPaid = 'AlreadyPaid',
  Cancelled = 'Cancelled',
  Url = 'Url',
  AddressRequired = 'AddressRequired',
}

export enum ContractAcceptResultType {
  AlreadyAccepted = 'AlreadyAccepted',
  Cancelled = 'Cancelled',
  Done = 'Done',
  AddressRequired = 'AddressRequired',
}

export interface IContractPaymentSecret {
  type: ContractPaymentSecretType;
  value: string;
}

export interface IContractAcceptResult {
  result: ContractAcceptResultType;
}

export enum ContractStatus {
  Offered = 'Offered',
  Created = 'Created',
  InProgress = 'InProgress',
  Complete = 'Complete',
  Cancelled = 'Cancelled',
  Disputed = 'Disputed',
}

export enum ContractDeliverableStatus {
  InProgress = 'InProgress',
  WaitingApproval = 'WaitingApproval',
  Approved = 'Approved',
  RevisionRequired = 'RevisionRequired',
  Disputed = 'Disputed',
}

export enum ContractPaymentStatus {
  Awaiting = 'Awaiting',
  Success = 'Success',
  Failed = 'Failed',
  Refunded = 'Refunded',
}

export enum ContractPayoutStatus {
  NotReady = 'NotReady',
  AwaitingFunds = 'AwaitingFunds',
  Success = 'Success',
  Failed = 'Failed',
  NotRequired = 'NotRequired',
  Partial = 'Partial',
}

export enum ContractActionType {
  PaymentRequested = 'PaymentRequested',
  PaymentFailed = 'PaymentFailed',
  ReviewRequested = 'ReviewRequested',
  RevisionRequested = 'RevisionRequested',
  DeadLineApproaching = 'DeadLineApproaching',
  LeaveFeedback = 'LeaveFeedback',
  AcceptContract = 'AcceptContract',
}

export enum ContractViewType {
  Buy = 0,
  Sell = 1,
}

export interface IDeliverable {
  name: string;
  status: ContractDeliverableStatus;
  revisionsRequested: number;
}

export interface IDeliverableForApproval extends IDeliverable {
  contractId?: string;
  contractName?: string;
  first?: boolean;
}

export interface IReviewMultipleDeliverablesForm {
  deliverables: IReviewedDeliverable[];
}

export interface IReviewedDeliverable {
  contractId: string;
  contractName: string;
  name: string;
  currentStatus?: ContractDeliverableStatus;
  newStatus: ContractDeliverableStatus | string;
}

export interface IReviewedDeliverableDisplay extends IReviewedDeliverable {
  numDeliverablesRemaining?: number;
  isLastDeliverable?: boolean;
  hasRevisionsRemaining?: boolean;
}

export interface IContract {
  contractId: string;
  contractType: ContractType;
  viewType: ContractViewType;
  name: string;
  displayAmount: number;
  createdDate: string;
  status: ContractStatus;
  completedDate: string;
  delivery: number;
  revisions: number;
  deadlineDate: string;
  paymentStatus: ContractPaymentStatus;
  paymentDate: string;
  chatThreadId: string;
  deliverables: IDeliverable[];
  //todo: consider if these are the proper fields to check if user is seller/buyer
  buyerAdditionalDetails?: IBuyerAdditionalDetails;
  sellerAdditionalDetails?: ISellerAdditionalDetails;
  contractDispute?: ContractDispute;
  currency: string;
  recurrence?: RecurrenceType;
  recurringAmount?: number;
  duration?: number;
  endDate?: string;
  release?: number;
  description?: string;
  currentPeriodEndDate?: string;
  settlementRefund?: SettlementRefund;
  isEnding?: boolean;
  endRelease?: number;
}

export interface IContractBasic {
  contractId: string;
  contractType: ContractType;
  viewType: ContractViewType;
  name: string;
  otherUserId: string;
  otherUserDisplayName: string;
  chatThreadId: string;
  currency: string;
  amount: number;
}

export interface IContractAction extends IContractBasic {
  action: ContractActionType;
}

export interface IContractDisplay extends IContract {
  numDeliverables?: number;
  numCompletedDeliverables?: number;
  paymentStatusText?: string;
  durationText?: string;
  releaseText?: string;
}

export interface IBuyerAdditionalDetails {
  sellerId: string;
  sellerDisplayName: string;
  totalBuyerAmount: number;
  buyerFee: number;
  profilePicture: string;
  paymentCardBrand: string;
  paymentCardLast4: string;
  payoutStatus?: ContractPayoutStatus;
  invoiceId?: string;
  invoiceNumber?: string;
}

export interface ISellerAdditionalDetails {
  buyerId: string;
  buyerDisplayName: string;
  sellerFee: number;
  stripeFee: number;
  totalSellerEarnings: number;
  payoutAmount: number;
  payoutAmountContractCurrency: number;
  payoutCurrency: number;
  paidOutDate: string;
  payoutArrivalDate: string;
  availableForPayoutDate: string;
  payoutStatus: ContractPayoutStatus;
}

export interface IContractFilters extends IPagingFilter {
  userId?: string;
  status?: string;
}

export const StatusColourDictionary: {
  [key: string]: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
} = {
  [ContractStatus.Created]: 'default',
  [ContractStatus.Offered]: 'default',
  [ContractStatus.Complete]: 'success',
  [ContractStatus.Disputed]: 'error',
  [ContractStatus.Cancelled]: 'info',
  [ContractStatus.InProgress]: 'warning',
};

export interface IContractReview {
  contractId: string;
  requestFeedbackMessage: string;
  rating: number;
  comment?: string;
}

interface ContractDispute {
  raisedByUserId: string;
  DateRaised: string;
  dateResolved: string;
  resolvedByUserId: string;
  disputeOtherUserId: string;
}

export interface IRefundContract {
  partialRefundType: RefundType | number;
  refundAmount?: number;
}

interface SettlementRefund {
  contractRefundTotal: number;
  paymentRefundTotal: number;
}

export interface IContractRetainerPayment {
  contractId: string;
  date: string;
  paymentStatus: ContractPaymentStatus;
  payoutStatus: ContractPayoutStatus;
  payoutAmount?: number;
  payoutCurrency?: string;
  paidOutDate?: string;
  payoutArrivalDate?: string;
  availableForPayoutDate?: string;
  invoiceId?: string;
}

export interface IContractEarnings {
  currency: string;
  amount: number;
}
