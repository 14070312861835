import {
  ContractPaymentStatus,
  ContractPayoutStatus,
  ContractStatus,
  IContract,
  IContractRetainerPayment,
} from 'global/interfaces/contract';

export const getContractStatusText = (contract: IContract, successfulPaymentRedirect?: boolean): string => {
  let status: string = ContractStatus[contract.status];
  if (contract?.status === ContractStatus.InProgress || successfulPaymentRedirect) {
    status = 'In Progress';
  } else if (contract?.status === ContractStatus.Created) {
    status = 'Awaiting Payment';
  } else if (contract?.status === ContractStatus.Offered) {
    status = 'Awaiting Acceptance';
  } else if (contract?.status === ContractStatus.Complete) {
    status = 'Complete';
  } else if (contract?.status === ContractStatus.Disputed) {
    status = 'Disputed';
  }
  return status;
};

export const getPayoutStatusText = (contract: IContract): string => {
  let status = '';
  if (contract.sellerAdditionalDetails === undefined || contract.status !== ContractStatus.Complete) {
    status = 'Work Not Completed';
  } else if (contract?.sellerAdditionalDetails?.payoutStatus === ContractPayoutStatus.AwaitingFunds) {
    status = 'Awaiting Funds';
  } else if (contract?.sellerAdditionalDetails?.payoutStatus === ContractPayoutStatus.Success) {
    status = 'Paid out';
  }

  return status;
};

export const getRetainerPaymentStatusText = (retainerPayment: IContractRetainerPayment): string => {
  let status = '';
  if (
    retainerPayment.paymentStatus === ContractPaymentStatus.Awaiting ||
    retainerPayment.paymentStatus === ContractPaymentStatus.Failed
  ) {
    status = 'Awaiting Payment';
  } else if (
    (retainerPayment.paymentStatus === ContractPaymentStatus.Success ||
      retainerPayment.paymentStatus === ContractPaymentStatus.Refunded) &&
    retainerPayment.payoutStatus === ContractPayoutStatus.Success
  ) {
    status = 'Released';
    if (retainerPayment.payoutAmount !== undefined && retainerPayment.payoutAmount !== null) {
      status = `Released ${retainerPayment.payoutCurrency?.toUpperCase()} ${retainerPayment.payoutAmount.toFixed(2)}`;
    }
  } else if (retainerPayment.paymentStatus === ContractPaymentStatus.Refunded) {
    status = 'Refunded';
  } else if (
    retainerPayment.paymentStatus === ContractPaymentStatus.Success &&
    retainerPayment.payoutStatus !== ContractPayoutStatus.Success
  ) {
    status = 'In escrow';
  }

  return status;
};

export const getReferenceSuffix = (length: number): string => {
  let ans = '';
  const arr = '1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  for (let i = length; i > 1; i--) {
    ans += arr[Math.floor(Math.random() * arr.length)];
  }
  ans += `${Math.floor(Math.random() * 10)}`;
  return ans;
};
