import { IChangePasswordForm } from 'global/interfaces/changePassword';
import { object, string, SchemaOf, ref } from 'yup';

export const getCharacterValidationError = (str: string) => `Your password must have at least 1 ${str} character`;

export const changePasswordValidation: SchemaOf<IChangePasswordForm> = object({
  newPassword: string()
    .required('New password is required')
    .min(8, 'New password must be a minimum of 8 characters')
    .max(50, 'Maximum of 50 characters allowed')
    .matches(/[0-9]/, 'Your password must have at least 1 number')
    .matches(/[a-z]/, getCharacterValidationError('lowercase'))
    .matches(/[A-Z]/, getCharacterValidationError('uppercase')),
  confirmNewPassword: string()
    .required('Confirm password is required')
    .oneOf([ref('newPassword'), null], 'Confirm Password must match New Password'),
});
