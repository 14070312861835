import { Box, CircularProgress, IconButton, Slider, Stack, styled, useMediaQuery, useTheme } from '@mui/material';
import { useRef, useState } from 'react';
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import { Add, Remove } from '@mui/icons-material';

const ResizeButtonContainer = styled(Stack)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.grey[400],
  borderRadius: '10px',
  gap: '10px',
  paddingInline: '8px',
  backgroundColor: theme.palette.grey[500],
  position: 'absolute',
  bottom: '16px',
  left: '16px',
  zIndex: theme.zIndex.modal + 2,
  width: '250px',
}));

const ResizeIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const StyledImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  cursor: 'grab',
  zIndex: theme.zIndex.modal + 1,
}));

const getParentWithHeight = (element: HTMLElement | null): HTMLElement | null => {
  if (element == null) {
    return null;
  }
  if (element?.clientHeight > 0) {
    return element;
  }
  return getParentWithHeight(element.parentElement);
};

interface IImageViewerProps {
  url: string;
  name: string;
}

export default function ImageViewer({ url, name }: IImageViewerProps) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const maxScale = isMobileScreen ? 2.5 : 4;
  const minScale = isMobileScreen ? 0.6 : 0.71;
  const [zoomValue, setZoomValue] = useState(minScale);
  const [loading, setLoading] = useState(true);

  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const parentRef = useRef<HTMLDivElement | null>(null);

  const handleChange = (_: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      transformComponentRef.current?.setTransform(100, 100, newValue);
    }
  };

  const handleTransformed = (
    ref: ReactZoomPanPinchRef,
    state: {
      scale: number;
      positionX: number;
      positionY: number;
    },
  ) => {
    setZoomValue(state.scale);
  };

  const onLoad = () => {
    const img = imageRef.current;
    const parent = getParentWithHeight(parentRef.current);
    if (img && parent) {
      const imgClientWidth = img.naturalWidth > parent.clientWidth ? parent.clientWidth : img.naturalWidth;
      const imgClientHeight =
        img.naturalWidth > parent.clientWidth
          ? img.naturalHeight * (parent.clientWidth / img.naturalWidth)
          : img.naturalHeight;

      const widthScale = parent.clientWidth / imgClientWidth;
      const heightScale = parent.clientHeight / imgClientHeight;
      const scale = Math.min(widthScale, heightScale, 1);

      const imgRenderedWidth = imgClientWidth * scale;
      const imgRenderedHeight = imgClientHeight * scale;

      const centerPositionX = (parent.clientWidth - imgRenderedWidth) / 2;
      const centerPositionY = (parent.clientHeight - imgRenderedHeight) / 2;
      transformComponentRef.current?.setTransform(centerPositionX, centerPositionY, scale, 0);
    }
    setLoading(false);
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%" ref={parentRef}>
      {loading && (
        <Box position="absolute" left={0} right={0}>
          <CircularProgress color="primary" size={70} />
        </Box>
      )}
      <TransformWrapper
        initialScale={minScale}
        ref={transformComponentRef}
        centerOnInit
        maxScale={maxScale}
        minScale={minScale}
        smooth
        doubleClick={{ disabled: true }}
        onTransformed={handleTransformed}
      >
        {utils => (
          <>
            {!loading && (
              <ResizeButtonContainer direction="row" alignItems="center">
                <ResizeIconButton onClick={() => utils.zoomOut()} disableTouchRipple>
                  <Remove />
                </ResizeIconButton>
                <StyledSlider
                  aria-label="Volume"
                  sx={{ color: 'common.white' }}
                  value={zoomValue}
                  onChange={handleChange}
                  min={minScale}
                  max={maxScale}
                  valueLabelDisplay="auto"
                  valueLabelFormat={value => `${(value * 100).toFixed(0)}%`}
                  step={0.1}
                />
                <ResizeIconButton onClick={() => utils.zoomIn()} disableTouchRipple>
                  <Add />
                </ResizeIconButton>
              </ResizeButtonContainer>
            )}

            <TransformComponent
              wrapperStyle={{
                width: '100%',
                height: '100%',
              }}
              contentStyle={isMobileScreen ? { width: `${window.innerWidth}px` } : {}}
            >
              <StyledImage
                sx={{ display: loading ? 'none' : undefined }}
                onLoad={onLoad}
                src={url}
                alt={name}
                ref={imageRef}
              />
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
    </Box>
  );
}
