import { LoadingButton } from '@mui/lab';
import { styled, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { BillingAddressDialog } from 'components/common/Stripe/BillingAddressDialog';
import useProgressBar from 'global/hooks/useProgressBar';
import IApiError from 'global/interfaces/api';
import { IStripeOnBoarding, StripeOnboardType } from 'global/interfaces/payout';
import { useEffect, useState } from 'react';
import { createOnBoardingUrl } from 'services/payoutService';
import { showError } from 'utils/errorHandler';

interface IPayoutHandlerProps {
  btnText: string;
  openOnLoad?: boolean;
  onClose?: () => void;
  returnUrl?: string;
}

export const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  padding: '18px 36px',
  borderRadius: theme.spacing(6),

  [theme.breakpoints.down('sm')]: {
    padding: '12px 22px',
  },
}));

export function PayoutHandler({ btnText, openOnLoad, onClose, returnUrl }: IPayoutHandlerProps) {
  const [progress, showProgress] = useProgressBar();
  const [billingDialogOpen, setBillingDialogOpen] = useState(false);
  const [billingAddressLoaded, setBillingAddressLoaded] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOnBoardClick = (showSpinner: boolean): void => {
    setBillingDialogOpen(false);
    if (showSpinner) showProgress(true);
    createOnBoardingUrl({
      returnUrl: returnUrl,
    })
      .then((res: IStripeOnBoarding) => {
        if (res.type == StripeOnboardType.Url && (billingAddressLoaded || !openOnLoad)) {
          window.location.href = res.value;
        } else if (res.type == StripeOnboardType.AddressRequired) {
          setBillingDialogOpen(true);
          showProgress(false);
        }
        setBillingAddressLoaded(true);
      })
      .catch((err: IApiError) => {
        showProgress(false);
        showError(err);
      });
  };
  const handleBillingCancel = (): void => {
    onClose && onClose();
    closeAddressDialog();
  };

  useEffect(() => {
    if (openOnLoad === true) {
      handleOnBoardClick(false);
    }
  }, [open]);

  const closeAddressDialog = () => {
    setBillingDialogOpen(false);
    showProgress(false);
  };

  return (
    <>
      <StyledLoadingButton onClick={() => handleOnBoardClick(true)} variant="contained" fullWidth={isMobile}>
        {btnText}
      </StyledLoadingButton>
      <BillingAddressDialog
        handleCancel={handleBillingCancel}
        afterSaveCompletes={openOnLoad ? () => closeAddressDialog() : () => handleOnBoardClick(true)}
        open={billingDialogOpen}
        message="Please provide your billing details prior to adding your payout details"
      />
      {progress}
    </>
  );
}
