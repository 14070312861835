import { useCallback, useEffect, useState } from 'react';
import { pdfjs, Document } from 'react-pdf';
import 'pdfjs-dist/build/pdf.worker.entry';
pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import type { PDFDocumentProxy, PageViewport } from 'pdfjs-dist';
import { VariableSizeList as DynamicList } from 'react-window';
import PdfLoader from './PdfLoader';
import PdfPage from './PdfPage';

const options = {
  verbosity: process.env.NODE_ENV === 'development' ? pdfjs.VerbosityLevel.WARNINGS : pdfjs.VerbosityLevel.ERRORS,
};

const maxPages = 100;

interface IPdfDocumentProps {
  url: string;
  width: number;
  height: number;
  limitPages?: number;
}

export default function PdfDocument({ url, width, height, limitPages }: IPdfDocumentProps): JSX.Element {
  const [pdf, setPdf] = useState<PDFDocumentProxy | null>(null);
  const [pageViewports, setPageViewports] = useState<PageViewport[] | null>(null);

  const getPageCount = () => {
    if (!pdf) {
      return 0;
    }

    const newPageNum = limitPages ? Math.min(pdf.numPages, limitPages) : pdf.numPages;
    return Math.min(maxPages, newPageNum);
  };

  useEffect(() => {
    setPageViewports(null);

    if (!pdf) {
      return;
    }

    (async () => {
      const pageNumbers = Array.from(new Array(getPageCount())).map((_, index) => index + 1);

      const nextPageViewports = await Promise.all(
        pageNumbers.map(pageNumber => pdf.getPage(pageNumber).then(page => page.getViewport({ scale: 1 }))),
      );

      setPageViewports(nextPageViewports);
    })();
  }, [pdf]);

  const onDocumentLoadSuccess = useCallback((nextPdf: PDFDocumentProxy) => {
    setPdf(nextPdf);
  }, []);

  const getPageHeight = useCallback(
    (pageIndex: number) => {
      if (!pageViewports) {
        return 0;
      }

      const pageViewport = pageViewports[pageIndex];
      if (!pageViewport) {
        return 0;
      }

      const scale = width / pageViewport.width;
      const actualHeight = pageViewport.height * scale;

      return actualHeight;
    },
    [pageViewports],
  );

  return (
    <Document
      file={url}
      onLoadSuccess={onDocumentLoadSuccess}
      loading={<PdfLoader height={height} />}
      options={options}
    >
      {pdf && pageViewports ? (
        <DynamicList
          width={width}
          height={height}
          estimatedItemSize={height}
          itemCount={getPageCount()}
          itemSize={getPageHeight}
          itemData={{ width }}
          style={limitPages == 1 ? { height: 'unset' } : undefined}
        >
          {PdfPage}
        </DynamicList>
      ) : null}
    </Document>
  );
}
