import { SvgIcon, SvgIconProps } from '@mui/material';

export const VerifiedTickIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 32 32" {...props}>
    <g clipPath="url(#clip0_3463_35685)">
      <path
        d="M24.7108 5.66724C25.0148 6.40227 25.5981 6.98652 26.3327 7.29154L28.9085 8.35846C29.6436 8.66295 30.2276 9.24697 30.5321 9.98205C30.8366 10.7171 30.8366 11.5431 30.5321 12.2782L29.4659 14.8521C29.1613 15.5875 29.1609 16.4143 29.4669 17.1493L30.5312 19.7225C30.6821 20.0866 30.7598 20.4768 30.7599 20.8709C30.76 21.2651 30.6824 21.6553 30.5316 22.0195C30.3807 22.3836 30.1596 22.7144 29.8809 22.9931C29.6022 23.2717 29.2713 23.4927 28.9071 23.6434L26.3331 24.7096C25.5981 25.0135 25.0139 25.5969 24.7088 26.3314L23.6419 28.9072C23.3374 29.6423 22.7534 30.2263 22.0183 30.5308C21.2832 30.8353 20.4573 30.8353 19.7222 30.5308L17.1483 29.4647C16.4132 29.1609 15.5876 29.1615 14.8529 29.4664L12.2771 30.5318C11.5425 30.8355 10.7173 30.8353 9.98276 30.5311C9.24828 30.2268 8.66458 29.6435 8.35989 28.9092L7.29265 26.3326C6.98873 25.5976 6.40536 25.0134 5.67079 24.7083L3.09501 23.6414C2.36025 23.3371 1.7764 22.7534 1.47181 22.0188C1.16721 21.2841 1.1668 20.4586 1.47066 19.7236L2.53681 17.1497C2.84055 16.4146 2.83993 15.5889 2.53509 14.8543L1.47046 12.2766C1.31952 11.9126 1.2418 11.5223 1.24174 11.1282C1.24167 10.7341 1.31927 10.3438 1.4701 9.97966C1.62092 9.61553 1.84202 9.28469 2.12075 9.00605C2.39949 8.7274 2.7304 8.50641 3.09457 8.3557L5.6685 7.28954C6.40289 6.98589 6.98679 6.40325 7.29204 5.66953L8.35896 3.09375C8.66344 2.35867 9.24747 1.77464 9.98255 1.47016C10.7176 1.16568 11.5436 1.16568 12.2787 1.47016L14.8526 2.53632C15.5877 2.84005 16.4133 2.83943 17.148 2.53459L19.7248 1.47182C20.4598 1.1675 21.2856 1.16757 22.0205 1.47199C22.7554 1.77641 23.3394 2.36027 23.6439 3.09516L24.7112 5.6717L24.7108 5.66724Z"
        fill="#115FF8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2665 11.8058C22.4803 11.4701 22.5519 11.0631 22.4656 10.6746C22.3794 10.286 22.1423 9.94759 21.8065 9.73383C21.4708 9.52006 21.0638 9.44843 20.6753 9.5347C20.2867 9.62097 19.9483 9.85806 19.7345 10.1938L13.8605 19.4238L11.1725 16.0638C10.924 15.753 10.5622 15.5536 10.1667 15.5095C9.77118 15.4655 9.37436 15.5803 9.06352 15.8288C8.75269 16.0773 8.55331 16.4391 8.50923 16.8347C8.46516 17.2302 8.58001 17.627 8.82852 17.9378L12.8285 22.9378C12.9776 23.1244 13.1691 23.2727 13.387 23.3703C13.6049 23.468 13.843 23.5122 14.0815 23.4993C14.32 23.4864 14.5519 23.4167 14.758 23.2961C14.9641 23.1755 15.1385 23.0074 15.2665 22.8058L22.2665 11.8058Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_3463_35685">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
