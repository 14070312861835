import { SelectProps } from '@mui/material/Select';
import PortfolioPDF from './PortfolioPDF';
import PortfolioVideo from './PortfolioVideo';
import PortfolioImage from './PortfolioImage';
import PortfolioMediaProgress from './PortfolioVideoProgress';
import { FileType } from 'global/enums/fileTypes';
import React from 'react';
import { Box, FormHelperText, IconButton, styled, Tooltip, Typography, useTheme } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { StyledBox, StyledImageListItem, StyledImageListItemBar } from './PortfolioFilesStyles';
import { PortfolioAudio } from './PortfolioAudio';
import { audioText, videoText } from '../MediaProgress';
import { IFileMetadata } from 'global/interfaces/file';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';

const StyledDeleteIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: '0',
  right: '-12px',
}));

const StyledMoveLeftIconButton = styled(IconButton)(() => ({
  top: '0',
  position: 'absolute',
  left: '-28px',
}));

const StyledMoveRightIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: '0',
  left: '4px',
}));

const VideoNameContainer = styled(Box)(() => ({
  tableLayout: 'fixed',
  display: 'table',
  width: '100%',
}));

const VideoNameTypography = styled(Typography)(() => ({
  overflow: 'hidden',
  display: 'table-cell',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

const PortfolioTileContainer = styled(StyledBox)(() => ({
  cursor: 'pointer',
}));

export const PortfolioTileImages = styled(StyledImageListItem)(() => ({
  gap: '2px',
}));

export const PortfolioTileImageBar = styled(StyledImageListItemBar)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
  },
}));

const StyledPreviewOutlinedIcon = styled(WorkspacePremiumOutlinedIcon)(() => ({
  position: 'absolute',
  right: '5px',
  top: '50px',
}));

interface IPortfolioTilesProps extends SelectProps {
  files: IFileMetadata[];
  onClickFile?: (e: React.MouseEvent, index: number, fileType: FileType) => void;
  onDelete?: (path: string) => void;
  onMoveLeft?: (path: string) => void;
  onMoveRight?: (path: string) => void;
  onNameChange?: (path: string, fileName: string) => void;
  onChecked?: (path: string, fileName: string) => void;
  errors?: string[];
}

export default function PortfolioTiles(props: IPortfolioTilesProps) {
  const theme = useTheme();

  return (
    <>
      {props.files.map((item: IFileMetadata, i: number) => (
        <React.Fragment key={i}>
          <PortfolioTileImages key={i}>
            <PortfolioTileImageBar
              position="top"
              className="image-list-header-bar"
              actionIcon={
                <>
                  <StyledMoveLeftIconButton
                    disabled={i === 0}
                    onClick={(e: React.MouseEvent) => {
                      if (props.onMoveLeft !== undefined) {
                        e.stopPropagation();
                        props.onMoveLeft(item.id);
                      }
                    }}
                  >
                    <ArrowBackOutlinedIcon sx={{ fontSize: 16 }} />
                  </StyledMoveLeftIconButton>
                  <StyledMoveRightIconButton
                    disabled={i == props.files.length - 1}
                    onClick={(e: React.MouseEvent) => {
                      if (props.onMoveRight !== undefined) {
                        e.stopPropagation();
                        props.onMoveRight(item.id);
                      }
                    }}
                  >
                    <ArrowForwardOutlinedIcon sx={{ fontSize: 16 }} />
                  </StyledMoveRightIconButton>
                  <StyledDeleteIconButton
                    onClick={(e: React.MouseEvent) => {
                      if (props.onDelete !== undefined) {
                        e.stopPropagation();
                        props.onDelete(item.id);
                      }
                    }}
                  >
                    <ClearOutlinedIcon sx={{ fontSize: 16 }} />
                  </StyledDeleteIconButton>
                </>
              }
            />
            <PortfolioTileContainer
              onClick={(e: React.MouseEvent) => {
                if (props.onClickFile !== undefined) {
                  props.onClickFile(e, i, item.type);
                }
              }}
              sx={
                i === 0
                  ? {
                      border: `1px solid ${theme.palette.grey[900]}`,
                    }
                  : undefined
              }
            >
              {i === 0 && <StyledPreviewOutlinedIcon />}
              {item.type === FileType.Pdf && (
                <PortfolioPDF
                  id={item.id}
                  directory={item.directory}
                  extension={item.extension}
                  fileName={item.name}
                  onNameChange={props.onNameChange}
                />
              )}

              {item.type === FileType.Video &&
                (item.isProcessed ? (
                  <PortfolioVideo
                    id={item.id}
                    directory={item.directory}
                    extension={item.extension}
                    fileName={item.name}
                  />
                ) : (
                  <PortfolioMediaProgress key={i} message={videoText} />
                ))}

              {item.type === FileType.Audio &&
                (item.isProcessed ? (
                  <PortfolioAudio key={i} disableIcon={i === 0} />
                ) : (
                  <PortfolioMediaProgress key={i} message={audioText} />
                ))}

              {item.type === FileType.Image && (
                <PortfolioImage
                  id={item.id}
                  directory={item.directory}
                  extension={item.extension}
                  fileName={item.name}
                />
              )}
            </PortfolioTileContainer>

            {(item.type === FileType.Video || item.type === FileType.Audio) && item.isProcessed && (
              <Box style={{ height: i !== 0 ? '38px' : undefined }} />
            )}

            {(item.type === FileType.Video || item.type === FileType.Audio) && !item.isProcessed && (
              <Tooltip title={item.name}>
                <VideoNameContainer>
                  <VideoNameTypography variant="body2">{item.name}</VideoNameTypography>
                </VideoNameContainer>
              </Tooltip>
            )}

            {i === 0 && (
              <Typography variant="subtitle2">
                Featured
                <Tooltip
                  arrow
                  enterTouchDelay={0}
                  placement="top"
                  title="The first item in your portfolio is displayed in search results so make a good selection to help your profile to stand out"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        maxWidth: '320px',
                        borderRadius: '12px',
                      },
                    },
                  }}
                >
                  <IconButton color="default">
                    <InfoRoundedIcon color="inherit" />
                  </IconButton>
                </Tooltip>
              </Typography>
            )}

            {props.errors && props.errors[i] && <FormHelperText error>{props.errors[i]}</FormHelperText>}
          </PortfolioTileImages>
        </React.Fragment>
      ))}
    </>
  );
}
