import { IChatMessageDisplay } from 'global/interfaces/chatMessage';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { ContractType } from 'global/interfaces/contract';

export default function ChatMessageContractDeclined(props: IChatMessageDisplay): JSX.Element {
  return (
    <React.Fragment>
      <Box>
        {props.contract?.contractType == ContractType.FixedPrice && (
          <Typography variant="body2" color="grey.900">
            Fixed price contract &quot;{props.contract?.name}&quot; has been declined.
          </Typography>
        )}

        {props.contract?.contractType == ContractType.Retainer && (
          <Typography variant="body2" color="grey.900">
            Retainer contract &quot;{props.contract?.name}&quot; has been declined.
          </Typography>
        )}
      </Box>
    </React.Fragment>
  );
}
