import { styled } from '@mui/material';
import { SplideSlide } from '@splidejs/react-splide';
import { FileType } from 'global/enums/fileTypes';
import { makeBlobUrl, makeBunnyThumbnailUrl } from 'utils/file';
import '@splidejs/react-splide/css';
import { IFileMetadata } from 'global/interfaces/file';
import ImageDialog from './ImageDialog';
import PdfViewer from 'components/common/PdfViewer/PdfViewer';
import { memo, useState } from 'react';
import MusicImage from '../../../../assets/images/musicImg.webp';
import VideoPlayer from 'components/common/Media/VideoPlayer';
import AudioPlayer from 'components/common/Media/AudioPlayer';
const ViewSlideContainer = styled('div')`
  height: 100%;
  background-color: black;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  img,
  video,
  audio {
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
  }
`;

const StyledAudioWrapper = styled('div')(() => ({
  height: '100%',
  backgroundColor: '#6B7280',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const AudioContainer = styled(ViewSlideContainer)(() => ({
  backgroundImage: `url(${MusicImage})`,
  width: '93.5%',
  height: '93%',
}));

interface ICarouselSplideProps extends IFileMetadata {
  isActive: boolean;
}

function CarouselSplide({ id, directory, extension, type, name, isActive }: ICarouselSplideProps) {
  const url = makeBlobUrl(directory, id, extension);
  const [audioPaused, setAudioPaused] = useState<boolean>(true);

  const handleAudioPlay = () => {
    setAudioPaused(false);
  };

  return (
    <SplideSlide>
      {type === FileType.Video && (
        <ViewSlideContainer>
          <VideoPlayer
            url={url}
            muted={true}
            playing={isActive}
            hlsStartLevel={3}
            controls={true}
            thumbnailUrl={
              url.startsWith(process.env.REACT_APP_BUNNY_VIDEO_CDN_URL ?? '') ? makeBunnyThumbnailUrl(id) : undefined
            }
          />
        </ViewSlideContainer>
      )}
      {type === FileType.Audio && (
        <StyledAudioWrapper>
          <AudioContainer>
            <AudioPlayer
              url={url}
              muted={false}
              playing={isActive && !audioPaused}
              controls={true}
              onPlay={handleAudioPlay}
              renderImmedatiely={true}
            />
          </AudioContainer>
        </StyledAudioWrapper>
      )}
      {type === FileType.Pdf && <PdfViewer url={url} enabled={isActive} />}
      {type === FileType.Image && (
        <ViewSlideContainer>
          <ImageDialog imageUrl={url}>
            <img data-splide-lazy={url} alt={name} />
          </ImageDialog>
        </ViewSlideContainer>
      )}
    </SplideSlide>
  );
}

export default memo(CarouselSplide);
