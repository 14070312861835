import { Link } from 'react-router-dom';
import { Box, Checkbox, FormControlLabel, FormHelperText, Typography, styled } from '@mui/material';
import { ChangeEvent } from 'react';

interface IFormTermsAndConditionsProps {
  name?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  isAccepted: boolean;
  error?: string | boolean;
  touched?: boolean;
  className?: string;
  isSeller: boolean;
}

export const StyledCheckbox = styled(Checkbox)`
  padding-top: 0;
  padding-bottom: 0;
  align-self: baseline;
`;

export const FormTermsAndConditions = ({
  onChange,
  isAccepted,
  error,
  touched,
  className,
  name,
}: IFormTermsAndConditionsProps) => (
  <Box className={className}>
    <FormControlLabel
      control={<StyledCheckbox required onChange={onChange} name={name ?? 'termsAccepted'} checked={isAccepted} />}
      label={
        <Typography variant="caption">
          I accept the{' '}
          <Link to={'/terms'} target="_blank">
            terms and conditions
          </Link>
        </Typography>
      }
    />
    {touched && error && (
      <FormHelperText error>{error === true ? 'The terms and conditions must be accepted.' : error}</FormHelperText>
    )}
  </Box>
);
