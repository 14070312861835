import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';

export const MessageIcon = (props: SvgIconProps) => {
  const theme = useTheme();
  return (
    <SvgIcon {...props}>
      <svg width="24" height="22" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7 8H15M7 12H11M10 1H12C16.9706 1 21 5.02944 21 10C21 14.9706 16.9706 19 12 19H5C2.79086 19 1 17.2091 1 15V10C1 5.02944 5.02944 1 10 1Z"
          stroke={props.color ?? theme.palette.primary.main}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    </SvgIcon>
  );
};
