import { Stack, Tooltip, Typography, styled } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import AudiotrackOutlinedIcon from '@mui/icons-material/AudiotrackOutlined';
import VideocamIcon from '@mui/icons-material/Videocam';
import ImageIcon from '@mui/icons-material/Image';
import { FileType } from 'global/enums/fileTypes';
import { SvgIconComponent } from '@mui/icons-material';

const Wrapper = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: '10px',
  padding: '10px',
  cursor: 'pointer',
}));

const IconWrapper = styled(Stack)(({ theme }) => ({
  minWidth: '36px',
  width: '36px',
  height: '36px',
  borderRadius: '10px',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
}));

const TextWrapper = styled(Stack)(() => ({
  padding: '0 10px',
  overflow: 'hidden',
}));

const Title = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

interface ITypeSetting {
  Icon: SvgIconComponent;
  description: string;
}

const getSettings = (type: FileType): ITypeSetting => {
  switch (type) {
    case FileType.Video: {
      return {
        Icon: VideocamIcon,
        description: 'Video',
      };
    }
    case FileType.Audio: {
      return {
        Icon: AudiotrackOutlinedIcon,
        description: 'Audio',
      };
    }
    case FileType.Image: {
      return {
        Icon: ImageIcon,
        description: 'Image',
      };
    }
    default: {
      return {
        Icon: DescriptionIcon,
        description: 'File',
      };
    }
  }
};

interface IMediaNotSupportedProps {
  name: string;
  type: FileType;
  onClick?: () => void;
}

export default function MediaNotSupported({ name, type, onClick }: IMediaNotSupportedProps) {
  const { Icon, description } = getSettings(type);

  return (
    <Wrapper direction="row" alignItems="center" onClick={onClick}>
      <IconWrapper alignItems="center" justifyContent="center">
        <Icon sx={{ fontSize: '16px' }} />
      </IconWrapper>
      <TextWrapper>
        <Tooltip title={name}>
          <Title variant="subtitle1">{name}</Title>
        </Tooltip>
        <Typography variant="body2">{description}</Typography>
      </TextWrapper>
    </Wrapper>
  );
}
