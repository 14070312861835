import { SwipeableDrawer } from '@mui/material';
import { StyledDrawerGrid } from 'components/workRoom/drawerStyles';
import { IContract } from 'global/interfaces/contract';
import { ContractDisplay } from './contractDisplay';
import React, { useState } from 'react';

export const SwipeableContractDrawer: React.FC<{
  contract: IContract;
  drawer: boolean;
  setDrawer: React.Dispatch<boolean>;
}> = ({ contract, drawer, setDrawer }) => {
  const [refundOption, setRefundOption] = useState<boolean>(false);

  return (
    <SwipeableDrawer anchor="right" open={drawer} onClose={() => setDrawer(false)} onOpen={() => setDrawer(true)}>
      <StyledDrawerGrid container justifyContent="space-between">
        <ContractDisplay
          contract={contract}
          setDrawer={setDrawer}
          refundOption={refundOption}
          setRefundOption={setRefundOption}
          drawer={drawer}
        />
      </StyledDrawerGrid>
    </SwipeableDrawer>
  );
};
