import { Box, Stack, styled, Typography, useTheme } from '@mui/material';
import OpportunitySubscribeForm from './OpportunitySubscribeForm';
import { useContext, useEffect, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { AuthContext } from 'contexts/AuthContext';
import { getNewsletterPreferences } from 'services/newsletterService';

const StyledMainBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#743EE41A',

  borderRadius: '12px',
  display: 'flex',
  minHeight: '84px',
  marginTop: '30px',

  [theme.breakpoints.down('md')]: {
    minHeight: '306px',
  },
}));

export default function OpportunitySubscribe() {
  const [completed, setCompleted] = useState(false);
  const [show, setShow] = useState(false);
  const authContext = useContext(AuthContext);
  const theme = useTheme();

  useEffect(() => {
    setShow(false);
    if (!authContext.user) {
      setShow(true);
      return;
    }

    getNewsletterPreferences().then(res => {
      if (res) {
        setShow(false);
      } else {
        setShow(true);
      }
    });
  }, [authContext.user]);

  if (!show) {
    return <></>;
  }

  return (
    <StyledMainBox>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={1}
        alignItems={{ xs: 'stretch', md: 'center' }}
        justifyContent={{ xs: 'space-evenly', md: 'space-between' }}
        sx={{ p: 3, width: '100%' }}
        alignContent="stretch"
      >
        {!completed ? (
          <OpportunitySubscribeForm setCompleted={setCompleted} />
        ) : (
          <Box marginTop={1} display={'flex'} alignItems={'center'}>
            <Typography variant="subtitle1" color={theme.palette.common.black} fontWeight={600} component={'span'}>
              You have subscribed to our Opportunity Newsletter
            </Typography>
            <CheckCircleIcon color="success" sx={{ ml: 1 }} />
          </Box>
        )}
      </Stack>
    </StyledMainBox>
  );
}
