import {
  ContractPaymentStatus,
  ContractPayoutStatus,
  ContractStatus,
  ContractType,
  IContractDisplay,
} from 'global/interfaces/contract';
import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { FlexBox } from 'components/workRoom/chatMessage';
import ContractDetails from './contractDetails';
import CancelContract from './cancelContract';
import { RefundContract } from './RefundContract';
import { Button, Grid, useMediaQuery } from '@mui/material';
import RaiseDispute from './RaiseDispute';
import CompleteContract from './CompleteContract';

export const DeliverablesBox = styled(FlexBox)(() => ({
  alignItems: 'flex-start',
}));

const LinkButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.dark,
}));

const DISPUTE_PATH = '/disputes';

export interface IContractDisplayProps {
  contract: IContractDisplay;
  setDrawer?: React.Dispatch<boolean>;
  hideHeading?: boolean;
  successfulPaymentRedirect?: boolean;
  refundOption?: boolean;
  setRefundOption?: React.Dispatch<boolean>;
  drawer?: boolean;
}

export const ContractDisplay = ({
  contract,
  setDrawer,
  hideHeading,
  successfulPaymentRedirect,
  refundOption,
  setRefundOption,
  drawer,
}: IContractDisplayProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [cancelOption, setCancelOption] = useState<boolean>(false);
  const isBuyer = Boolean(contract.buyerAdditionalDetails);

  useEffect(() => {
    if (drawer === false) {
      setTimeout(() => {
        setCancelOption(false);
        setRefundOption && setRefundOption(false);
      }, 300);
    }
  }, [drawer]);

  const showButtons: boolean =
    !cancelOption && !refundOption && !successfulPaymentRedirect && location.pathname.toLowerCase() !== DISPUTE_PATH;

  return (
    <>
      {!cancelOption && !refundOption && (
        <ContractDetails
          contract={contract}
          setDrawer={setDrawer}
          hideHeading={hideHeading}
          successfulPaymentRedirect={successfulPaymentRedirect}
        />
      )}

      {!cancelOption && !refundOption && (
        <Grid container pl={!isMobile ? 2.6 : 1} pr={2} pb={2}>
          {showButtons &&
            (contract?.status === ContractStatus.Created ||
              contract.status === ContractStatus.Offered ||
              (contract.contractType === ContractType.Retainer && contract?.status === ContractStatus.InProgress)) && (
              <Grid item xs={6}>
                <LinkButton variant="text" onClick={() => setCancelOption(true)}>
                  Cancel Contract
                </LinkButton>
              </Grid>
            )}

          {showButtons &&
            contract.sellerAdditionalDetails &&
            //Can refund fixed price contracts that are in progress
            //For retainers, the current period can be refunded if it hasn't been paid out or fully refunded. PayoutStatus will be not required if fully refunded
            contract?.status === ContractStatus.InProgress &&
            (contract.contractType == ContractType.FixedPrice ||
              (contract.contractType == ContractType.Retainer &&
                contract.paymentStatus != ContractPaymentStatus.Awaiting &&
                contract.sellerAdditionalDetails.payoutStatus != ContractPayoutStatus.Success &&
                contract.sellerAdditionalDetails.payoutStatus != ContractPayoutStatus.NotRequired)) && (
              <Grid
                container
                item
                xs={6}
                justifyContent={contract.contractType === ContractType.FixedPrice ? 'flex-start' : 'flex-end'}
              >
                <LinkButton variant="text" onClick={() => setRefundOption && setRefundOption(true)}>
                  Give Refund
                </LinkButton>
              </Grid>
            )}

          {showButtons &&
            (contract.contractType === ContractType.FixedPrice ||
              (contract.contractType === ContractType.Retainer && isBuyer)) && (
              <Grid
                container
                item
                xs={6}
                justifyContent={
                  contract.contractType === ContractType.FixedPrice && isBuyer ? 'flex-start' : 'flex-end'
                }
                sx={{ maxHeight: '35px' }}
              >
                <RaiseDispute contract={contract} drawer={drawer} setDrawer={setDrawer} />
              </Grid>
            )}
          {showButtons && contract.contractType === ContractType.FixedPrice && isBuyer && (
            <Grid container item xs={6} justifyContent={'flex-end'} sx={{ maxHeight: '35px' }}>
              <CompleteContract contract={contract} drawer={drawer} setDrawer={setDrawer} />
            </Grid>
          )}
        </Grid>
      )}
      {cancelOption && <CancelContract contract={contract} setCancelOption={setCancelOption} setDrawer={setDrawer} />}
      {refundOption && <RefundContract contract={contract} setRefundOption={setRefundOption} setDrawer={setDrawer} />}
    </>
  );
};
