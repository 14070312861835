import { FormHelperText, Stack, useMediaQuery, useTheme } from '@mui/material';
import { AddressElement } from '@stripe/react-stripe-js';
import { StripeAddressElementChangeEvent } from '@stripe/stripe-js';
import useProgressBar from 'global/hooks/useProgressBar';
import IApiError from 'global/interfaces/api';
import { IBillingAddress } from 'global/interfaces/billingaddress';
import { FormEvent, useState, useEffect } from 'react';
import { getBillingAddress, upsertBillingAddress } from 'services/userService';
import { showError } from 'utils/errorHandler';
import { RoundButton } from '../Button/RoundButton';

interface IAddressFormProps {
  handleCancelClick: () => void;
  afterSaveCompletes?: () => void;
  actionButtonFullWidth: boolean;
}

function AddressForm(props: IAddressFormProps): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [billingAddress, setBillingAddress] = useState<IBillingAddress | undefined>(undefined);
  const [progress, showProgress] = useProgressBar();

  useEffect(() => {
    getBillingAddress()
      .then((res: IBillingAddress) => {
        setBillingAddress(res);
        setIsLoaded(true);
      })
      .catch((err: IApiError) => {
        showError(err);
      });
  }, []);

  const onChange = (event: StripeAddressElementChangeEvent) => {
    setIsValid(event.complete);
    setBillingAddress(event.value);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (isValid && billingAddress !== undefined) {
      showProgress(true);
      upsertBillingAddress(billingAddress)
        .then(res => {
          if (res && props.afterSaveCompletes !== undefined) {
            props.afterSaveCompletes();
          }
        })
        .catch((err: IApiError) => {
          showError(err);
        })
        .finally(() => {
          showProgress(false);
        });
    }
  };

  return (
    <form onSubmit={onSubmit} style={{ width: '100%' }}>
      {isLoaded && (
        <AddressElement
          options={{
            mode: 'billing',
            allowedCountries: [billingAddress?.address.country ?? ''],
            defaultValues: billingAddress,
            autocomplete: { mode: 'google_maps_api', apiKey: `${process.env.REACT_APP_GOOGLE_PLACES_KEY}` },
          }}
          onChange={onChange}
        />
      )}
      {isSubmitted && !isValid && <FormHelperText error>Please complete your billing address</FormHelperText>}

      <Stack direction="row" justifyContent="flex-end" gap={2} mt={5}>
        <RoundButton
          fullWidth={props.actionButtonFullWidth}
          variant="outlined"
          size={isMobile ? 'medium' : 'large'}
          onClick={props.handleCancelClick}
        >
          Cancel
        </RoundButton>
        <RoundButton
          fullWidth={props.actionButtonFullWidth}
          type="submit"
          variant="contained"
          size={isMobile ? 'medium' : 'large'}
        >
          Save
        </RoundButton>
      </Stack>
      {progress}
    </form>
  );
}

export default AddressForm;
