interface ISupportedMime {
  [key: string]: Array<string>;
}

export const supportedPortfolioFileTypes: ISupportedMime = {
  //Image formats
  '.jpg': ['image/jpeg'],
  '.jpeg': ['image/jpeg'],
  '.png': ['image/png'],
  '.webp': ['image/webp'],
  '.gif': ['image/gif'],
  '.tiff': ['image/tiff'],
  '.bmp': ['image/bmp'],

  //Video formats
  '.mp4': ['video/mp4'],
  '.mpeg': ['video/mpeg'],
  '.webm': ['video/webm'],
  '.mov': ['video/quicktime'],
  '.mkv': ['video/x-matroska'],
  '.f4v': ['video/f4v'],
  '.ogv': ['video/ogg'],
  '.3gp': ['video/3gpp'],
  '.wmv': ['video/x-ms-wmv'],
  '.avi': ['video/avi', 'video/msvideo', 'video/x-msvideo'],

  //Audio formats
  '.mp3': ['audio/mpeg'],
  '.aac': ['audio/aac', 'audio/vnd.dlna.adts', 'audio/x-hx-aac-adts', 'audio/x-aac'],
  '.wav': ['audio/wav', 'audio/wave', 'audio/x-wav'],
  '.flac': ['audio/flac'],
  '.m4a': ['audio/m4a', 'audio/x-m4a'],
  '.oga': ['audio/ogg'],
  '.ogg': ['audio/ogg'],

  //Other formats
  '.pdf': ['application/pdf'],
};

export function getSupportedMediaMimes() {
  const supportedMimes: string[] = Object.keys(supportedPortfolioFileTypes).map(k =>
    supportedPortfolioFileTypes[k].join(', '),
  );
  return supportedMimes.filter(m => m.includes('image') || m.includes('video') || m.includes('audio'));
}

const playableVideoContentTypes = [
  'video/mp4', //.video/mp4
  'video/webm', //.webm
  'video/quicktime', //.mov
  'video/x-matroska', //.mkv
  'video/f4v', //.f4v
  'video/ogg', //.ogv
  'video/3gpp', //.3gp
];

const playableAudioContentTypes = [
  'audio/mpeg', //.mp3
  'audio/vnd.dlna.adts', //.aac
  'audio/x-hx-aac-adts', //.aac
  'audio/aac', //.aac
  'audio/x-aac', //.aac
  'audio/wav', //.wav
  'audio/wave', //.wav
  'audio/x-wav', //.wav
  'audio/flac', //.flac
  'audio/m4a', //.m4a
  'audio/x-m4a', //.m4a
  'audio/ogg', //.oga, .ogg
];

export function canPlayInBrowser(extension: string, contentType?: string) {
  if (!contentType) {
    return true;
  }

  const canPlayVideo = playableVideoContentTypes.indexOf(contentType) >= 0;
  const canPlayAudio = playableAudioContentTypes.indexOf(contentType) >= 0 && extension != '.mp2';

  return canPlayVideo || canPlayAudio;
}
