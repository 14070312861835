import { Chip, Grid, styled } from '@mui/material';
import { IFrontUser } from 'global/interfaces/user';

interface IUserSkillsProps {
  user: IFrontUser;
}

export const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.black[200],
  color: theme.palette.black[500],
  paddingLeft: '2px',
  paddingRight: '2px',
}));

export default function UserSkills({ user }: IUserSkillsProps) {
  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" rowSpacing={0.5} columnSpacing={1}>
      {user.skills.map((skill, i) => (
        <Grid item key={i}>
          <StyledChip label={skill.value} size="small" />
        </Grid>
      ))}
    </Grid>
  );
}
