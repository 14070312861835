import IApiError from 'global/interfaces/api';
import { IUser, IUserFilters, IVettingViewMessage } from 'global/interfaces/user';
import { useEffect, useState } from 'react';
import { getUsers } from 'services/userService';
import { showError } from 'utils/errorHandler';
import { AdminUserList } from 'components/adminUsers/AdminUserList';
import { Grid, useTheme, useMediaQuery, Box, SelectChangeEvent, FormControlLabel, Switch, styled } from '@mui/material';
import { VettingStatus } from 'global/enums/vettingStatus';
import FormSelect from 'components/common/Select/FormSelect';
import CategorySelect from 'components/common/Select/CategorySelect';
import { OrderDirection } from 'global/enums/orderDirection';
import { useCustomEventListener } from 'react-custom-events';
import { MessagePublisherEventType } from 'global/enums/messagePublisherEventType';
import { useTitle } from 'utils/router';
import { nameof } from 'ts-simple-nameof';
import { useSearchParams } from 'react-router-dom';

const mapToSearchParams = (filters: IUserFilters): URLSearchParams => {
  const newSearchParams = new URLSearchParams();
  Object.entries(filters).forEach(([key, value]) => {
    if (value !== undefined && value !== '' && value !== '') {
      newSearchParams.append(key, value);
    }
  });
  return newSearchParams;
};

const mapToFilters = (searchParams: URLSearchParams): IUserFilters => {
  const isSellingServices = searchParams.get(nameof<IUserFilters>(p => p.isSellingServices));
  const vettingStatusString = searchParams.get(nameof<IUserFilters>(p => p.vettingStatus));

  return {
    category: searchParams.get(nameof<IUserFilters>(p => p.category)) ?? undefined,
    isSellingServices: isSellingServices ? isSellingServices.toLowerCase() == 'true' : undefined,
    orderBy: searchParams.get(nameof<IUserFilters>(p => p.orderBy)) ?? undefined,
    vettingStatus: VettingStatus[vettingStatusString as keyof typeof VettingStatus] ?? undefined,
  };
};

const parseOrderBy = (orderBy: string): { field: string; direction: OrderDirection } => {
  const orderingParts = orderBy.split(' ');
  const field = orderingParts[0];
  const direction = orderingParts[1] as OrderDirection;
  return { field, direction };
};

const makeOrderBy = (field: string, direction: OrderDirection) => `${field} ${direction}`;

const StyledBox = styled(Box)`
  margin: ${({ theme }) => theme.spacing(4)} 0;
  width: 100%;
`;

const pageSize = 50;

const AdminUsers = () => {
  useTitle('Admin - Vetting');
  const [users, setUsers] = useState<IUser[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState<number>();
  const [filters, setFilters] = useState<IUserFilters>(
    searchParams.toString() == ''
      ? {
          isSellingServices: true,
          orderBy: makeOrderBy(
            nameof<IUser>(x => x.vetting.submittedOn),
            OrderDirection.Ascending,
          ),
          vettingStatus: VettingStatus.InProgress,
        }
      : mapToFilters(searchParams),
  );
  const ordering = parseOrderBy(filters.orderBy ?? '');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.UserVettingView],
    (vettingView: IVettingViewMessage) => {
      setUsers(currentUsers =>
        currentUsers.map(user => {
          if (user.id === vettingView.userId) {
            return { ...user, vetting: { ...user.vetting, viewedOn: vettingView.viewedOn } };
          }
          return user;
        }),
      );
    },
  );

  const fetchUsers = (pageNumber = 1) => {
    getUsers({ ...filters, pageNumber, pageSize })
      .then((retrievedUsers: IUser[]) => {
        if (pageNumber > 1) {
          setUsers(currentUsers => [...currentUsers, ...retrievedUsers]);
        } else {
          setPageNumber(pageNumber);
          setUsers([...retrievedUsers]);
        }
      })
      .catch((err: IApiError) => {
        if (err.status !== 404) {
          showError(err);
        }
      });
  };

  useEffect(() => {
    fetchUsers();
    setSearchParams(mapToSearchParams(filters));
  }, [filters]);

  useEffect(() => {
    if (pageNumber && pageNumber > 1) {
      fetchUsers(pageNumber);
    }
  }, [pageNumber]);

  const handleVettingStatusChange = (e: SelectChangeEvent<unknown>): void =>
    setFilters(currentFilters => ({
      ...currentFilters,
      vettingStatus: VettingStatus[e.target.value as keyof typeof VettingStatus],
    }));

  const handleCategoryChange = (e: SelectChangeEvent<unknown>): void =>
    setFilters(currentFilters => ({ ...currentFilters, category: e.target.value as string }));

  const handleIsSellingServicesChange = (): void =>
    setFilters(currentFilters => ({ ...currentFilters, isSellingServices: !currentFilters.isSellingServices }));

  const handleRequestSort = (property: string): void => {
    const isAsc = ordering.field === property && ordering.direction === OrderDirection.Ascending;
    setFilters(currentFilters => ({
      ...currentFilters,
      orderBy: makeOrderBy(property, isAsc ? OrderDirection.Descending : OrderDirection.Ascending),
    }));
  };

  const handleNextPage = () => {
    if (users.length === pageSize * (pageNumber ?? 1)) {
      setPageNumber(currentPageNumber => (currentPageNumber ?? 1) + 1);
    }
  };

  const vettingStatusItems = Object.values(VettingStatus).map(vs => ({ id: vs.toString(), label: vs.toString() }));
  const items = [{ id: '', label: 'None' }, ...vettingStatusItems];

  return (
    <StyledBox
      sx={{
        paddingRight: isMobile ? 3 : 10,
        paddingLeft: isMobile ? 3 : 10,
      }}
    >
      <Grid container mb={1} spacing={1}>
        <Grid item xs={4} md={3} lg={2}>
          <FormSelect
            value={filters.vettingStatus ?? ''}
            items={items}
            label="VetttingStatus"
            onChange={handleVettingStatusChange}
          />
        </Grid>
        <Grid item xs={4} md={4} lg={2}>
          <CategorySelect value={filters.category ?? ''} label="Main Category" onChange={handleCategoryChange} />
        </Grid>
        <Grid item container xs={4} md={3} lg={2} alignItems="center">
          <FormControlLabel
            control={
              <Switch
                onChange={handleIsSellingServicesChange}
                name="isSellingServices"
                checked={filters.isSellingServices}
              />
            }
            label="Selling services"
          />
        </Grid>
      </Grid>
      <AdminUserList
        orderDirection={ordering.direction}
        orderBy={ordering.field}
        onRequestSort={handleRequestSort}
        users={users}
        onNextPage={handleNextPage}
      />
    </StyledBox>
  );
};

export default AdminUsers;
