import { IChatMessageDisplay } from 'global/interfaces/chatMessage';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { ConnectionFirstContact } from 'global/enums/connectionFirstContact';

interface IChatMessageConnectionAcceptedProps extends IChatMessageDisplay {
  currentUserId?: string;
}

export default function ChatMessageConnectionAccepted(props: IChatMessageConnectionAcceptedProps): JSX.Element {
  return (
    <React.Fragment>
      {props.connection?.actionUserId !== props.currentUserId &&
        props.connection?.firstContact === ConnectionFirstContact.Message && (
          <>
            <Typography variant="body2" color="grey.900" whiteSpace="break-spaces">
              {props.connection?.actionUserDisplayName} has accepted your connection request. You included the message:
            </Typography>
            <Typography variant="body2" color="grey.900" whiteSpace="break-spaces" pt={2}>
              {props.connection?.description}
            </Typography>
          </>
        )}
      {props.connection?.actionUserId !== props.currentUserId &&
        props.connection?.firstContact === ConnectionFirstContact.Quote && (
          <>
            <Typography variant="body2" color="grey.900" whiteSpace="break-spaces">
              {props.connection?.actionUserDisplayName} has accepted your request for a quote. You included the
              following brief:
            </Typography>
            <Typography variant="body2" color="grey.900" whiteSpace="break-spaces" pt={2}>
              {props.connection?.description}
            </Typography>
          </>
        )}
      {props.connection?.actionUserId !== props.currentUserId &&
        props.connection?.firstContact === ConnectionFirstContact.Package && (
          <>
            <Typography variant="body2" color="grey.900" whiteSpace="break-spaces">
              {props.connection?.actionUserDisplayName} has accepted your request to enquire about the &quot;
              {props.connection?.packageName}&quot; package. You included the following brief:
            </Typography>
            <Typography variant="body2" color="grey.900" whiteSpace="break-spaces" pt={2}>
              {props.connection?.description}
            </Typography>
          </>
        )}

      {props.connection?.actionUserId === props.currentUserId &&
        props.connection?.firstContact === ConnectionFirstContact.Message && (
          <>
            <Typography variant="body2" color="grey.900">
              You accepted the connection request from {props.connection?.otherUserDisplayName}.{' '}
              <b>They included this message:</b>
            </Typography>
            <Typography variant="body2" color="grey.900" whiteSpace="break-spaces" pt={2}>
              {props.connection?.description}
            </Typography>
          </>
        )}
      {props.connection?.actionUserId === props.currentUserId &&
        props.connection?.firstContact === ConnectionFirstContact.Quote && (
          <>
            <Typography variant="body2" color="grey.900">
              You accepted the request for a quote from {props.connection?.otherUserDisplayName}.{' '}
              <b>They included the following brief:</b>
            </Typography>
            <Typography variant="body2" color="grey.900" whiteSpace="break-spaces" pt={2}>
              {props.connection?.description}
            </Typography>
            <Typography variant="body2" color="grey.900" component="div" mt={2}>
              Please clarify the details with {props.connection?.otherUserDisplayName} and then{' '}
              <b>create the contract.</b>
            </Typography>
          </>
        )}
      {props.connection?.actionUserId === props.currentUserId &&
        props.connection?.firstContact === ConnectionFirstContact.Package && (
          <>
            <Typography variant="body2" color="grey.900">
              You accepted the request to enquire about the {props.connection?.packageName} package from{' '}
              {props.connection?.otherUserDisplayName}. <b>They included the following brief:</b>
            </Typography>
            <Typography variant="body2" color="grey.900" whiteSpace="break-spaces" pt={2}>
              {props.connection?.description}
            </Typography>
            <Typography variant="body2" color="grey.900" component="div" mt={2}>
              Please clarify the details with {props.connection?.otherUserDisplayName} and then{' '}
              <b>create the contract.</b>
            </Typography>
          </>
        )}
    </React.Fragment>
  );
}
