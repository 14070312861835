import Typography from '@mui/material/Typography';
import { IconButton, ImageListItem, ImageListItemBar, Stack, styled } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { FileType } from 'global/enums/fileTypes';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { IChatFileDisplay } from 'global/interfaces/chatMessage';
import MediaNotSupported from './items/MediaNotSupported';
import ChatMessageMediaVideo from './items/ChatMessageMediaVideo';
import ChatMessageMediaAudio from './items/ChatMessageMediaAudio';
import ChatMessageMediaImage from './items/ChatMessageMediaImage';
import ChatMessageMediaPdf from './items/ChatMessageMediaPdf';
import ChatMessageMediaLoader from './items/ChatMessageMediaLoader';
import { canPlayInBrowser } from 'global/Constants/SupportedFileTypes';
import { triggerFileDownload } from 'utils/file';

const EllipsisWrapperDiv = styled('div')`
  display: table;
  table-layout: fixed;
  width: 100%;
  white-space: nowrap;
`;

const EllipsisTypography = styled(Typography)(({ theme }) => ({
  display: 'table-cell',
  color: theme.palette.grey[900],
}));

EllipsisTypography.defaultProps = {
  noWrap: true,
};

const StyledImageListItemBar = styled(ImageListItemBar)(() => ({
  '.MuiImageListItemBar-titleWrap': {
    paddingBottom: '0px',
  },
}));

const DownloadButton = styled(IconButton)(() => ({
  padding: '0 0 0 3px',
}));

interface IChatMessageMediaItemProps {
  chatFile: IChatFileDisplay;
  numCols: number;
  onClick?: () => void;
}

export default function ChatMessageMediaItem({ chatFile, numCols, onClick }: IChatMessageMediaItemProps): JSX.Element {
  const canPlay =
    (chatFile.fileType == FileType.Video || chatFile.fileType == FileType.Audio) &&
    canPlayInBrowser(chatFile.extension, chatFile.contentType);
  const cols = canPlay ? Math.min(numCols, 2) : undefined;

  const isLoading = chatFile.loading !== undefined && chatFile.loading;
  const isSupported = canPlay || !!chatFile.thumbnailUrl;
  const shouldDisplay = !isLoading && isSupported;

  const handleDownload = () =>
    triggerFileDownload(
      `${process.env.REACT_APP_CDN_URL || ''}${chatFile.url}`,
      `${chatFile.fileName}${chatFile.extension}`,
    );

  return (
    <ImageListItem
      key={chatFile.fileName + chatFile.extension}
      sx={{
        marginTop: '6px',
        gridColumnStart: chatFile.newLine ? 1 : undefined,
        minWidth: 0,
      }}
      cols={cols}
    >
      {isLoading && <ChatMessageMediaLoader />}
      {!isLoading && !isSupported && (
        <MediaNotSupported
          name={chatFile.fileName + chatFile.extension}
          type={chatFile.fileType}
          onClick={handleDownload}
        />
      )}
      {shouldDisplay && chatFile.fileType == FileType.Video && (
        <ChatMessageMediaVideo {...chatFile} onDownload={handleDownload} />
      )}
      {shouldDisplay && chatFile.fileType == FileType.Audio && <ChatMessageMediaAudio {...chatFile} />}
      {shouldDisplay && chatFile.fileType == FileType.Image && (
        <ChatMessageMediaImage {...chatFile} onClick={onClick} />
      )}
      {shouldDisplay && chatFile.fileType == FileType.Pdf && <ChatMessageMediaPdf {...chatFile} onClick={onClick} />}

      {isSupported && (
        <StyledImageListItemBar
          title={
            <Stack direction="row" alignItems="center">
              <Tooltip title={chatFile.fileName + chatFile.extension}>
                <EllipsisWrapperDiv>
                  <EllipsisTypography variant="caption" fontWeight={400}>
                    {chatFile.fileName + chatFile.extension}
                  </EllipsisTypography>
                </EllipsisWrapperDiv>
              </Tooltip>
              {!(chatFile.fileType == FileType.Video || chatFile.fileType == FileType.Audio) && (
                <DownloadButton onClick={handleDownload}>
                  <CloudDownloadIcon sx={{ fontSize: '16px' }} />
                </DownloadButton>
              )}
            </Stack>
          }
          position="below"
        />
      )}
    </ImageListItem>
  );
}
