import { IEditableUser } from 'global/interfaces/user';
import { NotableClientHistoryEditor } from './NotableClientHistoryEditor';
import { FormikProps } from 'formik';
import { INotableClient } from 'global/interfaces/INotableClient';

interface IEditNotableClientSectionProps {
  form: FormikProps<IEditableUser>;
}

export const EditNotableClientSection = ({ form }: IEditNotableClientSectionProps) => {
  const onUpdateNotableCLients = (companies: INotableClient[]): void => {
    form.setFieldValue('notableClients', [...companies]);
  };

  return (
    <NotableClientHistoryEditor
      companies={form.values.notableClients ?? []}
      onUpdateNotableClients={onUpdateNotableCLients}
      error={
        form.touched.notableClients !== undefined &&
        Boolean(form.errors.notableClients) &&
        typeof form.errors.notableClients === 'string'
          ? form.errors.notableClients
          : undefined
      }
    />
  );
};
