import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  styled,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useContext, useEffect, useState } from 'react';
import { IAuditLog } from 'global/interfaces/IAuditLog';
import { getUserAuditLog } from 'services/auditService';
import { useParams } from 'react-router-dom';
import { showError } from 'utils/errorHandler';
import AuditRenderer from './auditRenderer';
import { AuthContext } from 'contexts/AuthContext';
import { UserRole } from 'global/enums/userRole';

const StyledAccordion = styled(Accordion)(() => ({
  borderRadius: '8px',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    padding: '0 8px 0 8px',
  },
}));

export default function UserAudit() {
  const [auditHistory, setAuditHistory] = useState<IAuditLog[]>();
  const [loading, setLoading] = useState(true);
  const authContext = useContext(AuthContext);
  const [opened, setOpened] = useState(false);
  const { id } = useParams();
  const hasAdminRole = authContext.user && authContext.user.roles.indexOf(UserRole.Administrator) > -1;

  useEffect(() => {
    if (opened && id && !auditHistory) {
      getUserAuditLog(id)
        .then(res => {
          setAuditHistory(res);
          setLoading(false);
        })
        .catch(showError);
    }
  }, [opened]);

  if (!hasAdminRole) {
    return <></>;
  }

  return (
    <StyledBox mb={4}>
      <StyledAccordion square={true} onChange={() => setOpened(true)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content">
          Audit
        </AccordionSummary>
        {loading ? (
          <AccordionDetails>
            <Grid container justifyContent="center">
              <CircularProgress />
            </Grid>
          </AccordionDetails>
        ) : (
          <>
            {!auditHistory || auditHistory.length === 0 ? (
              <AccordionDetails>
                <Typography variant="body2">No changes</Typography>
              </AccordionDetails>
            ) : (
              <AuditRenderer auditLogs={auditHistory} />
            )}
          </>
        )}
      </StyledAccordion>
    </StyledBox>
  );
}
