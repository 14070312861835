import { IConnection } from 'global/interfaces/connection';
import { ContractStatus, ContractType } from 'global/interfaces/contract';
import { SelectItem } from 'global/interfaces/selects';
import { defaultFilterSelectValue } from './contractFilters';
import { splitCamelCase } from 'utils/stringUtils';

export const mapContractStatusesToJson = (): SelectItem[] => {
  const models: SelectItem[] = [];

  Object.keys(ContractStatus).forEach(field => {
    let label = ContractStatus[field as keyof typeof ContractStatus].toString();
    if ((field as keyof typeof ContractStatus) === ContractStatus.InProgress) {
      label = 'In Progress';
    }
    models.push({ id: field, label: label });
  });

  return models;
};

export const mapConnectionsToSelect = (connections: IConnection[]): SelectItem[] => {
  if (connections.length <= 0) {
    return [{ id: defaultFilterSelectValue, label: 'No connections available' }];
  }

  return connections.map(c => ({
    id: c?.otherUser?.userId ?? defaultFilterSelectValue,
    label: c?.otherUser?.displayName ?? '',
  }));
};

export const mapContractTypesToSelect = (): SelectItem[] => {
  const models: SelectItem[] = [];

  Object.keys(ContractType).forEach(field => {
    const label = ContractType[field as keyof typeof ContractType].toString();
    models.push({ id: field, label: splitCamelCase(label) });
  });

  return models;
};
