import { Dialog, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import AddressForm from './AddressForm';
import StripeElements from './StripeElements';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';

export const BillingAddressDialog: React.FC<{
  handleCancel: () => void;
  afterSaveCompletes?: () => void;
  open: boolean;
  message: string;
}> = ({ handleCancel, afterSaveCompletes, open, message }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullScreen={isMobileScreen}
      open={open}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StyledDialogTitle>
        <Stack gap={1}>
          <Typography variant="h6" color={theme.palette.grey[900]}>
            Billing Address
          </Typography>
          <Typography variant="body2">{message}</Typography>
        </Stack>

        <IconButton onClick={handleCancel}>
          <CloseOutlinedIcon />
        </IconButton>
      </StyledDialogTitle>

      <StyledDialogContent dividers>
        <StripeElements>
          <AddressForm
            handleCancelClick={handleCancel}
            afterSaveCompletes={afterSaveCompletes}
            actionButtonFullWidth={false}
          />
        </StripeElements>
      </StyledDialogContent>
    </Dialog>
  );
};
