import { useEffect, useRef, useState } from 'react';
import { getFrontUsers, updateUserViews } from 'services/userService';
import { UserOrderingGroup } from 'global/enums/userOrderingGroup';
import { IFrontUserBase } from 'global/interfaces/user';
import { showError } from 'utils/errorHandler';
import { debounce, Grid } from '@mui/material';
import { VerticalCardSkeleton } from 'components/common/Skeleton/VerticalCardSkeleton';
import UserSection from 'components/findUser/UserSection';

export default function Talent() {
  const pageSize = 36;
  const [users, setUsers] = useState<IFrontUserBase[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [muted, setMuted] = useState<boolean>(true);

  const viewedUsers = useRef<string[]>([]);
  const viewedUsersQueue = useRef<string[]>([]);

  const [activeUserId, setActiveUserId] = useState('');

  useEffect(() => {
    getFrontUsers({ orderingGroup: UserOrderingGroup.Featured, pageSize })
      .then((responseUsers: IFrontUserBase[]) => {
        setUsers(responseUsers);
      })
      .catch(showError)
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const enqueueUserViewUpdate = debounce(() => {
    if (viewedUsersQueue.current.length > 0) {
      updateUserViews(viewedUsersQueue.current);
      viewedUsersQueue.current = [];
    }
  }, 200);

  const handleOnInViewUserCard = (userId: string): void => {
    const currentViewedUsers = viewedUsers.current;
    const isViewed = currentViewedUsers.includes(userId);
    if (!isViewed) {
      viewedUsers.current = [...new Set([...currentViewedUsers, userId])];
      viewedUsersQueue.current = [...new Set([...viewedUsersQueue.current, userId])];
      enqueueUserViewUpdate();
    }
  };

  const setActiveUser = (userId: string, isActive: boolean) => {
    setActiveUserId(isActive ? userId : '');
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        {loading && <VerticalCardSkeleton />}
        {users && users.length > 0 && (
          <>
            <UserSection
              title=""
              onInViewUserCard={handleOnInViewUserCard}
              users={users}
              muted={muted}
              setMuted={setMuted}
              activeUserId={activeUserId}
              setActiveUser={setActiveUser}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
}
