import { VirtualizedGridItemProps } from './VirtualizedGrid';
import { IFrontUser } from 'global/interfaces/user';
import VerticalUserCard from 'components/findUser/VerticalUserCard';
import Grid from '@mui/material/Grid';
import { useInView } from 'react-intersection-observer';
import React, { useCallback, useEffect } from 'react';
import { useTheme } from '@mui/system';
import { useMediaQuery } from '@mui/material';

const triggerBeforeN = 15;

export interface ICardWrapperProps extends VirtualizedGridItemProps<IFrontUser> {
  muted: boolean;
  setMuted: React.Dispatch<React.SetStateAction<boolean>>;
  onNextPage: () => void;
  usersLength: number;
  activeUserId: string;
  setActiveUser: (userId: string, isActive: boolean) => void;
}

export default function CardWrapper({
  items,
  columnCount,
  rowIndex,
  columnIndex,
  style,
  muted,
  setMuted,
  onNextPage,
  usersLength,
  activeUserId,
  setActiveUser,
}: ICardWrapperProps): JSX.Element {
  const index = rowIndex * columnCount + columnIndex;
  const [ref, inView] = useInView();

  if (index > items.length - 1) {
    return <></>;
  }

  useEffect(() => {
    if (inView) {
      onNextPage();
    }
  }, [inView]);

  const triggerInView = index === usersLength - triggerBeforeN;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const setActiveUserCallback = useCallback((userId: string, active: boolean) => {
    setActiveUser(userId, active);
  }, []);

  return (
    <Grid
      key={index}
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      mt={!isMobile ? 3 : undefined}
      ref={triggerInView ? ref : null}
      style={style}
    >
      <VerticalUserCard
        user={items[index]}
        muted={muted}
        setMuted={setMuted}
        isUserSection={false}
        isActiveUser={items[index].id == activeUserId}
        setActiveUser={setActiveUserCallback}
      />
    </Grid>
  );
}
