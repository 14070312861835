import {
  ContractDeliverableStatus,
  ContractStatus,
  IContract,
  IDeliverableForApproval,
} from 'global/interfaces/contract';
import { SelectItem } from 'global/interfaces/selects';
import { useEffect, useState } from 'react';
import { getOpenContracts } from 'services/contractService';
import { showError } from 'utils/errorHandler';
import { uniqBy } from 'lodash';

interface IUseContractDeliverablesResult {
  deliverables: IDeliverableForApproval[];
  deliverableUsers: SelectItem[];
}

export default function useContractDeliverables(
  open: boolean,
  chatThreadId?: string,
  selectedUserId?: string,
): IUseContractDeliverablesResult {
  const [contracts, setContracts] = useState<IContract[]>([]);
  const inProgressSellContracts = contracts.filter(
    p => (p.status === ContractStatus.InProgress || p.status === ContractStatus.Disputed) && p.sellerAdditionalDetails,
  );

  useEffect(() => {
    if (open && chatThreadId) {
      getOpenContracts(chatThreadId)
        .then((responseContracts: IContract[]) => {
          setContracts(responseContracts);
        })
        .catch(showError);
    }
  }, [open, chatThreadId]);

  const getDeliverables = () => {
    const deliverables: IDeliverableForApproval[] = [];

    const relevantContracts = selectedUserId
      ? inProgressSellContracts.filter(c => c.sellerAdditionalDetails?.buyerId == selectedUserId)
      : inProgressSellContracts;

    let lastContractId: string | null = null;
    for (const contract of relevantContracts) {
      const relevantDeliverables: IDeliverableForApproval[] = contract.deliverables.filter(
        p => p.status !== ContractDeliverableStatus.Approved,
      );

      for (const del of relevantDeliverables) {
        del.first = lastContractId !== contract.contractId;
        del.contractId = contract.contractId;
        del.contractName = contract.name;

        lastContractId = contract.contractId;
        deliverables.push(del);
      }
    }

    return deliverables;
  };

  const getRelevantUsers = (): SelectItem[] =>
    uniqBy(
      inProgressSellContracts
        .filter(c => c.sellerAdditionalDetails != null)
        .map(c => ({
          id: c.sellerAdditionalDetails!.buyerId,
          label: c.sellerAdditionalDetails!.buyerDisplayName,
        })),
      c => c.id,
    );

  const deliverableUsers = getRelevantUsers();
  const deliverables = getDeliverables();

  return { deliverables, deliverableUsers };
}
