import { Box, Container, Grid, useMediaQuery, useTheme } from '@mui/material';
import { UserList } from 'components/findUser/UserList';
import { IFrontUserBase, IFrontUserFilters } from 'global/interfaces/user';
import { useContext, useEffect, useState } from 'react';
import { getFrontUsers } from 'services/userService';
import { showError } from 'utils/errorHandler';
import { UserFilters } from 'components/findUser/UserFilters';
import { useTitle } from 'utils/router';
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import { useSearchParams } from 'react-router-dom';
import { nameof } from 'ts-simple-nameof';
import { LocalToUsd } from 'utils/currency';
import { fallbackContractCountry } from 'components/common/Select/CountrySelect';
import { AuthContext } from 'contexts/AuthContext';

const pageSize = 36;

const mapToSearchParams = (formProps: IFrontUserFilters): URLSearchParams => {
  const newSearchParams = new URLSearchParams();
  Object.entries(formProps).forEach(([key, value]) => {
    if (value !== undefined && value !== '') {
      newSearchParams.append(key, value);
    }
  });
  return newSearchParams;
};

const mapToFilters = (searchParams: URLSearchParams): IFrontUserFilters => {
  const maxHourlyRate = searchParams.get(nameof<IFrontUserFilters>(p => p.maxHourlyRate));
  const maxDeliveryMethod = searchParams.get(nameof<IFrontUserFilters>(p => p.maxDeliveryMethod));
  const pageNumber = searchParams.get(nameof<IFrontUserFilters>(p => p.pageNumber));

  return {
    search: searchParams.get(nameof<IFrontUserFilters>(p => p.search)) ?? undefined,
    category: searchParams.get(nameof<IFrontUserFilters>(p => p.category)) ?? undefined,
    country: searchParams.get(nameof<IFrontUserFilters>(p => p.country)) ?? undefined,
    maxHourlyRate: maxHourlyRate ? parseInt(maxHourlyRate) : undefined,
    maxDeliveryMethod: maxDeliveryMethod ? parseInt(maxDeliveryMethod) : undefined,
    pageNumber: pageNumber ? parseInt(pageNumber) : undefined,
  };
};

const ViewUsers = () => {
  useTitle('Search');
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<IFrontUserBase[]>([]);
  const { updateScrollY, scrollY } = useContext(GlobalStateContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const filters = mapToFilters(searchParams);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeUserId, setActiveUserId] = useState('');
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const freshLoad = users.length == 0 || !filters.pageNumber || filters.pageNumber == 1;
    if (freshLoad) {
      setLoading(true);
    }
    const requestFilters: IFrontUserFilters =
      freshLoad && filters.pageNumber
        ? { ...filters, pageSize: pageSize * filters.pageNumber, pageNumber: 1 }
        : { ...filters, pageSize };
    if (requestFilters.maxHourlyRate) {
      requestFilters.maxHourlyRate = LocalToUsd(
        requestFilters.maxHourlyRate,
        authContext.viewerCountry ?? fallbackContractCountry.code,
      );
    }
    getFrontUsers(requestFilters)
      .then((retrievedUsers: IFrontUserBase[]) => {
        if (filters.pageNumber && filters.pageNumber > 1) {
          setUsers(currentUsers => [...currentUsers, ...retrievedUsers]);
        } else {
          setUsers([...retrievedUsers]);
        }
        if (freshLoad) {
          //timeout is used to counter scroll to 0,0 on app.js
          setTimeout(() => {
            window.scrollTo(0, scrollY);
          });
        }
      })
      .catch(showError)
      .finally(() => {
        if (freshLoad || loading) {
          setLoading(false);
        }
      });
  }, [
    filters.category,
    filters.country,
    filters.maxDeliveryMethod,
    filters.maxHourlyRate,
    filters.search,
    filters.pageNumber,
  ]);

  const handleFilterChange = (newFilters: IFrontUserFilters) => {
    updateScrollY(0);
    const newSearchParams = mapToSearchParams({
      ...newFilters,
      pageNumber: undefined,
    });
    setSearchParams(newSearchParams);
  };

  const handleNextPage = () => {
    const pageNumber = filters?.pageNumber ?? 1;
    if (users.length === pageSize * pageNumber) {
      const newSearchFilters = { ...filters, pageNumber: pageNumber + 1 };
      setSearchParams(mapToSearchParams(newSearchFilters), { replace: true });
    }
  };

  const setActiveUser = (userId: string, isActive: boolean) => {
    setActiveUserId(isActive ? userId : '');
  };

  useEffect(() => {
    const handleScroll = () => {
      if ('requestAnimationFrame' in window) {
        requestAnimationFrame(() => {
          updateScrollY(window.scrollY);
        });
      } else {
        setTimeout(() => {
          updateScrollY(window.scrollY);
        }, 10);
      }
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Box bgcolor="grey.50" minHeight="calc(100vh - 160px)" pt={isMobileScreen ? '12px' : '44px'}>
      <Container>
        <Grid item xs={12}>
          <UserFilters filters={filters} onFilterChange={handleFilterChange} />
        </Grid>
        <Grid item xs={12}>
          <UserList
            users={users}
            isLoading={loading}
            onNextPage={handleNextPage}
            activeUserId={activeUserId}
            setActiveUser={setActiveUser}
          />
        </Grid>
      </Container>
    </Box>
  );
};

export default ViewUsers;
