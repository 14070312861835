import Footer from 'Footer';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useScrollBlock } from 'global/hooks/useBlockScroll';
import { useMediaQuery, useTheme } from '@mui/material';

const blockScrollRoutesInMobile = ['/workroom', '/disputes'];

function FooterContainer(): JSX.Element {
  const location = useLocation();
  const [showFooter, setShowFooter] = React.useState<boolean>(true);
  const [blockScroll, allowScroll] = useScrollBlock();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  React.useEffect(() => {
    const show =
      !location.pathname.toLowerCase().startsWith('/workroom') &&
      !location.pathname.toLowerCase().startsWith('/disputes') &&
      !location.pathname.toLowerCase().startsWith('/login') &&
      !location.pathname.toLowerCase().startsWith('/password') &&
      !location.pathname.toLowerCase().startsWith('/billing') &&
      !location.pathname.toLowerCase().startsWith('/signup') &&
      !location.pathname.toLowerCase().startsWith('/confirmation');
    setShowFooter(show);

    if (
      show ||
      (isMobile &&
        !blockScrollRoutesInMobile.some(blockRoute => location.pathname.toLowerCase().startsWith(blockRoute)))
    ) {
      allowScroll();
    } else {
      blockScroll();
    }
  }, [location, isMobile]);

  return <React.Fragment>{showFooter && <Footer />}</React.Fragment>;
}

export default FooterContainer;
