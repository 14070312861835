import { Box, Stack, Typography, styled } from '@mui/material';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import EmptyData from '../EmptyData';

const StyledWorkHistoryIcon = styled(WorkHistoryIcon)(() => ({
  height: '32px',
  width: '32px',
}));

export default function NoClientIcon(): JSX.Element {
  return (
    <Box
      sx={{
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        width: {
          xs: '60%',
          md: '20%',
        },
        margin: 'auto',
      }}
    >
      <Stack gap={2}>
        <EmptyData icon={<StyledWorkHistoryIcon color={'disabled'} />} />
        <Typography
          color={'grey'}
          sx={{
            textAlign: 'center',
          }}
        >
          No notable clients
        </Typography>
      </Stack>
    </Box>
  );
}
