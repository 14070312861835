export function autolink(content: string) {
  if (isValidUrl(content)) {
    return `<a href="${filterParagraph(content)}">${content}</a>`;
  }

  return content;
}

export function isValidUrl(urlString: string) {
  const inputElement = document.createElement('input');
  inputElement.type = 'url';
  inputElement.value = filterParagraph(urlString);

  if (!inputElement.checkValidity()) {
    return false;
  } else {
    return true;
  }
}

function filterParagraph(content: string): string {
  return content.replace('<p>', '').replace('</p>', '');
}
