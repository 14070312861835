import { ChatType, IChatUserThread } from 'global/interfaces/chatThread';
import * as React from 'react';
import { UserAuth } from 'contexts/AuthContext';
import { WorkroomLeftMenuOptions } from 'global/enums/workroomLeftMenuOptions';
import { ActiveContracts } from './activeContracts';
import { IContractDisplay } from 'global/interfaces/contract';

export interface IActiveContractsProps {
  selectedThread: IChatUserThread | undefined;
  user: UserAuth | null;
  handleMiddleClick: () => void;
  addMemberDialogOpen: boolean;
  setAddMemberDialogOpen: React.Dispatch<boolean>;
  setSelectedThread: React.Dispatch<React.SetStateAction<IChatUserThread | undefined>>;
  contracts: IContractDisplay[];
  isAdminScreen?: boolean;
  onCreateContractButtonClick: (isCreating: boolean) => void;
  isCreatingContract: boolean;
}

export default function RightMenu(props: IActiveContractsProps): JSX.Element {
  const [rightMenuValue, setRightMenuValue] = React.useState(WorkroomLeftMenuOptions['Active Contracts']);

  React.useEffect(() => {
    if (
      props.addMemberDialogOpen ||
      (rightMenuValue === WorkroomLeftMenuOptions.Members && props.selectedThread?.type === ChatType.Group)
    ) {
      setRightMenuValue(WorkroomLeftMenuOptions.Members);
    } else {
      setRightMenuValue(WorkroomLeftMenuOptions['Active Contracts']);
    }
  }, [props, props.selectedThread, props.addMemberDialogOpen, rightMenuValue]);

  return <ActiveContracts {...props} />;
}
