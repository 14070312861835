import { Box, styled, Typography } from '@mui/material';
import { FileType } from 'global/enums/fileTypes';
import { IFileMetadata } from 'global/interfaces/file';
import BlobImage from '../BlobFiles/BlobImage';
import PdfViewer from '../PdfViewer/PdfViewer';
import { makeBlobUrl, makeBunnyThumbnailUrl } from 'utils/file';
import { memo } from 'react';
import MediaProgress, { audioText, videoText } from '../MediaProgress';
import VideoPlayer from '../Media/VideoPlayer';
import AudioPlayer from '../Media/AudioPlayer';

const CarouselSlideImage = styled(BlobImage)(() => ({
  maxHeight: '100%',
  maxWidth: '100%',
  width: 'auto',
  height: 'auto',
}));

const StyledBox = styled(Box)(() => ({
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}));

const renderWithTitle = (children: JSX.Element, title: string) => (
  <>
    {children}
    <Box
      sx={{
        textAlign: 'center',
        pt: 3,
      }}
    >
      <Typography color={'grey.500'} sx={{ fontSize: '0.8rem' }}>
        {title}
      </Typography>
    </Box>
  </>
);

interface IPortfolioCarouselSlideProps extends IFileMetadata {
  isActive: boolean;
}

//TODO: remove this and use FileViewerCarouselDialog
function PortfolioCarouselSlide({
  type,
  id,
  directory,
  name,
  extension,
  isProcessed,
  isActive,
}: IPortfolioCarouselSlideProps) {
  const url = makeBlobUrl(directory, id, extension);
  if (type == FileType.Pdf) {
    return <PdfViewer url={url} enabled={isActive} />;
  }

  return (
    <StyledBox>
      {type === FileType.Image && <CarouselSlideImage url={url} alt={name} />}

      {type === FileType.Video &&
        (isProcessed ? (
          <VideoPlayer
            url={url}
            muted={true}
            playing={isActive}
            hlsStartLevel={3}
            controls={true}
            thumbnailUrl={
              url.startsWith(process.env.REACT_APP_BUNNY_VIDEO_CDN_URL ?? '') ? makeBunnyThumbnailUrl(id) : undefined
            }
          />
        ) : (
          renderWithTitle(<MediaProgress message={videoText} />, name)
        ))}
      {type === FileType.Audio &&
        (isProcessed ? (
          <>
            <AudioPlayer url={url} muted={false} playing={isActive} controls={true} />
          </>
        ) : (
          renderWithTitle(<MediaProgress message={audioText} />, name)
        ))}
    </StyledBox>
  );
}

export default memo(PortfolioCarouselSlide);
