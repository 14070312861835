import { useTitle } from 'utils/router';

import {
  Box,
  BoxProps,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { BannerStack } from 'components/info/Hero';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DoneIcon from '@mui/icons-material/Done';

import im4 from 'assets/images/lps/04-min.webp';
import im3 from 'assets/images/lps/03-min.webp';
import im2 from 'assets/images/lps/02-min.webp';
import im1 from 'assets/images/lps/01-min.webp';
import contracttilt from 'assets/images/lps/createcontract_tilt.svg';
import stripelogo from 'assets/images/lps/stripelogo.png';
import innovatelogo from 'assets/images/lps/innovatelogo.png';
import microsoftlogo from 'assets/images/lps/microsoftlogo.png';

import { useNavigate } from 'react-router-dom';
import Talent from './buyer/Talent';

interface StyledFeatureBoxProps extends BoxProps {
  isMobile?: boolean;
}

const StyledLogoImage = styled('img')(({ theme }) => ({
  height: '60px',
  [theme.breakpoints.down('lg')]: {
    height: '50px',
  },
  [theme.breakpoints.down('md')]: {
    height: '40px',
  },
}));

const StyledImBox = styled(Box)(() => ({
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  width: '100%',
  height: '100%',
  padding: '32px 24px',
  borderRadius: '12px',
}));

const StyledIm1Box = styled(StyledImBox, {
  shouldForwardProp: prop => prop != 'isMobile',
})<StyledFeatureBoxProps>(({ isMobile }) => ({
  backgroundImage: isMobile ? 'unset' : `url(${im1})`,
}));

const StyledIm2Box = styled(StyledImBox, {
  shouldForwardProp: prop => prop != 'isMobile',
})<StyledFeatureBoxProps>(({ isMobile }) => ({
  backgroundImage: isMobile ? 'none' : `url(${im2})`,
}));

const StyledIm3Box = styled(StyledImBox, {
  shouldForwardProp: prop => prop != 'isMobile',
})<StyledFeatureBoxProps>(({ isMobile }) => ({
  backgroundImage: isMobile ? 'unset' : `url(${im3})`,
}));

const StyledIm4Box = styled(StyledImBox, {
  shouldForwardProp: prop => prop != 'isMobile',
})<StyledFeatureBoxProps>(({ isMobile }) => ({
  backgroundImage: isMobile ? 'unset' : `url(${im4})`,
}));

const StyledDoneIcon = styled(DoneIcon)(() => ({
  width: '26px',
  height: '26px',
  color: '#F17522',
  marginRight: '8px',
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: '0px',
  marginTop: '5px',
  [theme.breakpoints.down('md')]: {
    marginTop: '2px',
  },
}));

const StyledFeatureBox = styled(Box, {
  shouldForwardProp: prop => prop != 'isMobile',
})<StyledFeatureBoxProps>(({ isMobile }) => ({
  borderRadius: '12px',
  border: '1px solid #FFF',
  background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, #FFF 100%)',

  backdropFilter: 'blur(6px)',
  display: 'flex',
  height: isMobile ? 'unset' : '320px',

  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '12px',
  flex: '1 0 0',
  cursor: 'pointer',
}));

const StyledWorkBox = styled(Box)(() => ({
  borderRadius: '100px 100px 0px 0px',
  background: 'rgba(254, 231, 154, 0.30)',
  padding: '8px 64px',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledInvoiceBox = styled(Box)(() => ({
  borderRadius: '20px',
  border: '1px solid #FFF',
  background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, #FFF 100%)',
  backdropFilter: 'blur(6px)',

  display: 'flex',
  padding: '24px 32px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '0px',
  flex: '1 0 0',
  alignSelf: 'stretch',
}));

const StyledTalentBox = styled(StyledWorkBox)(() => ({
  background: 'rgba(248, 192, 160, 0.30)',
}));

const JustifiedBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
}));

const StyledImage = styled('img')(() => ({
  width: '100%',
  maxWidth: '490px',
}));

const FeatureTypography = styled(Typography)(({ theme }) => ({
  lineHeight: '140%',
  fontSize: '16px',
  color: '#0C111D',
  fontWeight: 500,
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
  },
}));

const StyledArrowForwardIcon = styled(ArrowForwardIcon)(() => ({
  width: '24px',
  height: '24px',
  marginTop: '4px',
}));

export default function Info() {
  useTitle('Shoutt | Freelancing made better');
  const theme = useTheme();
  const navigate = useNavigate();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Box sx={{ background: 'linear-gradient(180deg, #FFF 0%, #FEF4ED 19.51%, #FCE1CF 100%)' }}>
        <Container maxWidth="lg">
          <BannerStack direction={'column'} rowGap={{ xs: 3, md: 5, lg: 7 }} alignItems={'center'}>
            <Typography
              variant="h3"
              fontSize={{ xs: '24px', md: '40px', lg: '56px' }}
              color={theme.palette.common.black}
              maxWidth={'900px'}
              fontWeight={700}
              textAlign={'center'}
              letterSpacing={'-1.68px'}
              lineHeight={{ xs: '32px', md: '56px', lg: '72px' }}
            >
              For businesses who value quality, and freelancers who deliver it.
            </Typography>
            <Typography
              variant="body1"
              fontSize={{ xs: '14px', md: '18px', lg: '20px' }}
              color={'#344054'}
              maxWidth={'900px'}
              fontWeight={400}
              textAlign={'center'}
              letterSpacing={'-0.4px'}
            >
              Shoutt is a commission free platform for freelance work. Hire or get hired across creative, marketing and
              technical fields with next level ease and simplicity.
            </Typography>
            <Grid container columnSpacing={3} rowSpacing={3}>
              <Grid item xs={12} md={6}>
                <StyledWorkBox>
                  <Typography variant="body2" textAlign={'center'} fontWeight={500} color={'#795D01'}>
                    FIND WORK
                  </Typography>
                </StyledWorkBox>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <StyledFeatureBox
                      onClick={() => {
                        navigate('/opportunities');
                      }}
                      isMobile={isMobile}
                    >
                      <StyledIm1Box isMobile={isMobile}>
                        <Stack direction={'column'} spacing={1.5}>
                          <JustifiedBox>
                            <Typography
                              variant="h6"
                              fontWeight={600}
                              fontSize={{ xs: '20px', lg: '24px' }}
                              lineHeight={'32px'}
                            >
                              Explore live opportunities
                            </Typography>
                            <StyledArrowForwardIcon />
                          </JustifiedBox>
                          <Typography variant="body2" color={'#344054'} lineHeight={'20px'}>
                            Check out our range of 1000+ curated freelancer opportunities each month, all less than 7
                            days old.
                          </Typography>
                        </Stack>
                      </StyledIm1Box>
                    </StyledFeatureBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledFeatureBox
                      onClick={() => {
                        navigate('/signup');
                      }}
                      isMobile={isMobile}
                    >
                      <StyledIm2Box isMobile={isMobile}>
                        <Stack direction={'column'} spacing={1.5}>
                          <JustifiedBox>
                            <Typography
                              variant="h6"
                              fontWeight={600}
                              fontSize={{ xs: '20px', lg: '24px' }}
                              lineHeight={'32px'}
                            >
                              Get listed on our marketplace
                            </Typography>
                            <StyledArrowForwardIcon />
                          </JustifiedBox>
                          <Typography variant="body2" color={'#344054'} lineHeight={'20px'}>
                            Create a free profile and get listed so clients can find and contact you directly, no
                            bidding required.
                          </Typography>
                        </Stack>
                      </StyledIm2Box>
                    </StyledFeatureBox>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledTalentBox>
                  <Typography variant="body2" textAlign={'center'} fontWeight={500} color={'#722E09'}>
                    FIND TALENT
                  </Typography>
                </StyledTalentBox>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <StyledFeatureBox
                      onClick={() => {
                        navigate('/newjob');
                      }}
                      isMobile={isMobile}
                    >
                      <StyledIm3Box isMobile={isMobile}>
                        <Stack direction={'column'} spacing={1.5}>
                          <JustifiedBox>
                            <Typography
                              variant="h6"
                              fontWeight={600}
                              fontSize={{ xs: '20px', lg: '24px' }}
                              lineHeight={'32px'}
                            >
                              Post a new opportunity
                            </Typography>
                            <StyledArrowForwardIcon />
                          </JustifiedBox>
                          <Typography variant="body2" color={'#344054'} lineHeight={'20px'}>
                            Post your opportunity and chat to qualified freelancers. No GPT pitches, no drama. Just pure
                            quality.
                          </Typography>
                        </Stack>
                      </StyledIm3Box>
                    </StyledFeatureBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <StyledFeatureBox
                      onClick={() => {
                        navigate('/talent');
                      }}
                      isMobile={isMobile}
                    >
                      <StyledIm4Box isMobile={isMobile}>
                        <Stack direction={'column'} spacing={1.5}>
                          <JustifiedBox>
                            <Typography
                              variant="h6"
                              fontWeight={600}
                              fontSize={{ xs: '20px', lg: '24px' }}
                              lineHeight={'32px'}
                            >
                              Explore vetted talent
                            </Typography>
                            <StyledArrowForwardIcon />
                          </JustifiedBox>
                          <Typography variant="body2" color={'#344054'} lineHeight={'20px'}>
                            Browse and search our range of creative, marketing and technical talent with ease.
                          </Typography>
                        </Stack>
                      </StyledIm4Box>
                    </StyledFeatureBox>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box width={'100%'} marginTop={{ xs: '30px', md: '50px' }}>
              <Grid container spacing={6}>
                <Grid item xs={12} md={4}>
                  <StyledImage src={contracttilt} />
                </Grid>
                <Grid item xs={12} md={8}>
                  <StyledInvoiceBox>
                    <Typography
                      variant="h4"
                      fontSize={{ xs: '24px', md: '30px', lg: '36px' }}
                      fontWeight={700}
                      maxWidth={'600px'}
                    >
                      Invoices, payment protection and contracts - all rolled into one{' '}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize={{ xs: '16px', lg: '18px' }}
                      mt={{ xs: '20px', md: '36px' }}
                      mb={'16px'}
                    >
                      Our nifty solution for safely handling transactions means both parties can focus on getting great
                      work done, no stress!
                    </Typography>
                    <Grid container columnSpacing={4}>
                      <Grid item xs={12} md={6}>
                        <List disablePadding>
                          <ListItem alignItems="flex-start" disableGutters dense>
                            <StyledListItemIcon>
                              <StyledDoneIcon />
                            </StyledListItemIcon>
                            <ListItemText>
                              <FeatureTypography variant="body1">
                                Issue and pay invoices with a range of methods
                              </FeatureTypography>
                            </ListItemText>
                          </ListItem>
                          <ListItem alignItems="flex-start" disableGutters dense>
                            <StyledListItemIcon>
                              <StyledDoneIcon />
                            </StyledListItemIcon>
                            <ListItemText>
                              <FeatureTypography variant="body1">Secure two way escrow protection</FeatureTypography>
                            </ListItemText>
                          </ListItem>
                        </List>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <List disablePadding>
                          <ListItem alignItems="flex-start" disableGutters dense>
                            <StyledListItemIcon>
                              <StyledDoneIcon />
                            </StyledListItemIcon>
                            <ListItemText>
                              <FeatureTypography variant="body1">
                                Contracts with clear cut terms to avoid headaches
                              </FeatureTypography>
                            </ListItemText>
                          </ListItem>
                          <ListItem alignItems="flex-start" disableGutters dense>
                            <StyledListItemIcon>
                              <StyledDoneIcon />
                            </StyledListItemIcon>
                            <ListItemText>
                              <FeatureTypography variant="body1">100% commission free</FeatureTypography>
                            </ListItemText>
                          </ListItem>
                        </List>
                      </Grid>
                    </Grid>
                  </StyledInvoiceBox>
                </Grid>
              </Grid>
            </Box>
            <Box
              width={'100%'}
              alignContent={'center'}
              alignItems={'center'}
              display={'flex'}
              flexDirection={'column'}
              mt={3}
            >
              <Typography
                variant="h3"
                fontSize={{ xs: '24px', md: '36px' }}
                color={theme.palette.common.black}
                maxWidth={'600px'}
                fontWeight={700}
                textAlign={'center'}
                lineHeight={{ xs: '32px', md: '44px' }}
                mb={{ xs: 1, md: 4 }}
              >
                Check out some of our awesome freelance talent
              </Typography>
              <Talent />
            </Box>
            <Stack
              padding={'48px 80px'}
              direction={'column'}
              alignItems="center"
              spacing={{ xs: 4, md: 6 }}
              sx={{ borderRadius: '20px', border: '1px solid #FFF', background: 'rgba(255, 255, 255, 0.60)' }}
              mb={3}
            >
              <Typography
                variant="body1"
                textAlign={'center'}
                fontFamily={'Inter'}
                fontSize={{ xs: '16px', md: '18px', lg: '20px' }}
              >
                We’re proud to partner with
              </Typography>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 4, md: 8 }} alignItems={'center'}>
                <StyledLogoImage src={microsoftlogo} />
                <StyledLogoImage src={innovatelogo} />
                <StyledLogoImage src={stripelogo} />
              </Stack>
            </Stack>
          </BannerStack>
        </Container>
      </Box>
    </>
  );
}
