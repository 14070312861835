import { Drawer, Grid, Typography, styled, useTheme } from '@mui/material';
import { StyledDrawerGrid } from 'components/common/StyledDrawerGrid';
import IApiError from 'global/interfaces/api';
import { IContractDisplay } from 'global/interfaces/contract';
import { useState } from 'react';
import { raiseDispute } from 'services/contractService';
import { showError } from 'utils/errorHandler';
import { RoundButton } from 'components/common/Button/RoundButton';
import { LoadingButton } from '@mui/lab';

export const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  padding: '18px 36px',
  borderRadius: theme.spacing(6),

  [theme.breakpoints.down('sm')]: {
    padding: '12px 22px',
  },
}));

export const RaiseDisputeDrawer: React.FC<{ contract: IContractDisplay; handleClose: () => void; open: boolean }> = ({
  contract,
  handleClose,
  open,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(false);

  const handleRaiseDispute = () => {
    setLoading(true);
    raiseDispute(contract.contractId)
      .then(() => {
        setLoading(false);
        handleClose();
      })
      .catch((err: IApiError) => {
        setLoading(false);
        showError(err);
      });
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StyledDrawerGrid container>
        <Grid item xs={12} mb={3}>
          <Typography variant="h6" color={theme.palette.grey[900]}>
            Raise Dispute
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            Are you sure you want to raise a dispute for this contract? Please try and resolve any issues with the
            buyer/seller in the first instance. A dispute should only be raised as a last resort.
          </Typography>
        </Grid>
        <Grid container mt={6} gap={2} flexWrap="nowrap">
          <RoundButton fullWidth variant="outlined" onClick={handleClose}>
            No
          </RoundButton>
          <StyledLoadingButton fullWidth loading={loading} variant="contained" onClick={() => handleRaiseDispute()}>
            Yes
          </StyledLoadingButton>
        </Grid>
      </StyledDrawerGrid>
    </Drawer>
  );
};
