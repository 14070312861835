import { SvgIcon, SvgIconProps } from '@mui/material';

export const PaymentRequestedIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 22 14" {...props}>
    <path
      d="M 17.914 11.292 L 13.763 9.12 L 17.914 6.953 M 1.429 5.286 L 18.096 5.286 L 18.096 3.786 C 18.096 2.853 18.096 2.386 17.914 2.03 C 17.755 1.716 17.5 1.461 17.186 1.301 C 16.829 1.12 16.363 1.12 15.429 1.12 L 4.096 1.12 C 3.163 1.12 2.696 1.12 2.339 1.301 C 2.026 1.461 1.771 1.716 1.611 2.03 C 1.429 2.386 1.429 2.853 1.429 3.786 L 1.429 10.12 C 1.429 11.053 1.429 11.52 1.611 11.876 C 1.771 12.19 2.026 12.445 2.339 12.605 C 2.696 12.786 3.163 12.786 4.096 12.786 L 15.763 12.786"
      stroke={props.style?.color ?? 'white'}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M 14.263 9.12 L 20.763 9.12"
      stroke={props.style?.color ?? 'white'}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
  </SvgIcon>
);
