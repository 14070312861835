import Select, { SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { FormHelperText } from '@mui/material';
import { ErrorSelectProps, SelectItem } from 'global/interfaces/selects';

interface FormSelectProps extends SelectProps, ErrorSelectProps {
  items: SelectItem[];
}

export default function FormSelect({ items, ...props }: FormSelectProps): JSX.Element {
  const labelSize = props.size && props.size == 'small' ? 'small' : undefined;

  return (
    <FormControl fullWidth>
      <InputLabel size={labelSize} error={props.error} sx={{ color: 'grey.900' }}>
        {props.label}
      </InputLabel>
      <Select {...props}>
        {items.map(item => (
          <MenuItem key={item.id} value={item.id}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {props.error === true && <FormHelperText error>{props.helpertext}</FormHelperText>}
    </FormControl>
  );
}
