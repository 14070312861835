import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import { showError } from 'utils/errorHandler';
import IApiError from 'global/interfaces/api';
import { object, string } from 'yup';
import * as React from 'react';
import { resetPassword } from 'services/authService';
import { Box, Button, styled, useMediaQuery, useTheme } from '@mui/material';
import { WelcomeGraphicContainer } from 'components/common/WelcomeGraphicContainer';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useTitle } from 'utils/router';
import { AuthForm } from 'components/common/form/AuthForm';

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  padding: '18px 36px',
  borderRadius: theme.spacing(6),

  [theme.breakpoints.down('sm')]: {
    padding: '18px 0',
  },
}));

const StyledHomeButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary[600],
  marginLeft: '-16px',
  padding: '18px 36px',
  borderRadius: theme.spacing(6),
}));

function ForgotPassword(): JSX.Element {
  useTitle('Forgot Password');
  const theme = useTheme();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const form = useFormik<{ email: string }>({
    initialValues: {
      email: '',
    },
    validationSchema: object({
      email: string()
        .required('Email is required')
        .max(150, 'Maximum of 150 characters allowed')
        .email('Invalid email address'),
    }),
    onSubmit: (values: { email: string }) => {
      setLoading(true);
      resetPassword(values)
        .then(() => {
          //successRedirect('/');
          setLoading(false);
          setSubmitted(true);
        })
        .catch((err: IApiError) => {
          //showProgress(false);
          setLoading(false);
          showError(err);
        });
    },
  });

  return (
    <section>
      <WelcomeGraphicContainer>
        <AuthForm onSubmit={form.handleSubmit}>
          {!submitted && (
            <>
              <Typography
                textAlign="center"
                variant={isMobile ? 'h5' : 'h4'}
                color={theme.palette.grey[900]}
                marginTop={2}
              >
                Forgot Password
              </Typography>
              <Typography textAlign="center" variant="body1" color={theme.palette.grey[500]} marginTop={1}>
                Please enter your registered email address
              </Typography>
              <Grid container spacing={3} marginTop={1}>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControl variant="standard">
                      <TextField
                        size={isMobile ? 'small' : 'medium'}
                        name="email"
                        label="Email"
                        variant="outlined"
                        onChange={form.handleChange}
                        error={form.touched.email !== undefined && Boolean(form.errors.email)}
                        helperText={form.touched.email !== undefined ? form.errors.email : ''}
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid container marginTop={4} justifyContent="center">
                <StyledLoadingButton
                  type="submit"
                  variant="contained"
                  fullWidth={isMobile}
                  size={isMobile ? 'medium' : 'large'}
                  loading={loading}
                >
                  Reset Password
                </StyledLoadingButton>
              </Grid>
            </>
          )}
          {submitted && (
            <>
              <Typography
                textAlign="center"
                variant={isMobile ? 'h5' : 'h4'}
                color={theme.palette.grey[900]}
                marginTop={1}
              >
                Check email
              </Typography>
              <Typography textAlign="center" variant="body1" color={theme.palette.grey[500]} marginTop={2}>
                You will receive a password reset link shortly if your account exits. Please note that the link expires
                in 30 minutes.
              </Typography>
              <Box marginTop={2} display="flex" justifyContent="center">
                <StyledHomeButton size="medium" variant="text" onClick={() => navigate('/')}>
                  Return to home page
                </StyledHomeButton>
              </Box>
            </>
          )}
        </AuthForm>
      </WelcomeGraphicContainer>
    </section>
  );
}

export default ForgotPassword;
