import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import UserSkills from './UserProfileHeadSection/UserSkills';
import { IFrontUser } from 'global/interfaces/user';

interface IUserSkillSection {
  user: IFrontUser;
}

const StackContainer = styled(Stack)(({ theme }) => ({
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(4),
  gap: theme.spacing(2),

  [theme.breakpoints.down('md')]: {
    paddingInline: '16px',
  },
}));

export default function UserSkillSection({ user }: IUserSkillSection) {
  return (
    <StackContainer>
      <Stack direction="row" alignItems="center" gap={2}>
        <Typography variant="h6">Skills</Typography>
      </Stack>

      <UserSkills user={user} />
    </StackContainer>
  );
}
