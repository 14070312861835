import { styled } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';

export const StyledCTAButtton = styled(RoundButton)(({ theme }) => ({
  paddingTop: '24px',
  paddingBottom: '24px',
  fontSize: '14px',
  backgroundColor: theme.palette.primary.dark,
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
  },
  '&:hover': {
    backgroundColor: theme.palette.primary[700],
  },
}));

export const StyledCTALightButtton = styled(StyledCTAButtton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  '&:hover': {
    borderColor: theme.palette.grey[400],
    backgroundColor: `${theme.palette.grey[200]} !important`,
  },
}));
