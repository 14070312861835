import { authorizedGet, authorizedPost } from './baseApiService';
import { INewsletterPreference } from 'global/interfaces/newsletter';

const baseUrl = 'newsletter';

export async function subscribeToNewsletter(body: INewsletterPreference): Promise<void> {
  return await authorizedPost(`${baseUrl}`, body);
}

export async function getNewsletterPreferences(newsletterId?: string): Promise<INewsletterPreference> {
  return await authorizedGet(`${baseUrl}${newsletterId ? `?newsletterid=${newsletterId}` : ''}`);
}
