import { Page } from 'react-pdf';
import { ListChildComponentProps } from 'react-window';
import PdfLoader from './PdfLoader';

const spaceInPxBetweenPages = 10;

export default function PdfPage({
  index,
  style,
  data: { width },
}: ListChildComponentProps<{
  width: number;
}>) {
  const height = style.height ? +style.height - spaceInPxBetweenPages : undefined;
  return (
    <div
      style={{
        ...style,
        top: style.top ? +style.top + spaceInPxBetweenPages * index : undefined,
        height: style.height ? +style.height - spaceInPxBetweenPages : undefined,
      }}
    >
      <Page pageIndex={index} width={width} loading={<PdfLoader height={height} />} />
    </div>
  );
}
