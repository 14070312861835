import { SvgIcon, SvgIconProps } from '@mui/material';

export const RepeatIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M13 22.5L10 19.5M10 19.5L13 16.5M10 19.5H15C18.866 19.5 22 16.366 22 12.5C22 9.7076 20.3649 7.2971 18 6.17363M6 18.8264C3.63505 17.7029 2 15.2924 2 12.5C2 8.63401 5.13401 5.5 9 5.5H14M14 5.5L11 2.5M14 5.5L11 8.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
