import {
  Box,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  SelectChangeEvent,
  Stack,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import FormSelect from 'components/common/Select/FormSelect';
import { processOpportunityCategories } from 'components/opportunities/categories';
import {
  OppAdminAction,
  OppAdminConfirmationDialog,
} from 'components/opportunities/internal/OppAdminConfirmationDialog';
import OppFilters from 'components/opportunities/OppFilters';
import OpportunityAccordion from 'components/opportunities/OpportunityAccordion';
import {
  ApplyInstructionsType,
  IOpportunity,
  IOpportunityFilters,
  OpportunityStatus,
  OpportunityType,
} from 'global/interfaces/opportunity';
import { SelectItem } from 'global/interfaces/selects';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { getAdminOpportunities } from 'services/opportunityService';
import { nameof } from 'ts-simple-nameof';
import { showError } from 'utils/errorHandler';
import { useTitle } from 'utils/router';
import { mapToSearchParams } from 'utils/search';

const mapToFilters = (searchParams: URLSearchParams): IOpportunityFilters => {
  const statusString = searchParams.get(nameof<IOpportunityFilters>(p => p.status));
  const typeString = searchParams.get(nameof<IOpportunityFilters>(p => p.type));
  const isAssisted = searchParams.get(nameof<IOpportunityFilters>(p => p.isAssisted));
  return {
    status: OpportunityStatus[statusString as keyof typeof OpportunityStatus] ?? undefined,
    type: OpportunityType[typeString as keyof typeof OpportunityType] ?? undefined,
    isAssisted: isAssisted?.toLowerCase() == 'true' ? true : false,
  };
};

export default function ViewAdminOpporunities() {
  useTitle('Admin - Manage Opportunities');
  const [opportunities, setOpportunities] = useState<IOpportunity[]>([]);
  const [filteredOpps, setFilteredOpps] = useState<IOpportunity[]>([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [categories, setCategories] = useState<SelectItem[]>([
    {
      id: 'All',
      label: 'All',
    },
  ]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<IOpportunityFilters>(
    searchParams.toString() == ''
      ? {
          status: OpportunityStatus.UnderReview,
          isAssisted: false,
          type: 'All',
        }
      : mapToFilters(searchParams),
  );
  const [open, setOpen] = useState(false);
  const [selectedOpportunity, setSelectedOpportunity] = useState<IOpportunity>();
  const [dialogType, setDialogType] = useState<OppAdminAction>();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const processCategories = (retrievedOpps: IOpportunity[]) => {
    const items = processOpportunityCategories(retrievedOpps);
    setCategories(items);
  };

  useEffect(() => {
    processCategories(opportunities);
  }, [opportunities]);

  const fetchOpps = () => {
    getAdminOpportunities(filters)
      .then((retrievedOpps: IOpportunity[]) => {
        setOpportunities(retrievedOpps);
        setFilteredOpps(retrievedOpps);
      })
      .catch(showError)
      .finally(() => {
        setLoading(false);
        setSearchParams(mapToSearchParams(filters));
        setRefresh(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchOpps();
  }, [filters]);

  useEffect(() => {
    if (refresh) {
      setLoading(true);
      fetchOpps();
    }
  }, [refresh]);

  const statusItems = Object.values(OpportunityStatus).map(os => ({
    id: os.toString(),
    label: os.toString(),
  }));

  const typeItems = [
    {
      id: 'All',
      label: 'All',
    },
  ].concat(
    Object.values(OpportunityType).map(os => ({
      id: os.toString(),
      label: os.toString(),
    })),
  );

  const handleStatusChange = (e: SelectChangeEvent<unknown>): void =>
    setFilters(currentFilters => ({
      ...currentFilters,
      status: OpportunityStatus[e.target.value as keyof typeof OpportunityStatus],
    }));

  const handleTypeChange = (e: SelectChangeEvent<unknown>): void =>
    setFilters(currentFilters => ({
      ...currentFilters,
      type: e.target.value == 'All' ? 'All' : OpportunityType[e.target.value as keyof typeof OpportunityType],
    }));

  const handleIsAssistedChange = (): void =>
    setFilters(currentFilters => ({ ...currentFilters, isAssisted: !currentFilters.isAssisted }));

  const showDialog = (opportunity: IOpportunity, oppAdminAction: OppAdminAction) => {
    setSelectedOpportunity(opportunity);
    setDialogType(oppAdminAction);
    setOpen(true);
  };

  return (
    <Container maxWidth="lg">
      <Grid container direction={'row-reverse'} mt={4} mb={-5} spacing={2}>
        <Grid item xs={12} md={3}>
          <FormSelect
            value={filters.status}
            label="Status"
            items={statusItems}
            onChange={handleStatusChange}
            size={'small'}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormSelect
            value={filters.type}
            label="Type"
            items={typeItems}
            onChange={handleTypeChange}
            size={'small'}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <FormControl></FormControl>
          <FormControlLabel
            control={<Switch name="isAssisted" checked={filters.isAssisted} onChange={handleIsAssistedChange} />}
            label="Is Assisted"
          />
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
          <OppFilters
            categories={categories}
            setFilteredOpps={setFilteredOpps}
            opportunities={opportunities}
            setLoading={setLoading}
          ></OppFilters>
        </Grid>
        {loading && (
          <Grid item xs={12} sx={{ mb: 5 }} md={9}>
            <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height="19vh" mt={{ xs: 0, md: 5 }}>
              <CircularProgress />
            </Box>
          </Grid>
        )}
        {!loading && (
          <Grid item xs={12} md={9} sx={{ mb: 6 }}>
            <Box mt={{ xs: 0, md: 5 }}>
              <Typography variant="subtitle1" mb={2.25}>
                {filteredOpps.length} new {filteredOpps.length == 1 ? 'opportunity' : 'opportunities'}
              </Typography>
              {filteredOpps.map((opportunity: IOpportunity, index: number) => (
                <OpportunityAccordion
                  key={index}
                  opportunity={opportunity}
                  loggedIn={true}
                  extraHeaderInfo={
                    <>
                      {(opportunity.type == OpportunityType.Shoutt ||
                        (opportunity.type == OpportunityType.External && opportunity.isAssisted)) && (
                        <>
                          <span style={{ marginRight: '10px' }}>{'•'}</span>
                          <span style={{ marginRight: '10px' }}>{opportunity.tokenViews} app page view(s)</span>
                        </>
                      )}
                      <span style={{ marginRight: '10px' }}>{'•'}</span>
                      <Link
                        to={`/applicants/${opportunity.id}`}
                        target="_blank"
                        onClick={e => {
                          e.stopPropagation();
                        }}
                      >
                        <span style={{ marginRight: '10px' }}>{opportunity.applicantCount} applicant(s)</span>
                      </Link>
                    </>
                  }
                >
                  <Stack direction="column" spacing={1.5} width="100%">
                    {(opportunity.type == OpportunityType.Shoutt ||
                      (opportunity.type == OpportunityType.External && opportunity.isAssisted)) && (
                      <Typography variant={isMobile ? 'subtitle2' : 'subtitle1'} fontWeight={600}>
                        Contact Email:{' '}
                        {opportunity.type == OpportunityType.External && opportunity.isAssisted
                          ? opportunity.assistedEmail
                          : opportunity.email}
                      </Typography>
                    )}
                    {opportunity.type == OpportunityType.External && (
                      <Box>
                        <Typography variant={isMobile ? 'subtitle2' : 'subtitle1'} fontWeight={600}>
                          Apply Instructions:{' '}
                        </Typography>
                        {opportunity.applyInstructionsType == ApplyInstructionsType.Url ? (
                          <Link to={opportunity.applyInstructions ?? ''} target="_blank">
                            <Typography
                              variant={isMobile ? 'body2' : 'body1'}
                              mt={0.5}
                              color={theme.palette.primary.dark}
                              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                            >
                              {opportunity.applyInstructions}
                            </Typography>
                          </Link>
                        ) : (
                          <Typography variant={isMobile ? 'body2' : 'body1'} mt={0.5}>
                            {opportunity.applyInstructions}
                          </Typography>
                        )}
                      </Box>
                    )}
                    <Stack direction="row" spacing={1}>
                      {opportunity.status !== OpportunityStatus.Approved && (
                        <RoundButton
                          variant="contained"
                          onClick={() => {
                            showDialog(opportunity, OppAdminAction.Approve);
                          }}
                        >
                          Approve
                        </RoundButton>
                      )}
                      <RoundButton
                        variant="outlined"
                        onClick={() => navigate(`/admin/internal-jobs/${opportunity.id}`)}
                      >
                        Edit
                      </RoundButton>
                      {opportunity.status !== OpportunityStatus.Rejected && (
                        <RoundButton
                          variant="outlined"
                          color="error"
                          onClick={() => {
                            showDialog(opportunity, OppAdminAction.Reject);
                          }}
                        >
                          Reject
                        </RoundButton>
                      )}
                    </Stack>
                  </Stack>
                </OpportunityAccordion>
              ))}
            </Box>
          </Grid>
        )}
      </Grid>
      <OppAdminConfirmationDialog
        open={open}
        setOpen={setOpen}
        opportunity={selectedOpportunity}
        setRefresh={setRefresh}
        action={dialogType}
      />
    </Container>
  );
}
