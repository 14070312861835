import { UserProfileTabsValue } from 'global/enums/userProfileEnum';
import { IUser } from 'global/interfaces/user';
import { nameof } from 'ts-simple-nameof';

export const sectionToTabs: Record<UserProfileTabsValue, string[]> = {
  [UserProfileTabsValue.Profile]: [
    nameof<IUser>(x => x.profileImage),
    nameof<IUser>(x => x.email),
    nameof<IUser>(x => x.firstName),
    nameof<IUser>(x => x.lastName),
    nameof<IUser>(x => x.title),
    nameof<IUser>(x => x.country),
    nameof<IUser>(x => x.countryEditable),
    nameof<IUser>(x => x.bio),
    nameof<IUser>(x => x.languages),
    nameof<IUser>(x => x.skills),
    nameof<IUser>(x => x.links),
    nameof<IUser>(x => x.mainCategory),
    nameof<IUser>(x => x.secondCategory),
    nameof<IUser>(x => x.notableClients),
  ],
  [UserProfileTabsValue.SellService]: [nameof<IUser>(x => x.hourlyRate), nameof<IUser>(x => x.fixedPackages)],
  [UserProfileTabsValue.Portfolio]: [nameof<IUser>(x => x.portfolioFiles), nameof<IUser>(x => x.isUploading)],
};
