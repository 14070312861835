import { FilterList } from '@mui/icons-material';
import { Badge, IconButton, styled } from '@mui/material';

const StyledFilterButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary[600],
}));

interface FilterIconButtonProps {
  onClick: (event: React.KeyboardEvent | React.MouseEvent) => void;
  isFilterApplied: boolean;
}

export const FilterIconButton = ({ onClick, isFilterApplied }: FilterIconButtonProps) => (
  <StyledFilterButton onClick={onClick}>
    <Badge invisible={!isFilterApplied} color="primary" variant="dot">
      <FilterList />
    </Badge>
  </StyledFilterButton>
);
