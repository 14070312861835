import { styled } from '@mui/material';
import { useRef, useState } from 'react';
import useCalculatedHeight from './useCalculatedHeight';

const StyledImage = styled('img')(({ theme }) => ({
  maxWidth: '400px',
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: '10px',
}));

export interface IChatMessageMediaImageProps {
  thumbnailUrl?: string;
  fileName: string;
  extension: string;
  onClick?: () => void;
}

export default function ChatMessageMediaImage({
  thumbnailUrl,
  fileName,
  extension,
  onClick,
}: IChatMessageMediaImageProps): JSX.Element {
  const imageRef = useRef<HTMLImageElement>(null);
  const [loaded, setLoaded] = useState<boolean>(false);

  const onImageLoad = (): void => {
    setLoaded(true);
    if (imageRef.current != null) {
      const element = imageRef.current;
      element.style.height = '';
    }
  };

  useCalculatedHeight(imageRef.current, loaded);

  return (
    <StyledImage
      src={`${process.env.REACT_APP_CDN_URL ?? ''}${thumbnailUrl}`}
      alt={fileName + extension}
      ref={imageRef}
      onLoad={onImageLoad}
      className="MuiImageListItem-img"
      loading="lazy"
      onClick={onClick}
      sx={{ cursor: onClick ? 'pointer' : undefined }}
    />
  );
}
