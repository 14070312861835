import { createContext } from 'react';
import { HubConnection } from '@microsoft/signalr';
import { UserRole } from 'global/enums/userRole';
import { VettingStatus } from 'global/enums/vettingStatus';

export interface UserAuth {
  id: string;
  firstName: string;
  lastName: string;
  country: string;
  title: string;
  roles: UserRole[];
  vettingStatus: VettingStatus;
  email: string;
  isSellingServices: boolean;
  feedback: string;
  dismissVetting?: boolean;
  profileImageId?: string;
  subscriptionActive: boolean;
  mustChooseSubscription: boolean;
}

export interface AuthType {
  loading: boolean;
  user: UserAuth | null;
  hubConnection: HubConnection | null;
  refresh: () => Promise<UserAuth>;
  viewerCountry: string | undefined;
  setCountryCode: (countryCode: string) => void;
  vettingMessageDismissed?: boolean;
  setVettingMessageDismissed: () => void;
}

export const defaultAuthContext: AuthType = {
  loading: true,
  user: null,
  hubConnection: null,

  refresh(): Promise<UserAuth> {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return new Promise<UserAuth>(() => {});
  },
  viewerCountry: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCountryCode(): void {},
  vettingMessageDismissed: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setVettingMessageDismissed(): void {},
};

export const getCachedAuthContext = (): AuthType => {
  const item = localStorage.getItem('loggedInUser');
  const user = item !== undefined && item !== null ? (JSON.parse(item) as UserAuth) : null;
  return {
    loading: true,
    user: user !== undefined && user !== null ? user : null,
    hubConnection: null,
    refresh(): Promise<UserAuth> {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return new Promise<UserAuth>(() => {});
    },
    viewerCountry: user !== undefined && user !== null ? user.country : undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setCountryCode(): void {},
    vettingMessageDismissed: user !== undefined && user !== null ? user.dismissVetting : undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setVettingMessageDismissed(): void {},
  };
};

export const AuthContext = createContext<AuthType>(defaultAuthContext);
