import { Box, IconButton, styled } from '@mui/material';
import { FileType } from 'global/enums/fileTypes';
import React, { Dispatch, SetStateAction } from 'react';
import { isTouchScreen } from 'utils/device';
import { makeBlobUrl, makeBlobUrlFromPath, makeBunnyThumbnailUrl } from 'utils/file';
import { VolumeOff, VolumeUp } from '@mui/icons-material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import MusicImg from '../../assets/images/musicImg.webp';
import { IFileMetadata } from 'global/interfaces/file';
import VideoPlayer from 'components/common/Media/VideoPlayer';
import AudioPlayer from 'components/common/Media/AudioPlayer';

interface ICarouselRendererProps {
  portfolioFile: IFileMetadata;
  className: string;
  index: number;
  activeIndex: number;
  muted: boolean;
  setMuted: Dispatch<SetStateAction<boolean>>;
  paused: boolean;
  pausePlay(paused: boolean): void;
  carouselListLength: number;
  isActiveUser: boolean;
}

const StyledImage = styled('img')(({ theme }) => ({
  width: '100%',
  display: 'block',
  backgroundColor: theme.palette.common.black,
  objectFit: 'contain',
  height: '100%',
  aspectRatio: '4/3',
}));

const MediaContainer = styled('div')(() => ({
  width: '100%',
  aspectRatio: '4/3',
}));

const MuteUnmuteButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
  borderColor: theme.palette.common.black,
  backgroundColor: theme.palette.common.black,
  padding: '3px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',

  '&:hover': {
    backgroundColor: theme.palette.common.black,
  },
}));

const MuteUnmuteWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  paddingTop: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  width: '50px',
  height: '50px',
  zIndex: 200,
}));

const PlayPauseWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 200,
  paddingTop: theme.spacing(1),
  paddingRight: theme.spacing(1),
  width: '50px',
  height: '50px',
  display: 'flex',
  justifyContent: 'flex-end',
}));

const PlayIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  color: theme.palette.common.white,
  zIndex: 200,
  backgroundColor: theme.palette.common.black,
  padding: 0,
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',

  '&:hover': {
    backgroundColor: theme.palette.common.black,
  },
}));

const MemoizedStyledImage = React.memo(StyledImage);

export function CarouselRenderer({
  portfolioFile,
  index,
  className,
  activeIndex,
  muted,
  setMuted,
  pausePlay,
  paused,
  carouselListLength,
  isActiveUser,
}: ICarouselRendererProps): React.ReactElement {
  const pauseClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    pausePlay(true);
  };

  const playClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    pausePlay(false);
  };

  const toggleMute = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setMuted(prev => !prev);
  };

  const controls = () => (
    <div>
      <MuteUnmuteWrapper
        color="inherit"
        onClick={e => toggleMute(e)}
        sx={{
          fontSize: '1.8rem',
          display: isActiveUser || isTouchScreen() ? 'block' : 'none',
        }}
      >
        <MuteUnmuteButton>{muted ? <VolumeOff /> : <VolumeUp />}</MuteUnmuteButton>
      </MuteUnmuteWrapper>
      <PlayPauseWrapper onClick={e => (isActiveUser && !paused ? pauseClick(e) : playClick(e))}>
        <PlayIconButton size="small">
          {isActiveUser && !paused ? (
            <PauseCircleOutlineIcon
              sx={{
                fontSize: '1.8rem',
                display: 'block',
              }}
            />
          ) : (
            <PlayCircleOutlineIcon
              sx={{
                fontSize: '1.8rem',
                display: 'block',
              }}
            />
          )}
        </PlayIconButton>
      </PlayPauseWrapper>
    </div>
  );

  const renderFirst: boolean = activeIndex + 1 >= carouselListLength && index === 0;
  const renderLast: boolean = activeIndex - 1 <= 0 && carouselListLength - 1 === index;
  const shouldRender =
    activeIndex === index || activeIndex + 1 === index || activeIndex - 1 === index || renderFirst || renderLast;

  if (portfolioFile.type === FileType.Image && shouldRender) {
    const imgURL = `${portfolioFile.directory}/${portfolioFile.id}${portfolioFile.extension}`;
    return (
      <MediaContainer>
        <MemoizedStyledImage
          className={className}
          key={index}
          alt="User image"
          src={makeBlobUrlFromPath(imgURL, undefined, true)}
          loading={activeIndex === index ? 'eager' : 'lazy'}
        />
      </MediaContainer>
    );
  } else if (portfolioFile.type === FileType.Video && shouldRender) {
    const vidUrl = makeBlobUrl(portfolioFile.directory, portfolioFile.id, portfolioFile.extension);
    return (
      <MediaContainer key={index}>
        <>
          <VideoPlayer
            url={vidUrl}
            muted={muted}
            controls={false}
            playing={isActiveUser && activeIndex === index && !paused}
            className={className}
            thumbnailUrl={
              vidUrl.startsWith(process.env.REACT_APP_BUNNY_VIDEO_CDN_URL ?? '')
                ? makeBunnyThumbnailUrl(portfolioFile.id)
                : undefined
            }
            aspectRatio="4/3"
          />

          {controls()}
        </>
      </MediaContainer>
    );
  } else if (portfolioFile.type === FileType.Audio && shouldRender) {
    const audUrl = makeBlobUrl(portfolioFile.directory, portfolioFile.id, portfolioFile.extension);
    return (
      <MediaContainer key={index}>
        <>
          <AudioPlayer
            url={audUrl}
            muted={muted}
            playing={isActiveUser && activeIndex === index && !paused}
            className={className}
            thumbnailUrl={MusicImg}
            hideAudioControl={true}
            thumbnailAspectRatio="4/3"
            controls={false}
          />

          {controls()}
        </>
      </MediaContainer>
    );
  } else {
    return <div key={index} className={className}></div>;
  }
}
