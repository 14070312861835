import { Container, Grid, styled } from '@mui/material';
import ViewUserProfile from 'components/userProfileViews/UserProfile';
import { viewBuyerProfile, viewSellerProfile } from 'global/constants';
import IApiError from 'global/interfaces/api';
import { IFrontUser } from 'global/interfaces/user';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFrontUser } from 'services/userService';
import { showError } from 'utils/errorHandler';
import { useTitle } from 'utils/router';
import { pushToDataLayer } from 'utils/tagHelper';
import { UserNotFound } from './UserNotFound';

export const StyledContainer = styled(Container)(({ theme }) => ({
  marginTop: '32px',
  [theme.breakpoints.down('md')]: {
    padding: `0 0`,
    marginTop: '0px',
  },
}));

const ViewUser = () => {
  const [user, setUser] = useState<IFrontUser | null>(null);
  const [notFound, setNotFound] = useState(false);
  useTitle(user ? `${user.firstName} ${user.lastName}` : undefined, [user]);
  const urlParams = useParams();
  const { id: userId } = urlParams;

  useEffect(() => {
    if (userId !== undefined) {
      getFrontUser(userId)
        .then((res: IFrontUser) => {
          if (res.isSellingServices) {
            pushToDataLayer(viewSellerProfile);
          } else {
            pushToDataLayer(viewBuyerProfile);
          }
          setUser(res);
        })
        .catch((err: IApiError) => {
          if (err.status === 404) {
            setNotFound(true);
            return;
          }
          showError(err);
        });
    }
  }, [userId]);

  return (
    <StyledContainer maxWidth="lg">
      {!notFound ? <Grid container>{user !== null && <ViewUserProfile user={user} />}</Grid> : <UserNotFound />}
    </StyledContainer>
  );
};

export default ViewUser;
