import { Grid } from '@mui/material';
import MultiFileUpload from 'components/common/PortfolioFiles/MultiFileUpload';
import { FormikErrors, FormikProps } from 'formik';
import { IFileMetadata } from 'global/interfaces/file';
import { IEditableUser } from 'global/interfaces/user';
import { useEffect, useState } from 'react';

interface IEditPortfolioSectionProps {
  form: FormikProps<IEditableUser>;
}

export const EditPortfolioSection = ({ form }: IEditPortfolioSectionProps) => {
  const [files, setFiles] = useState<IFileMetadata[]>(form.values.portfolioFiles);

  useEffect(() => {
    if (files != form.initialValues.portfolioFiles) {
      form.setFieldValue('portfolioFiles', files);
    }
  }, [files]);

  const onUploadStatusChange = (status: boolean): void => {
    form.setFieldValue('isUploading', status);
    form.setFieldTouched('isUploading');
  };

  const handleDeleteFile = (id: string): void => {
    setFiles(currentFiles => currentFiles.filter(file => file.id !== id));
  };

  const handleMoveLeftFile = (id: string): void => {
    setFiles(currentFiles => {
      const index = currentFiles.findIndex(file => file.id == id);
      const newArray = currentFiles.slice(0);
      const item = currentFiles[index];
      newArray.splice(index, 1);
      newArray.splice(index !== 0 ? index - 1 : 0, 0, item); // handle 0 index
      return newArray;
    });
  };

  const handleMoveRightFile = (id: string): void => {
    setFiles(currentFiles => {
      const index = currentFiles.findIndex(file => file.id == id);
      const newArray = currentFiles.slice(0);
      const item = currentFiles[index];
      newArray.splice(index, 1);
      newArray.splice(index !== currentFiles.length - 1 ? index + 1 : currentFiles.length - 1, 0, item); // handle 0 index
      return newArray;
    });
  };

  const handleAddFile = (file: IFileMetadata): void => {
    setFiles(currentFiles => [
      ...currentFiles,
      {
        ...file,
      },
    ]);
  };

  const handleFileNameChange = (id: string, name: string): void => {
    setFiles(currentFiles =>
      currentFiles.map(currentFile => (currentFile.id === id ? { ...currentFile, name } : currentFile)),
    );
  };

  const formatError = () => {
    if (!form.touched.portfolioFiles) {
      return undefined;
    }

    if (form.errors.isUploading) {
      return form.errors.isUploading;
    }
    if (typeof form.errors.portfolioFiles === 'string') {
      return form.errors.portfolioFiles;
    }

    if (Array.isArray(form.errors.portfolioFiles) && form.errors.portfolioFiles.length > 0) {
      return (form.errors.portfolioFiles as FormikErrors<IFileMetadata>[]).map(f => f?.name ?? '');
    }
  };

  return (
    <Grid container spacing={0}>
      <MultiFileUpload
        error={formatError()}
        files={form.values.portfolioFiles}
        onAddFile={handleAddFile}
        onNameChange={handleFileNameChange}
        onDelete={handleDeleteFile}
        onMoveLeft={handleMoveLeftFile}
        onMoveRight={handleMoveRightFile}
        onUploadStatusChange={onUploadStatusChange}
        isSellingServices={form.values.isSellingServices}
        needsVettingValidation={form.values.needsVettingValidation}
        mainCategory={form.values.mainCategory}
      />
    </Grid>
  );
};
