import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { createRef, useCallback, useEffect, useState } from 'react';
import { Options, Splide, SplideSlide } from '@splidejs/react-splide';
import { Splide as SplideObj } from '@splidejs/splide';
import { ThumbnailExtension } from 'global/constants';
import { FileType } from 'global/enums/fileTypes';
import { getThumbnailId, makeBlobUrl, makeBunnyThumbnailUrl } from 'utils/file';
import '@splidejs/react-splide/css';
import { IFileMetadata } from 'global/interfaces/file';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CarouselSplide from './CarouselSplide';
import AudiotrackOutlinedIcon from '@mui/icons-material/AudiotrackOutlined';
import MusicImg from '../../../../assets/images/musicImg.webp';

const MainSplide = styled(Splide)(({ theme }) => ({
  '.splide__arrows .splide__arrow': {
    backgroundColor: theme.palette.common.white,
    opacity: 1,
    boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);',
    width: '32px',
    height: '32px',
  },
  '.splide__arrows .splide__arrow svg': {
    width: '16px',
    height: '16px',
  },
  '.splide__arrows .splide__arrow:hover': {
    opacity: 0.8,
  },
  '.splide__arrow--prev': {
    left: '-12px',
  },
  '.splide__arrow--next': {
    right: '-12px',
  },
}));

const ThumbSplide = styled(Splide)(({ theme }) => ({
  '.splide__track--nav>.splide__list>.splide__slide': {
    border: `1px solid ${theme.palette.grey[400]}`,
    opacity: 0.6,
  },

  '.splide__track--nav>.splide__list>.splide__slide.is-active': {
    border: `2px solid ${theme.palette.grey[600]}`,
    opacity: 1.0,
  },
}));

const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
`;

const PaddedBox = styled(FlexBox)(({ theme }) => ({
  marginTop: '32px',
  paddingRight: '24px',

  [theme.breakpoints.down('md')]: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
  marginTop: '16px',
  paddingRight: '24px',

  [theme.breakpoints.down('md')]: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}));

const ThumbCenterIconContainer = styled('div')(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

const ThumbRightIconContainer = styled('div')(() => ({
  position: 'absolute',
  top: '0',
  right: '0',
  padding: '5px',
}));

const ThumbMusicIconContainer = styled('div')(({ theme }) => ({
  color: theme.palette.grey[600],
}));

const mainOptions: Options = {
  type: 'loop',
  perPage: 1,
  perMove: 1,
  gap: '1rem',
  pagination: false,
  heightRatio: 0.75,
  width: '100%',
  lazyLoad: 'nearby',
  arrowPath:
    'M 20 6.667969 L 17.648438 9.015625 L 26.949219 18.332031 L 6.667969 18.332031 L 6.667969 21.667969 L 26.949219 21.667969 L 17.648438 30.984375 L 20 33.332031 L 33.332031 20 Z M 20 6.667969 ',
};

const thumbsOptions: Options = {
  width: '100%',
  type: 'slide',
  rewind: true,
  gap: '1rem',
  pagination: false,
  fixedWidth: 160,
  fixedHeight: 120,
  arrows: false,
  isNavigation: true,
  cover: true,
  useScroll: true,
  breakpoints: {
    900: {
      fixedWidth: 80,
      fixedHeight: 60,
      // JE: This is annoying but needed to stop horizontal scrollbar
      width: 'calc(100vw - 48px)',
    },
  },
};

interface IUserPortfolioSectionProps {
  isSeller: boolean;
  processedFiles: IFileMetadata[];
}

export default function UserPortfolioSection({ isSeller, processedFiles }: IUserPortfolioSectionProps) {
  const mainRef = createRef<Splide>();
  const thumbsRef = createRef<Splide>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const [activeSlide, setActiveSlide] = useState<number>(0);

  useEffect(() => {
    if (
      mainRef?.current !== undefined &&
      thumbsRef?.current !== undefined &&
      thumbsRef?.current?.splide !== undefined
    ) {
      if (mainRef?.current != null) {
        mainRef.current.sync(thumbsRef.current.splide);
      }
    }
  });

  const renderThumbnails = () =>
    processedFiles.map((file, i) => {
      const extension =
        file.type === FileType.Video || file.type === FileType.Pdf ? ThumbnailExtension : file.extension;
      const imageUrl = file.id.startsWith('bunv_')
        ? makeBunnyThumbnailUrl(file.id)
        : makeBlobUrl(file.directory, getThumbnailId(file.id), extension);
      const isAudio = file.type === FileType.Audio;
      return (
        <SplideSlide key={i}>
          <img src={isAudio ? MusicImg : imageUrl} alt={file.name} loading="lazy" />
          {file.type == FileType.Video && (
            <ThumbCenterIconContainer>
              <PlayArrowIcon style={{ fontSize: isMobile ? '40px' : '60px' }} />
            </ThumbCenterIconContainer>
          )}
          {file.type == FileType.Pdf && (
            <ThumbRightIconContainer>
              <DescriptionOutlinedIcon style={{ fontSize: isMobile ? '20px' : '25px' }} />
            </ThumbRightIconContainer>
          )}
          {isAudio && (
            <ThumbMusicIconContainer>
              <ThumbRightIconContainer>
                <AudiotrackOutlinedIcon />
              </ThumbRightIconContainer>
              <ThumbCenterIconContainer>
                <PlayArrowIcon style={{ fontSize: isMobile ? '40px' : '60px' }} />
              </ThumbCenterIconContainer>
            </ThumbMusicIconContainer>
          )}
        </SplideSlide>
      );
    });

  const renderSplides = () =>
    processedFiles.map((file, i) => <CarouselSplide key={i} {...file} isActive={i == activeSlide} />);

  const handleOnActive = useCallback((splide: SplideObj) => {
    setActiveSlide(splide.index);
  }, []);

  return (
    <>
      <PaddedBox>
        <Typography variant="h6">{isSeller ? 'Portfolio' : 'Gallery'}</Typography>
      </PaddedBox>
      <StyledGrid container spacing={2}>
        <Grid item xs={12}>
          <MainSplide
            options={mainOptions}
            ref={mainRef}
            aria-labelledby="thumbnail-slider-example"
            onActive={handleOnActive}
          >
            {renderSplides()}
          </MainSplide>
        </Grid>

        <Grid item xs={12}>
          <ThumbSplide
            options={thumbsOptions}
            ref={thumbsRef}
            aria-label="The carousel with thumbnails. Selecting a thumbnail will change the main carousel"
          >
            {renderThumbnails()}
          </ThumbSplide>
        </Grid>
      </StyledGrid>
    </>
  );
}
