import { SvgIcon, SvgIconProps } from '@mui/material';

export const MakePaymentIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 22 14" {...props}>
    <path
      d="M 17.963 11.876 L 20.811 9.453 M 20.811 9.453 L 17.963 6.953 M 20.811 9.453 L 16.811 9.453 L 13.811 9.453 M 1.478 5.286 L 18.144 5.286 L 18.144 3.786 C 18.144 2.852 18.144 2.386 17.963 2.029 C 17.803 1.716 17.548 1.461 17.234 1.301 C 16.878 1.119 16.411 1.119 15.478 1.119 L 4.144 1.119 C 3.211 1.119 2.744 1.119 2.388 1.301 C 2.074 1.461 1.819 1.716 1.659 2.029 C 1.478 2.386 1.478 2.852 1.478 3.786 L 1.478 10.119 C 1.478 11.053 1.478 11.519 1.659 11.876 C 1.819 12.189 2.074 12.444 2.388 12.604 C 2.744 12.786 3.211 12.786 4.144 12.786 L 15.811 12.786"
      stroke={props.style?.color ?? 'white'}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
