import { Box, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { IFrontUser } from 'global/interfaces/user';
import { useNavigate } from 'react-router-dom';
import { DashboardButton } from './DashboardButton';
import StarIcon from '@mui/icons-material/Star';
import { amber } from '@mui/material/colors';

interface IProfileInfoProps {
  user: IFrontUser | null;
}

const StyledBox = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: '12px',
  padding: '12px 20px 12px 20px',
  position: 'relative',
}));

export default function ProfileInfo(props: IProfileInfoProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <StyledBox mt={isMobile ? 1 : 0}>
      <Stack direction="row" spacing={isMobile ? 1 : 4} justifyContent={'space-between'}>
        <Stack direction="row" alignItems="center">
          <StarIcon sx={{ fill: amber[600], height: '24px', width: '24px', mb: '2px' }} />
          {props.user != null && (
            <Typography variant={'subtitle1'} fontWeight={600} ml={1}>
              {props.user?.contractStats?.reviewRate ?? 'No Ratings'}
            </Typography>
          )}
        </Stack>
        <DashboardButton variant="contained" onClick={() => navigate(`/users/${props.user?.id}`)}>
          View Profile
        </DashboardButton>
      </Stack>
    </StyledBox>
  );
}
