import { SvgIcon, SvgIconProps } from '@mui/material';

export const ConnectionRemovedIcon = (props: SvgIconProps) => (
  <SvgIcon width="19" height="18" viewBox="0 0 19 18" {...props}>
    <path
      d="M13.75 12.3333L17.9167 16.5M17.9167 12.3333L13.75 16.5M9.99999 11.9167H6.24999C5.08702 11.9167 4.50554 11.9167 4.03237 12.0602C2.96704 12.3834 2.13336 13.217 1.81019 14.2824C1.66666 14.7555 1.66666 15.337 1.66666 16.5M12.0833 5.25C12.0833 7.32107 10.4044 9 8.33332 9C6.26226 9 4.58332 7.32107 4.58332 5.25C4.58332 3.17893 6.26226 1.5 8.33332 1.5C10.4044 1.5 12.0833 3.17893 12.0833 5.25Z"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
