import { Typography } from '@mui/material';
import React, { useContext } from 'react';
import { AuthContext } from 'contexts/AuthContext';
import { IChatMessageDisplay } from 'global/interfaces/chatMessage';
import RatingComponent from 'components/common/RatingComponent';

export default function ChatMessageFeedback(props: IChatMessageDisplay) {
  const authContext = useContext(AuthContext);

  const rating = props.contract?.buyerRating ?? props.contract?.sellerRating;

  return (
    <React.Fragment>
      <Typography variant="body2" color="grey.900">
        {authContext.user?.id !== props.userId
          ? `You received feedback for contract "${props.contract?.name}"`
          : `You gave feedback for contract "${props.contract?.name}"`}
      </Typography>
      <Typography variant="body2">{rating ? <RatingComponent rating={rating} /> : null}</Typography>
      {props.content && (
        <Typography variant="body2" color="grey.900">
          {props.content}
        </Typography>
      )}
    </React.Fragment>
  );
}
