import React from 'react';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import MonthSelect from 'components/common/Select/MonthSelect';
import YearSelect from 'components/common/Select/YearSelect';
import { IWorkHistory } from 'global/interfaces/user';
import { useFormik } from 'formik';
import { workHistoryValidation } from 'global/validations/user';
import NoWorkHistoryIcon from 'components/common/StyledIcons/NoWorkHistoryIcon';
import { FormHelperText, styled, useMediaQuery, useTheme } from '@mui/material';
import { getHistoryDateString } from 'utils/workHistoryHelper';
import { RoundButton } from 'components/common/Button/RoundButton';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { compareWorkHistories } from 'utils/compareWorkHistoryUtil';

interface IWorkHistoryProps {
  workHistories: IWorkHistory[];
  onUpdateHistories: (histories: IWorkHistory[]) => void;
  error: boolean;
}

const AddHistoryButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.dark,
  paddingInline: 0,

  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const HistoryContainer = styled(Grid)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.grey[300],
  borderRadius: '8px',
  padding: '16px 24px',

  [theme.breakpoints.down('sm')]: {
    paddingRight: '16px',
  },
}));

export default function WorkHistoryEditor(props: IWorkHistoryProps): JSX.Element {
  const [open, setOpen] = React.useState<boolean>(false);
  const [editIndex, setEditIndex] = React.useState<number>(-1);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const form = useFormik<IWorkHistory>({
    initialValues: {
      company: '',
      title: '',
      fromMonth: 0,
      fromYear: 0,
      toMonth: 0,
      toYear: 0,
      isPresentPosition: false,
    },
    validationSchema: workHistoryValidation,
    onSubmit: (values: IWorkHistory) => {
      if (editIndex !== -1) {
        props.workHistories[editIndex] = values;
        props.onUpdateHistories([...props.workHistories]);
        setEditIndex(-1);
      } else {
        props.onUpdateHistories([...props.workHistories, values]);
      }
      form.resetForm();
      setOpen(false);
    },
  });

  const handleClickOpen = (index = -1): void => {
    if (index > -1) {
      form.setValues({ ...props.workHistories[index] });
      setEditIndex(index);
    }
    setOpen(true);
  };

  const handleClose = (): void => {
    form.resetForm();
    setEditIndex(-1);
    setOpen(false);
  };

  const getAvatarText = (history: IWorkHistory): string => history.company[0];

  const handleDelete = (index: number): void => {
    props.workHistories.splice(index, 1);
    props.onUpdateHistories(props.workHistories);
  };

  return (
    <React.Fragment>
      <Box width="100%" mb={props.workHistories?.length === 0 ? 0 : 3}>
        <Grid container display="grid" gridTemplateColumns={isMobileScreen ? '1fr' : 'repeat(2, 1fr)'} gap={2}>
          {props.workHistories.sort(compareWorkHistories).map((history, index) => (
            <HistoryContainer item xs={12} key={index}>
              <Stack direction="row" justifyContent="space-between">
                <Stack width="100%" direction="row" gap={2}>
                  {!isMobileScreen && (
                    <Avatar sx={{ width: 54, height: 54, bgcolor: 'primary.main', mt: 1.5 }}>
                      {getAvatarText(history)}
                    </Avatar>
                  )}
                  <Stack>
                    <Typography variant="subtitle1" align="left" color="grey.900">
                      {history.title}
                    </Typography>

                    <Typography variant="body2" color="grey.600">
                      {getHistoryDateString(history)}
                    </Typography>

                    <Typography variant="body2" color="grey.600">
                      {history.company}
                    </Typography>
                  </Stack>
                </Stack>

                <Stack direction="row" alignItems="flex-start">
                  <IconButton aria-label="edit" size="small" onClick={() => handleClickOpen(index)}>
                    <BorderColorOutlinedIcon fontSize="large" />
                  </IconButton>
                  <IconButton size="small" onClick={() => handleDelete(index)} aria-label="delete">
                    <HighlightOffIcon fontSize="large" />
                  </IconButton>
                </Stack>

                <Box></Box>
              </Stack>
            </HistoryContainer>
          ))}
        </Grid>
      </Box>

      {props.workHistories?.length === 0 && <NoWorkHistoryIcon />}

      <Grid container mt={3}>
        <Grid item xs={12} sm={12} md={12}>
          <AddHistoryButton startIcon={<AddOutlinedIcon />} size="large" onClick={() => handleClickOpen()}>
            Add Work History
          </AddHistoryButton>
        </Grid>
      </Grid>
      {props.error && (
        <Grid sx={{ p: 1 }} item xs={12}>
          <FormHelperText error>At least one work history item must be added</FormHelperText>
        </Grid>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
        fullScreen={isMobileScreen}
      >
        <form onSubmit={form.handleSubmit} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <StyledDialogTitle>
            <Typography color="grey.900" variant={isMobileScreen ? 'h6' : 'h5'}>
              {editIndex > -1 ? 'Edit Work History' : 'Add Work History'}
            </Typography>

            <IconButton onClick={handleClose}>
              <ClearOutlinedIcon />
            </IconButton>
          </StyledDialogTitle>
          <StyledDialogContent dividers>
            <Grid container columnSpacing={2.5} rowSpacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="company"
                  label="Company *"
                  value={form.values.company}
                  onChange={form.handleChange}
                  variant={'outlined'}
                  error={form.touched.company !== undefined && Boolean(form.errors.company)}
                  helperText={form.touched.company !== undefined ? form.errors.company : ''}
                  size="medium"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="title"
                  label="Job Title *"
                  value={form.values.title}
                  onChange={form.handleChange}
                  variant={'outlined'}
                  error={form.touched.title !== undefined && Boolean(form.errors.title)}
                  helperText={form.touched.title !== undefined ? form.errors.title : ''}
                  fullWidth
                  size="medium"
                />
              </Grid>
            </Grid>

            <Grid container rowSpacing={2} columnSpacing={2.5}>
              <Grid item xs={12} sm={6}>
                <MonthSelect
                  name="fromMonth"
                  value={form.values.fromMonth === 0 ? '' : form.values.fromMonth}
                  onChange={form.handleChange}
                  error={form.touched.fromMonth !== undefined && Boolean(form.errors.fromMonth)}
                  helpertext={form.touched.fromMonth !== undefined ? form.errors.fromMonth : ''}
                  label="From Month *"
                  size="medium"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <YearSelect
                  name="fromYear"
                  value={form.values.fromYear === 0 ? '' : form.values.fromYear}
                  onChange={form.handleChange}
                  error={form.touched.fromYear !== undefined && Boolean(form.errors.fromYear)}
                  helpertext={form.touched.fromYear !== undefined ? form.errors.fromYear : ''}
                  label="From Year *"
                  size="medium"
                />
              </Grid>
            </Grid>
            <Grid container rowSpacing={2} columnSpacing={2.5}>
              <Grid item xs={12} sm={6}>
                <MonthSelect
                  disabled={form.values.isPresentPosition}
                  name="toMonth"
                  value={form.values.toMonth === 0 ? '' : form.values.toMonth}
                  onChange={form.handleChange}
                  error={form.touched.toMonth !== undefined && Boolean(form.errors.toMonth)}
                  helpertext={form.touched.toMonth !== undefined ? form.errors.toMonth : ''}
                  label="To Month"
                  size="medium"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <YearSelect
                  disabled={form.values.isPresentPosition}
                  name="toYear"
                  value={form.values.toYear === 0 ? '' : form.values.toYear}
                  onChange={form.handleChange}
                  error={form.touched.toYear !== undefined && Boolean(form.errors.toYear)}
                  helpertext={form.touched.toYear !== undefined ? form.errors.toYear : ''}
                  label="To Year"
                  size="medium"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={form.handleChange}
                      name="isPresentPosition"
                      checked={form.values.isPresentPosition}
                      size="medium"
                    />
                  }
                  label="Present Position"
                />
              </Grid>
            </Grid>
          </StyledDialogContent>

          <StyledDialogActions>
            <RoundButton fullWidth={isMobileScreen} variant="outlined" onClick={handleClose}>
              Cancel
            </RoundButton>
            <RoundButton fullWidth={isMobileScreen} variant="contained" type="submit">
              Ok
            </RoundButton>
          </StyledDialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
