import { IClearbitResponse } from 'global/interfaces/clearbitResponse';

const resource = 'https://autocomplete.clearbit.com/v1/companies/suggest?query=';

export async function getClearbitLogo(company: string): Promise<IClearbitResponse[]> {
  const response = await fetch(`${resource}${company}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const data: IClearbitResponse[] = await response.json();
  return data;
}
