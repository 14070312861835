import { Grid } from '@mui/material';
import { desktopHeaderHeight, mobileHeaderHeight } from 'global/Constants/HeaderFooterConstants';

interface ISignUpContainerProps {
  children: React.ReactNode;
}

export const WelcomeGraphicContainer: React.FC<ISignUpContainerProps> = props => (
  <Grid
    paddingX={{ xs: 3, sm: 0 }}
    sx={{
      minHeight: {
        xs: `calc(100vh - ${mobileHeaderHeight}px - 100px)`,
        md: `calc(100vh - ${desktopHeaderHeight}px)`,
      },
    }}
    container
    justifyContent="center"
    margin={'auto'}
    maxWidth={'lg'}
    paddingTop={{ xs: 4, sm: 0 }}
    paddingBottom={{ xs: 10, sm: 0 }}
  >
    <Grid item xs={12} md={7} container alignItems={{ xs: 'flex-start', sm: 'center' }} justifyContent="center">
      {props.children}
    </Grid>
  </Grid>
);
