import {
  Box,
  FormHelperText,
  Grid,
  IconButton,
  List,
  ListItem,
  Radio,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FormikProps } from 'formik';
import { ChatType, IChatUserThread } from 'global/interfaces/chatThread';
import { ContractType, ICreateContractForm } from 'global/interfaces/contract';
import { IUser } from 'global/interfaces/user';
import { useState } from 'react';
import { RepeatIcon } from 'components/icon/RepeatIcon';
import { FixedPriceIcon } from 'components/icon/FixedPriceIcon';
import CloseIcon from '@mui/icons-material/Close';
import { nameof } from 'ts-simple-nameof';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle, StyledGrow } from 'components/common/Dialog';
import { RoundButton } from '../Button/RoundButton';
import { JustifiedBox } from './CreateContractDialog';
import { StyledListItemButton } from '../Button/StyledListItemButton';

const StyledList = styled(List)(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '20px',
}));

export const ChoosePackage: React.FC<{
  form: FormikProps<ICreateContractForm>;
  next: () => void;
  user: IUser | null;
  selectedThread: IChatUserThread | undefined;
  handleCancel: () => void;
}> = ({ next, form, selectedThread, handleCancel }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [error, setError] = useState<boolean>(false);
  const isOneToOneChat = selectedThread?.type == ChatType.OneToOne;
  return (
    <>
      <StyledDialogTitle>
        <Typography variant="h6" color={theme.palette.grey[900]}>
          New Contract {isOneToOneChat && `for ${selectedThread.displayName}`}
        </Typography>
        <IconButton onClick={() => handleCancel()} sx={{ marginRight: '-12px' }}>
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>

      <StyledDialogContent dividers>
        <Grid item xs={12}>
          <StyledGrow in={true} timeout={1000}>
            <StyledList disablePadding>
              <ListItem disablePadding sx={{ marginBottom: '20px' }}>
                <StyledListItemButton
                  selected={form.values.contractType === ContractType.FixedPrice}
                  onClick={() => {
                    setError(false);
                    form.setFieldValue(
                      nameof<ICreateContractForm>(x => x.contractType),
                      ContractType.FixedPrice,
                    );
                  }}
                  disableRipple
                >
                  <JustifiedBox>
                    <Stack direction="column">
                      <Box>
                        <Radio
                          checked={form.values.contractType === ContractType.FixedPrice}
                          sx={{ ml: '-10px', mt: '-1px' }}
                        />
                        <Typography component="span" variant="subtitle1">
                          Fixed Price
                        </Typography>
                      </Box>
                      <Typography variant="body2">Best for fixed scope projects with clear deliverables</Typography>
                    </Stack>
                    <FixedPriceIcon
                      sx={{
                        stroke:
                          form.values.contractType === ContractType.FixedPrice
                            ? theme.palette.primary[200]
                            : theme.palette.grey[300],
                        width: '80px',
                        height: '80px',
                        fill: 'none',
                        strokeWidth: 1.2,
                      }}
                    />
                  </JustifiedBox>
                </StyledListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <StyledListItemButton
                  selected={form.values.contractType === ContractType.Retainer}
                  onClick={() => {
                    setError(false);
                    form.setFieldValue(
                      nameof<ICreateContractForm>(x => x.contractType),
                      ContractType.Retainer,
                    );
                  }}
                  disableRipple
                >
                  <JustifiedBox>
                    <Stack direction="column">
                      <Box>
                        <Radio
                          checked={form.values.contractType === ContractType.Retainer}
                          sx={{ ml: '-10px', mt: '-1px' }}
                        />
                        <Typography component="span" variant="subtitle1">
                          Retainer
                        </Typography>
                      </Box>
                      <Typography variant="body2">Best for recurring payments on a weekly or monthly basis</Typography>
                    </Stack>
                    <RepeatIcon
                      sx={{
                        stroke:
                          form.values.contractType === ContractType.Retainer
                            ? theme.palette.primary[200]
                            : theme.palette.grey[300],
                        width: '80px',
                        height: '80px',
                        fill: 'none',
                        strokeWidth: 1.2,
                      }}
                    />
                  </JustifiedBox>
                </StyledListItemButton>
              </ListItem>
              <FormHelperText sx={{ color: '#F04438', mt: '16px' }}>
                {error ? 'Select a contract type' : ''}
              </FormHelperText>
            </StyledList>
          </StyledGrow>
        </Grid>
      </StyledDialogContent>

      <StyledDialogActions>
        <RoundButton fullWidth={isMobileScreen} variant="outlined" onClick={() => handleCancel()}>
          Cancel
        </RoundButton>
        <RoundButton
          fullWidth={isMobileScreen}
          variant="contained"
          onClick={() => {
            if (form.values.contractType === null) {
              setError(true);
              return;
            }
            next();
          }}
        >
          Next
        </RoundButton>
      </StyledDialogActions>
    </>
  );
};
