import { Avatar, Container, Grid, Skeleton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTitle } from 'utils/router';
import {
  BannerStack,
  FeatureSubText,
  FeatureText,
  MultiLineFeatureContainer,
  StyledBannerBox,
} from 'components/info/Hero';
import { ClockIcon } from 'components/icon/ClockIcon';
import { BriefIcon } from 'components/icon/BriefIcon';
import { ExclusiveIcon } from 'components/icon/ExclusiveIcon';
import userIm from 'assets/images/user/Imogen.png';
import OpportunitySubscribe from 'components/opportunities/newsletter/OpportunitySubscribe';
import { useState } from 'react';
import ViewOpportunitiesComponent from 'components/opportunities/ViewOpportunitiesComponent';

const ViewOpportunities = () => {
  useTitle('New Opportunities');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [numOpps, setNumOpps] = useState<number>();

  return (
    <>
      <StyledBannerBox>
        <Container maxWidth="lg">
          <BannerStack direction={'column'} rowGap={2}>
            <Grid container columnSpacing={6}>
              <Grid item xs={12} md={7}>
                <Typography
                  variant="h3"
                  fontWeight={700}
                  fontSize={{ xs: '26px', md: '44px', lg: '55px' }}
                  color={'#101828'}
                  maxWidth={'800px'}
                >
                  1000+ curated freelance opportunities each month
                </Typography>
                <Typography
                  variant="body1"
                  fontSize={{ xs: '16px', lg: '20px' }}
                  color={'#344054'}
                  maxWidth={'800px'}
                  mt={isMobile ? 2 : 3}
                >
                  Being a freelancer is hard. We make life a little bit easier with quick, easy access to good quality
                  opportunities every day.{' '}
                  <b>
                    {numOpps ? (
                      numOpps
                    ) : (
                      <Skeleton
                        variant="text"
                        width={isMobile ? '50px' : '80px'}
                        component={'span'}
                        sx={{ display: 'inline-block' }}
                      />
                    )}{' '}
                  </b>
                  live right now.
                </Typography>
              </Grid>
              <Grid item xs={12} md={5}>
                <Stack direction={'row'} spacing={2} mt={isMobile ? 2 : undefined}>
                  <Avatar
                    sx={{
                      width: isMobile ? '100px' : '146px',
                      height: isMobile ? '100px' : '146px',
                      border: '1px solid #A28CF3',
                      mt: isMobile ? 1.5 : undefined,
                    }}
                    src={userIm}
                  ></Avatar>
                  <Stack direction={'column'} pt={1}>
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      color={'#101828'}
                      fontSize={isMobile ? '16px' : '20px'}
                    >
                      Imogen H
                    </Typography>
                    <Typography variant="body2" fontWeight={400} color={'#475467'}>
                      Marketing Manager
                    </Typography>
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      fontSize={{ xs: '18px', md: '22px', lg: '24px' }}
                      lineHeight={{ xs: '22px', md: '26px', lg: '28px' }}
                      fontStyle={'italic'}
                      color={'#2B1161'}
                      mt={1}
                    >
                      &quot;I’ve landed two amazing long term clients&quot;
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Grid container mt={{ xs: '0px', md: '0px' }} spacing={isMobile ? '14px' : '22px'}>
              <Grid item xs={12} md={4}>
                <MultiLineFeatureContainer direction={'row'}>
                  <Stack direction={'column'} gap={'4px'} spacing={1}>
                    <Stack direction={'row'} spacing={1}>
                      <ClockIcon
                        sx={{
                          width: !isMobile ? '24px' : '20px',
                          height: !isMobile ? '24px' : '20px',
                        }}
                      />
                      <FeatureText>Save Precious Time</FeatureText>
                    </Stack>

                    <FeatureSubText>
                      We spend 40 hours per week curating opportunities from 30+ sources, saving you time and money.
                    </FeatureSubText>
                  </Stack>
                </MultiLineFeatureContainer>
              </Grid>
              <Grid item xs={12} md={4}>
                <MultiLineFeatureContainer direction={'row'}>
                  <Stack direction={'column'} gap={'4px'} spacing={1}>
                    <Stack direction={'row'} spacing={1}>
                      <BriefIcon
                        sx={{
                          width: !isMobile ? '24px' : '20px',
                          height: !isMobile ? '24px' : '20px',
                        }}
                      />
                      <FeatureText>Simple and Convenient</FeatureText>
                    </Stack>

                    <FeatureSubText>
                      Our live feed makes it fast and easy to browse opps in one place. All opportunities are max 7 days
                      old.
                    </FeatureSubText>
                  </Stack>
                </MultiLineFeatureContainer>
              </Grid>
              <Grid item xs={12} md={4}>
                <MultiLineFeatureContainer direction={'row'} height={'100%'}>
                  <Stack direction={'column'} gap={'4px'} spacing={1}>
                    <Stack direction={'row'} spacing={1}>
                      <ExclusiveIcon
                        sx={{
                          width: !isMobile ? '24px' : '20px',
                          height: !isMobile ? '24px' : '20px',
                        }}
                      />
                      <FeatureText>Free Profile</FeatureText>
                    </Stack>

                    <FeatureSubText>
                      List your profile on our marketplace for free to be discovered by new clients, no bidding
                      required.
                    </FeatureSubText>
                  </Stack>
                </MultiLineFeatureContainer>
              </Grid>
            </Grid>
          </BannerStack>
        </Container>
      </StyledBannerBox>
      <Container maxWidth="lg">
        <OpportunitySubscribe />

        <ViewOpportunitiesComponent setNumOpps={setNumOpps} />
      </Container>
    </>
  );
};

export default ViewOpportunities;
