import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import DoneIcon from '@mui/icons-material/Done';
import { Divider, Grid, List, ListItem, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';

interface PriceFeature {
  text: string;
}

const sellerFreePriceFeatures: PriceFeature[] = [
  { text: 'Apply for up to 100 selected free opportunities every month' },
  { text: 'Create profile and get listed on vetted marketplace so clients can find and contact you directly' },
];

const sellerPremPriceFeatures: PriceFeature[] = [
  { text: 'Access to Shoutt exclusive opportunities' },
  {
    text: 'Unlock access to 1000+ external opportunities per month sourced from over 30 sites daily',
  },
  { text: 'Apply to unlimited opportunities & respond to messages' },
];

const sellerPlusFeatures: PriceFeature[] = [
  { text: 'Invoice clients and accept payments via card, bank transfer and Apple / Google pay' },
  { text: 'Get paid on time with Shoutt payment protection (commission free)' },

  { text: 'Streamline your work & avoid scope creep with built-in contracts' },
];

const buyerFreePriceFeatures: PriceFeature[] = [
  { text: 'Post opportunities easily, only vetted talent can respond, saving you time' },
  { text: 'Browse talent profiles and send messages to our vetted freelance talent' },
];

const buyerPremPriceFeatures: PriceFeature[] = [
  { text: 'Pay freelancers via Shoutt with secure escrow protection to protect your funds' },
  { text: 'Create contracts with freelancers to help manage deliverables and scope of work' },
];

interface PricingTableProps {
  isSeller: boolean;
  currencySybmol: string;
  premPrice: number;
  freeBgColor?: string;
  freeCTA?: React.ReactNode;
  premCTA?: React.ReactNode;
}

const StyledPriceBox = styled(Box)(() => ({
  padding: '40px 32px 40px 32px',
  borderRadius: '24px',
  borderStyle: 'solid',
  borderWidth: '1px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

interface StyledFreePriceBoxProps {
  freeBgColor?: string;
}

const StyledFreePriceBox = styled(StyledPriceBox, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'freeBgColor',
})<StyledFreePriceBoxProps>(() => ({
  backgroundColor: '#F9FAFB',
  backdropFilter: 'blur(6px)',

  border: 'none',
}));

const StyledPremiumPriceBox = styled(StyledPriceBox)(() => ({
  background: 'linear-gradient(180deg, #FEF9EC 0%, #FCF0CF 100%)',

  border: 'none',
}));

const PriceFeatureTypography = styled(Typography)(({ theme }) => ({
  lineHeight: '140%',
  fontSize: '16px',
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
  },
  color: '#101828',
}));

const PriceFreeFeatureTypography = styled(PriceFeatureTypography)(() => ({
  color: '#101828',
}));

const PricePremFeatureTypography = styled(PriceFeatureTypography)(() => ({
  color: '#101828',
}));

const StyledDoneIcon = styled(DoneIcon)(() => ({
  width: '26px',
  height: '26px',
  color: '#121F31',
  marginRight: '16px',
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: '0px',
  marginTop: '5px',
  [theme.breakpoints.down('md')]: {
    marginTop: '2px',
  },
}));

export default function PricingTable({ currencySybmol, premPrice, freeBgColor, freeCTA, premCTA }: PricingTableProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Grid container spacing={{ xs: 0, md: 4 }} rowSpacing={4}>
      <Grid item xs={12} md={6} container justifyContent={{ xs: 'center', md: 'flex-end' }}>
        <StyledFreePriceBox freeBgColor={freeBgColor}>
          <Box mb={isMobile ? 1 : 3}>
            <Typography variant="h6" fontWeight={600} fontSize={'36px'} color={'#2B1161'}>
              Free
            </Typography>
            <Typography variant="body1" color={'#475467'} mt={1.5} component={'div'}>
              {currencySybmol}0 per month
              {!isMobile && (
                <>
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </>
              )}
            </Typography>
            <Typography
              mt={{ xs: 2, md: 3 }}
              variant="body2"
              textTransform={'uppercase'}
              color={'#101828'}
              fontWeight={600}
            >
              Looking for Work
            </Typography>
            <Divider sx={{ mt: 1.5 }}></Divider>
            <List sx={{ mt: isMobile ? 1.5 : 2.5 }}>
              {sellerFreePriceFeatures.map((feature: PriceFeature, p: number) => (
                <ListItem key={p} alignItems="flex-start" disableGutters dense>
                  <StyledListItemIcon>
                    <StyledDoneIcon />
                  </StyledListItemIcon>
                  <ListItemText>
                    <PriceFreeFeatureTypography variant="body1">{feature.text}</PriceFreeFeatureTypography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
          <Box>
            <Box mb={isMobile ? 1 : 3}>
              <Typography
                mt={{ xs: 2, md: 3 }}
                variant="body2"
                textTransform={'uppercase'}
                color={'#101828'}
                fontWeight={600}
              >
                Looking for Talent
              </Typography>
              <Divider sx={{ mt: 1.5 }}></Divider>

              <List sx={{ mt: isMobile ? 1.5 : 2.5 }}>
                {buyerFreePriceFeatures.map((feature: PriceFeature, p: number) => (
                  <ListItem key={p} alignItems="flex-start" disableGutters dense sx={{ mt: 1 }}>
                    <StyledListItemIcon>
                      <StyledDoneIcon />
                    </StyledListItemIcon>
                    <ListItemText>
                      <PricePremFeatureTypography variant="body1">{feature.text}</PricePremFeatureTypography>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
              {!isMobile && (
                <Typography variant="body2" color={'#475467'}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
              )}
            </Box>
            <Box width={'100%'}> {freeCTA}</Box>
          </Box>
        </StyledFreePriceBox>
      </Grid>
      <Grid item xs={12} md={6} container justifyContent={{ xs: 'center', md: 'flex-start' }}>
        <StyledPremiumPriceBox>
          <Box mb={isMobile ? 1 : 3}>
            <Typography variant="h6" fontWeight={600} fontSize={'36px'} color={'#2B1161'}>
              Premium
            </Typography>

            <Typography variant="body1" color={'#475467'} mt={1.5} component={'div'}>
              {currencySybmol}
              {premPrice} per month <br />
              Cancel anytime in settings
            </Typography>
            <Typography
              mt={{ xs: 2, md: 3 }}
              variant="body2"
              textTransform={'uppercase'}
              color={'#101828'}
              fontWeight={600}
            >
              Looking for Work
            </Typography>
            <Divider sx={{ mt: 1.5 }}></Divider>
            <Typography variant="body2" color={'#475467'} mt={2.5}>
              Everything in FREE +
            </Typography>
            <Typography variant="body1" color={'#1F4DE2'} mt={2.5} textTransform={'uppercase'} fontWeight={600}>
              GROW YOUR BUSINESS
            </Typography>
            <List>
              {sellerPremPriceFeatures.map((feature: PriceFeature, p: number) => (
                <ListItem key={p} alignItems="flex-start" disableGutters dense sx={{ mt: 1 }}>
                  <StyledListItemIcon>
                    <StyledDoneIcon />
                  </StyledListItemIcon>
                  <ListItemText>
                    <PricePremFeatureTypography variant="body1">{feature.text}</PricePremFeatureTypography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
            <Typography variant="body1" color={'#1F4DE2'} mt={2.5} textTransform={'uppercase'} fontWeight={600}>
              PLUS ALL THIS FOR FREE!
            </Typography>
            <List>
              {sellerPlusFeatures.map((feature: PriceFeature, p: number) => (
                <ListItem key={p} alignItems="flex-start" disableGutters dense sx={{ mt: 1 }}>
                  <StyledListItemIcon>
                    <StyledDoneIcon />
                  </StyledListItemIcon>
                  <ListItemText>
                    <PricePremFeatureTypography variant="body1">{feature.text}</PricePremFeatureTypography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>

            <Typography
              mt={{ xs: 2, md: 3 }}
              variant="body2"
              textTransform={'uppercase'}
              color={'#101828'}
              fontWeight={600}
            >
              Looking for Talent
            </Typography>
            <Divider sx={{ mt: 1.5 }}></Divider>
            <Typography variant="body2" color={'#475467'} mt={2.5}>
              Everything in FREE +
            </Typography>
            <List>
              {buyerPremPriceFeatures.map((feature: PriceFeature, p: number) => (
                <ListItem key={p} alignItems="flex-start" disableGutters dense sx={{ mt: 1 }}>
                  <StyledListItemIcon>
                    <StyledDoneIcon />
                  </StyledListItemIcon>
                  <ListItemText>
                    <PricePremFeatureTypography variant="body1">{feature.text}</PricePremFeatureTypography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Box>
          {premCTA}
        </StyledPremiumPriceBox>
      </Grid>
    </Grid>
  );
}
