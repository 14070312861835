import {
  Button,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  styled,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { FastField, FieldProps, FormikProps } from 'formik';
import { IEditableUser, IFixedPackage } from 'global/interfaces/user';
import React, { useEffect, useState } from 'react';
import { nameof } from 'ts-simple-nameof';
import { handleNumberChange, strictNumericCheck } from 'utils/inputs';
import ShouttServiceFee from './ShouttServiceFee';
import {
  countries,
  fallbackContractCountry,
  getCurrencySymbolByCountryCode,
} from 'components/common/Select/CountrySelect';

export const defaultFixedPackage: IFixedPackage = {
  price: 0,
  deliverables: [''],
  deliveryMethods: 7,
  revisions: 2,
};

export interface IPricingEditorProps {
  form: FormikProps<IEditableUser>;
}

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: '8px',
  border: '1px solid',
  borderColor: theme.palette.grey[300],
  borderRadius: '4px',
  width: '36px',
  height: '36px',
}));

export const StyledAddPackageButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary[600],
  paddingLeft: 0,
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  minHeight: 0,
  backgroundColor: theme.palette.common.white,
  borderBottomWidth: 0,
  maxWidth: 'fit-content',
  borderTopLeftRadius: '4px',
  borderTopRightRadius: '4px',

  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: 'black',
  },
}));

export const PricingEditor = ({ form }: IPricingEditorProps) => {
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (form.isSubmitting && form.touched.fixedPackages && form.errors.fixedPackages) {
      const tabsWithErrors = Object.entries(form.errors.fixedPackages)
        .filter(([_, value]) => value)
        .map(([key]) => key);
      const tabWithErrorIndex = +tabsWithErrors[0];

      if (tabIndex !== tabWithErrorIndex) {
        setTabIndex(tabWithErrorIndex);
      }
    }
  }, [form.isSubmitting, form.errors.fixedPackages, form.touched.fixedPackages, tabIndex]);

  const currencySymbol = getCurrencySymbolByCountryCode(form.values.country);
  const selectedCountry = countries.find(c => c.code === form.values.country);

  return (
    <Grid container>
      <Grid item xs={12} mb={3}>
        <Typography variant={'h6'}>Hourly Rate</Typography>
        <Typography variant="body2" mt="4px">
          {form.values.needsVettingValidation ? 'Required: ' : 'Recommended: '}Enter an indicative hourly rate in{' '}
          {!selectedCountry?.currency || selectedCountry.currency === fallbackContractCountry.currency
            ? `$${fallbackContractCountry.currency}`
            : currencySymbol}
          . This is a guideline for prospective clients only, each project is mutually agreed case by case.
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormGroup>
          <FormControl>
            <FastField name={nameof<IEditableUser>(x => x.hourlyRate)}>
              {({ field, meta }: FieldProps) => (
                <TextField
                  name={field.name}
                  value={field.value === 0 ? '' : field.value ?? ''}
                  onChange={e => handleNumberChange(e, form.handleChange)}
                  onKeyDown={strictNumericCheck}
                  error={meta.touched && Boolean(meta.error)}
                  helperText={meta.touched ? meta.error : ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {getCurrencySymbolByCountryCode(form.values.country) ?? fallbackContractCountry.currencySymbol}
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ inputMode: 'numeric', min: 0 }}
                  variant={'outlined'}
                  placeholder="Enter your hourly rate"
                />
              )}
            </FastField>
          </FormControl>
        </FormGroup>
      </Grid>

      <ShouttServiceFee hourlyRate={Number(form.values.hourlyRate)} countryCode={form.values.country} />
    </Grid>
  );
};
