import { FaqOption } from 'global/interfaces/faq';

export const billingFaqOptions: FaqOption[] = [
  {
    id: '01',
    question: 'Where do you get the opportunities from?',
    answer:
      'We scour 30+ sources from around the web on a daily basis - bringing you a fresh, curated feed of good quality opportunities that’s easy to use. All opportunities are max 7 days old.\n\nWe’re also working to bring you Shoutt exclusive opportunities that have been posted directly on our site.',
  },
  {
    id: '02',
    question: 'Why should we pay when we can find these opportunities for free?',
    answer:
      'Sure you could do that! Our team spends 40 hours per week finding and curating opportunities, saving you a considerable amount of time and making it a lot more convenient. Our Premium plan costs less than a pizza, so it’s incredible value!',
  },
  {
    id: '03',
    question: 'What makes Shoutt different?',
    answer:
      'No race to the bottom. No commissions. Less noise. We help both freelancers and businesses by simplifying the way they collaborate and encouraging strong relationships to be built. You can even bypass us whenever you want and nobody will throw suspension threats at you!',
  },
  {
    id: '04',
    question: 'What else do you offer apart from access to opportunities?',
    answer:
      'We offer a range of payment protection, contract and invoice tools. Freelancers can use these to ensure they get paid on time, manage deliverables and to build reviews against their profile, which in turn can help to convert more prospects into clients.',
  },
  {
    id: '05',
    question: 'How does payment protection work?',
    answer:
      'By billing your clients through Shoutt, they will need to pay upfront in order to activate their contract with you. We hold onto the funds while the work gets done and then pay directly to your bank. If something goes wrong, we can step in and help. Both fixed price and weekly / monthly retainers are supported with a range of customisation options. ',
  },
  {
    id: '06',
    question: 'How can I cancel?',
    answer:
      'Simply go to your account menu > My Subscriptions and downgrade to the free plan. You’ll continue to have full access until your billing date. ',
  },
  {
    id: '07',
    question: 'Where is Shoutt based?',
    answer:
      'We are a UK based startup with our registered office at 86-90 Paul Street, London, EC2A 4NE. Our team works remotely from the UK, US and Europe. We are a global platform and we support 40+ countries in GBP, EUR and USD. We are partners with Innovate UK, a government backed accelerator, plus Microsoft for Startups who are supporting us with tools and resources.',
  },
];
