export interface IStripeConnect {
  detailsSubmitted: boolean;
  ordersEnabled: boolean;
  payoutsEnabled: boolean;
  moreInformationRequired: boolean;
  payoutMethods: IStripePayoutMethod[];
  iconUrl?: string;
  logoUrl?: string;
}

export interface IPaymentMethods {
  bankTransfer: boolean;
  bacsDebit: boolean;
  sepaDebit: boolean;
  achDebit: boolean;
}

export enum StripePayoutType {
  BankAccount = 0,
  Card = 1,
}

export interface IStripePayoutMethod {
  name: string;
  last4: string;
  type: StripePayoutType;
}

export enum StripeOnboardType {
  Url = 'Url',
  AddressRequired = 'AddressRequired',
}

export interface IStripeOnBoarding {
  type: StripeOnboardType;
  value: string;
}

export interface IOnBoardingOptions {
  returnUrl?: string;
}
