import { useEffect, useState } from 'react';
import PublicIcon from '@mui/icons-material/Public';

export function LogoAdornmentHandler({ url }: { url: string }) {
  const [showExternalImg, setShowExternalImg] = useState(false);
  const [hostName, setHostName] = useState(url);

  const imgPath = `https://logo.clearbit.com/${hostName}`;

  const handleError = () => {
    setShowExternalImg(false);
  };

  useEffect(() => {
    try {
      const parsedUrl: string = new URL(url).hostname;
      setHostName(parsedUrl);
    } catch {
      try {
        const parsedUrl: string = new URL(`https://${url}`).hostname;
        setHostName(parsedUrl);
      } catch {
        setHostName(url);
      }
    }
  }, [url]);

  return (
    <>
      {!showExternalImg && <img src={imgPath} width={20} height={20} onLoad={() => setShowExternalImg(true)} hidden />}
      {showExternalImg ? <img src={imgPath} width={20} height={20} onError={handleError} /> : <PublicIcon />}
    </>
  );
}
