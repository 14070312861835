import { Box, Grid, Typography } from '@mui/material';

export const ContactUsConfirmation: React.FC = () => (
  <Grid container item xs={12} justifyContent="center">
    <Box maxWidth={600}>
      <Grid container item xs={11} pt="72px" gap={2} spacing={3} justifyContent="center">
        <Grid xs={12} item>
          <Typography textAlign="center" variant="h4">
            Message Sent
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <Typography textAlign="center" variant="body1">
            Your message has been received by our support team. We will get back to you within 2 business days.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  </Grid>
);
