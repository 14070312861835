import { AuthContext } from 'contexts/AuthContext';
import { UserRole } from 'global/enums/userRole';
import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

interface IProtectedRouteProps {
  role?: UserRole;
}

export const ProtectedRoute = ({ role }: IProtectedRouteProps) => {
  const authContext = useContext(AuthContext);
  const location = useLocation();

  if (authContext.loading) {
    return <></>;
  }

  const loggedIn = Boolean(authContext.user);
  const roleMatch = loggedIn && role ? authContext.user?.roles.includes(role) : undefined;
  if (!loggedIn || roleMatch === false) {
    const redirectPath = !loggedIn ? '/login' : '/';
    return <Navigate to={redirectPath} replace state={{ redirectTo: location }} />;
  }

  return <Outlet />;
};
