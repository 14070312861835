import * as React from 'react';
import { showError } from 'utils/errorHandler';
import { createOnBoardingUrl } from 'services/payoutService';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { IStripeOnBoarding, StripeOnboardType } from 'global/interfaces/payout';

function StripeReAuth(): JSX.Element {
  React.useEffect(() => {
    createOnBoardingUrl()
      .then((res: IStripeOnBoarding) => {
        if (res.type === StripeOnboardType.Url) {
          window.location.replace(res.value);
        }
      })
      .catch(showError);
  });

  return (
    <React.Fragment>
      <Container maxWidth="sm">
        <Typography variant="h5" component="div" align="center" sx={{ flexGrow: 1, fontWeight: 'bold', mt: 5 }}>
          Please wait while you are re-directed to Stripe.
        </Typography>
      </Container>
    </React.Fragment>
  );
}

export default StripeReAuth;
