import { Box, Container, Grid, Stack, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { NetworkTabValue } from 'global/enums/networkEnums';
import { IConnection } from 'global/interfaces/connection';
import { useEffect, useState } from 'react';
import { getInvitations } from 'services/connectionService';
import { showError } from 'utils/errorHandler';
import { ViewInvites } from 'components/network/ViewInvites';
import { useCustomEventListener } from 'react-custom-events';
import { MessagePublisherEventType } from 'global/enums/messagePublisherEventType';
import { useTitle } from 'utils/router';
import { PillStyledTab, PillStyledTabs } from 'components/common/StyledTabs/PillTabs';
import { ConnectionDirection } from 'global/enums/connectionDirection';
import { ConnectionStatus } from 'global/enums/connectionStatus';
import { StyledLink } from 'components/common/Link/StyledLink';
import { ChevronRight } from '@mui/icons-material';

const TitleGrid = styled(Grid)(() => ({
  marginTop: '36px',
  marginBottom: '20px',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
}));

const ContainerTopMargin = styled(Grid)(() => ({
  marginTop: '20px',
  width: '100%',
}));

const StyleTabCount = styled(Box)(({ theme }) => ({
  padding: '2px 8px',
  borderRadius: '64px',
  backgroundColor: theme.palette.primary.main,
}));

const ViewNetworkInvites: React.FC = () => {
  const [tabValue, setTabValue] = useState<NetworkTabValue>(NetworkTabValue.InvitationsRecieved);
  const [invitationsSent, setInvitationsSent] = useState<IConnection[]>([]);
  const [invitationsRecieved, setInvitationsRecieved] = useState<IConnection[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useTitle('Network');

  useEffect(() => {
    if (invitationsRecieved.length > 0) {
      setTabValue(NetworkTabValue.InvitationsRecieved);
    }
  }, [invitationsRecieved]);

  useEffect(() => {
    loadInvitations();
  }, [setRefresh, refresh]);

  const loadInvitations = () => {
    getInvitations()
      .then(res => {
        if (res.length > 0) {
          const sentInvitations: IConnection[] = [];
          const recievedInvitations: IConnection[] = [];
          res.forEach(invitation => {
            if (invitation.direction === ConnectionDirection.Send) {
              sentInvitations.push(invitation);
            } else if (
              invitation.direction === ConnectionDirection.Receive &&
              invitation.status === ConnectionStatus.Invitation
            ) {
              recievedInvitations.push(invitation);
            }
          });

          sentInvitations.sort((a, b) => {
            if (a.status === ConnectionStatus.Invitation && b.status !== ConnectionStatus.Invitation) {
              return -1;
            }
            if (a.status !== ConnectionStatus.Invitation && b.status === ConnectionStatus.Invitation) {
              return 1;
            }
            return 0;
          });

          setInvitationsSent(sentInvitations);
          setInvitationsRecieved(recievedInvitations);
        } else {
          setInvitationsSent([]);
          setInvitationsRecieved([]);
        }
      })
      .catch(showError);
  };

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.ConnectionInvite],
    () => {
      loadInvitations();
    },
    [],
  );

  const handleTabValueChange = (newValue: NetworkTabValue) => {
    setTabValue(newValue);
  };

  return (
    <Container>
      <Grid container margin="auto" maxWidth="lg">
        <Grid container item xs={12}>
          <TitleGrid container item xs={12}>
            <Typography variant={isMobileScreen ? 'h5' : 'h4'}>Invites</Typography>
            <StyledLink to="/network/connections">
              View Connections <ChevronRight />
            </StyledLink>
          </TitleGrid>
          <Box maxWidth={isMobileScreen ? '100%' : 'fit-content'}>
            <PillStyledTabs
              value={tabValue}
              onChange={(e, newValue) => {
                setTabValue(newValue);
                handleTabValueChange(newValue);
              }}
            >
              <PillStyledTab
                label={
                  <Stack direction="row" gap={1} alignItems="center">
                    <Typography variant="subtitle1">Received</Typography>

                    {!!invitationsRecieved?.length && (
                      <StyleTabCount>
                        <Typography variant="caption" color="common.white">
                          {invitationsRecieved?.length}
                        </Typography>
                      </StyleTabCount>
                    )}
                  </Stack>
                }
                sx={{ textTransform: 'none' }}
                value={NetworkTabValue.InvitationsRecieved}
              />

              <PillStyledTab
                label={
                  <Stack direction="row" gap={1} alignItems="center">
                    <Typography variant="subtitle1">Sent</Typography>
                  </Stack>
                }
                sx={{ textTransform: 'none' }}
                value={NetworkTabValue.InvitationsSent}
              />
            </PillStyledTabs>
          </Box>

          <ContainerTopMargin>
            <Grid item xs={12} sx={{ mb: 6 }}>
              {tabValue === NetworkTabValue.InvitationsSent && (
                <ViewInvites invites={invitationsSent} setRefresh={setRefresh} />
              )}

              {tabValue === NetworkTabValue.InvitationsRecieved && (
                <ViewInvites invites={invitationsRecieved} setRefresh={setRefresh} isRecieved={true} />
              )}
            </Grid>
          </ContainerTopMargin>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ViewNetworkInvites;
