import { Box, Dialog, Typography, useMediaQuery, useTheme } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';

interface IConfirmDialogProps {
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  onConfirm: () => void | ((selectedThreadId: string, recipientUserId: string) => void);
  title: string;
  content: string;
  onCancel: () => void;
  isConfirming?: boolean;
}

export const ConfirmDialog: React.FC<IConfirmDialogProps> = ({
  open,
  title,
  content,
  onConfirm,
  onCancel,
  isConfirming,
}) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog open={open} fullWidth fullScreen={isMobileScreen}>
      <StyledDialogTitle>
        <Typography variant="h6">{title}</Typography>
      </StyledDialogTitle>

      <StyledDialogContent dividers>
        <Box pb={2}>
          <Typography variant="body1">{content}</Typography>
        </Box>
      </StyledDialogContent>

      <StyledDialogActions>
        <RoundButton fullWidth={isMobileScreen} variant="outlined" onClick={onCancel}>
          Cancel
        </RoundButton>
        <RoundButton
          loading={isConfirming}
          fullWidth={isMobileScreen}
          variant="contained"
          onClick={onConfirm}
          autoFocus
        >
          Confirm
        </RoundButton>
      </StyledDialogActions>
    </Dialog>
  );
};
