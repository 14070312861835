import { Skeleton, styled } from '@mui/material';

export const VerticalCardSkeleton = styled(Skeleton)(({ theme }) => ({
  height: 450,
  width: 300,

  [theme.breakpoints.down('sm')]: {
    height: 450,
    width: 250,
  },
}));
