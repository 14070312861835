import { FileType } from 'global/enums/fileTypes';
import { IChatFileDisplay } from 'global/interfaces/chatMessage';
import { canPlayInBrowser } from 'global/Constants/SupportedFileTypes';

const fileTypeOrder = [FileType.Image, FileType.Pdf, FileType.Audio, FileType.Video, FileType.Other];

export const sortChatMediaFiles = (files: IChatFileDisplay[]) =>
  files
    .map(file => ({
      ...file,
      canPlay:
        ((file.fileType == FileType.Video || file.fileType == FileType.Audio) &&
          canPlayInBrowser(file.extension, file.contentType)) ||
        !!file.thumbnailUrl,
    }))
    .sort((x, y) => {
      const orderA = fileTypeOrder.indexOf(x.fileType);
      const orderB = fileTypeOrder.indexOf(y.fileType);
      return orderA - orderB;
    })
    .sort((x, y) => Number(y.canPlay) - Number(x.canPlay))
    .map((file, i, arr) => ({
      ...file,
      newLine:
        (file.canPlay && file.fileType == FileType.Video) ||
        (arr[i - 1] &&
          ((arr[i - 1].canPlay && !file.canPlay) ||
            (arr[i - 1].fileType != file.fileType && file.fileType == FileType.Audio))),
    }));
