export const getQueryParams = (filters: object | null): string => {
  const searchParams = new URLSearchParams();
  if (filters !== null) {
    Object.entries(filters)
      .filter(([_, v]) => v !== undefined)
      .forEach(([k, v]) =>
        Array.isArray(v) ? v.forEach(arrayItem => searchParams.append(k, arrayItem)) : searchParams.append(k, v),
      );
  }

  if (searchParams.get('pageSize') === null) {
    searchParams.append('pageSize', `${30}`);
  }

  return searchParams.toString();
};

export function getNameFromUrl(url: string): string {
  try {
    if (!url.includes('www.')) {
      if (url.startsWith('https://')) {
        url = url.replace('https://', 'https://www.');
      } else if (url.startsWith('http://')) {
        url = url.replace('http://', 'http://www.');
      }
    }

    const parsedUrl = new URL(url).hostname.split('.')[1];
    return `${parsedUrl.charAt(0).toUpperCase()}${parsedUrl.slice(1)}`;
  } catch {
    if (url === '' || url === 'http://' || url === 'https://') {
      return '';
    }

    let urlClone = url;
    let name = '';

    //Edge case, some urls can end in . apparently
    if (url[url.length - 1] === '.') {
      urlClone = url.slice(0, url.length - 1);
    }

    const parts = urlClone.toLowerCase().replace('https://', '.').replace('http://', '').replace('www.', '').split('.');

    for (const part of parts) {
      if (part !== '') {
        name = part;
        break;
      }
    }

    name = `${name[0].toUpperCase()}${name.slice(1)}`;
    return name;
  }
}

export function parseAsRelativeUrl(url: string): string {
  if (url.startsWith('www.') || (!url.startsWith('https://') && !url.startsWith('http://'))) {
    return `//${url}`;
  }
  return url;
}
