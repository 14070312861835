import { Container, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { RoundButton } from 'components/common/Button/RoundButton';
import DeleteProfileDialog from 'components/common/DeleteProfileDialog/DeleteProfile';
import { AuthContext } from 'contexts/AuthContext';
import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { logout } from 'services/authService';
import { redirectToLogin } from 'utils/router';

const Unsubscribe = () => {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [isDeleteProfileVisible, setIsDeleteProfileVisible] = useState<boolean>(false);

  const handleShowDeleteProfile = (): void => {
    if (authContext.user) {
      setIsDeleteProfileVisible(true);
    } else {
      redirectToLogin(navigate, location);
    }
  };

  const handleCloseDeleteProfile = (): void => {
    setIsDeleteProfileVisible(false);
  };

  const handleLogoutClick = (): void => {
    logout();
    authContext.refresh().finally(() => {
      navigate('/');
    });
  };
  return (
    <Container maxWidth="sm">
      <Grid container flexDirection="column" alignItems="center" minHeight={'calc(100vh - 158px)'} pb={9}>
        <>
          <Grid item xs={12} pt={5}>
            <Typography variant="h4" textAlign="center">
              Unsubscribe
            </Typography>
            <Typography variant="body1" pt={4} textAlign={'justify'}>
              Shoutt only sends emails to users that have expressly signed up with us and created an account. These are
              a mandatory part of the experience. The only way to unsubscribe from these emails is to delete your
              account.
            </Typography>

            <Typography variant="body1" pt={3} textAlign={'justify'}>
              Please note that as part of the terms & conditions that you accepted upon account creation, you will need
              to conclude any in-progress contracts no matter if you&apos;re a buyer or seller, before you can delete
              your account.
            </Typography>

            <Typography variant="body1" pt={3} textAlign={'justify'}>
              We hope you decide to stay with us and thank you for your participation in the Shoutt community.
            </Typography>

            <Typography textAlign="center" variant="body1" pt={4} component={'div'}>
              <RoundButton variant="contained" onClick={handleShowDeleteProfile}>
                Delete Account
              </RoundButton>
            </Typography>
          </Grid>
        </>
      </Grid>
      <DeleteProfileDialog
        open={isDeleteProfileVisible}
        onClose={handleCloseDeleteProfile}
        onLogout={handleLogoutClick}
      />
    </Container>
  );
};

export default Unsubscribe;
