import { Button, styled } from '@mui/material';

export const TextButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.dark,
  paddingInline: 0,

  '&:hover': {
    backgroundColor: 'transparent',
  },
}));
