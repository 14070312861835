import { Grid, Stack, Tooltip, styled, useMediaQuery, useTheme } from '@mui/material';
import { acceptConnectionRequest, rejectConnectionRequest } from 'services/connectionService';
import { showError } from 'utils/errorHandler';
import { useState } from 'react';
import useProgressBar from 'global/hooks/useProgressBar';
import { useNavigate } from 'react-router-dom';
import { RoundButton } from 'components/common/Button/RoundButton';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { RejectConnectionDialog } from './RejectConnectionDialog';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { StyledIconButton } from './contractHistory';

const StyledDeclineButton = styled(RoundButton)(({ theme }) => ({
  borderColor: theme.palette.grey[300],
  color: theme.palette.grey[900],
  '&:hover': {
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.grey[100],
  },
}));

const DeclineIconButton = styled(StyledIconButton)(({ theme }) => ({
  borderColor: theme.palette.grey[300],
}));

export const QuoteOptionButtons: React.FC<{
  connectionId: string | undefined;
  setRefresh: React.Dispatch<boolean>;
  otherUserId: string | undefined;
}> = ({ connectionId, setRefresh }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [progress, showProgress] = useProgressBar();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallLaptopScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));

  const handleCreatConnection = (id: string | undefined) => {
    if (id !== undefined) {
      showProgress(true);
      acceptConnectionRequest(id)
        .then(res => {
          showProgress(false);
          navigate(`/workroom/${res.chatThreadId}`);
        })
        .catch(showError);
    }
  };

  const rejectInvitation = (id: string, reason: string) => {
    showProgress(true);
    rejectConnectionRequest(id, reason)
      .then(() => {
        setRefresh(true);
        showProgress(false);
        setOpen(false);
      })
      .catch(showError);
  };

  return (
    <>
      <Stack
        direction={'row'}
        gap={1}
        width="100%"
        justifyContent={isSmallLaptopScreen ? 'center' : 'flex-start'}
        flexWrap={isSmallLaptopScreen ? 'wrap' : 'nowrap'}
      >
        {isSmallLaptopScreen ? (
          <Tooltip title="Decline">
            <DeclineIconButton onClick={() => setOpen(true)}>
              <CancelOutlinedIcon />
            </DeclineIconButton>
          </Tooltip>
        ) : (
          <Grid item xs={12}>
            <StyledDeclineButton
              fullWidth={isMobileScreen}
              variant="outlined"
              startIcon={<CancelOutlinedIcon />}
              onClick={() => setOpen(true)}
            >
              Decline
            </StyledDeclineButton>
          </Grid>
        )}

        {isSmallLaptopScreen ? (
          <Tooltip title="Accept">
            <StyledIconButton color="primary" onClick={() => handleCreatConnection(connectionId)}>
              <CheckCircleOutlineOutlinedIcon />
            </StyledIconButton>
          </Tooltip>
        ) : (
          <Grid item xs={12}>
            <RoundButton
              color="primary"
              variant="outlined"
              fullWidth={isMobileScreen}
              startIcon={<CheckCircleOutlineOutlinedIcon />}
              onClick={() => handleCreatConnection(connectionId)}
            >
              Accept
            </RoundButton>
          </Grid>
        )}
      </Stack>

      <RejectConnectionDialog
        open={open}
        setOpen={setOpen}
        confirmReject={rejectInvitation}
        id={connectionId}
        progress={progress}
      />

      {progress}
    </>
  );
};
