import { countries, fallbackContractCountry } from 'components/common/Select/CountrySelect';
import { IEditableUser } from 'global/interfaces/user';

export function setInitialValues(initialValues: IEditableUser): IEditableUser {
  initialValues.fixedPackages.forEach(fp => {
    if (fp.revisions === null) {
      fp.revisions = 2;
    }
    if (fp.deliveryMethods === null) {
      fp.deliveryMethods = 7;
    }
  });

  initialValues.currency =
    countries.find(c => c.code === initialValues.country)?.currency ?? fallbackContractCountry.currency;
  return initialValues;
}
