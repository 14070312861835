import {
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle, StyledGrow } from '../Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Dispatch, useEffect, useState } from 'react';
import { RoundButton } from '../Button/RoundButton';
import { ConnectionsSelectList } from '../SelectLists/ConnectionsSelectList';
import { FormikProps, useFormik } from 'formik';
import { IChatUserThread } from 'global/interfaces/chatThread';
import { getThread } from 'services/chatThreadService';
import { showError } from 'utils/errorHandler';
import { CreateContractStep } from './CreateContractDialog';
import { TeamsSelectList } from './TeamsSelectList';
import { ConnectionOption } from 'global/enums/contractConnectionOptions';
import { IFormSelectContractConnection } from 'global/interfaces/selectContractConnection';
import { selectContractConnectionValidation } from 'global/validations/selectContractConnection';
import { IConnection } from 'global/interfaces/connection';
import { IUser } from 'global/interfaces/user';
import { IEmailInvitationForm } from 'global/interfaces/emailInvitation';
import EmailInviteFromButton from 'components/network/EmailInviteFormButtons';
import EmailInviteFromContent from 'components/network/EmailInviteFormContent';
import { getActiveConnections } from 'services/connectionService';
import { EmailInviteStep } from 'global/enums/networkEnums';

interface ISelectConnectionProps {
  setOpenDialog: Dispatch<boolean>;
  setSelectedThread?: Dispatch<IChatUserThread | undefined>;
  setViewPackage: Dispatch<CreateContractStep>;
  setOtherUserId: (userId: string) => void;
  otherUserId?: string | null;
  setRedirectToThread: Dispatch<boolean>;
  user?: IUser | null;
}

export function SelectConnection({
  setOpenDialog,
  setSelectedThread,
  setViewPackage,
  otherUserId,
  setOtherUserId,
  setRedirectToThread,
  user,
}: ISelectConnectionProps) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);
  const [connections, setConnections] = useState<IConnection[]>();
  const [emailInviteStep, setEmailInviteStep] = useState<EmailInviteStep>(EmailInviteStep.Form);
  const [inviteForm, setInviteForm] = useState<FormikProps<IEmailInvitationForm>>();

  const form = useFormik<IFormSelectContractConnection>({
    initialValues: {
      selectedThreadId: '',
      selectedTeamThreadId: '',
      option: null,
    },
    validationSchema: selectContractConnectionValidation,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      getThread(values.selectedTeamThreadId !== '' ? values.selectedTeamThreadId! : values.selectedThreadId!)
        .then(res => {
          setSelectedThread && setSelectedThread(res);
          setRedirectToThread(true);
          setLoading(false);
          setViewPackage(CreateContractStep.SelectType);
        })
        .catch(error => {
          showError(error);
          setLoading(false);
          setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    getActiveConnections()
      .then(res => {
        setConnections(res);
        setLoading(false);
      })
      .catch(showError);
  }, []);

  return (
    <>
      <StyledDialogTitle>
        <Typography variant="h6" color={theme.palette.grey[900]}>
          New Contract
        </Typography>
        <IconButton onClick={() => setOpenDialog(false)} sx={{ marginRight: '-12px' }}>
          <CloseIcon />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent dividers>
        <StyledGrow in={true} timeout={1000}>
          <Grid container rowSpacing={1.5} mt={0.5}>
            {emailInviteStep == EmailInviteStep.Form && (
              <>
                <Typography variant="subtitle1" fontWeight={600}>
                  Who is the contract with?
                </Typography>
                <Typography variant="body2">
                  Required: You can create a contract with one of your connections or invite a user to Shoutt.
                </Typography>
                <Grid item xs={12}>
                  <RadioGroup name="option" value={form.values.option} onChange={form.handleChange} row>
                    <FormControlLabel
                      value={ConnectionOption.Connect}
                      control={<Radio />}
                      label="Connection"
                      disabled={connections?.length === 0}
                    />
                    <FormControlLabel value={ConnectionOption.Invite} control={<Radio />} label="Send Invitation" />
                  </RadioGroup>
                  <FormHelperText error>{form.errors.option}</FormHelperText>
                </Grid>
              </>
            )}
            {form.values.option == ConnectionOption.Connect && (
              <>
                <Grid item xs={12}>
                  <ConnectionsSelectList
                    name="selectedThreadId"
                    value={form.values.selectedThreadId ?? ''}
                    onChange={form.handleChange}
                    error={form.touched.selectedThreadId && Boolean(form.errors.selectedThreadId)}
                    setSelectedUserId={setOtherUserId}
                    connections={connections}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TeamsSelectList
                    name="selectedTeamThreadId"
                    selectedUserId={otherUserId}
                    value={form.values.selectedTeamThreadId ?? ''}
                    onChange={form.handleChange}
                    error={form.touched.selectedTeamThreadId && Boolean(form.errors.selectedTeamThreadId)}
                    form={form}
                  />
                </Grid>
              </>
            )}
            {user && form.values.option == ConnectionOption.Invite && (
              <>
                <Grid item xs={12}>
                  <EmailInviteFromContent
                    emailInviteStep={emailInviteStep}
                    user={user}
                    setInviteForm={setInviteForm}
                    setEmailInviteStep={setEmailInviteStep}
                    setLoading={setLoading}
                    setOtherUserId={setOtherUserId}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </StyledGrow>
      </StyledDialogContent>
      <StyledDialogActions>
        {form.values.option != ConnectionOption.Invite && (
          <>
            <RoundButton fullWidth={isMobileScreen} variant="outlined" onClick={() => setOpenDialog(false)}>
              Cancel
            </RoundButton>
            <RoundButton loading={loading} fullWidth={isMobileScreen} variant="contained" onClick={form.submitForm}>
              Next
            </RoundButton>
          </>
        )}
        {form.values.option == ConnectionOption.Invite && (
          <>
            <EmailInviteFromButton
              loading={loading}
              emailInviteStep={emailInviteStep}
              otherUserId={otherUserId ?? ''}
              form={inviteForm}
              handleFormClose={() => setOpenDialog(false)}
              setLoading={setLoading}
            />
          </>
        )}
      </StyledDialogActions>
    </>
  );
}
