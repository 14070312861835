import ActionBox from './ActionBox';

import CopyToClipboardButton from 'components/common/Button/CopyToClipboardButton';
import { getReferralUrl } from 'utils/user';
import { AuthContext } from 'contexts/AuthContext';
import { useContext } from 'react';
import { Tooltip, useTheme } from '@mui/material';

export default function ActionRefer() {
  const authContext = useContext(AuthContext);
  const theme = useTheme();
  return (
    <ActionBox
      title="Refer a friend"
      body={
        <>
          Refer a friend and get a month of premium for FREE when they sign up to the premium plan.{' '}
          <Tooltip
            arrow
            enterTouchDelay={0}
            placement="bottom"
            title="You must already be on the premium plan. You will receive a month of premium for FREE for each friend you refer when they sign up to premium. Refer 12 and you will get a whole year for FREE! Each friend will also receive 50% off their first month of premium. "
            componentsProps={{
              tooltip: {
                sx: {
                  maxWidth: '320px',
                  borderRadius: '12px',
                },
              },
            }}
          >
            <span style={{ textDecoration: 'underline', color: theme.palette.primary.dark }}>Learn more.</span>
          </Tooltip>
        </>
      }
    >
      <CopyToClipboardButton
        textToCopyToClipboard={getReferralUrl(authContext.user?.id ?? '')}
        buttonLabel="Copy link"
        roundButton={true}
        sx={{
          padding: '14px 28px',
          borderRadius: theme.spacing(6),

          [theme.breakpoints.down('sm')]: {
            padding: '12px 22px',
          },
        }}
      />
    </ActionBox>
  );
}
