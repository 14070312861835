import {
  ImageList,
  Typography,
  FormHelperText,
  useTheme,
  IconButton,
  SelectChangeEvent,
  Dialog,
  useMediaQuery,
  Stack,
  Box,
  styled,
} from '@mui/material';
import IApiError from 'global/interfaces/api';
import { IChatFile, IChatFileDisplay } from 'global/interfaces/chatMessage';
import { IChatUserThread } from 'global/interfaces/chatThread';
import { saveChatFile } from 'services/storageService';
import { showError, showErrorMessage } from 'utils/errorHandler';
import { getFileType } from 'utils/file';
import { UserAuth } from 'contexts/AuthContext';
import { newFileMessage } from 'services/chatMessageService';
import useProgressBar from 'global/hooks/useProgressBar';
import CloseIcon from '@mui/icons-material/Close';
import useContractDeliverables from './useContractDeliverables';
import { IDeliverableForApproval } from 'global/interfaces/contract';
import { useEffect, useState } from 'react';
import FormSelect from 'components/common/Select/FormSelect';
import DeliverablesSelect from './DeliverablesSelect';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { RoundButton } from 'components/common/Button/RoundButton';
import ChatMessageMediaItem from './media/ChatMessageMediaItem';
import { sortChatMediaFiles } from './media/chatMediaUtils';

interface IUploadFilesProps {
  user: UserAuth | null;
  selectedThread?: IChatUserThread;
  selectedFiles: FileList | null;
  handleUploadFilesClose: () => void;
}

const StyledImageList = styled(ImageList)(() => ({
  marginBottom: '0px',
}));

const maxFileSize = 1024 * 1024 * 1024;

export default function UploadFilesDialog(props: IUploadFilesProps): JSX.Element {
  const [open, setOpen] = useState(false);
  const [uploadedChatFiles, setUploadedChatFiles] = useState<IChatFileDisplay[]>([]);
  const [uploadingComplete, setUploadingComplete] = useState<boolean>(false);
  const [sendClicked, setSendClicked] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [selectedDeliverables, setSelectedDeliverables] = useState<IDeliverableForApproval[]>([]);
  const [progress, showProgress] = useProgressBar();

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const numCols = 2;
  const gap = 10;

  const handleDeliverablesChange = (deliverables: IDeliverableForApproval[]) => {
    setSelectedDeliverables(deliverables);
  };

  const handleClose = (): void => {
    setOpen(false);
    props.handleUploadFilesClose();
  };

  const handleSend = (): void => {
    setSendClicked(true);
    if (uploadingComplete) {
      setOpen(false);
      showProgress(true);
      newFileMessage(props.selectedThread?.chatThreadId ?? '', uploadedChatFiles, selectedDeliverables)
        .then(() => {
          // Add message to UI
          showProgress(false);
          props.handleUploadFilesClose();
        })
        .catch((err: IApiError) => {
          showErrorMessage('Could not send message. Please refresh the page: ' + err.message);
        });
    }
  };

  const createTemporaryChatFiles = (files: FileList, chatThreadId: string): IChatFileDisplay[] => {
    const chatFiles: IChatFileDisplay[] = [];
    /* eslint-disable no-unmodified-loop-condition */
    for (let i = 0; files !== null && i < files?.length; i++) {
      const nameParts: string[] = files[i].name.split('.');
      const extension = `.${nameParts.pop() ?? ''}`;
      const fileName = files[i].name.replace(extension, '');
      const chatFile: IChatFileDisplay = {
        chatThreadId,
        fileName,
        extension,
        fileType: getFileType(files[i].type),
        loading: true,
      };
      chatFiles.push(chatFile);
    }

    return chatFiles;
  };

  const uploadFilesToServer = (): void => {
    if (props.selectedFiles !== null && props.selectedThread?.chatThreadId !== undefined) {
      if (Array.from(props.selectedFiles).some(f => f.size > maxFileSize)) {
        showErrorMessage('File size must be no more than 1GB.');
        setOpen(false);
        props.handleUploadFilesClose();
      } else {
        const initialFiles = createTemporaryChatFiles(props.selectedFiles, props.selectedThread.chatThreadId);
        setUploadedChatFiles(initialFiles);

        const tempUploadedChatFiles: IChatFile[] = [];
        for (let i = 0; i < props.selectedFiles.length; i++) {
          const file = props.selectedFiles[i];
          let controller: AbortController | null = new AbortController();
          saveChatFile(file, props.selectedThread?.chatThreadId, controller)
            .then((fileResponse: IChatFileDisplay) => {
              controller = null;
              tempUploadedChatFiles.push(fileResponse);
              setUploadedChatFiles(current => current.map((item, index) => (index === i ? fileResponse : item)));

              if (props.selectedFiles !== null && tempUploadedChatFiles.length === props.selectedFiles.length) {
                setUploadingComplete(true);
              }
            })
            .catch(showError);
        }
      }
    }
  };

  useEffect(() => {
    setUploadingComplete(false);
    setSendClicked(false);
    setSelectedUserId(undefined);
    setSelectedDeliverables([]);
    const chatThreadId: string = props.selectedThread?.chatThreadId ?? '';
    if (chatThreadId != '' && props.selectedFiles !== null && props.selectedFiles.length > 0) {
      setOpen(true);
      uploadFilesToServer();
    }
  }, [props.selectedThread, props.selectedFiles, props.user]);

  const { deliverables, deliverableUsers } = useContractDeliverables(
    open,
    props.selectedThread?.chatThreadId,
    selectedUserId,
  );

  const sortedUploadedChatFiles: IChatFileDisplay[] = sortChatMediaFiles(uploadedChatFiles);
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        fullScreen={isMobileScreen}
        aria-labelledby="upload-files-dialog-title"
        aria-describedby="upload-files-description"
      >
        <StyledDialogTitle>
          <Typography variant="h6">Upload Files</Typography>
          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>

        <StyledDialogContent dividers>
          <StyledImageList cols={numCols} gap={gap}>
            {sortedUploadedChatFiles.map(item => (
              <ChatMessageMediaItem key={item.fileName + item.extension} chatFile={item} numCols={numCols} />
            ))}
          </StyledImageList>

          {sendClicked && !uploadingComplete && (
            <FormHelperText error sx={{ mb: 1 }}>
              Files are still uploading
            </FormHelperText>
          )}

          {deliverables.length > 0 && (
            <Stack gap={1}>
              <Box>
                <Typography variant="subtitle1">Deliverables</Typography>
                <Typography variant="body2">Optional. Submit one or more deliverables for approval.</Typography>
              </Box>

              {deliverableUsers.length > 1 && (
                <FormSelect
                  items={[{ id: '', label: 'None' }, ...deliverableUsers]}
                  value={selectedUserId ?? ''}
                  onChange={(e: SelectChangeEvent<unknown>) => {
                    setSelectedUserId(e.target.value as string);
                  }}
                  label="Buyer"
                  name="buyerId"
                  size="small"
                />
              )}

              {((deliverableUsers.length > 1 && selectedUserId) || deliverableUsers.length == 1) && (
                <DeliverablesSelect onChange={handleDeliverablesChange} deliverables={deliverables} />
              )}
            </Stack>
          )}
        </StyledDialogContent>

        <StyledDialogActions>
          <RoundButton fullWidth={isMobileScreen} variant="outlined" onClick={handleClose}>
            Cancel
          </RoundButton>
          <RoundButton fullWidth={isMobileScreen} variant="contained" onClick={handleSend}>
            Send
          </RoundButton>
        </StyledDialogActions>
      </Dialog>
      {progress}
    </>
  );
}
