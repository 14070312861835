import { InsertLink } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';

interface IUserScreeningLinksProps {
  links: string[];
}

export const UserScreeningLinks = ({ links }: IUserScreeningLinksProps): JSX.Element => (
  <Stack sx={{ padding: 1 }} direction="row" flexWrap="wrap" gap={1}>
    {links.length > 0
      ? links.map((link, i) => (
          <Button target="_blank" href={link} key={`link-${i}`} variant="outlined" startIcon={<InsertLink />}>
            {link}
          </Button>
        ))
      : 'No Links'}
  </Stack>
);
