import { ExpandMore } from '@mui/icons-material';
import { CircularProgress, SelectProps, Tooltip, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { showError } from 'utils/errorHandler';
import FormSelect from '../Select/FormSelect';
import { SelectItem } from 'global/interfaces/selects';
import { getGroupThreadsForUsers } from 'services/chatThreadService';
import { ChatType, IChatUserThread } from 'global/interfaces/chatThread';
import { getLimitedChars } from 'utils/text';
import { FormikProps } from 'formik';
import { IFormSelectContractConnection } from 'global/interfaces/selectContractConnection';

interface ITeamsSelectListProps extends SelectProps {
  selectedUserId?: string | null;
  form: FormikProps<IFormSelectContractConnection>;
}

export function TeamsSelectList({ selectedUserId, form, ...props }: ITeamsSelectListProps) {
  const [groupThreads, setGroupThreads] = useState<IChatUserThread[]>();
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    if (!selectedUserId) {
      return;
    }

    getGroupThreadsForUsers(selectedUserId)
      .then(res => {
        const sortedThreads = res.sort((a, b) => {
          if (a.type === ChatType.OneToOne && b.type !== ChatType.OneToOne) {
            return -1;
          }
          if (a.type !== ChatType.OneToOne && b.type === ChatType.OneToOne) {
            return 1;
          }
          return 0;
        });
        setGroupThreads(sortedThreads);
        if (sortedThreads.length > 0) {
          form.setFieldValue('selectedTeamThreadId', sortedThreads[0].chatThreadId);
        }
        setLoading(false);
      })
      .catch(showError);
  }, [selectedUserId]);

  if (!groupThreads || groupThreads?.length <= 1) {
    return null;
  }

  if (groupThreads && groupThreads.length > 0) {
    return (
      <>
        <Typography variant="subtitle1" fontWeight={600} mt={2}>
          Direct Message or Team Chat?
        </Typography>
        <Typography variant="body2" mb={2}>
          Required: Would like you like to associate the contract with the DM or a Team?
          <Tooltip
            arrow
            enterTouchDelay={0}
            placement="bottom"
            title="Choosing a team allows you to keep all the contracts associated with that team/project in one place making it easier to track costs and progress. If associating with a Team, contract and payment data will not be visible to other Team members."
            componentsProps={{
              tooltip: {
                sx: {
                  maxWidth: '320px',
                  borderRadius: '12px',
                },
              },
            }}
          >
            <span style={{ textDecoration: 'underline', color: theme.palette.primary.dark }}>Learn more</span>
          </Tooltip>
        </Typography>
        <FormSelect
          label=""
          fullWidth
          endAdornment={loading ? <CircularProgress /> : undefined}
          IconComponent={loading ? 'span' : ExpandMore}
          items={groupThreads ? mapToSlectItems(groupThreads) : []}
          helpertext={props.error === true ? 'Select a Team' : undefined}
          disabled={loading}
          {...props}
        />
      </>
    );
  }

  return null;
}

function mapToSlectItems(threads: IChatUserThread[]): SelectItem[] {
  return threads.map(thread => ({
    id: thread.chatThreadId ?? '',
    label: thread.displayName
      ? thread.type === ChatType.Group
        ? `#${getLimitedChars(thread.displayName)}`
        : 'Direct Message'
      : '',
  }));
}
