import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const VerifiedBadgeIcon = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 81 81" {...props}>
    <g clipPath="url(#clip0_4920_15854)">
      <path
        d="M62.5684 14.3379C63.3294 16.1783 64.7901 17.6412 66.6294 18.4049L73.0788 21.0764C74.9194 21.8388 76.3817 23.3011 77.1441 25.1416C77.9065 26.9822 77.9064 29.0502 77.1441 30.8908L74.4745 37.3356C73.7118 39.177 73.7108 41.2471 74.4769 43.0876L77.1418 49.5305C77.5198 50.4421 77.7144 51.4193 77.7146 52.4061C77.7147 53.393 77.5204 54.3702 77.1428 55.2819C76.7651 56.1936 76.2115 57.022 75.5136 57.7197C74.8157 58.4174 73.9871 58.9707 73.0753 59.3481L66.6305 62.0176C64.79 62.7786 63.3272 64.2393 62.5634 66.0786L59.892 72.528C59.1296 74.3686 57.6673 75.8309 55.8267 76.5933C53.9861 77.3557 51.9181 77.3557 50.0775 76.5933L43.6327 73.9237C41.7921 73.1632 39.7248 73.1648 37.8854 73.9281L31.4359 76.5957C29.5964 77.3563 27.5301 77.3556 25.6911 76.5939C23.852 75.8321 22.3905 74.3715 21.6276 72.5329L18.9554 66.0816C18.1944 64.2412 16.7337 62.7783 14.8944 62.0145L8.44498 59.3431C6.60522 58.581 5.14333 57.1196 4.38066 55.2801C3.618 53.4406 3.61696 51.3735 4.37779 49.5333L7.04732 43.0885C7.80782 41.2479 7.80627 39.1806 7.043 37.3411L4.3773 30.887C3.99936 29.9754 3.80475 28.9983 3.80459 28.0114C3.80444 27.0246 3.99873 26.0474 4.37638 25.1356C4.75403 24.2239 5.30763 23.3955 6.00555 22.6978C6.70347 22.0001 7.53203 21.4468 8.44388 21.0695L14.8887 18.3999C16.7275 17.6396 18.1895 16.1808 18.9538 14.3436L21.6253 7.89417C22.3877 6.0536 23.85 4.59128 25.6905 3.8289C27.5311 3.06651 29.5991 3.06651 31.4397 3.8289L37.8845 6.49842C39.7251 7.25893 41.7924 7.25738 43.6319 6.4941L50.084 3.83304C51.9243 3.07108 53.9919 3.07123 55.8321 3.83347C57.6723 4.5957 59.1345 6.05761 59.897 7.89769L62.5692 14.349L62.5684 14.3379Z"
        fill="#115FF8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.4477 29.7081C56.983 28.8674 57.1623 27.8485 56.9463 26.8755C56.7303 25.9026 56.1367 25.0553 55.296 24.52C54.4552 23.9848 53.4363 23.8055 52.4634 24.0215C51.4904 24.2375 50.6432 24.8311 50.1079 25.6718L35.4001 48.7826L28.6697 40.3696C28.0475 39.5913 27.1415 39.0921 26.1512 38.9817C25.1609 38.8714 24.1673 39.159 23.389 39.7812C22.6107 40.4034 22.1115 41.3093 22.0011 42.2997C21.8908 43.29 22.1784 44.2836 22.8006 45.0619L32.8161 57.5813C33.1894 58.0484 33.6688 58.4197 34.2145 58.6642C34.7602 58.9088 35.3563 59.0195 35.9534 58.9872C36.5505 58.9548 37.1313 58.7804 37.6473 58.4784C38.1634 58.1764 38.5999 57.7555 38.9206 57.2508L56.4477 29.7081Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4920_15854">
        <rect width="80.1242" height="80.1242" fill="white" transform="translate(0.694824 0.147461)" />
      </clipPath>
    </defs>
  </SvgIcon>
);
