import {
  Box,
  FormControl,
  FormGroup,
  Grid,
  IconButton,
  styled,
  SwipeableDrawer,
  TextField,
  Typography,
} from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { IFrontUser } from 'global/interfaces/user';
import { Dispatch, useState } from 'react';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { IConnection } from 'global/interfaces/connection';
import { createInvitation } from 'services/connectionService';
import { pushToDataLayer } from 'utils/tagHelper';
import { showError } from 'utils/errorHandler';
import { useNavigate, useParams } from 'react-router-dom';
import { connectionRequestEvent } from 'global/constants';
import { Close } from '@mui/icons-material';
import { StyledDialogActions } from 'components/common/Dialog';

const TitleBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  padding: 24px;
  margin-bottom: 16px;
`;

const TitleAddDetBox = styled(TitleBox)`
  margin-bottom: 0px;
`;

interface IProjectDetailsProps {
  user: IFrontUser;
  open: boolean;
  setOpen: Dispatch<boolean>;
  setDetailsDrawer: Dispatch<boolean>;
}

export default function ProjectDetails({ setOpen, open, user, setDetailsDrawer }: IProjectDetailsProps) {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState(false);

  const form = useFormik<{ message: string }>({
    initialValues: {
      message: '',
    },
    validationSchema: object({
      message: string().max(2000, 'Message must be at most 2000 characters').required('You must enter a message'),
    }),
    onSubmit: values => {
      setLoading(true);

      createInvitation({
        userId: id,
        message: values.message,
      })
        .then((connection: IConnection) => {
          pushToDataLayer(connectionRequestEvent);
          setLoading(false);
          if (connection.chatThreadId) navigate(`/workroom/${connection.chatThreadId}`);
          else setSuccessMessage(true);
        })
        .catch(showError);
    },
  });

  const handleClose = () => {
    setOpen(false);
    setDetailsDrawer(false);
  };

  return (
    <SwipeableDrawer anchor="right" onClose={handleClose} open={open} onOpen={() => setOpen(true)}>
      {!successMessage && (
        <>
          <TitleAddDetBox>
            <Typography variant="h5">Connect with {user.firstName} </Typography>
            <IconButton aria-label="Close" onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </TitleAddDetBox>
          <Box paddingLeft={3} paddingRight={3}>
            <Box>
              <Typography variant="body2" maxWidth={'500px'}>
                Add some details about your project. Please <b>avoid</b> including your email address or contact details
                in your initial message to the freelancer.
              </Typography>
            </Box>
          </Box>
          <Box>
            <Grid container item xs={12} pt={'24px'} spacing={3} justifyContent="center">
              <Grid item xs={11}>
                <FormGroup>
                  <FormControl>
                    <TextField
                      name="message"
                      multiline
                      rows={5}
                      fullWidth
                      value={form.values.message}
                      onChange={form.handleChange}
                      error={form.touched.message !== undefined && Boolean(form.errors.message)}
                      helperText={form.touched.message !== undefined ? form.errors.message : ''}
                    />
                  </FormControl>
                </FormGroup>
              </Grid>
              <>
                <StyledDialogActions sx={{ marginTop: '8px', width: '100%' }}>
                  <Grid container justifyContent="flex-end" gap={2}>
                    <RoundButton variant="outlined" type="button" onClick={handleClose}>
                      Cancel
                    </RoundButton>

                    <RoundButton
                      loading={loading}
                      disabled={loading}
                      variant="contained"
                      type="button"
                      onClick={() => form.handleSubmit()}
                    >
                      Send
                    </RoundButton>
                  </Grid>
                </StyledDialogActions>
              </>
            </Grid>
          </Box>
        </>
      )}
      {successMessage && (
        <>
          <TitleAddDetBox>
            <Typography variant="h5">Message Sent</Typography>
            <IconButton aria-label="Close" onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </TitleAddDetBox>
          <Box paddingLeft={3} paddingRight={3}>
            <Box>
              <Typography variant="body2">Your message has been sent. Please wait for a response.</Typography>
            </Box>
          </Box>
          <StyledDialogActions sx={{ marginTop: '8px', width: '100%' }}>
            <RoundButton variant="contained" type="button" onClick={handleClose}>
              Close
            </RoundButton>
          </StyledDialogActions>
        </>
      )}
    </SwipeableDrawer>
  );
}
