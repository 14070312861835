const getWidgetElement = () => document.getElementById('_hj_feedback_container');

export const hideFeedbackWidget = () => {
  const feedbackBox = getWidgetElement();
  if (feedbackBox) {
    feedbackBox.style.display = 'none';
  }
};

export const showFeedbackWidget = () => {
  const feedbackBox = getWidgetElement();
  if (feedbackBox) {
    feedbackBox.style.display = 'block';
  }
};
