import WorkHistoryEditor from 'components/userProfileEdits/sections/EditWorkHistorySection/WorkHistoryEditor';
import { FormikProps } from 'formik';
import { IEditableUser, IWorkHistory } from 'global/interfaces/user';

interface IEditWorkHistorySectionProps {
  form: FormikProps<IEditableUser>;
}

export const EditWorkHistorySection = ({ form }: IEditWorkHistorySectionProps) => {
  // On History Change
  const onUpdateHistories = (history: IWorkHistory[]): void => {
    form.setFieldValue('workHistories', [...history]);
  };

  return (
    <>
      <WorkHistoryEditor
        error={form.touched.workHistories !== undefined && Boolean(form.errors.workHistories)}
        onUpdateHistories={onUpdateHistories}
        workHistories={form.values.workHistories}
      />
    </>
  );
};
