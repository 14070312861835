import { useEffect, useRef, useState } from 'react';

export const useIntersection = (element: any, rootMargin: string): boolean => {
  const [isVisible, setState] = useState(false);
  const elementRef = useRef(element);

  useEffect(() => {
    const { current } = elementRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
      },
      { rootMargin },
    );

    current !== null && observer.observe(current);

    return () => {
      observer.unobserve(current);
    };
  }, [elementRef.current]);

  return isVisible;
};

type IntersectionObserverCallback = (entries: IntersectionObserverEntry[]) => void;
type IntersectionObserverOptions = IntersectionObserverInit;

export const useIntersectionObserver = (
  callback: IntersectionObserverCallback,
  options: IntersectionObserverOptions,
) => {
  const observer = useRef(new IntersectionObserver(entries => callback(entries), options));
  const targetRef = useRef(null);

  useEffect(() => {
    if (targetRef.current) {
      observer.current.observe(targetRef.current);
    }

    return () => {
      observer.current.disconnect();
    };
  }, [targetRef.current]);

  return targetRef;
};
