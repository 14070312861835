import { dateStringFromDate, timeStringFromDate } from 'utils/dateFormat';
import { MakePaymentIcon } from 'components/icon/MakePaymentIcon';
import { ChatMessageType, IChatMessageDisplay } from 'global/interfaces/chatMessage';
import { AuthType } from 'contexts/AuthContext';
import { IChatUserThread } from 'global/interfaces/chatThread';
import { ReviewContentIcon } from 'components/icon/ReviewContentIcon';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { PeopleAcceptedIcon } from 'components/icon/PeopleAcceptedIcon';
import { ConnectionRemovedIcon } from 'components/icon/ConnectionRemovedIcon';
import { PaymentRequestedIcon } from 'components/icon/PaymentRequestedIcon';
import { PaymentSuccessfulIcon } from 'components/icon/PaymentSuccessfulIcon';
import { ReviewCompletedIcon } from 'components/icon/ReviewCompletedIcon';
import { ContractCompletedIcon } from '../icon/ContractCompletedIcon';
import RateReviewOutlined from '@mui/icons-material/RateReviewOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { FileCheckIcon } from 'components/icon/FileCheckIcon';
import { FileXIcon } from 'components/icon/FileXIcon';
import { FileIcon } from 'components/icon/FileIcon';

export function updateMessage(
  m: IChatMessageDisplay,
  lastDate: string,
  authContext: AuthType,
  thread: IChatUserThread | undefined,
): string {
  m.time = timeStringFromDate(m.createdDateTime);
  m.date = dateStringFromDate(m.createdDateTime);
  m.firstDate = m.date !== lastDate;
  lastDate = m.date;
  m.isSystemMessage = false;
  m.messageTitle = m.userDisplayName;
  const isBuyer = authContext.user?.id == m.contract?.buyerUserId;

  if (authContext.user?.id === m.userId) {
    m.messageTitle = 'You';
  }

  if (m.messageType === ChatMessageType.Payment) {
    m.messageTitle = isBuyer ? 'Make Payment' : 'Payment Requested';
    m.systemIcon = isBuyer ? <MakePaymentIcon /> : <PaymentRequestedIcon />;
    m.isSystemMessage = true;
  } else if (m.messageType === ChatMessageType.PaymentSuccessful) {
    m.messageTitle = 'Payment Successful';
    m.systemIcon = <PaymentSuccessfulIcon />;
    m.isSystemMessage = true;
  } else if (m.messageType === ChatMessageType.Review) {
    m.messageTitle = 'Review Requested';
    m.systemIcon = <ReviewContentIcon />;
    m.isSystemMessage = true;
  } else if (m.messageType === ChatMessageType.ReviewComplete) {
    m.messageTitle = 'Review Complete';
    m.systemIcon = <ReviewCompletedIcon />;
    m.isSystemMessage = true;
  } else if (m.messageType === ChatMessageType.ContractComplete) {
    m.messageTitle = 'Contract Complete';
    m.systemIcon = <ContractCompletedIcon />;
    m.isSystemMessage = true;
  } else if (m.messageType === ChatMessageType.ContractCancelled) {
    m.messageTitle = 'Contract Cancelled';
    m.systemIcon = <CloseOutlinedIcon />;
    m.isSystemMessage = true;
  } else if (m.messageType === ChatMessageType.ConnectionAccepted) {
    m.messageTitle = 'Connection Request Accepted';
    m.systemIcon = <PeopleAcceptedIcon />;
    m.isSystemMessage = true;
  } else if (m.messageType === ChatMessageType.ConnectionAutoAccepted) {
    m.messageTitle = 'New Connection';
    m.systemIcon = <PeopleAcceptedIcon />;
    m.isSystemMessage = true;
  } else if (m.messageType === ChatMessageType.ConnectionRemoved) {
    m.messageTitle = 'Connection Removed';
    m.systemIcon = <ConnectionRemovedIcon />;
    m.isSystemMessage = true;
  } else if (m.messageType === ChatMessageType.NewReviewAdded) {
    m.messageTitle = authContext.user?.id === m.userId ? 'Feedback Given' : 'Feedback Received';
    m.systemIcon = <RateReviewOutlined sx={{ strokeWidth: 0.1 }} />;
    m.isSystemMessage = true;
  } else if (m.messageType === ChatMessageType.ContractRefunded) {
    m.messageTitle = 'Refund Successful';
    m.systemIcon = <RefreshIcon />;
    m.isSystemMessage = true;
  } else if (m.messageType === ChatMessageType.PaymentFailed) {
    m.messageTitle = 'Payment Failed';
    m.systemIcon = <ErrorOutlineOutlinedIcon style={{ strokeWidth: 0.5 }} />;
    m.isSystemMessage = true;
  } else if (m.messageType === ChatMessageType.AcceptContract) {
    m.messageTitle = isBuyer ? 'Contract Offer Sent' : 'Accept Contract';
    m.systemIcon = <FileIcon />;
    m.isSystemMessage = true;
  } else if (m.messageType === ChatMessageType.ContractAccepted) {
    m.messageTitle = 'Contract Accepted';
    m.systemIcon = <FileCheckIcon />;
    m.isSystemMessage = true;
  } else if (m.messageType === ChatMessageType.ContractDeclined) {
    m.messageTitle = 'Contract Declined';
    m.systemIcon = <FileXIcon />;
    m.isSystemMessage = true;
  } else if (m.messageType === ChatMessageType.InvitationAccepted) {
    m.messageTitle = 'Invitation Accepted';
    m.systemIcon = <PeopleAcceptedIcon />;
    m.isSystemMessage = true;
  }

  if (m.visibleToUserId !== undefined && m.visibleToUserId !== null) {
    let visibleMessage = 'Only visible to';
    let needAnd = false;
    if (m.visibleToUserId === authContext.user?.id || m.visibleToOtherUserId === authContext.user?.id) {
      visibleMessage += ' you';
      needAnd = true;
    }

    if (m.visibleToUserId !== authContext.user?.id) {
      const firstUser = thread?.recipients.find(p => p.userId === m.visibleToUserId);
      visibleMessage += needAnd ? ` and ${firstUser?.displayName}` : ` ${firstUser?.displayName}`;
    }

    if (
      m.visibleToOtherUserId !== undefined &&
      m.visibleToOtherUserId !== null &&
      m.visibleToOtherUserId !== authContext.user?.id
    ) {
      const secondUser = thread?.recipients.find(p => p.userId === m.visibleToOtherUserId);
      visibleMessage += ` and ${secondUser?.displayName}`;
    }

    m.visibleMessage = visibleMessage;
  }
  return lastDate;
}
