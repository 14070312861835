export enum WorkroomLeftMenuOptions {
  'Active Contracts' = 0,
  Members = 1,
}

export enum WorkRoomView {
  Left = 1,
  Middle = 2,
  Right = 3,
}
