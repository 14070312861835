import { Grid, styled } from '@mui/material';

export const DrawerBody = styled(Grid)(({ theme }) => ({
  paddingTop: '36px',
  paddingInline: '36px',
  paddingBottom: '16px',
  [theme.breakpoints.down('sm')]: {
    paddingInline: '24px',
  },
}));

export const DrawerFooter = styled(Grid)(({ theme }) => ({
  padding: '16px 24px',
  width: '500px',
  backgroundColor: theme.palette.common.white,
  overflowX: 'hidden',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
    flexWrap: 'nowrap',
  },
}));

export const DrawerHeader = styled(Grid)(({ theme }) => ({
  paddingTop: '17px',
  paddingBottom: '16px',
  paddingInline: '36px',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  borderBottom: '1px solid',
  borderColor: theme.palette.grey[200],

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingInline: '24px',
  },
}));
