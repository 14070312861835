import { SvgIcon, SvgIconProps } from '@mui/material';

export const ContractCompletedIcon = (props: SvgIconProps) => (
  <SvgIcon width="16" height="20" viewBox="0 0 16 20" {...props}>
    <path
      d="M9.66668 1.89127V5.33338C9.66668 5.80009 9.66668 6.03345 9.7575 6.21171C9.8374 6.36851 9.96488 6.49599 10.1217 6.57589C10.2999 6.66672 10.5333 6.66672 11 6.66672H14.4421M5.50001 13.3333L7.16668 15L10.9167 11.25M9.66668 1.66666H5.33334C3.93321 1.66666 3.23315 1.66666 2.69837 1.93914C2.22796 2.17882 1.84551 2.56128 1.60583 3.03168C1.33334 3.56646 1.33334 4.26653 1.33334 5.66666V14.3333C1.33334 15.7335 1.33334 16.4335 1.60583 16.9683C1.84551 17.4387 2.22796 17.8212 2.69837 18.0608C3.23315 18.3333 3.93321 18.3333 5.33334 18.3333H10.6667C12.0668 18.3333 12.7669 18.3333 13.3017 18.0608C13.7721 17.8212 14.1545 17.4387 14.3942 16.9683C14.6667 16.4335 14.6667 15.7335 14.6667 14.3333V6.66666L9.66668 1.66666Z"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
