import { Box, Stack, styled, Typography } from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { ReactNode } from 'react';

interface I_ImageUploadButtonProps {
  accept: string;
  description: string | ReactNode;
  onImageChange: React.ChangeEventHandler<Element>;
  multiple?: boolean;
  disabled?: boolean;
}

const StyledFileInputBox = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  padding: '16px 24px',
  border: '1px solid',
  borderRadius: '12px',
  borderColor: theme.palette.grey[200],
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  justifyContent: 'center',
  alignItems: 'center',
  height: '130px',
}));

const StyleCloudUploadIcon = styled(CloudUploadOutlinedIcon)(() => ({
  fontSize: '40px',
}));

export default function FileUploadButton({
  onImageChange,
  accept,
  multiple,
  description,
  disabled,
}: I_ImageUploadButtonProps) {
  return (
    <StyledFileInputBox component="label">
      <StyleCloudUploadIcon />

      {!disabled ? (
        <Stack gap={0.5}>
          <Stack direction="row" gap={0.5} justifyContent="center">
            <Typography variant="body2" fontWeight={600} color={'primary.700'}>
              Click to Upload
            </Typography>
            <Typography variant="body2" color="grey.600">
              or Drag and Drop
            </Typography>
          </Stack>

          {isReactNode(description) ? (
            <>{description}</>
          ) : (
            <Typography
              variant="caption"
              color="grey.600"
              textAlign="center"
              dangerouslySetInnerHTML={{ __html: description as string }}
            />
          )}
        </Stack>
      ) : (
        <Stack gap={0.5}>
          <Typography variant="body2" color="grey.600">
            Cannot Upload
          </Typography>
        </Stack>
      )}
      <input onChange={onImageChange} hidden multiple={multiple} accept={accept} type="file" disabled={disabled} />
    </StyledFileInputBox>
  );
}

const isReactNode = (value: any): value is ReactNode => typeof value !== 'string';
