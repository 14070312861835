import React, { useCallback, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Carousel from 'react-material-ui-carousel';
import { IFileMetadata } from 'global/interfaces/file';
import PortfolioCarouselSlide from './PortfolioCarouselSlide';

interface IPortfolioCarouselDialogProps {
  files: IFileMetadata[];
  isOpen: boolean;
  initialIndex: number;
  onClose: React.MouseEventHandler;
}

//TODO: remove this and use FileViewerCarouselDialog
export default function PortfolioCarouselDialog({
  isOpen,
  onClose,
  initialIndex,
  files,
}: IPortfolioCarouselDialogProps): JSX.Element {
  const [activeSlide, setActiveSlide] = useState<number>();

  useEffect(() => {
    setActiveSlide(initialIndex);
  }, [initialIndex]);

  const handleOnChange = useCallback((now?: number) => {
    if (now !== undefined) {
      setActiveSlide(now);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      setActiveSlide(initialIndex);
    }
  }, [isOpen]);

  return (
    <Dialog maxWidth="md" open={isOpen} onClose={onClose} fullWidth>
      <Carousel
        navButtonsProps={{
          style: {
            opacity: '0.3',
          },
        }}
        navButtonsAlwaysVisible={true}
        fullHeightHover={false}
        autoPlay={false}
        sx={{
          textAlign: 'center',
          height: {
            xs: '40vh',
            md: '50vh',
            lg: '60vh',
          },
        }}
        height="100%"
        index={initialIndex}
        IndicatorIcon={false}
        onChange={handleOnChange}
      >
        {files.map((item, i) => (
          <PortfolioCarouselSlide key={i} isActive={i == activeSlide} {...item} />
        ))}
      </Carousel>
    </Dialog>
  );
}
