import { pushToDataLayer } from 'utils/tagHelper';
import { shareUserProfile } from 'global/constants';
import ShareButton from 'components/ShareButton';

export default function ProfileShareButton({ userId, url }: { userId: string; url: string }) {
  const eventFunc = () => {
    pushToDataLayer(shareUserProfile, {
      userId: userId,
    });
  };

  return <ShareButton url={url} eventFunc={eventFunc} />;
}
