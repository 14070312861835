import { Grid, Stack, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { IFrontUser } from 'global/interfaces/user';
import { BorderedBox } from '../common/BorderedBox';
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';

const StyledTranslateOutlinedIcon = styled(TranslateOutlinedIcon)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fill: theme.palette.grey[600],
    width: '32px',
    height: '32px',
    marginRight: '8px',
  },
}));

interface IUserLanguagesSectionProps {
  user: IFrontUser;
}

export default function UserLanguagesSection({ user }: IUserLanguagesSectionProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <BorderedBox showBorder={!isMobile} pt="24px">
      <Stack mb={2} ml={isMobile ? 'unset' : '-4px'} alignItems="center" gap={1} flexDirection="row">
        {!isMobile && <StyledTranslateOutlinedIcon />}
        <Typography variant={'h6'}>Languages</Typography>
      </Stack>

      {user.languages.map((language, i) => (
        <Grid item xs={12} key={i} container alignItems="center">
          <Typography variant="subtitle2" mr="8px">
            {language.language}:
          </Typography>
          <Typography variant="body2">{language.proficiency}</Typography>
        </Grid>
      ))}
    </BorderedBox>
  );
}
