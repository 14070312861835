import Grid from '@mui/material/Grid';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Dialog,
  FormHelperText,
  IconButton,
  Paper,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { getBase64Image } from 'utils/file';
import Croppie from 'croppie';
import 'croppie/croppie.css';
import { JustifiedBox } from 'components/common/StyledWorkroom/WorkroomStyles';
import { RoundButton } from 'components/common/Button/RoundButton';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import FileUploadButton from 'components/common/Button/FileUploadButton';
import { refreshAvatar } from 'components/common/UserAvatar/avatarCache';
import { AuthContext, UserAuth } from 'contexts/AuthContext';
import { UserAvatar } from 'components/common/UserAvatar';
import { useParams } from 'react-router-dom';

interface IProfileImageEditorProps {
  handleProfileImgChange: (file: File) => Promise<string>;
  error: string | undefined;
  imagePath: string | undefined;
  isTouched: boolean;
  isSellingServices: boolean;
  disabled?: boolean;
  hasChanged?: boolean;
}

const StyledJustifiedBox = styled(JustifiedBox)(({ theme }) => ({
  width: '100%',
  alignItems: 'flex-end',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
}));

const StyledUserAvatar = styled(UserAvatar)(({ theme }) => ({
  mt: 1,
  width: '130px',
  height: '130px',
  borderRadius: '8px',

  [theme.breakpoints.down('sm')]: {
    margin: 'auto',
  },
}));

export default function ProfileImageEditor(props: IProfileImageEditorProps): JSX.Element {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  const authContext = useContext(AuthContext);

  const [cropImage, setCropImage] = useState('');
  const [croppie, setCroppie] = useState<Croppie | null>(null);
  const [loading, setLoading] = useState(false);
  const [fileTypeError, setTypeError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [isTempProfileImage, setIsTempProfileImage] = useState(false);

  // Initialize image cropper
  const handleImage = (image: string): void => {
    const el = document.getElementById('image-helper');
    if (el !== null) {
      const croppieInstance = new Croppie(el, {
        viewport: {
          height: 280,
          width: matchesSm ? 310 : 360,
        },
        showZoomer: false,
      });
      croppieInstance.bind({
        url: image,
        zoom: 0,
      });
      setCroppie(croppieInstance);
    }
  };

  const handleImageInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file: File | null = event.target.files !== null ? event.target.files[0] : null;
    if (file !== null) {
      setFileSizeError(false);
      setTypeError(false);
      const maxFileSize = 10 * 1024 * 1024;
      if (file.size > maxFileSize) {
        setFileSizeError(true);
        return;
      }

      if (
        !Object.keys(supportedFileTypes)
          .map(k => supportedFileTypes[k as keyof typeof supportedFileTypes])
          .includes(file.type)
      ) {
        setTypeError(true);
        return;
      }

      getBase64Image(file).then((res: string) => {
        setCropImage(file.name);
        handleImage(res);
      });
    }
  };

  const handleCancelCrop = (e: React.MouseEvent): void => {
    e.preventDefault();
    setCroppie(null);
    setCropImage('');
  };

  const handleApplyCrop = (e: React.MouseEvent): void => {
    e.preventDefault();
    setLoading(true);
    if (croppie !== null) {
      croppie
        .result({
          type: 'blob',
          size: 'viewport',
          quality: 1,
        })
        .then((blob: Blob) => {
          props
            .handleProfileImgChange(new File([blob], cropImage, { type: blob.type }))
            .then(() => {
              if (authContext.user) {
                setIsTempProfileImage(true);
                refreshAvatar(authContext.user.id);
              }
            })
            .finally(() => {
              setCropImage('');
              setCroppie(null);
              setLoading(false);
            });
        });
    }
  };

  // Check edit mode or existing picture and render relevant sub-component
  return cropImage !== ''
    ? imageCropper(props, matchesSm, handleCancelCrop, handleApplyCrop, loading)
    : profilePicture(
        props,
        matchesSm,
        handleImageInputChange,
        fileTypeError,
        fileSizeError,
        isTempProfileImage || (props.hasChanged ?? false),
        authContext?.user,
        props.disabled,
      );
}

function imageCropper(
  props: IProfileImageEditorProps,
  isMobileScreen: boolean,
  handleCancelCrop: React.MouseEventHandler,
  handleApplyCrop: React.MouseEventHandler,
  loading: boolean,
): JSX.Element {
  return (
    <Dialog open fullWidth fullScreen={isMobileScreen} maxWidth={'sm'}>
      <StyledDialogTitle>
        <Typography color="grey.900" variant={isMobileScreen ? 'h6' : 'h5'}>
          Crop Profile Picture
        </Typography>

        <IconButton onClick={handleCancelCrop}>
          <ClearOutlinedIcon />
        </IconButton>
      </StyledDialogTitle>

      <StyledDialogContent dividers>
        <Grid xs={12} item>
          <Paper
            variant="outlined"
            sx={{
              mt: 1,
              p: '0.1rem',
              width: 'auto',
              height: '290px',
              maxWidth: '100%',
            }}
          >
            <div id="image-helper"></div>
          </Paper>
        </Grid>
      </StyledDialogContent>

      <StyledDialogActions>
        <RoundButton
          size="large"
          fullWidth={isMobileScreen}
          onClick={handleCancelCrop}
          variant="outlined"
          startIcon={<ClearIcon />}
        >
          Cancel
        </RoundButton>
        <RoundButton
          loading={loading}
          size="large"
          fullWidth={isMobileScreen}
          onClick={handleApplyCrop}
          variant="contained"
          startIcon={<DoneIcon />}
        >
          Apply
        </RoundButton>
      </StyledDialogActions>
    </Dialog>
  );
}

function profilePicture(
  props: IProfileImageEditorProps,
  isMobileScreen: boolean,
  handleProfileImgChange: React.ChangeEventHandler,
  fileTypeError: boolean,
  fileSizeError: boolean,
  isTempProfileImage: boolean,
  user?: UserAuth | null,
  disabled?: boolean,
): JSX.Element {
  const { id } = useParams();
  return (
    <Grid container width={1}>
      <Stack gap={0.5} pb="24px">
        <Typography variant="h6">Profile Photo</Typography>
        <Typography variant="body2">
          {props.isSellingServices
            ? 'Required: Upload your profile photo or logo'
            : 'Required: Use your logo, avatar, or anything else to represent your business!'}
        </Typography>
      </Stack>

      <StyledJustifiedBox rowGap={'24px'} columnGap={'24px'}>
        <Stack gap={3} alignSelf="flex-start" width={isMobileScreen ? '100%' : 'auto'}>
          <Stack width={isMobileScreen ? '100%' : 'auto'}>
            <StyledUserAvatar
              userId={disabled ? id : user?.id}
              displayName={`${user?.firstName} ${user?.lastName}`}
              noLink
              isTemp={isTempProfileImage}
            />
          </Stack>
        </Stack>

        <Grid item width={'100%'}>
          <FileUploadButton
            accept={Object.keys(supportedFileTypes).join(', ')}
            description="PNG, JPG, JPEG, WEBP, GIF, TIFF or BMP"
            onImageChange={handleProfileImgChange}
            disabled={disabled}
          />
          {fileSizeError && <FormHelperText error>File size must be no more than 10MB</FormHelperText>}
          {fileTypeError && (
            <FormHelperText error>
              File must be one of{' '}
              {Object.keys(supportedFileTypes).map(
                (k: string, i: number) => `${k}${i !== Object.keys(supportedFileTypes).length - 1 ? ', ' : ''}`,
              )}
            </FormHelperText>
          )}
        </Grid>
      </StyledJustifiedBox>

      {props.isTouched && Boolean(props.error) && (
        <Grid item>
          <FormHelperText sx={{ textAlign: 'center' }} error>
            {props.error}
          </FormHelperText>
        </Grid>
      )}
    </Grid>
  );
}

const supportedFileTypes = {
  '.jpg': 'image/jpeg',
  '.jpeg': 'image/jpeg',
  '.png': 'image/png',
  '.webp': 'image/webp',
  '.gif': 'image/gif',
  '.tiff': 'image/tiff',
  '.bmp': 'image/bmp',
};
