import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';

export const DashboardButton = styled(LoadingButton)(({ theme }) => ({
  padding: '14px 28px',
  borderRadius: theme.spacing(6),

  [theme.breakpoints.down('sm')]: {
    padding: '12px 22px',
  },
}));
