import { GlobalStateContext } from 'contexts/GlobalStateContext';
import { addDays } from 'date-fns';
import { useContext, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

interface ILocationHandlerProps {
  refresh: (x?: boolean) => void;
  children?: React.ReactNode;
}

export function LocationHandler({ refresh, children }: ILocationHandlerProps) {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { updateShowGlobalNav } = useContext(GlobalStateContext);

  const setReferral = () => {
    if (searchParams && searchParams.has('referral') && searchParams.get('referral') != null) {
      const refId = searchParams.get('referral') ?? '';
      localStorage.setItem('ReferralID', refId);
      //We are giving referrals a 14 day expiry. If they don't sign-up within 14 days of clicking through then it doesn't count
      const referralExpiry = addDays(new Date(), 14);
      localStorage.setItem('ReferralIDExpiry', referralExpiry.toUTCString());
    } else if (localStorage.getItem('ReferralIDExpiry') != null) {
      const referralExpiry = new Date(localStorage.getItem('ReferralIDExpiry') ?? new Date());
      if (new Date() > referralExpiry) {
        localStorage.removeItem('ReferralID');
        localStorage.removeItem('ReferralIDExpiry');
      }
    }
  };

  const setSource = () => {
    if (searchParams && searchParams.has('source') && searchParams.get('source') != null) {
      const refId = searchParams.get('source') ?? '';
      localStorage.setItem('SourceID', refId);
      //We are source referrals a 14 day expiry. If they don't sign-up within 14 days of clicking through then it doesn't count
      const referralExpiry = addDays(new Date(), 14);
      localStorage.setItem('SourceIDExpiry', referralExpiry.toUTCString());
    } else if (localStorage.getItem('SourceIDExpiry') != null) {
      const referralExpiry = new Date(localStorage.getItem('SourceIDExpiry') ?? new Date());
      if (new Date() > referralExpiry) {
        localStorage.removeItem('SourceID');
        localStorage.removeItem('SourceIDExpiry');
      }
    }
  };

  useEffect(() => {
    refresh();
    setReferral();
    setSource();
  }, []); //Needed to go to the top of pages on route changes

  useEffect(() => {
    window.scrollTo(0, 0);
    updateShowGlobalNav(true);
  }, [pathname]);

  return <>{children}</>;
}
