import { CircularProgress } from '@mui/material';
import { EditUserProfile } from 'components/userProfileEdits/EditUserProfile';
import { IUser } from 'global/interfaces/user';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getUser } from 'services/userService';
import { showError } from 'utils/errorHandler';
import { useTitle } from 'utils/router';
import { UserNotFound } from 'views/users/UserNotFound';

export default function AdminUserEdit() {
  const { id } = useParams();
  const [user, setUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState(true);

  useTitle(user ? `Admin - ${user.firstName} ${user.lastName}` : undefined, [user]);

  useEffect(() => {
    getUser(id)
      .then((responseUser: IUser) => {
        setUser(responseUser);
        setLoading(false);
      })
      .catch(showError);
  }, []);

  if (!loading && !user) {
    return <UserNotFound />;
  }

  return <>{user ? <EditUserProfile user={user} isApplyPhase={false} /> : <CircularProgress />}</>;
}
