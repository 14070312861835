import { Box, CircularProgress, Grid, Stack, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { IFrontUser } from 'global/interfaces/user';
import { BorderedBox } from '../common/BorderedBox';
import { useLocalization } from 'global/hooks/useLocalization';
import { fallbackContractCountry } from 'components/common/Select/CountrySelect';
import { useContext } from 'react';
import { AuthContext } from 'contexts/AuthContext';
import { CoinsStackedIcon } from 'components/icon/CoinsStackedIcon';

interface IUserHourlyRateSectionProps {
  user: IFrontUser;
  setDrawer: () => void;
}

const StyledPointOfSaleIcon = styled(CoinsStackedIcon)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fill: theme.palette.grey[600],
    width: '32px',
    height: '32px',
    marginRight: '16px',
    marginLeft: '-8px',
  },
}));

export default function UserHourlyRateSection(props: IUserHourlyRateSectionProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { localCurrencySymbol, rateInCurrency } = useLocalization(props.user.usdHourlyRate);
  const authContext = useContext(AuthContext);

  return (
    <BorderedBox showBorder={!isMobile}>
      <Stack mb={2} ml={isMobile ? 'unset' : '-4px'} alignItems="center" gap={1} flexDirection="row">
        {!isMobile && <StyledPointOfSaleIcon />}
        <Typography variant="h6">Hourly Rate</Typography>
      </Stack>

      <Box mt={1}>
        {authContext.viewerCountry ? (
          <Typography variant="subtitle2">
            {localCurrencySymbol ?? fallbackContractCountry.currencySymbol} {rateInCurrency} / Hour
          </Typography>
        ) : (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        )}
      </Box>
    </BorderedBox>
  );
}
