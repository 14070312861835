import {
  ContractDeliverableStatus,
  ContractPaymentStatus,
  ContractPayoutStatus,
  ContractStatus,
  ContractType,
  IContract,
  IContractAcceptResult,
  IContractAction,
  IContractBasic,
  IContractDisplay,
  IContractEarnings,
  IContractFilters,
  IContractPaymentSecret,
  IContractRetainerPayment,
  ICreateContractForm,
  IDeliverableForApproval,
  IRefundContract,
  IReviewedDeliverable,
  RecurrenceType,
} from 'global/interfaces/contract';
import { authorizedGet, authorizedPost } from './baseApiService';
import { getQueryParams } from 'utils/url';
import { IFrontUserReview } from 'global/interfaces/userReview';

const coreResource = 'core/Contract';

interface ICreateContractRequest extends ICreateContractForm {
  chatThreadId?: string;
}

export function updateContract(contract: IContractDisplay): void {
  const isBuyer = Boolean(contract.buyerAdditionalDetails);
  contract.numDeliverables = contract.deliverables.length;
  const completedDeliverables = contract.deliverables.filter(p => p.status === ContractDeliverableStatus.Approved);
  contract.numCompletedDeliverables = completedDeliverables.length;

  if (contract.status === ContractStatus.Complete && contract.contractType === ContractType.Retainer) {
    contract.paymentStatusText = 'Complete';
  } else if (
    contract.status === ContractStatus.Complete &&
    !isBuyer &&
    contract.sellerAdditionalDetails?.payoutStatus === ContractPayoutStatus.Success
  ) {
    contract.paymentStatusText = 'Complete';
  } else if (contract.status === ContractStatus.Complete && isBuyer) {
    contract.paymentStatusText = 'Complete';
  } else if (contract.status === ContractStatus.Complete && contract.sellerAdditionalDetails) {
    if (contract.sellerAdditionalDetails?.payoutStatus === ContractPayoutStatus.Success) {
      contract.paymentStatusText =
        'Paid Out: ' +
        contract.sellerAdditionalDetails.payoutCurrency +
        ' ' +
        contract.sellerAdditionalDetails.payoutAmount;
    } else {
      const payoutDateString = contract.sellerAdditionalDetails?.availableForPayoutDate;
      if (payoutDateString != undefined && payoutDateString != '') {
        const date = new Date(payoutDateString);
        if (date > new Date()) {
          const dateStr = new Intl.DateTimeFormat().format(date);
          contract.paymentStatusText = `On: ${dateStr}`;
        } else {
          contract.paymentStatusText = 'Processing';
        }
      } else {
        contract.paymentStatusText = '';
      }
    }
  } else if (
    contract.paymentStatus === ContractPaymentStatus.Success &&
    (contract.buyerAdditionalDetails?.payoutStatus === ContractPayoutStatus.Success ||
      contract.sellerAdditionalDetails?.payoutStatus === ContractPayoutStatus.Success)
  ) {
    contract.paymentStatusText = 'Released';
  } else if (
    contract.paymentStatus === ContractPaymentStatus.Success &&
    (contract.buyerAdditionalDetails?.payoutStatus === ContractPayoutStatus.Partial ||
      contract.sellerAdditionalDetails?.payoutStatus === ContractPayoutStatus.Partial)
  ) {
    contract.paymentStatusText = (contract.endRelease ?? 0) * 100 + '% in escrow';
  } else if (contract.paymentStatus === ContractPaymentStatus.Success) {
    contract.paymentStatusText = 'In escrow';
  } else if (contract.paymentStatus === ContractPaymentStatus.Refunded) {
    contract.paymentStatusText = 'Refunded';
  } else if (contract.status === ContractStatus.Cancelled) {
    contract.paymentStatusText = 'Cancelled';
  } else if (contract.status === ContractStatus.Offered) {
    contract.paymentStatusText = 'Awaiting Acceptance';
  } else {
    contract.paymentStatusText = 'Awaiting';
  }

  if (contract.duration !== undefined && contract.duration !== null && contract.recurrence !== null) {
    const endText = contract.recurrence === RecurrenceType.Monthly ? ' month(s)' : ' week(s)';
    contract.durationText = contract.duration > 0 ? contract.duration + endText : 'Rolling';
  }

  if (contract.release !== undefined && contract.release != null) {
    if (contract.release === 1) {
      contract.releaseText = 'End of period';
    } else if (contract.release === 0.5) {
      contract.releaseText = 'Middle of period';
    } else {
      contract.releaseText = 'Start of period';
    }
  }
}

export async function createContract(
  createContractForm: ICreateContractRequest,
  chatThreadId: string | undefined,
): Promise<boolean> {
  createContractForm.chatThreadId = chatThreadId;
  return await authorizedPost(`${coreResource}/create-contract`, createContractForm);
}

export async function getContractPaymentSecret(contractId: string): Promise<IContractPaymentSecret> {
  return await authorizedGet<IContractPaymentSecret>(`${coreResource}/get-payment-secret?contractId=${contractId}`);
}

export async function getContractInvoiceSecret(contractId: string): Promise<IContractPaymentSecret> {
  return await authorizedGet<IContractPaymentSecret>(`${coreResource}/get-invoice-secret?contractId=${contractId}`);
}

export async function getContractInvoiceUrl(invoiceId: string, isRetainerInvoice: boolean): Promise<string> {
  return await authorizedGet<string>(
    `${coreResource}/get-invoice-url?invoiceId=${invoiceId}&isRetainerInvoice=${isRetainerInvoice}`,
  );
}

export async function getContractRetainerPaymentSecret(contractId: string): Promise<IContractPaymentSecret> {
  return await authorizedGet<IContractPaymentSecret>(
    `${coreResource}/get-retainer-payment-secret?contractId=${contractId}`,
  );
}

export async function getRetainerPayments(contractId: string): Promise<IContractRetainerPayment[]> {
  return await authorizedGet<IContractRetainerPayment[]>(
    `${coreResource}/get-retainer-payments?contractId=${contractId}`,
  );
}

export async function getContract(contractId: string): Promise<IContract> {
  return await authorizedGet<IContract>(`${coreResource}/get-contract?contractId=${contractId}`);
}

export async function getOpenContracts(chatThreadId: string): Promise<IContract[]> {
  return await authorizedGet<IContract[]>(`${coreResource}/get-open-contracts?chatThreadId=${chatThreadId}`);
}

export async function getActionRequiredContracts(): Promise<IContractAction[]> {
  return await authorizedGet<IContractAction[]>(`${coreResource}/get-action-required-contracts`);
}

export async function getEarnings(): Promise<IContractEarnings[]> {
  return await authorizedGet<IContractEarnings[]>(`${coreResource}/get-earnings`);
}

export async function getContractsInEscrow(): Promise<IContractBasic[]> {
  return await authorizedGet<IContractBasic[]>(`${coreResource}/get-contracts-in-escrow`);
}

export async function getContracts(filters: IContractFilters | null): Promise<IContract[]> {
  return await authorizedGet<IContract[]>(`${coreResource}/get-contracts?${getQueryParams(filters)}`);
}

export async function reviewDeliverables(
  reviewedDeliverables: IReviewedDeliverable[],
  messageId: string,
): Promise<boolean> {
  return await authorizedPost(`${coreResource}/review-deliverables`, {
    reviewedDeliverables,
    messageId,
  });
}

export async function completeContract(contractId: string): Promise<boolean> {
  return await authorizedPost(`${coreResource}/complete-contract/${contractId}`, {});
}

export async function sendDeliverablesForApproval(
  chatThreadId: string,
  deliverablesForApproval: IDeliverableForApproval[],
): Promise<boolean> {
  return await authorizedPost<boolean>(`${coreResource}/send-deliverables-for-approval`, {
    chatThreadId,
    deliverablesForApproval,
  });
}

export async function cancelContract(contractId: string): Promise<boolean> {
  return await authorizedPost<boolean>(`${coreResource}/cancel-contract?contractId=${contractId}`, {});
}

export async function acceptOrDeclineContract(contractId: string, accept: boolean): Promise<IContractAcceptResult> {
  return await authorizedPost<IContractAcceptResult>(
    `${coreResource}/accept-decline-contract?contractId=${contractId}&accept=${accept}`,
    {},
  );
}

export async function validateContractName(name: string | undefined): Promise<boolean> {
  return await authorizedPost<boolean>(`${coreResource}/validations/name`, {
    name: name,
  });
}

export async function raiseDispute(contractId: string): Promise<boolean> {
  return await authorizedPost<boolean>(`${coreResource}/raise-dispute/${contractId}`, {});
}

export async function getDisputes(): Promise<IContract[]> {
  return await authorizedGet<IContract[]>(`${coreResource}/get-disputes`);
}

export async function resolveDipute(resolveDisputeModel: {
  resolutionType: string;
  contractId: string;
}): Promise<boolean> {
  return await authorizedPost<boolean>(`${coreResource}/resolve-dispute`, resolveDisputeModel);
}

export async function getUserReviews(userId: string) {
  return await authorizedGet<IFrontUserReview[]>(`${coreResource}/get-user-reviews/${userId}`);
}

export async function refundContract(contractId: string, refundContract: IRefundContract) {
  return await authorizedPost<IContract>(`${coreResource}/refund-user/${contractId}`, refundContract);
}
