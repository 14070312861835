import { SelectProps } from '@mui/material';
import { ErrorSelectProps } from 'global/interfaces/selects';
import FormSelect from './FormSelect';
import CategoryLookups from 'json/CategoryLookups.json';

interface CategorySelectProps extends ErrorSelectProps, SelectProps {}

export default function CategorySelect({ ...props }: CategorySelectProps): JSX.Element {
  const lookups = [...CategoryLookups];
  lookups.sort((a: { label: string }, b: { label: any }) => a.label.localeCompare(b.label));
  const items = [{ id: '', label: 'None' }, ...lookups];

  return <FormSelect {...props} items={items} />;
}
