import { DialogContentText, Grid, TextField, Typography } from '@mui/material';
import { FormikProps, useFormik } from 'formik';
import { EmailInvitationResponseType, EmailInviteStep } from 'global/enums/networkEnums';
import IApiError from 'global/interfaces/api';
import { IEmailInvitationForm, IEmailInvitationResponse } from 'global/interfaces/emailInvitation';
import { IUser } from 'global/interfaces/user';
import { emailInvitationValidationSchema } from 'global/validations/emailInvitation';
import { Dispatch, useEffect } from 'react';
import { createEmailInvitation } from 'services/connectionService';
import { showError } from 'utils/errorHandler';

interface EmailInviteFromContentProps {
  emailInviteStep: EmailInviteStep;
  user: IUser | null | undefined;
  setInviteForm: Dispatch<FormikProps<IEmailInvitationForm>>;
  setLoading: (value: boolean) => void;
  setOtherUserId: (value: string) => void;
  setEmailInviteStep: (value: EmailInviteStep) => void;
}

const EmailInviteFromContent: React.FC<EmailInviteFromContentProps> = ({
  emailInviteStep,
  user,
  setInviteForm,
  setLoading,
  setOtherUserId,
  setEmailInviteStep,
}) => {
  const form = useFormik<IEmailInvitationForm>({
    initialValues: {
      email: '',
      message: `I use Shoutt to manage my contracts and billing. I'd like to invite you to join me by creating a free account so we can collaborate easily. Thanks, ${user?.firstName}`,
    },
    validationSchema: emailInvitationValidationSchema,
    onSubmit: (values: IEmailInvitationForm) => {
      setLoading(true);
      createEmailInvitation(values)
        .then((response: IEmailInvitationResponse) => {
          if (response.responseType === EmailInvitationResponseType.ExistingConnection) {
            setOtherUserId(response.otherUserId ? response.otherUserId : '');
            setEmailInviteStep(EmailInviteStep.ExistingConnection);
          } else if (response.responseType === EmailInvitationResponseType.ExistingUser) {
            setOtherUserId(response.otherUserId ? response.otherUserId : '');
            setEmailInviteStep(EmailInviteStep.ExistingUser);
          } else {
            setEmailInviteStep(EmailInviteStep.InvitationSent);
          }
        })
        .catch((err: IApiError) => {
          setEmailInviteStep(EmailInviteStep.Failure);
          showError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  useEffect(() => {
    setInviteForm(form);
  }, []);

  return (
    <Grid item>
      {(emailInviteStep === EmailInviteStep.Form || emailInviteStep === EmailInviteStep.Failure) && (
        <>
          <Typography variant="body2">Enter the email address and a message to send an invite.</Typography>
          <TextField
            autoFocus
            margin="dense"
            name="email"
            label="Email *"
            type="email"
            fullWidth
            variant="outlined"
            onChange={form.handleChange}
            error={form.touched.email !== undefined && Boolean(form.errors.email)}
            helperText={form.touched.email !== undefined ? form.errors.email : ''}
          />
          <TextField
            margin="dense"
            name="message"
            label="Invite Message *"
            type="text"
            fullWidth
            multiline
            rows={6}
            variant="outlined"
            value={form.values.message}
            onChange={form.handleChange}
            error={form.touched.message !== undefined && Boolean(form.errors.message)}
            helperText={form.touched.message !== undefined ? form.errors.message : ''}
          />
          {emailInviteStep === EmailInviteStep.Failure && (
            <DialogContentText>Failed to send the invitation, please try again.</DialogContentText>
          )}
        </>
      )}
      {emailInviteStep === EmailInviteStep.InvitationSent && (
        <Typography variant="body1">Invitation sent successfully!</Typography>
      )}
      {emailInviteStep === EmailInviteStep.ExistingUser && (
        <Typography variant="body1">
          The user is already on Shoutt, would you like to connect with that person?
        </Typography>
      )}
      {emailInviteStep === EmailInviteStep.ExistingConnection && (
        <Typography variant="body1">
          This user is already on Shoutt and is one of your connections. Would you like to chat to them now?
        </Typography>
      )}
    </Grid>
  );
};

export default EmailInviteFromContent;
