import { FormikProps } from 'formik';
import { IEditableUser, IUser } from 'global/interfaces/user';
import { Dispatch, useEffect, useState } from 'react';
import { EditUserHeadSection } from './sections/EditUserHeadSection/EditUserHeadSection';
import { MessagePublisherEventType } from 'global/enums/messagePublisherEventType';
import { useCustomEventListener } from 'react-custom-events';
import { nameof } from 'ts-simple-nameof';
import { EditPortfolioSection } from './sections/EditPortfolioSection';
import { EditSellServicesSection } from './sections/EditSellServicesSection/EditSellServicesSection';
import { useDebouncedValidate } from '../../utils/formikDebounceValidation';
import { UserProfileTabsValue } from 'global/enums/userProfileEnum';
import ProfileFormButtons from './ProfileFormButtons';
import { sectionToTabs } from './TabFieldMapping';
import ConfirmSubmitApprovalDialog from './ConfirmSubmitApprovalDialog';

const fieldToTab = Object.entries(sectionToTabs).reduce<Record<string, UserProfileTabsValue>>(
  (transformed, [key, values]) => (values.forEach(value => (transformed[value] = +key)), transformed),
  {},
);

interface IEditUserProfileFormProps {
  form: FormikProps<IEditableUser>;
  activeTab: UserProfileTabsValue;
  onCancel: () => void;
  onBack: () => void;
  onNext: () => void;
  scrollToFirstError: () => void;
  isApplyPhase: boolean;
  navigateToTab: (tab: UserProfileTabsValue) => void;
  showConfirmDialog: boolean;
  setShowConfirmDialog: Dispatch<boolean>;
}

export const EditUserProfileForm = ({
  form,
  onCancel,
  activeTab,
  onBack,
  onNext,
  scrollToFirstError,
  isApplyPhase,
  navigateToTab,
  showConfirmDialog,
  setShowConfirmDialog,
}: IEditUserProfileFormProps) => {
  const [showingError, setShowingError] = useState<boolean>(false);

  const saveAndSubmit = (submit = false) => {
    if (form.values.isSubmittingForVetting != submit) {
      form.setFieldValue(
        nameof<IUser>(s => s.isSubmittingForVetting),
        submit,
        false,
      );
    }
    if (form.values.isDraftSave) {
      form.setFieldValue(
        nameof<IUser>(s => s.isDraftSave),
        false,
      );
    }
    form.submitForm();
  };

  const saveAsDraft = async () => {
    form.setFieldValue(
      nameof<IUser>(s => s.isDraftSave),
      true,
    );
    if (form.values.isSubmittingForVetting) {
      form.setFieldValue(
        nameof<IUser>(s => s.isSubmittingForVetting),
        false,
      );
    }
    await form.validateForm({ ...form.values, isDraftSave: true });
    form.submitForm();
  };

  useEffect(() => {
    if (showingError && !form.isValid) {
      scrollToFirstError();
      setShowingError(false);
    }
  }, [showingError, form.isValid]);

  useEffect((): void => {
    setShowConfirmDialog(false);
    if (form.isSubmitting && !form.isValid) {
      const newTab = Math.min(...Object.keys(form.errors).map(key => fieldToTab[key]));
      if (newTab !== undefined) {
        setShowingError(true);
        if (activeTab !== newTab) {
          navigateToTab(newTab);
        } else {
          scrollToFirstError();
          setShowingError(false);
        }
      }
    }
  }, [form.isSubmitting]);

  useCustomEventListener(MessagePublisherEventType[MessagePublisherEventType.VideoProcessed], (id: string) => {
    const files = form.values.portfolioFiles;
    const fileIndex = files.findIndex(file => file.id === id);
    if (fileIndex > -1) {
      files[fileIndex].isProcessed = true;
      files[fileIndex].extension = '.mp4';
      form.setFieldValue('portfolioFiles', [...files]);
    }
  });

  useDebouncedValidate({
    validate: values => {
      form.validateForm(values);
    },
    debounceTime: 200,
    values: form.values,
  });

  return (
    <form onSubmit={form.handleSubmit}>
      {UserProfileTabsValue.Profile === activeTab && <EditUserHeadSection form={form} isApplyPhase={isApplyPhase} />}

      {UserProfileTabsValue.Portfolio === activeTab && <EditPortfolioSection form={form} />}

      {UserProfileTabsValue.SellService === activeTab && <EditSellServicesSection form={form} />}

      <ProfileFormButtons
        form={form}
        isApplyPhase={isApplyPhase}
        activeTab={activeTab}
        onBack={onBack}
        onCancel={onCancel}
        onNext={onNext}
        saveAndSubmit={saveAndSubmit}
        saveAsDraft={saveAsDraft}
      />
      <ConfirmSubmitApprovalDialog
        onClose={() => setShowConfirmDialog(false)}
        open={showConfirmDialog}
        handleConfirm={() => saveAndSubmit(true)}
      />
    </form>
  );
};
