import { AuthContext } from 'contexts/AuthContext';
import { adminParam } from 'global/constants';
import { useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

export function isAdminEdit(): boolean {
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const authContext = useContext(AuthContext);

  return searchParams.has(adminParam) && id !== authContext.user?.id;
}
