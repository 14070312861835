import React, { Dispatch, createContext, useCallback, useEffect, useState } from 'react';

export interface IGlobalState {
  isAway: boolean;
  titlePrefix: string;
  updateTitlePrefix: Dispatch<string>;
  scrollY: number;
  updateScrollY: Dispatch<number>;
  showGlobalNav: boolean;
  updateShowGlobalNav: Dispatch<boolean>;
}
// eslint-disable-next-line @typescript-eslint/no-empty-function
const defaultValue: IGlobalState = {
  isAway: false,
  titlePrefix: '',
  updateTitlePrefix: () => null,
  scrollY: 0,
  updateScrollY: () => null,
  showGlobalNav: true,
  updateShowGlobalNav: () => null,
};
export const GlobalStateContext = createContext<IGlobalState>(defaultValue);

interface IGlobalStateProviderProps {
  children: React.ReactNode;
}

export const GlobalStateProvider = ({ children }: IGlobalStateProviderProps) => {
  const [state, setState] = useState<IGlobalState>(defaultValue);

  const updateTitlePrefix = useCallback((newPrefix: string) => {
    setState(prev => ({ ...prev, titlePrefix: newPrefix }));
  }, []);

  const updateScrollY = useCallback((newPosition: number) => {
    setState(prev => ({ ...prev, scrollY: newPosition }));
  }, []);

  const updateShowGlobalNav = useCallback((newShow: boolean) => {
    setState(prev => ({ ...prev, showGlobalNav: newShow }));
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setState(prev => ({ ...prev, isAway: document.hidden }));
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <GlobalStateContext.Provider value={{ ...state, updateTitlePrefix, updateScrollY, updateShowGlobalNav }}>
      {children}
    </GlobalStateContext.Provider>
  );
};
