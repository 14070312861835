import { IPagingFilter } from './ordering';

export interface INewOpportunityForm {
  name: string;
  email: string;
  title: string;
  budget?: string;
  description: string;
  token?: string | null;
  userId?: string;
  isExternal: boolean;
  applyInstructions?: string;
  categories: string[];
  locationType?: OpportunityLocationType;
  location?: string;
  isFree?: boolean;
  isAssisted?: boolean;
  assistedEmail?: string;
}

export enum OpportunityType {
  Shoutt = 'Shoutt',
  External = 'External',
}

export enum OpportunityLocationType {
  Remote = 'Remote',
  Local = 'Local',
}

export interface IApplicant {
  userId: string;
  name: string;
  title: string;
  message: string;
}

export interface IOpportunity {
  id: string;
  name: string;
  title: string;
  budget?: string;
  description: string;
  userId?: string;
  type: OpportunityType;
  createdDate?: string;
  agoDesc?: string;
  categories: string[];
  locationType?: OpportunityLocationType;
  location?: string;
  applied: boolean;
  status: OpportunityStatus;
  email?: string;
  applyInstructions?: string;
  applyInstructionsType: ApplyInstructionsType;
  isFree: boolean;
  applicants?: IApplicant[];
  isAssisted: boolean;
  applicantCount?: number;
  assistedEmail?: string;
  tokenViews?: number;
  lastTokenViewDate?: string;
  accessToken?: string;
}

export interface IApplyShouttOpp {
  opportunityId: string;
  message: string;
}

export enum ApplyInstructionsType {
  Url = 'Url',
  Text = 'Text',
}

export interface IOppApplyExternalReponse {
  id: string;
  applyInstructions?: string;
  type: ApplyInstructionsType;
}

export enum OpportunityStatus {
  Approved = 'Approved',
  UnderReview = 'UnderReview',
  Rejected = 'Rejected',
}

export interface IOpportunityFilters extends IPagingFilter {
  status?: OpportunityStatus;
  type?: OpportunityType | 'All';
  isAssisted: boolean;
}
