import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import StarIcon from '@mui/icons-material/Star';
import { amber } from '@mui/material/colors';

interface IUserInfoProps {
  title: string;
  description: string | number | JSX.Element;
}

export default function UserInfo({ title, description }: IUserInfoProps) {
  return (
    <Stack gap={0.5}>
      <Typography variant="caption" color="grey.500">
        {title}
      </Typography>
      <Stack direction="row" gap={1} width="100%">
        <Typography variant="subtitle2">{description}</Typography>
      </Stack>
    </Stack>
  );
}

export function RatingInfo({ rating }: { rating: number }) {
  return (
    <Stack direction="row" width="100%" alignItems="center">
      <StarIcon sx={{ fill: amber[600], marginLeft: '-3px', marginRight: '2px' }} />
      {rating}
    </Stack>
  );
}
