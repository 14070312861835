import { styled } from '@mui/material/styles';
import { Box, CircularProgress } from '@mui/material';

const LoaderContainer = styled(Box)(() => ({
  minHeight: '50px',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export default function PdfLoader({ height }: { height?: number }) {
  return (
    <LoaderContainer sx={{ height: height }}>
      <CircularProgress color="primary" size={48} />
    </LoaderContainer>
  );
}
