import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { logout, refreshToken } from './authService';

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken != null && config.headers != null) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  return config;
};

const onRequestError = async (error: AxiosError): Promise<never> => await Promise.reject(error);

const onResponse = (response: AxiosResponse): AxiosResponse => response;

const onResponseError = async (originalError: any): Promise<unknown> => {
  const originalRequest = originalError.config;
  if (originalError.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    //access token expired
    try {
      await refreshToken();
    } catch (rtError) {
      if ((rtError as AxiosError).response?.status == 401) {
        //refresh token expired
        logout();
        return await Promise.reject(originalError);
      }
      return await Promise.reject(rtError);
    }

    //token refreshed, retry original request
    return await authApi(originalError.config);
  }
  return await Promise.reject(originalError);
};

const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};

const authApi: AxiosInstance = setupInterceptorsTo(
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  }),
);

export default authApi;
