import { AxiosError } from 'axios';
import IApiError, { IErrorResponse } from 'global/interfaces/api';
import { enqueueSnackbar } from 'notistack';

export function showError(error: IApiError): void {
  if (error.status == 400) {
    showErrorMessage(error.message);
  } else if (error.status == 401 || error.status == 403) {
    showErrorMessage('Unauthorized. Please login.');
  } else {
    if (error.traceId) {
      showErrorMessage(
        `Unexpected error. Please contact support@shoutt.co and provide error no.:\n${error.traceId}`,
        false,
      );
    } else {
      showErrorMessage('Unexpected error. Please contact support@shoutt.co.');
    }
  }
}

export function showErrorMessage(message: string, autoHide = true): void {
  enqueueSnackbar(message, {
    variant: 'error',
    persist: !autoHide,
    style: { whiteSpace: 'pre-wrap' },
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isErrorResponse = (obj: any): obj is IErrorResponse => 'traceId' in obj && 'title' in obj;

export function handleAxiosError(err: AxiosError): IApiError {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(err);
  }

  //No response body
  if (!err.response) {
    return { status: 500, message: 'Unexpected error.' };
  }

  let traceId: string | undefined;
  let message = '';
  if (typeof err.response.data === 'string') {
    message = err.response.data;
  } else if (isErrorResponse(err.response.data)) {
    message = err.response.data.title;
    const traceIdParts = err.response.data.traceId.split('-');
    traceId = traceIdParts.length == 4 ? traceIdParts[1] : err.response.data.traceId;
  }

  return { status: err.response.status, message, traceId };
}
