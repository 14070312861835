import { useEffect } from 'react';

export const maxMediaHeight = 350;

export default function useCalculatedHeight(htmlElement: HTMLElement | null, loaded = false) {
  useEffect(() => {
    if (!loaded && htmlElement !== null) {
      const listContainer = htmlElement.closest('.MuiImageListItem-root');
      const height = Math.min(listContainer?.clientWidth ?? maxMediaHeight, maxMediaHeight);
      htmlElement.style.height = `${height}px`;
    }
  }, [htmlElement]);
}
