import { Avatar, FormHelperText, Grid, Stack, styled, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import FileUploadButton from 'components/common/Button/FileUploadButton';
import { JustifiedBox } from 'components/common/StyledWorkroom/WorkroomStyles';

import { useState } from 'react';

interface IBrandingImageUploaderProps {
  url?: string;
  handleFileChange: (file: File) => void;
}

const StyledJustifiedBox = styled(JustifiedBox)(({ theme }) => ({
  width: '100%',
  alignItems: 'flex-end',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
}));

const StyledLogoAvatar = styled(Avatar)(({ theme }) => ({
  mt: 1,
  width: '128px',
  height: '128px',
  borderRadius: '8px',

  [theme.breakpoints.down('sm')]: {
    margin: 'auto',
  },
  img: {
    objectFit: 'contain',
  },
}));

const supportedFileTypes = {
  '.jpg': 'image/jpeg',
  '.jpeg': 'image/jpeg',
  '.png': 'image/png',
};

export function BrandingImageUploader({ url, handleFileChange: handleFileChange }: IBrandingImageUploaderProps) {
  const [fileTypeError, setTypeError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleImageInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file: File | null = event.target.files !== null ? event.target.files[0] : null;
    if (file !== null) {
      setFileSizeError(false);
      setTypeError(false);
      const maxFileSize = 10 * 1024 * 1024;
      if (file.size > maxFileSize) {
        setFileSizeError(true);
        return;
      }

      if (
        !Object.keys(supportedFileTypes)
          .map(k => supportedFileTypes[k as keyof typeof supportedFileTypes])
          .includes(file.type)
      ) {
        setTypeError(true);
        return;
      }

      handleFileChange(file);
    }
  };

  return (
    <StyledJustifiedBox rowGap={'24px'} columnGap={'24px'} mt={2}>
      <Stack gap={3} alignSelf="flex-start" width={isMobileScreen ? '100%' : 'auto'}>
        <Stack width={isMobileScreen ? '100%' : 'auto'}>
          <StyledLogoAvatar src={url} />
        </Stack>
      </Stack>

      <Grid item width={'100%'}>
        <FileUploadButton
          accept={Object.keys(supportedFileTypes).join(', ')}
          description="PNG, JPEG"
          onImageChange={handleImageInputChange}
        />
        {fileSizeError && <FormHelperText error>File size must be no more than 10MB</FormHelperText>}
        {fileTypeError && (
          <FormHelperText error>
            File must be one of{' '}
            {Object.keys(supportedFileTypes).map(
              (k: string, i: number) => `${k}${i !== Object.keys(supportedFileTypes).length - 1 ? ', ' : ''}`,
            )}
          </FormHelperText>
        )}
      </Grid>
    </StyledJustifiedBox>
  );
}
