import { styled } from '@mui/material';

const StyledClearBitLink = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  fontSize: '12pt',
  color: theme.palette.grey[500],
}));

export function ClearBitLink() {
  return <StyledClearBitLink href="https://clearbit.com/">Logos provided by Clearbit</StyledClearBitLink>;
}
