import { CircularProgress, Paper } from '@mui/material';
import { useRef } from 'react';
import useCalculatedHeight from './useCalculatedHeight';

export default function ChatMessageMediaLoader(): JSX.Element {
  const imageRef = useRef<HTMLImageElement>(null);
  useCalculatedHeight(imageRef.current);

  return (
    <Paper
      variant="outlined"
      ref={imageRef}
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Paper>
  );
}
