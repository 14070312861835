import { SvgIcon, SvgIconProps } from '@mui/material';

export const ReviewContentIcon = (props: SvgIconProps) => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M9.58334 1.66667H12.6667C14.0668 1.66667 14.7669 1.66667 15.3016 1.93916C15.7721 2.17884 16.1545 2.56129 16.3942 3.0317C16.6667 3.56647 16.6667 4.26654 16.6667 5.66667V14.3333C16.6667 15.7335 16.6667 16.4335 16.3942 16.9683C16.1545 17.4387 15.7721 17.8212 15.3016 18.0609C14.7669 18.3333 14.0668 18.3333 12.6667 18.3333H7.33334C5.9332 18.3333 5.23314 18.3333 4.69836 18.0609C4.22795 17.8212 3.8455 17.4387 3.60582 16.9683C3.33334 16.4335 3.33334 15.7335 3.33334 14.3333V7.50001M13.3333 10.8333H8M13.3333 7.50001H9.58334M13.3333 14.1667H6.66667"
      stroke={props.style?.color ?? 'white'}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M1.09424 3.75471C1.0456 3.67769 1.02128 3.63919 1.00767 3.57979C0.997444 3.53518 0.997444 3.46482 1.00767 3.42021C1.02128 3.36081 1.0456 3.32231 1.09424 3.24529C1.49617 2.60887 2.69255 1 4.51577 1C6.33898 1 7.53537 2.60887 7.9373 3.24529C7.98594 3.32231 8.01026 3.36081 8.02387 3.42021C8.03409 3.46482 8.03409 3.53518 8.02387 3.57979C8.01026 3.63919 7.98594 3.67769 7.9373 3.75471C7.53537 4.39113 6.33898 6 4.51577 6C2.69255 6 1.49617 4.39113 1.09424 3.75471Z"
      stroke={props.style?.color ?? 'white'}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M4.51577 4.57143C5.1075 4.57143 5.5872 4.09173 5.5872 3.5C5.5872 2.90827 5.1075 2.42857 4.51577 2.42857C3.92404 2.42857 3.44434 2.90827 3.44434 3.5C3.44434 4.09173 3.92404 4.57143 4.51577 4.57143Z"
      stroke={props.style?.color ?? 'white'}
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
