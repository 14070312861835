import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useContext } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { removeRecipient } from 'services/chatThreadService';
import { showError } from 'utils/errorHandler';
import { IChatUserThread } from 'global/interfaces/chatThread';
import CloseIcon from '@mui/icons-material/Close';
import { ConfirmDialog } from './confirmDialog';
import { UserAvatar } from 'components/common/UserAvatar';
import { AuthContext, UserAuth } from 'contexts/AuthContext';
import { StyledDialogTitle, WrappedTypography } from 'components/common/Dialog';
import { AddMemberDialog } from './AddMemberDialog';
import { UserPlusIconSecondary } from 'components/icon/UserPlusSecondaryIcon';
import { FlexBox } from './chatMessage';
import { UsersIcon } from 'components/icon/UsersIcon';

interface ITeamMemberProps {
  addMemberDialogOpen: boolean;
  setAddMemberDialogOpen: React.Dispatch<boolean>;
  setSelectedThread: React.Dispatch<React.SetStateAction<IChatUserThread | undefined>>;
  user: UserAuth | null;
  selectedThread: IChatUserThread | undefined;
}

const StyledTypography = styled(Typography)(() => ({
  marginLeft: '5px',
}));

const JustifiedBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledListItemAvatar = styled(ListItemAvatar)(() => ({
  minWidth: '40px',
  marginRight: '0px',
}));

const StyledAvatar = styled(UserAvatar)(() => ({
  width: '40px',
  height: '40px',
  marginRight: '8px',
}));

const EllipsisWrapperDiv = styled('div')`
  display: table;
  table-layout: fixed;
  width: 100%;
  white-space: nowrap;
  padding-right: 4px;
`;

const StyledAddMemberButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.dark,
  marginLeft: '16px',

  '& .MuiButton-startIcon': {
    marginRight: '22px',
  },

  [theme.breakpoints.down('md')]: {
    marginLeft: 'unset',
  },
}));

const StyledDialogContent = styled(DialogContent)(() => ({
  padding: '16px !important',
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '24px !important',
}));

const StyledMembersButton = styled(FlexBox)(({ theme }) => ({
  cursor: 'pointer',
  paddingLeft: '4px',
  paddingRight: '4px',
  paddingTop: '2px',
  paddingBottom: '2px',
  marginLeft: '8px',
  backgroundColor: theme.palette.grey[100],
  border: `1px solid ${theme.palette.grey[200]}`,
  color: theme.palette.grey[500],
  borderRadius: '2px',
  transition: 'background-color 0.3s, border-color 0.3s',
  justifyContent: 'space-evenly',

  '&:hover': {
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.grey[200],
  },
}));

const StyledMembersButtonMultiple = styled(FlexBox)(({ theme }) => ({
  cursor: 'pointer',
  paddingTop: '2px',
  paddingBottom: '2px',
  marginLeft: '8px',
  backgroundColor: theme.palette.grey[100],
  border: `1px solid ${theme.palette.grey[200]}`,
  color: theme.palette.grey[500],
  borderRadius: '2px',
  transition: 'background-color 0.3s, border-color 0.3s',
  justifyContent: 'space-evenly',

  '&:hover': {
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.grey[200],
  },
}));

export const TeamMembers: React.FC<ITeamMemberProps> = ({ selectedThread, user, setSelectedThread }) => {
  const theme = useTheme();
  const [confirmRemoveRecipient, setConfirmRemoveRecipient] = React.useState(false);
  const [selectedRecipientId, setSelectedRecipient] = React.useState('');
  const [selectedTeamThreadId, setSelectedTeamThreadId] = React.useState('');
  const [openTeamDialog, setOpenTeamDialog] = React.useState(false);
  const [addMemberDialogOpen, setAddMemberDialogOpen] = React.useState(false);
  const [isRemovingRecipient, setIsRemovingRecipient] = React.useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const authContext = useContext(AuthContext);

  const removeRecipientClick = (chatThreadId: string, recipientUserId: string) => {
    setIsRemovingRecipient(true);

    removeRecipient({ recipientUserId, chatThreadId })
      .then(() => {
        setSelectedThread((prev: IChatUserThread | undefined) => {
          if (prev !== undefined) {
            return {
              ...prev,
              recipients: prev.recipients.filter(recipient => recipient.userId !== recipientUserId),
            };
          }

          return prev;
        });
      })
      .catch(showError)
      .finally(() => {
        setIsRemovingRecipient(false);
        setConfirmRemoveRecipient(false);
      });
  };

  const activeRecipients = selectedThread?.recipients.filter(recipient => recipient.active) ?? [];

  return (
    <Grid container item xs={12}>
      {selectedThread?.createdByUserId === authContext.user?.id && selectedThread !== undefined ? (
        <StyledMembersButtonMultiple width="80px">
          <FlexBox gap="4px" marginLeft="-4px" marginRight="-4px" onClick={() => setOpenTeamDialog(true)}>
            <UsersIcon />
            <Typography color={theme.palette.grey[500]} variant="subtitle2">
              {activeRecipients.length}
            </Typography>
          </FlexBox>
          <Divider orientation="vertical" flexItem />
          <FlexBox onClick={() => setAddMemberDialogOpen(true)}>
            <UserPlusIconSecondary />
          </FlexBox>
        </StyledMembersButtonMultiple>
      ) : (
        <StyledMembersButton width="50px" gap="8px" onClick={() => setOpenTeamDialog(true)}>
          <UsersIcon />
          <Typography color={theme.palette.grey[500]} variant="subtitle2">
            {activeRecipients.length}
          </Typography>
        </StyledMembersButton>
      )}

      <Dialog open={openTeamDialog} fullWidth fullScreen={isMobile}>
        <StyledDialogTitle>
          <WrappedTypography variant="h6">{`Team #${selectedThread?.displayName}`}</WrappedTypography>

          <IconButton onClick={() => setOpenTeamDialog(false)}>
            <CloseIcon />
          </IconButton>
        </StyledDialogTitle>

        <StyledDialogContent dividers>
          <List>
            {selectedThread?.recipients.map(recipient => (
              <section key={recipient.userId}>
                {recipient.active && (
                  <ListItem
                    key={recipient.userId}
                    disablePadding
                    sx={{
                      '&:hover .hidden-close': {
                        display: 'flex',
                      },
                    }}
                  >
                    <ListItemButton disableRipple disableGutters={isMobile}>
                      <StyledListItemAvatar>
                        <StyledAvatar userId={recipient.userId} displayName={recipient.displayName} />
                      </StyledListItemAvatar>

                      <ListItemText
                        primary={
                          <JustifiedBox>
                            <EllipsisWrapperDiv>
                              <StyledTypography variant="body1">
                                {user?.id === recipient.userId ? 'You' : recipient.displayName}
                              </StyledTypography>
                            </EllipsisWrapperDiv>
                          </JustifiedBox>
                        }
                      />

                      {user?.id !== recipient.userId &&
                        activeRecipients?.length > 1 &&
                        selectedThread?.createdByUserId === authContext.user?.id && (
                          <IconButton
                            className="hidden-close"
                            sx={{ display: isMobile ? undefined : 'none' }}
                            onClick={() => {
                              setSelectedRecipient(recipient.userId);
                              setSelectedTeamThreadId(selectedThread.chatThreadId);
                              setConfirmRemoveRecipient(true);
                            }}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        )}
                    </ListItemButton>
                  </ListItem>
                )}

                {selectedThread?.createdByUserId === authContext.user?.id && (
                  <ConfirmDialog
                    isConfirming={isRemovingRecipient}
                    open={confirmRemoveRecipient}
                    setOpen={setConfirmRemoveRecipient}
                    onConfirm={() => {
                      removeRecipientClick(selectedTeamThreadId, selectedRecipientId);
                    }}
                    title="Remove from chat"
                    content="Are you sure you want to remove this member?"
                    onCancel={() => setConfirmRemoveRecipient(false)}
                  />
                )}
              </section>
            ))}
          </List>

          {selectedThread?.createdByUserId === authContext.user?.id && (
            <Box>
              <StyledAddMemberButton
                variant="text"
                startIcon={<AddIcon style={{ color: theme.palette.primary.dark }} />}
                onClick={() => setAddMemberDialogOpen(true)}
              >
                <Typography variant="body1" color="primary.dark">
                  Add member
                </Typography>
              </StyledAddMemberButton>
            </Box>
          )}
        </StyledDialogContent>
      </Dialog>

      <AddMemberDialog open={addMemberDialogOpen} setOpen={setAddMemberDialogOpen} selectedThread={selectedThread} />
    </Grid>
  );
};
