import { Box, Chip, Fab, Grid, Stack, styled, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { IFrontUser } from 'global/interfaces/user';
import InfoIcon from '@mui/icons-material/Info';
import { UserAvatar } from 'components/common/UserAvatar';
import EditIcon from '@mui/icons-material/Edit';
import { AuthContext } from 'contexts/AuthContext';
import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import UserInfo, { RatingInfo } from './UserInfo';
import { VerifiedTickIcon } from 'components/icon/VerifiedTickIcon';
import { countries } from 'components/common/Select/CountrySelect';
import ShareButton from './ShareButton';
import { VettingStatus } from 'global/enums/vettingStatus';
import { useLocalization } from 'global/hooks/useLocalization';
import { RoundButton } from 'components/common/Button/RoundButton';
import { adminParam } from 'global/constants';
import { isAdminEdit } from 'utils/adminUtil';
import { UserRole } from 'global/enums/userRole';

const StyledChip = styled(Chip)(({ theme }) => ({
  height: '28px',

  backgroundColor: theme.palette.black[200],
  borderRadius: '16px',
  color: theme.palette.black[500],
  cursor: 'pointer',
  padding: '4px 12px',
}));

const StyledHeaderGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: '0 32px 24px 0',
  position: 'relative',

  [theme.breakpoints.down('md')]: {
    backgroundColor: theme.palette.grey[50],
    paddingBlock: '24px',
    paddingLeft: '16px',
  },
}));

const StyledTypography = styled(Typography)`
  word-break: 'break-all';
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const StyledAvatar = styled(UserAvatar)(({ theme }) => ({
  width: '148px',
  height: '148px',
  borderRadius: '8px',

  [theme.breakpoints.down('sm')]: {
    width: '96px',
    height: '96px',
  },
}));

const AvatarBox = styled(Box)(({ theme }) => ({
  width: '148px',
  height: '148px',
  borderRadius: '8px',
  position: 'relative',

  [theme.breakpoints.down('sm')]: {
    width: '96px',
    height: '96px',
  },
}));

const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
`;

export const PaddedBox = styled(FlexBox)(({ theme }) => ({
  marginTop: '16px',
  paddingRight: '24px',

  [theme.breakpoints.down('md')]: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}));

const InfoContainer = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
  borderRadius: '8px',
  border: `1px solid ${theme.palette.grey[200]}`,
  minWidth: 120,
  backgroundColor: theme.palette.grey[50],
}));

const InfoHeadingText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
  marginBottom: '4px',
}));

const InfoDescriptionText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
  fontWeight: 600,
}));

const StyledFab = styled(Fab)(({ theme }) => ({
  position: 'absolute',
  height: '40px',
  width: '40px',
  top: '0',
  right: '8px',

  [theme.breakpoints.down('md')]: {
    top: '24px',
  },
}));

const StyledRoundButton = styled(RoundButton)(() => ({
  position: 'absolute',

  top: '0',
  right: '8px',
}));

const StyledVerifiedTickIcon = styled(VerifiedTickIcon)`
  position: absolute;
  top: -5px;
  right: -5px;
`;

const StyledInfoIcon = styled(InfoIcon)`
  font-size: inherit;
  margin-left: 4px;
`;

const StyledStack = styled(Stack)`
  overflow: hidden;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
`;

interface IUserProfileHeadSectionProps {
  user: IFrontUser;
}

export default function UserProfileHeadSection({ user }: IUserProfileHeadSectionProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const authContext = useContext(AuthContext);
  const isAdminView = isAdminEdit();
  const country = countries.find(c => c.code === user.country)?.label;
  const { localCurrencySymbol, rateInCurrency } = useLocalization(user.contractStats.totalValue);
  const navigate = useNavigate();

  const totalValue = rateInCurrency ? (rateInCurrency < 1000 ? '<1' : Math.round(rateInCurrency / 1000)) : undefined;

  const hasAdminRole = authContext.user && authContext.user.roles.indexOf(UserRole.Administrator) > -1;
  const baseUrl = hasAdminRole ? process.env.REACT_APP_ADMIN_SHARE_USER_URL : process.env.REACT_APP_SHARE_USER_URL;
  let shareUrl = `${baseUrl}?userId=${user.id}`;

  if (!hasAdminRole && authContext.user?.id) {
    shareUrl = shareUrl + `&source=${authContext.user.id}`;
  } else if (!hasAdminRole) {
    shareUrl = shareUrl + `&source=nou`;
  }

  const renderUserInfo = (
    <>
      <UserInfo title="Location" description={country ?? ''} />

      <UserInfo
        title="Total Value"
        description={
          <FlexBox>
            {user.contractStats.contractsCount < 1
              ? 'New'
              : `${totalValue ? `${localCurrencySymbol}${totalValue}K` : '-'}`}
            <Tooltip title="Includes all purchases and sales">
              <StyledInfoIcon />
            </Tooltip>
          </FlexBox>
        }
      />
      <UserInfo
        title="Total Jobs"
        description={user.contractStats.contractsCount < 1 ? 'New' : user.contractStats.contractsCount}
      />
      <UserInfo
        title="Rating"
        description={user.contractStats.reviewRate < 1 ? 'New' : <RatingInfo rating={user.contractStats.reviewRate} />}
      />
    </>
  );

  return (
    <>
      <StyledHeaderGrid container rowGap={2}>
        <Grid item width="100%">
          <Stack direction="row" spacing={3} width="100%">
            <AvatarBox>
              <StyledAvatar userId={user.id} displayName={`${user.firstName} ${user.lastName}`} variant="square" />
              {user.vettingStatus == VettingStatus.Approved && user.isSellingServices && <StyledVerifiedTickIcon />}
            </AvatarBox>
            <StyledStack gap={1}>
              <Grid container>
                <Grid container item xs={12}>
                  <StyledTypography variant={isMobile ? 'h6' : 'h5'} textTransform="capitalize">
                    {user.firstName} {user?.lastName}
                  </StyledTypography>
                  <ShareButton userId={user.id} url={shareUrl} />
                </Grid>
                <Grid item xs={12}>
                  <StyledTypography variant="subtitle2" color="grey.500" minHeight={'17px'}>
                    {user.title}
                  </StyledTypography>
                </Grid>
              </Grid>

              <Stack direction="row" alignItems="center" mt="4px">
                {user.isSellingServices && user.mainCategory != null && user.mainCategory != '' ? (
                  <StyledChip label={user.mainCategory} size="small" />
                ) : (
                  <Box minHeight={'28px'} />
                )}
              </Stack>

              {!isMobile && (
                <Stack direction="row" alignItems="center" mt={1} rowGap={2} columnGap={5} flexWrap="wrap">
                  {renderUserInfo}
                </Stack>
              )}
            </StyledStack>
          </Stack>
        </Grid>

        {isMobile && (
          <Grid display="grid" container gap={1} gridTemplateColumns={'repeat(2, 1fr)'} mt="16px">
            <InfoContainer>
              <InfoHeadingText variant="caption">Location</InfoHeadingText>
              <InfoDescriptionText variant="subtitle1">{country ?? ''}</InfoDescriptionText>
            </InfoContainer>
            <InfoContainer>
              <InfoHeadingText variant="caption">Rating</InfoHeadingText>
              <InfoDescriptionText variant="subtitle1">
                {user.contractStats.reviewRate < 1 ? 'New' : <RatingInfo rating={user.contractStats.reviewRate} />}
              </InfoDescriptionText>
            </InfoContainer>
            <InfoContainer>
              <InfoHeadingText variant="caption">Total Jobs</InfoHeadingText>
              <InfoDescriptionText variant="subtitle1">
                {user.contractStats.contractsCount < 1 ? 'New' : user.contractStats.contractsCount}
              </InfoDescriptionText>
            </InfoContainer>
            <InfoContainer>
              <InfoHeadingText variant="caption">Total Value</InfoHeadingText>
              <InfoDescriptionText variant="subtitle1">
                <FlexBox>
                  {user.contractStats.contractsCount < 1
                    ? 'New'
                    : `${totalValue ? `${localCurrencySymbol}${totalValue}K` : '-'}`}
                  <Tooltip title="Includes all purchases and sales" enterTouchDelay={0}>
                    <StyledInfoIcon />
                  </Tooltip>
                </FlexBox>
              </InfoDescriptionText>
            </InfoContainer>
          </Grid>
        )}

        {(user.id == authContext.user?.id || isAdminView) && isMobile && (
          <Link to={!isAdminView ? `/users/${user.id}/edit` : `/admin/users/${user.id}/edit?${adminParam}`}>
            <StyledFab color="primary" aria-label="edit">
              <EditIcon />
            </StyledFab>
          </Link>
        )}
        {(user.id == authContext.user?.id || isAdminView) && !isMobile && (
          <StyledRoundButton
            variant="contained"
            onClick={() =>
              navigate(!isAdminView ? `/users/${user.id}/edit` : `/admin/users/${user.id}/edit?${adminParam}`)
            }
          >
            Edit Profile
          </StyledRoundButton>
        )}
      </StyledHeaderGrid>
    </>
  );
}
