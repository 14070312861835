import { Typography, styled } from '@mui/material';

const EllipsisWrapperDiv = styled('div')`
  display: table;
  table-layout: fixed;
  width: 100%;
  white-space: nowrap;
`;

export const EllipsisTypography = styled((props: React.ComponentProps<typeof Typography>) => (
  <EllipsisWrapperDiv>
    <Typography {...props} />
  </EllipsisWrapperDiv>
))(() => ({
  display: 'table-cell',
}));

EllipsisTypography.defaultProps = {
  noWrap: true,
};
