import { Box, Typography, styled } from '@mui/material';
import icon from 'assets/images/icons/video-processing.png';

const StyledBox = styled(Box)(() => ({
  backgroundImage: `url(${icon})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '12px',
}));

const TextContainer = styled(Box)(() => ({
  padding: '12px',
  backgroundColor: 'rgba(0,0,0,0.5)',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '12px',
  textAlign: 'center',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
}));

interface IVideoProgressProps {
  fullWidth?: boolean;
  message: string;
}

export default function MediaProgress({ fullWidth, message }: IVideoProgressProps): JSX.Element {
  return (
    <StyledBox sx={{ width: fullWidth ? '100%' : '256px', height: fullWidth ? '100%' : '256px' }}>
      <TextContainer>
        <StyledTypography variant="body2">{message}</StyledTypography>
      </TextContainer>
    </StyledBox>
  );
}

export const audioText = 'Audio processing. Please save and your audio will be automatically available later.';
export const videoText = 'Video processing. Please save and your video will be automatically available later.';
