import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import { useCustomEventListener } from 'react-custom-events';
import { ConnectionRestarted, MessagePublisherEventType } from 'global/enums/messagePublisherEventType';
import DeleteProfileDialog from 'components/common/DeleteProfileDialog/DeleteProfile';
import { getNumberUnread } from 'services/chatMessageService';
import { showError } from 'utils/errorHandler';
import HeaderBanner from 'HeaderBanner';
import { IChatUnread } from 'global/interfaces/chatMessage';
import { getConnectionsCount } from 'services/connectionService';
import { IConnectionCount } from 'global/interfaces/connection';
import { logout } from 'services/authService';
import { useContext, useEffect, useState } from 'react';
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import { FaviconType, changeFaviconType, getFaviconType } from 'utils/favicon';
import MainAppDrawer from 'components/persistentDrawer/mainAppDrawer';

const Header = () => {
  const authContext = useContext(AuthContext);
  const [isDeleteProfileVisible, setIsDeleteProfileVisible] = useState<boolean>(false);
  const [numberOfUnreadMessages, setNumberOfUnreadMessages] = useState<number>(0);
  const [numberOfConnections, setNumberOfConnections] = useState<number>(0);
  const navigate = useNavigate();

  const { isAway, updateTitlePrefix, showGlobalNav } = useContext(GlobalStateContext);

  useEffect(() => {
    const faviconType = getFaviconType();
    if (numberOfUnreadMessages > 0) {
      if (faviconType !== FaviconType.Notification) {
        changeFaviconType(FaviconType.Notification);
      }
      updateTitlePrefix(`(${numberOfUnreadMessages})`);
    } else if (numberOfUnreadMessages == 0) {
      if (faviconType !== FaviconType.Main) {
        changeFaviconType(FaviconType.Main);
      }
      updateTitlePrefix('');
    }
  }, [numberOfUnreadMessages, isAway]);

  const handleLogoutClick = (): void => {
    logout();
    authContext.refresh().finally(() => {
      navigate('/');
    });
  };

  const handleCloseDeleteProfile = (): void => {
    setIsDeleteProfileVisible(false);
  };

  const loadNumberUnread = (): void => {
    if (authContext.user) {
      getNumberUnread()
        .then((res: IChatUnread) => {
          setNumberOfUnreadMessages(res.numberUnread);
        })
        .catch(showError);
    }
  };

  const loadNumberConnections = (): void => {
    if (authContext.user) {
      getConnectionsCount()
        .then((res: IConnectionCount) => {
          setNumberOfConnections(res.count);
        })
        .catch(showError);
    }
  };

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.NewMessage],
    () => {
      loadNumberUnread();
      if (numberOfConnections < 1) {
        loadNumberConnections();
      }
    },
    [authContext],
  );

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.ReadMessages],
    () => {
      loadNumberUnread();
    },
    [authContext],
  );

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.UpdateThread],
    () => {
      loadNumberUnread();
    },
    [authContext],
  );

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.SubscriptionEnded],
    () => {
      authContext.refresh();
    },
    [authContext],
  );

  const loadAll = () => {
    if (!authContext.loading) {
      loadNumberUnread();
      loadNumberConnections();
    }
  };

  useEffect(() => {
    loadAll();
  }, [authContext.user, authContext.loading]);

  useCustomEventListener(
    ConnectionRestarted,
    () => {
      loadAll();
    },
    [authContext],
  );

  return (
    <>
      {showGlobalNav && <MainAppDrawer />}
      {process.env.REACT_APP_PRIMING !== 'true' && <HeaderBanner />}
      <DeleteProfileDialog
        open={isDeleteProfileVisible}
        onClose={handleCloseDeleteProfile}
        onLogout={handleLogoutClick}
      />
    </>
  );
};

export default Header;
