import { useMediaQuery, useTheme } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { FormikProps } from 'formik';
import { connectionRequestEvent } from 'global/constants';
import { EmailInviteStep } from 'global/enums/networkEnums';
import IApiError from 'global/interfaces/api';
import { IConnection } from 'global/interfaces/connection';
import { IEmailInvitationForm } from 'global/interfaces/emailInvitation';
import { useNavigate } from 'react-router-dom';
import { getOrCreateConnectionToUser } from 'services/connectionService';
import { showError } from 'utils/errorHandler';
import { pushToDataLayer } from 'utils/tagHelper';

interface EmailInviteFromButtonProps {
  emailInviteStep: EmailInviteStep;
  loading: boolean;
  otherUserId: string | undefined;
  form: FormikProps<IEmailInvitationForm> | undefined;
  handleFormClose: () => void;
  setLoading: (value: boolean) => void;
}
const EmailInviteFromButton: React.FC<EmailInviteFromButtonProps> = ({
  emailInviteStep,
  loading,
  otherUserId,
  form,
  handleFormClose,
  setLoading,
}) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  const handleOpenConnection = () => {
    setLoading(true);
    if (otherUserId) {
      getOrCreateConnectionToUser(otherUserId)
        .then((connection: IConnection) => {
          pushToDataLayer(connectionRequestEvent);
          setLoading(false);
          navigate(`/workroom/${connection.chatThreadId}`);
        })
        .catch((err: IApiError) => {
          showError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    handleFormClose();
  };

  return (
    <>
      {(emailInviteStep === EmailInviteStep.ExistingUser || emailInviteStep === EmailInviteStep.ExistingConnection) && (
        <>
          <RoundButton fullWidth={isMobileScreen} onClick={handleFormClose} variant="outlined">
            No
          </RoundButton>
          <RoundButton fullWidth={isMobileScreen} loading={loading} onClick={handleOpenConnection} variant="contained">
            Yes
          </RoundButton>
        </>
      )}
      {emailInviteStep === EmailInviteStep.InvitationSent && (
        <RoundButton fullWidth={isMobileScreen} onClick={handleFormClose} variant="contained">
          Close
        </RoundButton>
      )}
      {emailInviteStep === EmailInviteStep.Form && (
        <>
          <RoundButton fullWidth={isMobileScreen} onClick={handleFormClose} variant="outlined">
            Cancel
          </RoundButton>
          <RoundButton loading={loading} fullWidth={isMobileScreen} variant="contained" onClick={form?.submitForm}>
            Send
          </RoundButton>
        </>
      )}
    </>
  );
};

export default EmailInviteFromButton;
