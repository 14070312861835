import { IChatMessageDisplay } from 'global/interfaces/chatMessage';
import Sanitize from 'components/common/Sanitize';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';

const StyledTypography = styled(Typography)`
  p {
    min-height: 20px;
  }
  ul {
    list-style-type: disc;
  }
`;

export default function ChatMessageText(props: IChatMessageDisplay): JSX.Element {
  return (
    <StyledTypography variant="body2" color="grey.900">
      <Sanitize html={props.content} />
    </StyledTypography>
  );
}
