import { ThumbnailExtension } from 'global/constants';
import PortfolioImage from './PortfolioImage';
import { IconButton, styled, useMediaQuery, useTheme } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

const Container = styled('div')(() => ({
  position: 'relative',
}));

const IconContainer = styled('div')(() => ({
  position: 'absolute',
  top: '0',
  right: '0',
}));

interface IPortfolioPDFProps {
  extension: string;
  id: string;
  directory: string;
  fileName: string;
  onNameChange?: (id: string, fileName: string) => void;
}

export default function PortfolioPDF(props: IPortfolioPDFProps): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  return (
    <Container>
      <PortfolioImage
        id={props.id}
        directory={props.directory}
        extension={ThumbnailExtension}
        fileName={props.fileName}
      />
      <IconContainer>
        <IconButton>
          <DescriptionOutlinedIcon style={{ fontSize: isMobile ? '20px' : '25px' }} />
        </IconButton>
      </IconContainer>
    </Container>
  );
}
