import { AccordionDetails, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { AuthContext } from 'contexts/AuthContext';
import { formatDistanceStrict } from 'date-fns';
import { IAuditLog, LogChange } from 'global/interfaces/IAuditLog';
import React, { useContext } from 'react';
import { splitCamelCase } from 'utils/stringUtils';

export default function AuditRenderer({ auditLogs }: { auditLogs: IAuditLog[] }) {
  const authContext = useContext(AuthContext);
  return (
    <AccordionDetails>
      {auditLogs.map((log, i) => (
        <React.Fragment key={i}>
          <Stack direction="row" key={i} justifyContent="space-between" mt={i === 0 ? undefined : 4}>
            <Typography variant="subtitle1">
              {authContext.user?.id === log.changedByUserId ? 'You' : log.changedByEmail} changed:
            </Typography>
            <Typography variant="caption" color="grey.500">
              {formatDistanceStrict(new Date(log.createdDate), new Date(), {
                addSuffix: true,
              })}
            </Typography>
          </Stack>
          <Stack direction="column">
            {log.changes.map((change, changeIndex) => {
              try {
                //Needed since JSON.parse parses numbers as valid json
                if (isNumericString(change.oldValue) || isNumericString(change.newValue)) {
                  throw '';
                }

                return <React.Fragment key={changeIndex}>{renderNestedChangeObject(change)}</React.Fragment>;
              } catch (error) {
                return (
                  <Stack key={changeIndex} direction="row" mb={2}>
                    <Typography typography="body2" fontWeight={800}>
                      {splitCamelCase(change.key)}
                    </Typography>
                    <Typography typography="body2">&nbsp; from: {change.oldValue}</Typography>
                    <Typography typography="body2">&nbsp; to: {change.newValue}</Typography>
                  </Stack>
                );
              }
            })}
          </Stack>
        </React.Fragment>
      ))}
    </AccordionDetails>
  );
}

function isNumericString(str: string): boolean {
  return !isNaN(Number(str)) && !isNaN(parseFloat(str));
}

const getFirstProperty = (obj: object): any => Object.values(obj)[0];

function renderNestedChangeObject(change: LogChange): JSX.Element {
  const oldValue: Record<string, any>[] = JSON.parse(change.oldValue);
  const newValue: Record<string, any>[] = JSON.parse(change.newValue);
  const isRemoved: boolean = oldValue.length > newValue.length;

  const actionText: 'removed' | 'added' = isRemoved ? 'removed' : 'added';

  const newChange: Record<string, any>[] = isRemoved
    ? oldValue.filter(oldItem => !newValue.some(newItem => getFirstProperty(newItem) === getFirstProperty(oldItem)))
    : newValue.filter(newItem => !oldValue.some(oldItem => getFirstProperty(oldItem) === getFirstProperty(newItem)));

  return (
    <Stack direction="row" mb={2}>
      <Typography typography="body2" fontWeight={800}>
        {splitCamelCase(change.key)}: &nbsp;
      </Typography>
      <Stack direction="column">
        {newChange.map((change, i) => (
          <Stack key={i} mb={1}>
            <Typography variant="body2" fontWeight={800}>
              {actionText}:
            </Typography>
            {Object.keys(newChange[i]).map((key, fieldIndex) => {
              const item = newChange[i];
              return (
                <Typography key={fieldIndex} variant="body2">
                  {key}: {item[key]}
                </Typography>
              );
            })}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}
