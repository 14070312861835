import { Badge, Box, Container, Grid, Link, ListItemAvatar, Typography, styled } from '@mui/material';
import { UserAvatar } from '../UserAvatar';
import { desktopHeaderHeight, mobileBottomNavHeight, mobileHeaderHeight } from 'global/Constants/HeaderFooterConstants';

const ScrollableGridItem = styled(Grid)`
  height: 100%;
  overflow-y: auto;
  padding-top: 16px;
`;

interface GridProps {
  isMobile: boolean;
  needsPadding: boolean;
}

export const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    padding: `0 0`,
  },
}));

interface StyledGridProps {
  showGlobalNav?: boolean;
}

export const StyledGrid = styled(Grid, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'showGlobalNav',
})<StyledGridProps>(({ showGlobalNav, theme }) => ({
  height: `calc(var(--app-height) - ${desktopHeaderHeight}px - 1px)`,
  [theme.breakpoints.down('md')]: {
    height: showGlobalNav
      ? `calc(var(--app-height) - ${mobileBottomNavHeight}px - ${mobileHeaderHeight}px)`
      : `calc(var(--app-height) - ${mobileBottomNavHeight}px - 1px)`,
  },
}));

export const LeftScrollableGridItem = styled(ScrollableGridItem, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'isMobile' && prop !== 'needsPadding',
})<GridProps>(({ isMobile, needsPadding }) => ({
  paddingLeft: needsPadding ? '24px' : '0px',
  paddingRight: isMobile ? '24px' : '0px',
}));

export const RightScrollableGridItem = styled(ScrollableGridItem, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'isMobile' && prop !== 'needsPadding',
})<GridProps>(({ isMobile, needsPadding }) => ({
  paddingLeft: isMobile ? '24px' : '0px',
  paddingRight: needsPadding ? '24px' : '0px',
}));

export const LeftSectionTypography = styled(Typography)(({ theme }) => ({
  paddingBottom: '6px',

  [theme.breakpoints.down('md')]: {
    paddingLeft: '4px',
  },
}));

export const JustifiedBox = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const EllipsisWrapperDiv = styled('div')`
  display: table;
  table-layout: fixed;
  width: 100%;
  white-space: nowrap;
  padding-right: 4px;
`;

export const EllipsisTypography = styled(Typography)(() => ({
  display: 'table-cell',
}));

export const StyledBadge = styled(Badge)`
  & .MuiBadge-badge {
    font-weight: 500;
    top: 9px;
    left: -18px;
  }
`;

export const BadgeBox = styled(Box)(() => ({
  height: '19px',
  width: '19px',
}));

export const StyledListItemAvatar = styled(ListItemAvatar)(() => ({
  minWidth: '40px',
  marginRight: '0px',
}));

export const StyledAvatar = styled(UserAvatar)(() => ({
  height: '24px',
  width: '24px',
  marginRight: '4px',

  '&.MuiAvatar-root': {
    fontSize: '10px',
  },
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  fontSize: '13px',
  color: theme.palette.error['main'],
  textDecorationColor: theme.palette.error['main'],
}));

export const IndentSmallTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2.5),
}));

export const DeliverableTypography = styled(IndentSmallTypography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  overflowWrap: 'anywhere',
}));
