import { Outlet } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { AuthContext, AuthType, getCachedAuthContext, UserAuth } from 'contexts/AuthContext';
import Header from './Header';
import { useState } from 'react';
import { restartSignalrHub, startSignalrHub, stopSignalrHub } from 'services/signalRService';
import { ThemeProvider } from '@mui/material';
import { appTheme } from './themes/theme';
import { reactPlugin } from './AppInsights';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import FooterContainer from 'FooterContainer';
import MainBox from 'MainBox';
import { getUserAuth } from 'services/authService';
import { GlobalStateProvider } from 'contexts/GlobalStateContext';
import GlobalSnackbarProvider from 'components/common/GlobalSnackbarProvider';
import ErrorPage from 'ErrorPage';
import { LocationHandler } from 'LocationHandler';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getUserLocation } from 'services/localizationService';
import { fallbackContractCountry } from 'components/common/Select/CountrySelect';
import { useCustomEventListener } from 'react-custom-events';
import { MessagePublisherEventType } from 'global/enums/messagePublisherEventType';
import { HubConnection } from '@microsoft/signalr';
import TrackSubPayment from 'components/common/Subscription/TrackSubPayment';

const App = () => {
  const [auth, setAuth] = useState<AuthType>(getCachedAuthContext());

  const startSignalR = (user: UserAuth) => {
    if (auth.hubConnection) {
      if (auth.user?.id == user.id) {
        restartSignalrHub(auth.hubConnection);
        return auth.hubConnection;
      } else {
        stopSignalrHub(auth.hubConnection);
      }
    }
    return startSignalrHub();
  };

  const handleGetUserLocation = () => {
    getUserLocation()
      .then(res => {
        setCountryCode(res);
      })
      .catch(() => {
        setCountryCode(fallbackContractCountry.code);
      });
  };

  const refresh = (): Promise<UserAuth> =>
    new Promise<UserAuth>((resolve, reject) => {
      getUserAuth()
        .then((user: UserAuth) => {
          setAndCacheAuth(user);
          resolve(user);
        })
        .catch(() => {
          localStorage.removeItem('loggedInUser');
          //logout
          if (auth.hubConnection) {
            stopSignalrHub(auth.hubConnection);
          }
          setAuth(currentAuth => ({
            ...currentAuth,
            loading: false,
            user: null,
            viewerCountry: fallbackContractCountry.code,
            vettingMessageDismissed: false,
          }));
          handleGetUserLocation();
          reject();
        });
    });

  const setAndCacheAuth = (user: UserAuth) => {
    const item = localStorage.getItem('loggedInUser');
    const cachedUser = item !== undefined && item !== null ? (JSON.parse(item) as UserAuth) : undefined;
    let noChanges = false;

    if (cachedUser !== undefined) {
      for (const key in cachedUser) {
        if (cachedUser[key as keyof typeof cachedUser] !== user[key as keyof typeof user]) {
          noChanges = false;
          break;
        }
        noChanges = true;
      }
    }

    if (noChanges) {
      const hubConnection = startSignalR(user);
      setHub(hubConnection);
      setEndLoading();
    } else {
      const hubConnection = startSignalR(user);
      localStorage.setItem('loggedInUser', JSON.stringify(user));
      setAuth(currentAuth => ({
        ...currentAuth,
        loading: false,
        user,
        hubConnection,
        viewerCountry: user.country,
        vettingMessageDismissed: user.dismissVetting,
      }));
    }
  };

  const setHub = (hub: HubConnection) => {
    if (auth.hubConnection != hub) setAuth(prev => ({ ...prev, hubConnection: hub }));
  };

  const setEndLoading = () => {
    if (auth.loading) setAuth(prev => ({ ...prev, loading: false }));
  };

  const setCountryCode = (countryCode: string) => {
    setAuth(prev => ({ ...prev, viewerCountry: countryCode }));
  };

  const setVettingMessageDismissed = () => {
    setAuth(prev => ({ ...prev, vettingMessageDismissed: true }));
  };

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.VettingChange],
    () => {
      refresh();
    },
    [auth.user],
  );

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.UserUpdated],
    (user: UserAuth) => {
      setAndCacheAuth(user);
    },
    [auth.user],
  );

  return (
    <AppInsightsErrorBoundary onError={() => <ErrorPage />} appInsights={reactPlugin}>
      <>
        <GlobalStateProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <AuthContext.Provider value={{ ...auth, refresh, setCountryCode, setVettingMessageDismissed }}>
              <ThemeProvider theme={appTheme}>
                <CssBaseline />
                <Header />
                <LocationHandler refresh={refresh} />
                <MainBox>
                  <section>
                    <Outlet />
                  </section>
                </MainBox>
                <FooterContainer />
                <GlobalSnackbarProvider />
                <TrackSubPayment />
              </ThemeProvider>
            </AuthContext.Provider>
          </LocalizationProvider>
        </GlobalStateProvider>
      </>
    </AppInsightsErrorBoundary>
  );
};

export default App;
