import { IChatMessageDisplay } from 'global/interfaces/chatMessage';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { ContractType } from 'global/interfaces/contract';
import { dateStringFromDate } from 'utils/dateFormat';

export default function ChatMessageContractCancelled(props: IChatMessageDisplay): JSX.Element {
  return (
    <React.Fragment>
      <Box>
        {props.contract?.contractType == ContractType.FixedPrice && (
          <Typography variant="body2" color="grey.900">
            Fixed price contract &quot;{props.contract?.name}&quot; has been cancelled.
          </Typography>
        )}
        {props.contract?.contractType == ContractType.Retainer && !props.contract.completedOn && (
          <Typography variant="body2" color="grey.900">
            Retainer contract &quot;{props.contract?.name}&quot; has been scheduled for termination on{' '}
            {dateStringFromDate(props.contract?.endDate)} .
          </Typography>
        )}
        {props.contract?.contractType == ContractType.Retainer && props.contract.completedOn && (
          <Typography variant="body2" color="grey.900">
            Retainer contract &quot;{props.contract?.name}&quot; has been cancelled.
          </Typography>
        )}
      </Box>
    </React.Fragment>
  );
}
