import { Button, styled } from '@mui/material';
import { RaiseDisputeDrawer } from 'components/workRoom/raiseDisputeDrawer';
import { ContractStatus, IContract } from 'global/interfaces/contract';
import { useState } from 'react';

const LinkButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.dark,
}));

interface IRaiseDisputeProps {
  contract: IContract;
  drawer?: boolean;
  setDrawer?: React.Dispatch<boolean>;
}

export default function RaiseDispute({ contract, setDrawer }: IRaiseDisputeProps) {
  const [disputeDrawer, setDisputeDrawer] = useState<boolean>(false);
  return (
    <>
      {contract.status === ContractStatus.InProgress && (
        <LinkButton variant="text" onClick={() => setDisputeDrawer(true)}>
          Raise Dispute
        </LinkButton>
      )}
      <RaiseDisputeDrawer
        contract={contract}
        open={disputeDrawer}
        handleClose={() => {
          setDisputeDrawer(false);
          setDrawer && setDrawer(false);
        }}
      />
    </>
  );
}
