import { Container, Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { RoundButton } from 'components/common/Button/RoundButton';
import { CheckMarkCircleIcon } from 'components/icon/CheckMarkCircleIcon';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const StyledCheckMarkCircleIcon = styled(CheckMarkCircleIcon)(({ theme }) => ({
  stroke: theme.palette.primary[600],
  strokeWidth: '1.5px',
  width: '48px',
  height: '48px',
  fill: 'none',
}));

const PaymentDetailsUpdated = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const handleWorkroomClick = (): void => {
    navigate('/workroom');
  };

  return (
    <Container maxWidth="md">
      <Grid
        container
        flexDirection="column"
        alignItems="center"
        minHeight={'calc(100vh - 158px)'}
        pb={9}
        maxWidth="690px"
        marginLeft="auto"
        marginRight="auto"
      >
        <Grid container item xs={12} pt={isMobileScreen ? 5 : 10} columnGap={2.5} rowGap={3.5} justifyContent="left">
          <>
            {!isMobileScreen && <StyledCheckMarkCircleIcon />}
            <Typography
              textAlign="left"
              variant={isMobileScreen ? 'h5' : 'h4'}
              component="span"
              sx={{ display: 'inline-flex', alignItems: 'center' }}
            >
              Payment Details Updated
            </Typography>
            <Typography textAlign="left" variant="body1" color="grey.500" component="div">
              Thanks updating your payment details! We will take payment within the next 3 working days. Please continue
              collaborating with the seller in the workroom.
            </Typography>
            <RoundButton
              variant="contained"
              color="primary"
              startIcon={<ChevronRightIcon />}
              onClick={handleWorkroomClick}
            >
              Go to Workroom
            </RoundButton>
          </>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PaymentDetailsUpdated;
