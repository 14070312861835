import { Box, ImageListItem, ImageListItemBar, Paper, styled } from '@mui/material';

export const StyledPaper = styled(Paper)(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  alignItems: 'center',
  border: 'none',
  boxShadow: 'none',
  aspectRatio: '4 / 3',
  minHeight: '100px',
}));

export const StyledImageListItem = styled(ImageListItem)(() => ({
  paddingTop: '42px',
  gap: '8px',
  '&:hover .MuiImageListItemBar-root': {
    display: 'flex',
  },
}));

export const StyledImageListItemBar = styled(ImageListItemBar)(() => ({
  padding: '0px',
  '& .MuiImageListItemBar-actionIcon': {
    position: 'relative',
    width: '100%',
    padding: '0px',
  },
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.grey[200],
  borderRadius: '12px',
  img: {
    borderRadius: '12px',
  },
  '.MuiPaper-root': {
    borderRadius: '12px',
  },
}));
