import { SelectProps } from '@mui/material/Select';
import { Dialog, Typography, useMediaQuery, useTheme } from '@mui/material';
import { showError } from 'utils/errorHandler';
import IApiError from 'global/interfaces/api';
import useProgressBar from 'global/hooks/useProgressBar';
import { deleteUser } from 'services/userService';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { RoundButton } from '../Button/RoundButton';

interface IDeleteProfileDialogProps extends SelectProps {
  onClose: () => void;
  onLogout: () => void;
  open: boolean;
}

export default function DeleteProfileDialog(props: IDeleteProfileDialogProps): JSX.Element {
  const [progress, showProgress] = useProgressBar();

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = (): void => {
    props.onClose();
  };

  const handleConfirm = (): void => {
    showProgress();
    deleteUser()
      .then(() => {
        props.onClose();
        // Use same logout protocol as for the rest of application
        props.onLogout();
      })
      .catch((err: IApiError) => {
        props.onClose();
        showError(err);
      })
      .finally(() => {
        showProgress(false);
      });
  };

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      fullScreen={isMobileScreen}
    >
      <StyledDialogTitle>
        <Typography variant="h6" color="grey.900">
          Delete Profile
        </Typography>
      </StyledDialogTitle>
      <StyledDialogContent dividers>
        <Typography variant="body1">Are you sure you want to delete your profile? This can not be reversed</Typography>
      </StyledDialogContent>
      <StyledDialogActions>
        <RoundButton variant="outlined" fullWidth={isMobileScreen} onClick={handleClose}>
          Cancel
        </RoundButton>
        <RoundButton variant="contained" fullWidth={isMobileScreen} onClick={handleConfirm} type="submit">
          Delete
        </RoundButton>
      </StyledDialogActions>
      {progress}
    </Dialog>
  );
}
