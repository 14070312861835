import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, styled, useMediaQuery, useTheme } from '@mui/material';
import { useContext } from 'react';
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import { desktopHeaderHeight, mobileHeaderHeight } from 'global/Constants/HeaderFooterConstants';

interface StyledBoxProps {
  showTopPadding: boolean;
  isMobile: boolean;
}

//top paddings are calculated offset for app bar
const StyledBox = styled(Box, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'showTopPadding' && prop !== 'isMobile',
})<StyledBoxProps>(({ theme, showTopPadding, isMobile }) => ({
  paddingTop: showTopPadding ? (isMobile ? `${mobileHeaderHeight}px` : `${desktopHeaderHeight}px`) : '0',
  backgroundColor: theme.palette.common.white,
  minHeight: 'calc(var(--app-height) - 86px)',
}));

interface IMainBoxProps {
  children: React.ReactNode;
}

function MainBox(props: IMainBoxProps): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { pathname } = useLocation();
  const { showGlobalNav } = useContext(GlobalStateContext);

  const [showTopPadding, setShowTopPadding] = React.useState<boolean>(false);

  React.useEffect(() => {
    const isHomePage = pathname.toLowerCase() === '/talent';
    setShowTopPadding(!isHomePage && showGlobalNav);
  }, [pathname, showGlobalNav]);

  return (
    <StyledBox showTopPadding={showTopPadding} isMobile={isMobile}>
      {props.children}
    </StyledBox>
  );
}

export default MainBox;
