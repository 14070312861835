import { Container, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTitle } from 'utils/router';
import AddressForm from 'components/common/Stripe/AddressForm';
import StripeElements from 'components/common/Stripe/StripeElements';
import { useNavigate } from 'react-router-dom';

function BillingAddress(): JSX.Element {
  const navigate = useNavigate();
  useTitle('Billing Address');

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const navigateToHomePage = (): void => {
    navigate('/');
  };

  return (
    <section>
      <Container maxWidth="md">
        <StripeElements>
          <Stack alignItems="center" mt={isMobileScreen ? '32px' : '72px'} gap={3} pb={3}>
            <Typography variant={isMobileScreen ? 'h5' : 'h4'} alignSelf="flex-start" textAlign="left">
              Billing Address
            </Typography>
            <AddressForm
              actionButtonFullWidth
              handleCancelClick={navigateToHomePage}
              afterSaveCompletes={navigateToHomePage}
            />
          </Stack>
        </StripeElements>
      </Container>
    </section>
  );
}

export default BillingAddress;
