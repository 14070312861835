import { Box, Fab, styled, useMediaQuery, useTheme } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import PdfDocument from 'components/common/PdfViewer/PdfDocument';

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[500],
  flexDirection: 'column',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
}));

const DesktopButton = styled(Fab)(() => ({
  position: 'absolute',
  top: '30px',
  right: '45px',
}));

const MobileCenterContainer = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  zIndex: 5, //pdf content is also absolute (zIndex between 1-4) so we need higher zIndex
}));

interface IPdfViewerProps {
  url: string;
  enabled?: boolean;
  showOpenInNewTab?: boolean;
  paddingY?: number;
}

export default function PdfViewer({ url, paddingY, enabled = true, showOpenInNewTab = true }: IPdfViewerProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (containerRef.current && !dimensions) {
      setDimensions({ width: containerRef.current.offsetWidth, height: containerRef.current.offsetHeight });
    }
  }, [containerRef.current]);

  const openPdf = () => {
    window.open(url, '_blank');
  };

  const iPaddingY = paddingY || (isMobile ? 8 : 25);
  const width = dimensions ? dimensions.width - iPaddingY * 2 : undefined;
  const showPdf = dimensions && enabled;

  return (
    <Container ref={containerRef} sx={{ justifyContent: isMobile ? 'center' : 'flex-start' }}>
      {showPdf && (
        <Box sx={{ overflow: isMobile ? 'hidden' : 'unset', marginTop: isMobile ? '8px' : '20px' }}>
          <PdfDocument
            url={url}
            width={width ?? 0}
            height={dimensions.height ?? 0}
            limitPages={isMobile ? 1 : undefined}
          />
        </Box>
      )}

      {!isMobile && showOpenInNewTab && (
        <DesktopButton size="small" onClick={openPdf} color="primary">
          <OpenInNew sx={{ fontSize: '15px' }} />
        </DesktopButton>
      )}

      {isMobile && (
        <MobileCenterContainer>
          <Fab color="primary" onClick={openPdf}>
            <OpenInNew />
          </Fab>
        </MobileCenterContainer>
      )}
    </Container>
  );
}
