import { Box, Button, Grid, GridProps, Stack, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { FormikProps } from 'formik';
import { SelectItem } from 'global/interfaces/selects';
import TextField from '@mui/material/TextField';
import CategorySelect from 'components/common/Select/CategorySelect';
import CountrySelect from 'components/common/Select/CountrySelect';
import { nameof } from 'ts-simple-nameof';
import { RoundButton } from 'components/common/Button/RoundButton';
import { IFrontUserFilters } from 'global/interfaces/user';
import { handleNumberChange, strictNumericCheck } from 'utils/inputs';

const StyledGrid = styled(Grid)(({ theme }) => ({
  width: '100%',
  '& .MuiInputLabel-root': {
    color: theme.palette.grey[900],
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.grey[200],
    },
    '&:hover fieldset': {
      borderColor: theme.palette.grey[400],
    },
    '&.Mui-focused fieldset': {
      borderColor: `${theme.palette.primary[600]}`,
    },
  },
  '& .MuiOutlinedInput-input': {
    color: theme.palette.grey[900],
  },
  '& .MuiInputBase-input::placeholder': {
    color: theme.palette.grey[900],
    opacity: 1,
  },
}));

export const ResetFilterButton = styled(Button)(({ theme }) => ({
  padding: '16px 20px',
  borderRadius: theme.spacing(6),
  color: theme.palette.primary.main,

  [theme.breakpoints.up('lg')]: {
    padding: '18px 36px',
  },
}));

interface IGridWrapperProps extends GridProps {
  children: React.ReactElement;
}

const GridWrapper = ({ children, ...props }: IGridWrapperProps) => (
  <StyledGrid item xs={12} sm={6} lg={2} {...props}>
    {children}
  </StyledGrid>
);

interface IFilterSectionProps {
  form: FormikProps<IFrontUserFilters>;
  items: SelectItem[];
  onlySellingServices: boolean;
  toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  handleResetFilter: () => void;
}

export default function FilterSection({ form, toggleDrawer, handleResetFilter }: IFilterSectionProps) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleCountryChange = (_: React.SyntheticEvent, value: { label: string; code: string } | null): void => {
    form.setFieldValue(
      nameof<IFrontUserFilters>(p => p.country),
      value?.code,
      true,
    );
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={isMobileScreen ? 2 : 1}>
        {isMobileScreen && (
          <Grid item xs={12} container justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Filter By</Typography>
            <Button color="primary" onClick={handleResetFilter}>
              <Typography variant="subtitle2" color="primary">
                Clear filters
              </Typography>
            </Button>
          </Grid>
        )}
      </Stack>

      <Stack
        direction={isMobileScreen ? 'column' : 'row'}
        gap={isMobileScreen ? 2 : 1}
        alignItems={isMobileScreen ? 'flex-start' : 'center'}
        width="100%"
        mt={isMobileScreen ? 0 : 1}
      >
        <GridWrapper lg={3} md={3}>
          <CategorySelect
            value={form.values.category ?? ''}
            name="category"
            label="Category"
            onChange={form.handleChange}
            size={isMobileScreen ? 'medium' : 'small'}
          />
        </GridWrapper>

        <GridWrapper lg={3} md={3}>
          <CountrySelect
            label="Country"
            onChange={handleCountryChange}
            value={form.values.country}
            size={isMobileScreen ? 'medium' : 'small'}
          />
        </GridWrapper>

        <GridWrapper xs={12} md={2.5} lg={2.5}>
          <TextField
            id="maxHourlyRate"
            value={form.values.maxHourlyRate ?? ''}
            onChange={e => handleNumberChange(e, form.handleChange, '')}
            label="Max Hourly rate"
            placeholder="Max Hourly rate"
            onKeyDown={strictNumericCheck}
            fullWidth
            inputProps={{ inputMode: 'numeric' }}
            size={isMobileScreen ? 'medium' : 'small'}
            variant="outlined"
          />
        </GridWrapper>

        <Box width={isMobileScreen ? '100%' : 'auto'}>
          {isMobileScreen ? (
            <Grid item xs={12} spacing={2} container pb="24px" justifyContent="space-between">
              <Grid item xs={6}>
                <RoundButton variant="outlined" fullWidth onClick={toggleDrawer(false)}>
                  Cancel
                </RoundButton>
              </Grid>
              <Grid item xs={6}>
                <RoundButton
                  variant="contained"
                  fullWidth
                  onClick={event => {
                    form.submitForm();
                    toggleDrawer(false)(event);
                  }}
                >
                  Apply
                </RoundButton>
              </Grid>
            </Grid>
          ) : (
            <ResetFilterButton fullWidth={isMobileScreen} variant="text" onClick={handleResetFilter}>
              Reset
            </ResetFilterButton>
          )}
        </Box>
      </Stack>
    </>
  );
}
