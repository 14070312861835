import { CircularProgress, IconButton, styled, Box } from '@mui/material';
import React, { useEffect } from 'react';
import { IUploadQueue } from 'global/interfaces/user';
import { savePortfolioFile } from 'services/storageService';
import IApiError from 'global/interfaces/api';
import { showError } from 'utils/errorHandler';
import { IFileMetadata } from 'global/interfaces/file';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { StyledBox, StyledImageListItemBar, StyledPaper } from './PortfolioFilesStyles';
import { EllipsisTypography } from '../Typography/EllipsisTypography';

interface IUploadProgressProps {
  upload: IUploadQueue;
  onUploadCancel: (tempId: string) => void;
  onUploadComplete: (tempId: string, response: IFileMetadata) => void;
}

const StyledDeleteIconButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: '-12px',
  right: '-12px',
}));

export default function UploadProgress(props: IUploadProgressProps): JSX.Element {
  const uploadCancel = (): void => {
    props.onUploadCancel(props.upload.tempId);
  };

  useEffect(() => {
    let controller: AbortController | null = new AbortController();
    savePortfolioFile(props.upload.file, controller)
      .then((fileResponse: IFileMetadata) => {
        controller = null;
        props.onUploadComplete(props.upload.tempId, fileResponse);
      })
      .catch((err: IApiError) => {
        props.onUploadCancel(props.upload.tempId);
        if (err.message !== 'canceled') {
          showError(err);
        }
      });
  }, []);

  return (
    <>
      <StyledImageListItemBar
        position="top"
        className="image-list-header-bar"
        actionIcon={
          <>
            <StyledDeleteIconButton onClick={uploadCancel}>
              <ClearOutlinedIcon />
            </StyledDeleteIconButton>
          </>
        }
      />
      <StyledBox>
        <StyledPaper>
          <CircularProgress />
        </StyledPaper>
      </StyledBox>
      <Box mt={1}>
        <EllipsisTypography variant="body2">{props.upload.file?.name}</EllipsisTypography>
      </Box>
    </>
  );
}
