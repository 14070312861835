import { IChatMessageDisplay } from 'global/interfaces/chatMessage';
import * as React from 'react';
import Typography from '@mui/material/Typography';

interface IChatMessageConnectionRemovedProps extends IChatMessageDisplay {
  currentUserId?: string;
}

export default function ChatMessageConnectionRemoved(props: IChatMessageConnectionRemovedProps): JSX.Element {
  return (
    <React.Fragment>
      {props.connection?.actionUserId !== props.currentUserId && (
        <>
          <Typography variant="body2" color="grey.900">
            {props.connection?.actionUserDisplayName} has removed you as a connection.
          </Typography>
        </>
      )}
      {props.connection?.actionUserId === props.currentUserId && (
        <>
          <Typography variant="body2" color="grey.900">
            You removed your connection with {props.connection?.otherUserDisplayName}.
          </Typography>
        </>
      )}
    </React.Fragment>
  );
}
