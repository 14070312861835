import {
  Box,
  Button,
  Drawer,
  FormHelperText,
  Grid,
  IconButton,
  SelectChangeEvent,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { IChatUserThread } from 'global/interfaces/chatThread';
import useProgressBar from 'global/hooks/useProgressBar';
import { UserAuth } from 'contexts/AuthContext';
import { sendDeliverablesForApproval } from 'services/contractService';
import IApiError from 'global/interfaces/api';
import { showErrorMessage } from 'utils/errorHandler';
import CloseIcon from '@mui/icons-material/Close';
import { StyledDrawerGrid } from 'components/common/StyledDrawerGrid';
import { useEffect, useState } from 'react';
import useContractDeliverables from './useContractDeliverables';
import DeliverablesSelect from './DeliverablesSelect';
import { IDeliverableForApproval } from 'global/interfaces/contract';
import FormSelect from 'components/common/Select/FormSelect';
import { RoundButton } from 'components/common/Button/RoundButton';

const StyledTypography = styled(Typography)(({ theme }) => ({
  overflowWrap: 'break-word',
  color: theme.palette.grey[900],
}));

const StyledGrid = styled(Grid)(() => ({
  marginTop: '24px',
}));

interface ISendDeliverablesForApprovalProps {
  user: UserAuth | null;
  open: boolean;
  selectedThread?: IChatUserThread;
  handleClose: () => void;
}

export default function SendDeliverablesDrawer({
  handleClose,
  selectedThread,
  open,
}: ISendDeliverablesForApprovalProps) {
  const [sendClicked, setSendClicked] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [selectedDeliverables, setSelectedDeliverables] = useState<IDeliverableForApproval[]>([]);
  const [progress, showProgress] = useProgressBar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleDeliverablesChange = (deliverables: IDeliverableForApproval[]) => {
    setSelectedDeliverables(deliverables);
  };

  useEffect(() => {
    if (open) {
      setSendClicked(false);
      setSelectedUserId(undefined);
      setSelectedDeliverables([]);
    }
  }, [open, selectedThread]);

  const handleSend = () => {
    setSendClicked(true);
    if (selectedDeliverables.length > 0) {
      handleClose();
      showProgress(true);
      sendDeliverablesForApproval(selectedThread?.chatThreadId ?? '', selectedDeliverables)
        .then(() => {
          showProgress(false);
        })
        .catch((err: IApiError) => {
          showErrorMessage(`Could not submit deliverables for approval ${err.message}`);
        });
    }
  };

  const { deliverables, deliverableUsers } = useContractDeliverables(
    open,
    selectedThread?.chatThreadId,
    selectedUserId,
  );
  const deliverablesAvailable = deliverables.length > 0;

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <StyledDrawerGrid container>
          <Grid item xs={10} display="flex" alignItems="center">
            <StyledTypography variant={isMobile ? 'subtitle1' : 'h6'}>Send Deliverables For Approval</StyledTypography>
          </Grid>
          <Grid item xs={2} container justifyContent="flex-end">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          {deliverableUsers.length > 1 && (
            <Grid item xs={12} sx={{ mt: 2 }}>
              <FormSelect
                items={[{ id: '', label: 'None' }, ...deliverableUsers]}
                value={selectedUserId ?? ''}
                onChange={(e: SelectChangeEvent<unknown>) => {
                  setSelectedUserId(e.target.value as string);
                }}
                label="Buyer"
                name="buyerId"
                size="small"
              />
              {sendClicked && !selectedUserId && (
                <FormHelperText error sx={{ mb: 1 }}>
                  Please select the buyer
                </FormHelperText>
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            {deliverablesAvailable &&
              ((deliverableUsers.length > 1 && selectedUserId) || deliverableUsers.length == 1) && (
                <>
                  <Box mt={1}>
                    <DeliverablesSelect onChange={handleDeliverablesChange} deliverables={deliverables} />
                    {sendClicked && selectedDeliverables.length === 0 && (
                      <FormHelperText error sx={{ mb: 1 }}>
                        Please select one or more deliverables
                      </FormHelperText>
                    )}
                  </Box>
                </>
              )}
            {!deliverablesAvailable && (
              <Typography variant="subtitle1">
                You currently have no open contracts with deliverables which need approved.
              </Typography>
            )}
          </Grid>
          <StyledGrid item container columnSpacing={2}>
            {!deliverablesAvailable && <Button onClick={handleClose}>OK</Button>}
            {deliverablesAvailable && (
              <>
                <Grid item xs={6}>
                  <RoundButton variant="outlined" onClick={handleClose} fullWidth>
                    Cancel
                  </RoundButton>
                </Grid>
                <Grid item xs={6}>
                  <RoundButton variant="contained" onClick={handleSend} fullWidth>
                    Send
                  </RoundButton>
                </Grid>
              </>
            )}
          </StyledGrid>
        </StyledDrawerGrid>
      </Drawer>
      {progress}
    </>
  );
}
