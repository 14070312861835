import { IChatMessageDisplay } from 'global/interfaces/chatMessage';
import * as React from 'react';
import Typography from '@mui/material/Typography';

interface IChatMessageConnectionInvitationAcceptedProps extends IChatMessageDisplay {
  currentUserId?: string;
}

export default function ChatMessageConnectionInvitationAccepted(
  props: IChatMessageConnectionInvitationAcceptedProps,
): JSX.Element {
  return (
    <React.Fragment>
      {props.connection?.actionUserId == props.currentUserId && (
        <>
          <Typography variant="body2" color="grey.900" whiteSpace="break-spaces">
            You have accepted the invite from {props.connection?.otherUserDisplayName} and you are now connected with
            them. You can talk to them directly in this chat, send them a contract or add them to a Team. As a reminder
            Shoutt is <b>100% commission free.</b>
          </Typography>
        </>
      )}
      {props.connection?.actionUserId !== props.currentUserId && (
        <>
          <Typography variant="body2" color="grey.900" whiteSpace="break-spaces">
            You invited {props.connection?.actionUserDisplayName} to Shoutt and they have accepted. You can talk to them
            directly in this chat, send them a contract or add them to a Team. As a reminder Shoutt is{' '}
            <b>100% commission free.</b>
          </Typography>
        </>
      )}
    </React.Fragment>
  );
}
