import { Box, styled } from '@mui/material';

const StyledBox = styled(Box)(() => ({
  display: 'flex',
}));

const StyledAudio = styled('audio')(() => ({
  width: '100%',
}));

export interface IChatMessageMediaAudioProps {
  url?: string;
}

export default function ChatMessageMediaAudio({ url }: IChatMessageMediaAudioProps): JSX.Element {
  return (
    <StyledBox>
      <StyledAudio
        controls
        src={`${process.env.REACT_APP_CDN_URL ?? ''}${url}`}
        crossOrigin="anonymous"
        preload="metadata"
      >
        Your browser does not currently recognize this audio format.
      </StyledAudio>
    </StyledBox>
  );
}
