import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { IConnection } from 'global/interfaces/connection';
import { Dispatch } from 'react';
import { NetworkOptionButtons } from './networkOptionButtons';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { dateStringFromDate } from 'utils/dateFormat';
import UserBasicInfo from './UserBasicInfo';

interface NetworkProps {
  connections: IConnection[] | undefined;
  setRefresh: Dispatch<boolean>;
}

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  '& .MuiTableCell-head': {
    backgroundColor: theme.palette.blue['200'],
    fontWeight: 600,
  },
}));

export const ViewNetworkTable: React.FC<NetworkProps> = props => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  return <>{isMobileScreen ? <MobileNetworkView {...props} /> : <DesktopView {...props} />}</>;
};

const DesktopView: React.FC<NetworkProps> = ({ connections, setRefresh }) => {
  const HeaderData = [
    {
      id: 'user',
      label: 'Name',
      minWidth: 180,
    },
    { id: 'last-contract', label: 'Last contract', minWidth: 180 },
    { id: 'total-spent', label: 'Total amount', minWidth: 180 },
    { id: 'options', label: 'Options', minWidth: 180 },
  ];

  return (
    <TableContainer sx={{ overflowX: 'auto' }}>
      <Table sx={{ minWidth: 750 }}>
        <StyledTableHead>
          <TableRow>
            {HeaderData.map(headerCell => (
              <TableCell key={headerCell.id}>
                <Typography variant="subtitle2">{headerCell.label}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {connections?.map((con, i) => (
            <TableRow key={`connection-${i}`}>
              <TableCell>
                <UserBasicInfo user={con?.otherUser} />
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {con.contract?.lastContractCreatedOn
                    ? dateStringFromDate(con.contract?.lastContractCreatedOn)
                    : 'N/A'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {con.contract?.totalAmount ? `$${con.contract?.totalAmount}` : 'N/A'}
                </Typography>
              </TableCell>

              <TableCell align="right" width={330}>
                <NetworkOptionButtons connection={con} setRefresh={setRefresh} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const MobileNetworkView: React.FC<NetworkProps> = ({ connections, setRefresh }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Grid container gap={4} mt={1}>
        {connections?.map(con => (
          <Grid key={con.connectionId} container item>
            <Grid container item xs={12} md={4}>
              <UserBasicInfo user={con.otherUser} />
            </Grid>

            <Grid container xs={12} md={8} gap={1} mt={2}>
              <Grid xs={12} md={3} item container justifyContent="space-between">
                <Grid item xs={6} md={12}>
                  <Typography variant="subtitle2">Last contract</Typography>
                </Grid>

                <Typography variant="body2">
                  {con.contract?.lastContractCreatedOn
                    ? `${dateStringFromDate(con.contract?.lastContractCreatedOn)}`
                    : 'N/A'}
                </Typography>
              </Grid>

              <Grid xs={12} md={3} item container justifyContent="space-between">
                <Grid item xs={6} md={12}>
                  <Typography variant="subtitle2">Total amount</Typography>
                </Grid>
                <Typography variant="body2">
                  {con.contract?.totalAmount ? `$${con.contract?.totalAmount}` : 'N/A'}
                </Typography>
              </Grid>

              <Grid item container xs={12} alignItems="center" mt={1} mb={2}>
                <NetworkOptionButtons connection={con} setRefresh={setRefresh} />
              </Grid>

              {!isMobileScreen && (
                <Grid xs={1.2} md={1} container alignItems="center" justifyContent="center">
                  <StarOutlineIcon />
                </Grid>
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
