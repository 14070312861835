import { SelectChangeEvent, SelectProps } from '@mui/material';
import { IConnection } from 'global/interfaces/connection';
import { Dispatch } from 'react';
import FormSelect from '../Select/FormSelect';
import { SelectItem } from 'global/interfaces/selects';

interface IConnectionsSelectListProps extends SelectProps {
  setSelectedUserId: Dispatch<string>;
  connections: IConnection[] | undefined;
}

export function ConnectionsSelectList({ setSelectedUserId, connections, ...props }: IConnectionsSelectListProps) {
  const handleChange = (event: SelectChangeEvent<unknown>, child: React.ReactNode) => {
    if (props.onChange && typeof event.target.value === 'string') {
      const selectedUserId = connections?.find(c => c.chatThreadId === event.target.value)?.otherUser?.userId;
      setSelectedUserId(selectedUserId!);
      props.onChange(event, child);
    }
  };

  return (
    <FormSelect
      {...props}
      label="Connections"
      fullWidth
      items={connections ? mapToSlectItems(connections) : []}
      helpertext={props.error === true ? 'Select a connection' : undefined}
      onChange={handleChange}
    />
  );
}

function mapToSlectItems(connections: IConnection[]): SelectItem[] {
  return connections.map(connection => ({
    id: connection.chatThreadId ?? '',
    label: connection.otherUser?.displayName ?? '',
  }));
}
