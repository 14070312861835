import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Dispatch, useState } from 'react';
import { removeConnection } from 'services/connectionService';
import { showError } from 'utils/errorHandler';
import { IConnection } from 'global/interfaces/connection';
import { ContractStatus } from 'global/interfaces/contract';
import useProgressBar from 'global/hooks/useProgressBar';
import { useNavigate } from 'react-router-dom';
import { DeleteIcon } from 'components/icon/DeleteIcon';
import { MessageIcon } from 'components/icon/MessageIcon';
import { RoundButton } from 'components/common/Button/RoundButton';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { Close } from '@mui/icons-material';
import { LowPriorityButton } from 'components/common/Button/LowPriorityButton';

export const NetworkOptionButtons: React.FC<{ connection: IConnection | undefined; setRefresh: Dispatch<boolean> }> = ({
  connection,
  setRefresh,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Stack direction={'row'} gap={1} width="100%">
        <LowPriorityButton
          fullWidth={isMobileScreen}
          variant="outlined"
          startIcon={<DeleteIcon />}
          onClick={() => setOpen(true)}
        >
          Remove
        </LowPriorityButton>
        <RoundButton
          color="primary"
          variant="outlined"
          startIcon={<MessageIcon />}
          onClick={() => navigate(`/workroom/${connection?.chatThreadId}`)}
          fullWidth={isMobileScreen}
        >
          Message
        </RoundButton>
      </Stack>

      {connection?.contract?.lastContractStatus === ContractStatus.InProgress ||
      connection?.contract?.lastContractStatus === ContractStatus.Disputed ? (
        <ExistingContractDialog open={open} setOpen={setOpen} />
      ) : (
        <RemoveConnectionDialog open={open} setOpen={setOpen} connection={connection} setRefresh={setRefresh} />
      )}
    </>
  );
};

interface DialogOpenProps {
  open: boolean;
  setOpen: Dispatch<boolean>;
}

const RemoveConnectionDialog: React.FC<
  {
    connection: IConnection | undefined;
    setRefresh: Dispatch<boolean>;
  } & DialogOpenProps
> = ({ open, setOpen, connection, setRefresh }) => {
  const [progress, showProgress] = useProgressBar();
  const [deleteError, setDeleteError] = useState<boolean>(false);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleRemoval = () => {
    if (connection !== undefined) {
      showProgress(true);
      removeConnection(connection.connectionId ?? '')
        .then((res: boolean) => {
          if (res) {
            setRefresh(true);
          } else {
            setDeleteError(true);
          }
          showProgress(false);
        })
        .catch(showError);
    }
  };

  return (
    <Dialog open={open} fullWidth fullScreen={isMobileScreen}>
      <StyledDialogTitle>
        <Typography variant="h6" color={theme.palette.grey[900]}>
          {deleteError ? 'Connection cannot be deleted' : 'Remove Connection'}
        </Typography>
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </StyledDialogTitle>

      <StyledDialogContent dividers>
        <Grid pb={5}>
          <Typography>
            {deleteError
              ? 'You cannot remove a connection with an ongoing contract'
              : `By removing the connection you will no longer be able to work with ${connection?.otherUser?.displayName}. Are you sure you want to remove this connection?`}
          </Typography>
        </Grid>
      </StyledDialogContent>

      <StyledDialogActions>
        <RoundButton
          fullWidth={isMobileScreen}
          onClick={() => {
            setOpen(false);
            setDeleteError(false);
          }}
          variant="outlined"
        >
          Cancel
        </RoundButton>
        {!deleteError && (
          <RoundButton fullWidth={isMobileScreen} type="submit" variant="contained" onClick={() => handleRemoval()}>
            Confirm
          </RoundButton>
        )}
      </StyledDialogActions>
      {progress}
    </Dialog>
  );
};

const ExistingContractDialog: React.FC<DialogOpenProps> = (props: DialogOpenProps) => (
  <Dialog open={props.open} fullWidth>
    <DialogTitle>Cannot remove this contact</DialogTitle>
    <DialogContent>
      <DialogContentText>
        This contact has an outstanding contract and deliverables in progress or in dispute and cannot be removed. If
        you need further assisstance, contact support
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <RoundButton onClick={() => props.setOpen(false)} variant="outlined">
        Cancel
      </RoundButton>
    </DialogActions>
  </Dialog>
);
