import { IOnBoardingOptions, IPaymentMethods, IStripeConnect, IStripeOnBoarding } from 'global/interfaces/payout';
import { authorizedGet, authorizedPost } from './baseApiService';

const resource = 'Payout';

export async function getPayout(): Promise<IStripeConnect> {
  return await authorizedGet<IStripeConnect>(`${resource}`);
}

export async function getPaymentMethods(): Promise<IPaymentMethods> {
  return await authorizedGet<IPaymentMethods>(`${resource}/payment-methods`);
}

export function getLowCostMethodsToTurnOn(paymentMethods: IPaymentMethods): string[] {
  const newMethods = [];
  if (!paymentMethods.bankTransfer) newMethods.push('Bank Transfer');
  if (!paymentMethods.bacsDebit) newMethods.push('Bacs Debit');
  if (!paymentMethods.sepaDebit) newMethods.push('SEPA Debit');
  if (!paymentMethods.achDebit) newMethods.push('ACH Debit');
  return newMethods;
}

export async function createOnBoardingUrl(options?: IOnBoardingOptions): Promise<IStripeOnBoarding> {
  return await authorizedPost<IStripeOnBoarding>(
    `${resource}/create-onboarding-url`,
    options !== undefined
      ? {
          ...options,
        }
      : {},
  );
}
