import { SvgIcon, SvgIconProps } from '@mui/material';

export const PaymentSuccessfulIcon = (props: SvgIconProps) => (
  <SvgIcon width="20" height="15" viewBox="0 0 20 15" fill="none" {...props}>
    <path
      d="M13.3333 12L15 13.6667L18.3333 10.3333M18.3333 5.33333H1.66666M18.3333 7V3.83334C18.3333 2.89991 18.3333 2.4332 18.1517 2.07669C17.9919 1.76308 17.7369 1.50811 17.4233 1.34833C17.0668 1.16667 16.6001 1.16667 15.6667 1.16667H4.33333C3.39991 1.16667 2.9332 1.16667 2.57668 1.34832C2.26308 1.50811 2.00811 1.76308 1.84832 2.07668C1.66666 2.4332 1.66666 2.89991 1.66666 3.83333V10.1667C1.66666 11.1001 1.66666 11.5668 1.84832 11.9233C2.00811 12.2369 2.26308 12.4919 2.57668 12.6517C2.9332 12.8333 3.39991 12.8333 4.33333 12.8333H10"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
