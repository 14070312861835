import { SvgIcon, SvgIconProps } from '@mui/material';

export const PeopleAcceptedIcon = (props: SvgIconProps) => (
  <SvgIcon width="20" height="18" viewBox="0 0 20 18" {...props}>
    <path
      d="M13.3333 14L15 15.6667L18.3333 12.3333M9.99999 11.5H6.66666C5.11352 11.5 4.33695 11.5 3.72438 11.7537C2.90762 12.092 2.2587 12.741 1.92039 13.5577C1.66666 14.1703 1.66666 14.9469 1.66666 16.5M12.9167 1.7423C14.1383 2.23679 15 3.43443 15 4.83333C15 6.23224 14.1383 7.42988 12.9167 7.92437M11.25 4.83333C11.25 6.67428 9.75761 8.16667 7.91666 8.16667C6.07571 8.16667 4.58332 6.67428 4.58332 4.83333C4.58332 2.99238 6.07571 1.5 7.91666 1.5C9.75761 1.5 11.25 2.99238 11.25 4.83333Z"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
