import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { deleteThread, getThread, getThreads } from 'services/chatThreadService';
import { ChatType, IChatThreadUpdated, IChatUserThread } from 'global/interfaces/chatThread';
import IApiError from 'global/interfaces/api';
import { showError } from 'utils/errorHandler';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MessagesSection from 'components/workRoom/messagesSection';
import RightMenu from 'components/workRoom/rightMenu';
import { useCustomEventListener } from 'react-custom-events';
import { MessagePublisherEventType } from 'global/enums/messagePublisherEventType';
import { IChatMessage } from 'global/interfaces/chatMessage';
import { AuthContext } from 'contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Divider, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { AddTeamDialog } from 'components/workRoom/AddTeamDialog';
import { StartChatDialog } from 'components/workRoom/StartChatDialog';
import CloseIcon from '@mui/icons-material/Close';
import { ConfirmDialog } from 'components/workRoom/confirmDialog';
import { getOpenContracts, updateContract } from 'services/contractService';
import { ContractStatus, IContractDisplay } from 'global/interfaces/contract';
import {
  BadgeBox,
  EllipsisTypography,
  EllipsisWrapperDiv,
  JustifiedBox,
  LeftSectionTypography,
  StyledAvatar,
  StyledBadge,
  StyledGrid,
  StyledListItemAvatar,
} from 'components/common/StyledWorkroom/WorkroomStyles';
import { WorkRoomView } from 'global/enums/workroomLeftMenuOptions';
import { useTitle } from 'utils/router';
import { useContext, useEffect, useState } from 'react';
import CreateContractDialog from 'components/common/Contract/CreateContractDialog';

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  paddingLeft: '6px',
  paddingRight: '0px',
  paddingTop: '4px',
  paddingBottom: '4px',
  borderRadius: '8px',
  height: '36px',

  '&.Mui-selected': {
    backgroundColor: theme.palette.grey[200],
  },
  '&.Mui-selected:hover': {
    backgroundColor: theme.palette.grey[200],
  },
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
}));

EllipsisTypography.defaultProps = {
  noWrap: true,
};

const StyledAddButton = styled(Button)(({ theme }) => ({
  paddingLeft: '10px',
  marginBottom: '4px',

  [theme.breakpoints.down('md')]: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const StyledCloseIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[700],
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: 0,
  },
}));

const StyledAddTeamIcon = styled(AddIcon)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

const LeftSection = styled(Grid)(({ theme }) => ({
  padding: '16px 8px',
  overflow: 'auto',
  maxHeight: '100%',
  backgroundColor: theme.palette.common.white,
  paddingLeft: 0,

  [theme.breakpoints.down('md')]: {
    paddingLeft: '8px',
  },

  '::-webkit-scrollbar': {
    width: '4px',
  },

  '::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[400],
    borderRadius: '25px',
  },
}));

type ChatThreadType = 'team' | 'dm';

interface IConfirmDialogData {
  open: boolean;
  chatThreadId: string;
  chatThreadName: string;
  isChatOwner?: boolean;
  isOneToOne?: boolean;
  chatThreadType: ChatThreadType;
}

function WorkRoom(): JSX.Element {
  useTitle('Work Room');
  const authContext = useContext(AuthContext);
  const [view, setView] = useState<WorkRoomView>(WorkRoomView.Left);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isRightSectionCollapsed, setIsRightSectionCollapsed] = useState<boolean>(true);
  const needsPadding = useMediaQuery(theme.breakpoints.down('lg'));
  const [threads, setThreads] = useState<IChatUserThread[]>([]);
  const [selectedThread, setSelectedThread] = useState<IChatUserThread>();
  const [addTeamDialog, setAddTeamDialog] = useState<boolean>(false);
  const [startChatDialog, setStartChatDialog] = useState<boolean>(false);
  const [isAddMemberDialogOpen, setIsAddMemberDialogOpen] = useState<boolean>(false);
  const [contracts, setContracts] = useState<IContractDisplay[]>([]);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [confirmDialog, setConfirmDialog] = useState<IConfirmDialogData>({
    open: false,
    chatThreadId: '',
    chatThreadName: '',
    chatThreadType: 'dm',
  });
  const [openCreateContractDialog, setOpenCreateContractDialog] = React.useState(false);
  const [isRemovingThread, setIsRemovingThread] = React.useState(false);

  const navigate = useNavigate();

  const urlParams = useParams();
  const { id } = urlParams;

  useEffect(() => {
    setView(WorkRoomView.Left);
    getThreads(id)
      .then((res: IChatUserThread[]) => {
        if (res.length > 0) {
          if (id !== undefined) {
            setSelectedThread(res.find(x => x.chatThreadId === id));
            setView(WorkRoomView.Middle);
          } else {
            setSelectedThread(res[0]);
          }
        }
        setThreads(res);
      })
      .catch(showError);
  }, []);

  useEffect(() => {
    setContracts([]);
    const chatThreadId: string = selectedThread?.chatThreadId ?? '';
    if (chatThreadId != '') {
      getOpenContracts(chatThreadId)
        .then((res: IContractDisplay[]) => {
          for (const m of res) {
            updateContract(m);
          }
          setContracts(res);
        })
        .catch(showError);
    }
  }, [selectedThread]);

  useEffect(() => {
    if (id != selectedThread?.chatThreadId) {
      setSelectedThread(id ? threads.find(t => t.chatThreadId == id) : threads[0]);
    }
    if (isMobile) {
      if (id && view != WorkRoomView.Middle) {
        setView(WorkRoomView.Middle);
      } else if (!id && view != WorkRoomView.Left) {
        setView(WorkRoomView.Left);
      }
    }
  }, [id]);

  const handleMessageClick = (event: React.MouseEvent<HTMLElement>, item: IChatUserThread): void => {
    const target = event.target as HTMLElement;
    const includesDeleteTarget =
      target.tagName.includes('BUTTON') || target.tagName.includes('svg') || target.tagName.includes('path');

    if (includesDeleteTarget) {
      return;
    }

    navigate(`/workroom/${item.chatThreadId}`, { replace: true });
  };

  const handleMiddleClick = (): void => {
    setView(WorkRoomView.Middle);
  };

  const handleLeftClick = (): void => {
    navigate(`/workroom`, { replace: true });
  };

  const handleRightClick = (): void => {
    setView(WorkRoomView.Right);
  };

  const handleRightCollapseClick = (): void => {
    setIsRightSectionCollapsed(current => !current);
  };

  const dmThreads = threads.filter(p => p.type === ChatType.OneToOne);
  const teamThreads = threads.filter(p => p.type === ChatType.Group);

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.NewMessage],
    (res: IChatMessage) => {
      if (res.userId !== authContext.user?.id) {
        setIsTyping(false);
        setThreads(current =>
          current.map(item =>
            item.chatThreadId === res.chatThreadId
              ? { ...item, numberOfItemsUnread: item.numberOfItemsUnread + 1 }
              : item,
          ),
        );
      }
    },
    [dmThreads, teamThreads],
  );

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.ReadMessages],
    (res: IChatMessage) => {
      setThreads(current =>
        current.map(item => (item.chatThreadId === res.chatThreadId ? { ...item, numberOfItemsUnread: 0 } : item)),
      );
    },
    [threads],
  );

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.UpdateThread],
    (res: IChatThreadUpdated) => {
      getThread(res.id)
        .then((res: IChatUserThread) => {
          setThreads(current => {
            if (current.findIndex(p => p.chatThreadId === res.chatThreadId) === -1) return [...current, res];
            else {
              return current.map(item => (item.chatThreadId === res.chatThreadId ? res : item));
            }
          });
          if (selectedThread === undefined || selectedThread?.chatThreadId === res.chatThreadId) {
            setSelectedThread(res);
          }
        })
        .catch(showError);
    },
    [dmThreads, teamThreads, selectedThread],
  );

  useCustomEventListener(
    MessagePublisherEventType[MessagePublisherEventType.ContractChange],
    (changedContract: IContractDisplay) => {
      updateContract(changedContract);
      if (changedContract.chatThreadId == selectedThread?.chatThreadId) {
        setContracts(contracts => {
          if (!contracts.some(c => c.contractId == changedContract.contractId)) {
            return [changedContract, ...contracts];
          } else {
            return contracts.map(c => (c.contractId == changedContract.contractId ? changedContract : c));
          }
        });
      }
    },
    [selectedThread],
  );

  const changeSelectedThreadAfterLeave = (threads: IChatUserThread[], chatThreadId: string) => {
    const deletedChatThreadIndex = threads.findIndex(thread => thread.chatThreadId === chatThreadId);

    if (deletedChatThreadIndex !== -1) {
      if (deletedChatThreadIndex === threads.length - 1) {
        if (threads.length > 1) {
          setSelectedThread(threads[0]);
          navigate(`/workroom/${threads[0].chatThreadId}`, { replace: true });
        } else {
          setSelectedThread(undefined);
          navigate('/workroom', { replace: true });
        }
      } else {
        setSelectedThread(threads[deletedChatThreadIndex + 1]);
        navigate(`/workroom/${threads[deletedChatThreadIndex + 1].chatThreadId}`, { replace: true });
      }
    }
  };

  const leaveChat = (chatThreadId: string, chatThreadType: ChatThreadType) => {
    setIsRemovingThread(true);
    deleteThread(chatThreadId)
      .then(() => {
        setThreads(current => current.filter(x => x.chatThreadId !== chatThreadId));

        if (selectedThread?.chatThreadId === chatThreadId) {
          if (chatThreadType === 'team') {
            changeSelectedThreadAfterLeave(teamThreads, chatThreadId);
          } else {
            changeSelectedThreadAfterLeave(dmThreads, chatThreadId);
          }
        }
      })
      .catch((err: IApiError) => {
        showError(err);
      })
      .finally(() => setIsRemovingThread(false));
  };

  useEffect(() => {
    setIsRightSectionCollapsed(!contracts.length);
  }, [selectedThread, contracts]);

  const hasActiveSellingContract =
    contracts.filter(x => x.sellerAdditionalDetails && x.status == ContractStatus.InProgress).length > 0;
  const setConfirmDialogOpen = (open: boolean) =>
    setConfirmDialog(currentConfirmDialog => ({
      ...currentConfirmDialog,
      open,
    }));

  return (
    <section>
      <StyledContainer maxWidth="lg">
        <StyledGrid container spacing={0}>
          {(!isMobile || view === WorkRoomView.Left) && (
            <LeftSection item xs={12} md={3.0} lg={2.0}>
              <LeftSectionTypography variant="subtitle1">Teams</LeftSectionTypography>
              <List disablePadding>
                {teamThreads.map((item: IChatUserThread, i: number) => (
                  <ListItem
                    key={i}
                    disablePadding
                    sx={{
                      '&:hover .hidden-close': {
                        display: 'flex',
                      },
                    }}
                  >
                    <StyledListItemButton
                      onClick={e => handleMessageClick(e, item)}
                      selected={item.chatThreadId === selectedThread?.chatThreadId && !isMobile}
                      disableRipple
                    >
                      <StyledListItemAvatar>
                        <Typography variant={isMobile ? 'body1' : 'body2'} width="100%" paddingLeft={0.5}>
                          #
                        </Typography>
                      </StyledListItemAvatar>
                      <ListItemText
                        primary={
                          <JustifiedBox>
                            <EllipsisWrapperDiv>
                              <EllipsisTypography variant={isMobile ? 'body1' : 'body2'} title={item.displayName}>
                                {item.displayName}
                              </EllipsisTypography>
                            </EllipsisWrapperDiv>
                            <StyledBadge color="primary" badgeContent={item.numberOfItemsUnread}>
                              <BadgeBox></BadgeBox>
                            </StyledBadge>
                          </JustifiedBox>
                        }
                        sx={{ margin: '0px' }}
                      />
                      <StyledCloseIconButton
                        className="hidden-close"
                        sx={{ display: isMobile ? undefined : 'none' }}
                        size="small"
                        onClick={() =>
                          setConfirmDialog({
                            open: true,
                            isChatOwner: item.createdByUserId == authContext.user?.id,
                            chatThreadId: item.chatThreadId,
                            chatThreadName: item.displayName,
                            chatThreadType: 'team',
                          })
                        }
                      >
                        <CloseIcon fontSize="small" />
                      </StyledCloseIconButton>
                    </StyledListItemButton>
                  </ListItem>
                ))}
              </List>

              <StyledAddButton
                startIcon={<StyledAddTeamIcon style={{ color: theme.palette.primary.dark }} />}
                onClick={() => setAddTeamDialog(true)}
                style={{ backgroundColor: 'transparent' }}
              >
                <Typography variant={isMobile ? 'body1' : 'body2'} color={theme.palette.primary.dark} paddingLeft={1.5}>
                  Add Team
                </Typography>
              </StyledAddButton>

              {isMobile && <Divider />}

              <LeftSectionTypography mt={'12px'} variant="subtitle1">
                Direct Messages
              </LeftSectionTypography>

              <List disablePadding>
                {dmThreads.map((item: IChatUserThread, i: number) => (
                  <ListItem
                    key={i}
                    disablePadding
                    sx={{
                      '&:hover .hidden-close': {
                        display: 'flex',
                      },
                    }}
                  >
                    <StyledListItemButton
                      onClick={e => handleMessageClick(e, item)}
                      selected={item.chatThreadId === selectedThread?.chatThreadId && !isMobile}
                      disableRipple
                    >
                      <StyledListItemAvatar>
                        <StyledAvatar userId={item.otherUserId} displayName={item?.displayName} noLink />
                      </StyledListItemAvatar>
                      <ListItemText
                        primary={
                          <JustifiedBox>
                            <EllipsisWrapperDiv>
                              <EllipsisTypography variant={isMobile ? 'body1' : 'body2'} title={item.displayName}>
                                {item.displayName}
                              </EllipsisTypography>
                            </EllipsisWrapperDiv>
                            <StyledBadge color="primary" badgeContent={item.numberOfItemsUnread}>
                              <BadgeBox></BadgeBox>
                            </StyledBadge>
                          </JustifiedBox>
                        }
                      />
                      <StyledCloseIconButton
                        className="hidden-close"
                        size="small"
                        sx={{ display: isMobile ? undefined : 'none' }}
                        onClick={() => {
                          setConfirmDialog({
                            open: true,
                            isOneToOne: true,
                            chatThreadId: item.chatThreadId,
                            chatThreadName: item.displayName,
                            chatThreadType: 'dm',
                          });
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </StyledCloseIconButton>
                    </StyledListItemButton>
                  </ListItem>
                ))}
              </List>

              <StyledAddButton
                startIcon={<StyledAddTeamIcon style={{ color: theme.palette.primary.dark }} />}
                onClick={() => setStartChatDialog(true)}
                style={{ backgroundColor: 'transparent' }}
              >
                <Typography variant={isMobile ? 'body1' : 'body2'} color={theme.palette.primary.dark} paddingLeft={1.5}>
                  Start Chat
                </Typography>
              </StyledAddButton>

              <ConfirmDialog
                isConfirming={isRemovingThread}
                open={confirmDialog.open}
                setOpen={setConfirmDialogOpen}
                onConfirm={() => {
                  leaveChat(confirmDialog.chatThreadId, confirmDialog.chatThreadType);
                  setConfirmDialogOpen(false);
                }}
                title={`${
                  confirmDialog.isOneToOne ? 'Delete chat' : confirmDialog.isChatOwner ? 'Delete chat' : 'Leave chat'
                } '${confirmDialog.chatThreadName}'`}
                content={
                  confirmDialog.isOneToOne
                    ? 'Are you sure you want to remove this chat?'
                    : confirmDialog.isChatOwner
                    ? 'Are you sure you want to delete this entire thread? Contracts will still be visible in Contract History'
                    : 'Are you sure you want to leave this chat? Contracts will still be visible in Contract History'
                }
                onCancel={() => setConfirmDialogOpen(false)}
              />
            </LeftSection>
          )}
          {(!isMobile || view === WorkRoomView.Middle) && (
            <Grid
              item
              xs={12}
              md={isRightSectionCollapsed ? 9 : 5}
              lg={isRightSectionCollapsed ? 10 : 6.5}
              sx={{
                height: '100%',
                paddingRight: !isMobile && isRightSectionCollapsed && needsPadding ? '24px' : '0px',
              }}
            >
              <MessagesSection
                selectedThread={selectedThread}
                handleLeftClick={handleLeftClick}
                handleRightClick={handleRightClick}
                handleRightCollapseClick={handleRightCollapseClick}
                isRightSectionCollapsed={isRightSectionCollapsed}
                teams={teamThreads}
                isTyping={isTyping}
                setIsTyping={setIsTyping}
                hasActiveSellingContract={hasActiveSellingContract}
                onCreateContractButtonClick={setOpenCreateContractDialog}
                isCreatingContract={openCreateContractDialog}
                haveContracts={!!contracts.length}
                addMemberDialogOpen={isAddMemberDialogOpen}
                setAddMemberDialogOpen={setIsAddMemberDialogOpen}
                setSelectedThread={setSelectedThread}
                contracts={contracts}
              />
            </Grid>
          )}
          {((!isMobile && !isRightSectionCollapsed) || view === WorkRoomView.Right) && (
            <Grid item xs={12} md={4} lg={3.5} pl={{ xs: 1, md: 1.5 }} pr={isMobile ? 1 : 0}>
              <RightMenu
                selectedThread={selectedThread}
                user={authContext.user}
                handleMiddleClick={handleMiddleClick}
                addMemberDialogOpen={isAddMemberDialogOpen}
                setAddMemberDialogOpen={setIsAddMemberDialogOpen}
                setSelectedThread={setSelectedThread}
                contracts={contracts}
                onCreateContractButtonClick={setOpenCreateContractDialog}
                isCreatingContract={openCreateContractDialog}
              />
            </Grid>
          )}
        </StyledGrid>
      </StyledContainer>

      <AddTeamDialog
        open={addTeamDialog}
        setOpen={setAddTeamDialog}
        setThreads={setThreads}
        setSelectedThread={setSelectedThread}
      />

      <StartChatDialog open={startChatDialog} setOpen={setStartChatDialog} setSelectedThread={setSelectedThread} />

      {selectedThread && (
        <CreateContractDialog
          setOpenDialog={setOpenCreateContractDialog}
          fullWidth={isMobile}
          selectedThread={selectedThread}
          open={openCreateContractDialog}
          returnUrl={`workroom/${selectedThread?.chatThreadId}`}
        />
      )}
    </section>
  );
}

export default WorkRoom;
