import { IApprovalConfirmation } from 'global/interfaces/approvalConfirmation';
import { SchemaOf, boolean, object } from 'yup';

const submitValidationApprovalText = 'This must be confirmed';

const createBooleanField = () => boolean().required().oneOf([true], submitValidationApprovalText);

export const approvalConfirmationValidation: SchemaOf<IApprovalConfirmation> = object({
  isMediaAccepted: createBooleanField(),
  isProfileAccepted: createBooleanField(),
});
