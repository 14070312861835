import { Dialog, FormControlLabel, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { StyledCheckbox } from 'components/common/form/FormTermsAndConditions';
import { useFormik } from 'formik';
import { IApprovalConfirmation } from 'global/interfaces/approvalConfirmation';
import { approvalConfirmationValidation } from 'global/validations/approvalConfirmation';
import { Link } from 'react-router-dom';
import { nameof } from 'ts-simple-nameof';

interface IConfirmSubmitApprovalProps {
  onClose: () => void;
  open: boolean;
  handleConfirm: () => void;
  error?: string | boolean;
  touched?: boolean;
  name?: string;
}

const StyledFormHelperText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  marginTop: '-10px',
}));

export default function ConfirmSubmitApprovalDialog({ open, onClose, handleConfirm }: IConfirmSubmitApprovalProps) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  const form = useFormik<IApprovalConfirmation>({
    initialValues: {
      isMediaAccepted: false,

      isProfileAccepted: false,
    },
    validationSchema: approvalConfirmationValidation,
    onSubmit: () => {
      handleConfirm();
    },
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      fullScreen={isMobileScreen}
    >
      <form onSubmit={form.handleSubmit}>
        <StyledDialogTitle>
          <Typography variant="h6" color="grey.900">
            Does everything look good?
          </Typography>
        </StyledDialogTitle>
        <StyledDialogContent dividers>
          <Typography variant="body1">
            Your application is about to be submitted to our moderators. Please confirm the following. If in doubt,
            review our{' '}
            <Link to={process.env.REACT_APP_MODERATION_URL ?? ''} target="_blank">
              policy.
            </Link>
          </Typography>
          <FormControlLabel
            control={
              <StyledCheckbox
                onChange={form.handleChange}
                name={nameof<IApprovalConfirmation>(x => x.isMediaAccepted)}
                checked={form.values.isMediaAccepted}
              />
            }
            label={
              <Typography variant="body2">
                I&apos;ve curated a comprehensive portfolio to showcase my unique style and capabilities.
              </Typography>
            }
          />
          {form.touched.isMediaAccepted !== undefined && Boolean(form.errors.isMediaAccepted) && (
            <StyledFormHelperText variant="caption">{form.errors.isMediaAccepted}</StyledFormHelperText>
          )}
          <FormControlLabel
            control={
              <StyledCheckbox
                onChange={form.handleChange}
                name={nameof<IApprovalConfirmation>(x => x.isProfileAccepted)}
                checked={form.values.isProfileAccepted}
              />
            }
            label={
              <Typography variant="body2">My profile is well written and free of spelling / grammar errors</Typography>
            }
          />
          {form.touched.isProfileAccepted !== undefined && Boolean(form.errors.isProfileAccepted) && (
            <StyledFormHelperText variant="caption">{form.errors.isProfileAccepted}</StyledFormHelperText>
          )}
        </StyledDialogContent>
        <StyledDialogActions>
          <RoundButton variant="outlined" fullWidth={isMobileScreen} onClick={onClose}>
            Continue Editing
          </RoundButton>
          <RoundButton variant="contained" fullWidth={isMobileScreen} type="submit">
            Submit Now
          </RoundButton>
        </StyledDialogActions>
      </form>
    </Dialog>
  );
}
