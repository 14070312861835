import { IChatFileDisplay, IChatMessageDisplay } from 'global/interfaces/chatMessage';
import { ImageList, useMediaQuery, useTheme } from '@mui/material';
import ChatMessageMediaItem from './ChatMessageMediaItem';
import { sortChatMediaFiles } from './chatMediaUtils';
import { FileType } from 'global/enums/fileTypes';
import FileViewerCarouselDialog from 'components/common/FileViewer/FileViewerCarouselDialog';
import { useCallback, useState } from 'react';
import { ICarouselFile } from 'components/common/FileViewer/ICarouselFile';

interface IChatMessageMediaProps extends IChatMessageDisplay {
  isRightSectionCollapsed: boolean;
}

export default function ChatMessageMedia({ sending, isRightSectionCollapsed, files }: IChatMessageMediaProps) {
  const [activeCarouselSlide, setActiveCarouselSlide] = useState<number>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSm = useMediaQuery(theme.breakpoints.up(650), { noSsr: true });
  const matchesLg = useMediaQuery(theme.breakpoints.up(1200), { noSsr: true });
  const matchesXl = useMediaQuery(theme.breakpoints.up('xl'), { noSsr: true });
  let numCols = matchesXl ? 3 : matchesLg ? 2 : 1;
  if (isRightSectionCollapsed) {
    numCols = matchesXl ? 4 : matchesLg ? 3 : matchesSm ? 2 : 1;
  }
  const gap = 10;

  const openInNewTab = (url?: string) => {
    window.open(`${process.env.REACT_APP_CDN_URL ?? ''}${url}`, '_blank');
  };

  const displayFiles: IChatFileDisplay[] = files.map(file => ({ ...file, loading: sending }));
  const sortedFiles = sortChatMediaFiles(displayFiles);

  const modalShownFiles: ICarouselFile[] = sortedFiles
    .filter(x => x.canPlay && (x.fileType == FileType.Image || (x.fileType == FileType.Pdf && !isMobile)))
    .map(x => ({
      name: `${x.fileName}${x.extension}`,
      type: x.fileType,
      url: `${process.env.REACT_APP_CDN_URL ?? ''}${x.url}`,
      isLoading: !!sending,
    }));

  const closeModal = useCallback(() => setActiveCarouselSlide(undefined), []);

  return (
    <>
      <ImageList cols={numCols} gap={gap}>
        {sortedFiles?.map((file, index) => (
          <ChatMessageMediaItem
            key={file.fileName + file.extension}
            onClick={
              file.fileType == FileType.Pdf && isMobile
                ? () => openInNewTab(file.url)
                : () => setActiveCarouselSlide(index)
            }
            numCols={numCols}
            chatFile={file}
          />
        ))}
      </ImageList>
      {modalShownFiles.length > 0 && (
        <FileViewerCarouselDialog files={modalShownFiles} openingSlide={activeCarouselSlide} onClose={closeModal} />
      )}
    </>
  );
}
