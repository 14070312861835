import { ContractType, ContractViewType, ICreateContractForm, RecurrenceType } from 'global/interfaces/contract';
import { validateContractName } from 'services/contractService';
import { array, boolean, mixed, number, object, SchemaOf, string } from 'yup';

export const createContractValidation: SchemaOf<ICreateContractForm> = object({
  name: string()
    .required('Contract Title is required')
    .max(20, 'Maximum of 20 characters allowed')
    .test(
      'contract-name-backend-validation', // Name
      'You already have an active contract with this title', // Msg
      async name => {
        // Res from backend will be flag. True means name is good otherwise it's false
        if (name === undefined) return false;

        const isValid = await validateContractName(name);
        return isValid;
      },
    ),
  contractType: mixed<ContractType>().required('Contract Type is required'),
  buySell: mixed<ContractViewType>().required('You must select whether you are the buyer or seller'),
  deliverables: array().when('contractType', {
    is: ContractType.FixedPrice,
    then: array()
      .min(1, 'Min of 1')
      .max(30, 'Max of 30')
      .test('deliverablesNotEmpty', 'Deliverable text must not be empty', item => {
        let valid = false;
        /* eslint-disable no-unmodified-loop-condition */
        for (let i = 0; item !== undefined && i < item.length; i++) {
          valid = item[i] !== undefined && item[i].length > 0;
          if (!valid) {
            break;
          }
        }
        return valid;
      }),
  }),
  price: number().nullable().required('Price is required').min(10, 'The minimum contract price is $10'),
  revisions: number()
    .nullable()
    .notRequired()
    .when('contractType', {
      is: ContractType.FixedPrice,
      then: number().nullable().required('Revision is required'),
    }),
  delivery: number()
    .nullable()
    .notRequired()
    .when('contractType', {
      is: ContractType.FixedPrice,
      then: number().nullable().required('Delivery is required'),
    }),
  otherUserId: string().nullable().notRequired(),
  termsAccepted: boolean().required().oneOf([true], 'The terms and conditions must be accepted.'),
  currency: string().nullable().required(),
  recurrence: mixed<RecurrenceType>()
    .notRequired()
    .when('contractType', {
      is: ContractType.Retainer,
      then: mixed<RecurrenceType>().required('Recurrence is required'),
    }),
  duration: number()
    .nullable()
    .notRequired()
    .when('contractType', {
      is: ContractType.Retainer,
      then: number().nullable().required('Duration is required'),
    }),
  description: string()
    .nullable()
    .notRequired()
    .when('contractType', {
      is: ContractType.Retainer,
      then: string().nullable().required('Services is required').max(500, 'Max of 500 characters'),
    }),
  release: number().nullable().required('Release is required'),
});
