import { Skeleton, Stack, styled } from '@mui/material';
import React from 'react';

interface IEmptyDataProps {
  icon: string | React.ReactNode;
}

const Container = styled(Stack)(() => ({
  padding: '8px 12px',
  gap: '12px',
  alignItems: 'center',
  boxShadow: '0px 1px 2px -1px rgba(0, 0, 0, 0.10), 0px 2px 3px 0px rgba(0, 0, 0, 0.10)',
  borderRadius: '8px',
}));

export default function EmptyData({ icon }: IEmptyDataProps) {
  return (
    <Container direction="row">
      {typeof icon === 'string' ? (
        <img src={icon} alt="No Data Found" height="32px" width="32px" loading="lazy" />
      ) : (
        icon
      )}

      <Skeleton variant="text" width={'100%'} height={18} />
    </Container>
  );
}
