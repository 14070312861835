import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { emitCustomEvent } from 'react-custom-events';
import { ConnectionRestarted, MessagePublisherEventType } from 'global/enums/messagePublisherEventType';
import { refreshToken } from './authService';

const retryTimeout = 1000;

const startConnection = async (connection: HubConnection) => {
  try {
    if (connection.state !== HubConnectionState.Connecting && connection.state !== HubConnectionState.Connected)
      await connection.start();
  } catch (e) {
    const error = e as Error;
    //using string check until this is fixed: https://github.com/dotnet/aspnetcore/issues/39079
    if (error.message && error.message.includes("Status code '401'")) {
      await refreshToken();
      if (connection.state !== HubConnectionState.Connecting && connection.state !== HubConnectionState.Connected)
        await connection.start();
    } else {
      throw e;
    }
  }
};

export const restartSignalrHub = async (connection: HubConnection) => {
  if (connection.connectionId == null || connection.state == HubConnectionState.Disconnected) {
    try {
      await startConnection(connection);
      emitCustomEvent(ConnectionRestarted);
    } catch (e) {
      const error = e as Error;
      //If we get here and we can't re-authenticate then we should logout and stop the hub.
      if (error.message.includes('status code 401')) {
        //Can't refresh token so stop hub and refresh page
        location.reload();
      } else {
        setTimeout(() => restartSignalrHub(connection), retryTimeout);
      }
    }
  }
};

export const stopSignalrHub = async (connection: HubConnection) => {
  const events = Object.keys(MessagePublisherEventType).filter(v => isNaN(Number(v)));
  events.forEach(event => {
    connection.off(event);
  });
  connection.stop();
};

export const startSignalrHub = (): HubConnection => {
  const signalRUrl = `${process.env.REACT_APP_API_URL ?? ''}/SignalR`;

  const connection = new HubConnectionBuilder()
    .withUrl(signalRUrl, { accessTokenFactory: () => localStorage.getItem('accessToken') ?? '' })
    // Just try auto-reconnect twice fairly quickly so if that doesn't work then we go for a full reconnect
    .withAutomaticReconnect([250, 250])
    .build();

  const start = async () => {
    try {
      await startConnection(connection);
    } catch (e) {
      setTimeout(start, retryTimeout);
    }
  };

  const visibilitychange = () => {
    if (!document.hidden) {
      restartSignalrHub(connection);
    }
  };

  const events = Object.keys(MessagePublisherEventType).filter(v => isNaN(Number(v)));
  events.forEach(event => {
    connection.on(event, data => {
      emitCustomEvent(event, data);
    });
  });
  connection.onreconnected(() => {
    emitCustomEvent(ConnectionRestarted);
  });
  connection.onclose(async () => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      //connection lost when user is still logged in, try to restart it
      restartSignalrHub(connection);
    } else {
      document.removeEventListener('visibilitychange', visibilitychange);
    }
  });
  document.addEventListener('visibilitychange', visibilitychange);

  start();
  return connection;
};
