import { Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { useState } from 'react';
import { withdrawConnectionRequest } from 'services/connectionService';
import { showError } from 'utils/errorHandler';

export const WithdrawButton: React.FC<{ connectionId: string; setRefresh: React.Dispatch<boolean> }> = ({
  connectionId,
  setRefresh,
}) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallLaptopScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const [loading, setLoading] = useState<boolean>(false);

  const handleWithdraw = (id: string) => {
    setLoading(true);
    withdrawConnectionRequest(id)
      .then(() => {
        setLoading(false);
        setRefresh(true);
      })
      .catch(() => {
        setLoading(false);
        showError;
      });
  };

  return (
    <Stack
      direction={isMobileScreen ? 'column' : 'row'}
      gap={1}
      width="100%"
      justifyContent={isSmallLaptopScreen ? 'center' : 'flex-start'}
      flexWrap={isSmallLaptopScreen ? 'wrap' : 'nowrap'}
    >
      <Grid item xs={12}>
        <RoundButton
          color="primary"
          variant="outlined"
          fullWidth={isMobileScreen}
          onClick={() => handleWithdraw(connectionId)}
          loading={loading}
        >
          Withdraw
        </RoundButton>
      </Grid>
    </Stack>
  );
};
