export function isTouchScreen() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}

export function isiOS() {
  return ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
    navigator.platform,
  );
}

export function isStableShareDevice() {
  return (
    navigator.share !== undefined &&
    (/iPad|iPhone|iPod/.test(navigator.userAgent) ||
      /Android/.test(navigator.userAgent) ||
      /Windows/.test(navigator.userAgent))
  );
}
