import { ThumbnailExtension } from 'global/constants';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { IconButton, styled, useMediaQuery, useTheme } from '@mui/material';
import PortfolioImage from './PortfolioImage';

const CenterDiv = styled('div')(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

interface IPortfolioVideoProps {
  extension: string;
  id: string;
  directory: string;
  fileName: string;
}

export default function PortfolioVideo(props: IPortfolioVideoProps): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  return (
    <>
      <PortfolioImage
        id={props.id}
        directory={props.directory}
        extension={ThumbnailExtension}
        fileName={props.fileName}
      />
      <CenterDiv>
        <IconButton>
          <PlayArrowIcon style={{ fontSize: isMobile ? '40px' : '60px' }} />
        </IconButton>
      </CenterDiv>
    </>
  );
}
