import { IconButton, styled } from '@mui/material';
import { SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';
import IconClose from '@mui/icons-material/Close';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.common.white,
}));

function SnackbarCloseButton({ snackbarKey }: { snackbarKey: SnackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <StyledIconButton onClick={() => closeSnackbar(snackbarKey)}>
      <IconClose />
    </StyledIconButton>
  );
}
export default function GlobalSnackbarProvider() {
  return <SnackbarProvider action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />} />;
}
