import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Autocomplete, css, IconButton, styled, useMediaQuery, useTheme } from '@mui/material';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import { nameof } from 'ts-simple-nameof';
import { Search } from '@mui/icons-material';
import { getSkills } from 'services/skillService';
import { ISkill } from 'global/interfaces/skill';
import { debounce } from 'lodash';

interface SearchFormProps {
  search: string;
}

const StyledTextField = styled(TextField)(
  ({ theme }) =>
    css`
      margin-right: ${theme.spacing(1)};
      & .MuiInputBase-input {
        padding: ${theme.spacing(0.75)} 0 ${theme.spacing(0.75)} 14px;
        height: 20px;
        box-sizing: border-box;
        font-size: 14px;
      }
      & .MuiOutlinedInput-root {
        border-radius: 50px;
        padding-right: 0;
      }
      & .MuiInputAdornment-root {
        cursor: pointer;
      }
    `,
);

const SearchIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,

  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const UserSearch = () => {
  const navigate = useNavigate();
  const { updateScrollY } = useContext(GlobalStateContext);
  const [searchParams] = useSearchParams();
  const searchValueInParams = searchParams.get(nameof<SearchFormProps>(p => p.search)) ?? '';
  const [open, setOpen] = useState(false);
  const [skills, setSkills] = useState<ISkill[]>([]);
  const [selectedInput, setSelectedInput] = useState<NonNullable<string | ISkill>>();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const form = useFormik<SearchFormProps>({
    initialValues: {
      search: '',
    },
    onSubmit: (values: SearchFormProps): void => {
      if (values.search.trim() === '' || values.search != searchValueInParams) {
        updateScrollY(0);
        const queryParams = values.search ? new URLSearchParams({ search: values.search }).toString() : '';
        setOpen(false);
        navigate(`/users?${queryParams}`);
      }
    },
    validationSchema: object({
      search: string().notRequired(),
    }),
  });

  useEffect(() => {
    form.setFieldValue(
      nameof<SearchFormProps>(p => p.search),
      searchValueInParams,
    );
    debouncedCallback.cancel();
  }, [searchValueInParams]);

  const loadSkills = () => {
    if (form.values.search === '') {
      setOpen(false);
      return;
    }

    getSkills({
      pageSize: 5,
      search: form.values.search,
    }).then(res => {
      setSkills(res);
      setOpen(true);
    });
  };

  const ref = useRef(loadSkills);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current?.();
    };
    return debounce(func, 500);
  }, []);

  const handleChange = (e: React.SyntheticEvent<Element, Event>, newInputValue: string) => {
    if (newInputValue === searchValueInParams && newInputValue !== '') {
      setOpen(false);
      return;
    }

    form.setFieldValue(
      nameof<SearchFormProps>(x => x.search),
      newInputValue,
    );

    if (newInputValue === '') {
      setOpen(false);
      return;
    }

    debouncedCallback();
  };

  useEffect(() => {
    ref.current = loadSkills;
  }, [form.values.search]);

  useEffect(() => {
    if (selectedInput && typeof selectedInput !== 'string' && open) {
      if (selectedInput.value === form.values.search) {
        setOpen(false);
        form.submitForm();
      }
    }
  }, [selectedInput, open]);

  return (
    <form onSubmit={form.handleSubmit}>
      <Autocomplete
        freeSolo
        disableClearable
        open={open}
        onClose={() => setOpen(false)}
        getOptionLabel={option => (typeof option === 'string' ? option : option.value)}
        options={skills}
        onChange={(e, option) => setSelectedInput(option)}
        onInputChange={handleChange}
        value={form.values.search ?? ''}
        renderInput={params =>
          !isMobile ? (
            <StyledTextField
              {...params}
              id="search"
              variant="outlined"
              placeholder="Find talent"
              size="small"
              value={form.values.search}
              InputProps={{
                ref: params.InputProps.ref,
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIconButton type="submit" size="small">
                      <SearchIcon />
                    </SearchIconButton>
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <TextField
              {...params}
              id="search"
              placeholder="Find talent"
              fullWidth
              size="small"
              variant="outlined"
              value={form.values.search}
              InputProps={{
                ref: params.InputProps.ref,
                autoComplete: 'off',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton type="submit" size="small">
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )
        }
      />
    </form>
  );
};

export default UserSearch;
