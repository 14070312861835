import { useCallback, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Carousel from 'react-material-ui-carousel';
import CarouselSlide from './CarouselSlide';
import { ICarouselFile } from './ICarouselFile';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import { hideFeedbackWidget, showFeedbackWidget } from 'utils/feedbackWidget';

const StyledDialog = styled(Dialog)(() => ({
  '.MuiDialog-paper': {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
  },
}));

interface IFileViewerDialogProps {
  files: ICarouselFile[];
  openingSlide?: number;
  onClose: () => void;
}

export default function FileViewerCarouselDialog({
  onClose,
  openingSlide,
  files,
}: IFileViewerDialogProps): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [activeSlide, setActiveSlide] = useState<number>();

  useEffect(() => {
    if (openingSlide !== undefined) {
      setActiveSlide(openingSlide);
      hideFeedbackWidget();
    }
  }, [openingSlide]);

  const handleClose = useCallback(() => {
    showFeedbackWidget();
    onClose();
  }, [onClose]);

  const handleOnChange = useCallback((now?: number) => {
    if (now) {
      setActiveSlide(now);
    }
  }, []);

  return (
    <StyledDialog
      maxWidth="lg"
      open={openingSlide !== undefined}
      onClose={handleClose}
      fullWidth
      slotProps={{ backdrop: { invisible: true } }}
      fullScreen={isMobile}
    >
      <Carousel
        navButtonsProps={{
          style: {
            color: 'rgba(0, 0, 0, 0.54)',
            backgroundColor: '#fff',
            boxShadow: '0 3px 9px rgba(0,0,0,0.3)',
          },
        }}
        navButtonsAlwaysVisible={true}
        navButtonsAlwaysInvisible={files.length == 1}
        fullHeightHover={false}
        autoPlay={false}
        sx={{
          textAlign: 'center',
          height: {
            xs: '100vh',
            md: '80vh',
            lg: '90vh',
          },
        }}
        height="100%"
        index={openingSlide ?? 0}
        IndicatorIcon={false}
        onChange={handleOnChange}
        swipe={false}
      >
        {files.map((item, i) => (
          <CarouselSlide key={i} isActive={i == activeSlide} {...item} onClose={handleClose} />
        ))}
      </Carousel>
    </StyledDialog>
  );
}
