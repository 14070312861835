import styled from '@emotion/styled';

const StyledVideo = styled('video')(() => ({
  maxHeight: '100%',
  maxWidth: '100%',
  width: 'auto',
  height: 'auto',
}));

interface BlobImageProps {
  url: string;
}

export default function BlobVideo({ url }: BlobImageProps): JSX.Element {
  return <StyledVideo controls src={url} controlsList="nodownload" />;
}
