import {
  Chip,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import { getLimitedWords } from 'utils/text';
import { QuoteOptionButtons } from '../viewContracts/QuoteOptionButtons';
import { PackageType } from 'global/enums/packageType';
import { ConnectionDirection } from 'global/enums/connectionDirection';
import UserBasicInfo from './UserBasicInfo';
import { ConnectionFirstContact } from 'global/enums/connectionFirstContact';
import { IConnection } from 'global/interfaces/connection';
import { StyledTableHead } from './viewNetwork';
import { ConnectionStatus } from 'global/enums/connectionStatus';
import { WithdrawButton } from 'components/viewContracts/withdrawButton';

const StyledStatusIdicator = styled(Chip)(() => ({
  padding: '0px 8px',
  borderRadius: '64px',
}));

const StyledContentTitleGrid = styled(Grid)(() => ({
  marginBottom: '16px',
}));

const StyledQuoteContentGrid = styled(Grid)(() => ({
  marginBottom: '16px',
}));

interface ViewContractQuoteProps {
  invites: IConnection[] | undefined;
  setRefresh: React.Dispatch<boolean>;
  isRecieved?: boolean;
}

type HeaderDataType = TableCellProps & { [key: string]: string };

export const ViewInvites = ({ invites, setRefresh, isRecieved = false }: ViewContractQuoteProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      {isMobileScreen ? (
        <MobileQuotesistoryView invites={invites} setRefresh={setRefresh} isRecieved={isRecieved} />
      ) : (
        <DesktopView invites={invites} setRefresh={setRefresh} isRecieved={isRecieved} />
      )}
    </>
  );
};

const DesktopView = ({ invites, setRefresh, isRecieved }: ViewContractQuoteProps) => {
  const theme = useTheme();
  const isSmallLaptopScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));

  const HeaderData: HeaderDataType[] = [
    { id: 'freelancer', label: 'Name', align: 'left' },
    { id: 'status', label: 'Status', align: 'left' },
    { id: 'description', label: 'Description', align: 'left' },
    { id: 'package', label: 'Enquiry', align: 'left' },
    { id: 'options', label: 'Options', align: isSmallLaptopScreen ? 'center' : 'left' },
  ];

  return (
    <>
      {invites && invites.length > 0 ? (
        <TableContainer>
          <Table>
            <StyledTableHead>
              <TableRow>
                {HeaderData.map(headerCell => {
                  if (isRecieved && headerCell.id === 'status') {
                    return;
                  }
                  return (
                    <TableCell key={headerCell.id} align={headerCell.align}>
                      <Typography variant="subtitle2"> {headerCell.label}</Typography>
                    </TableCell>
                  );
                })}
              </TableRow>
            </StyledTableHead>
            <TableBody>
              {invites?.map((conn, i) => (
                <TableRow key={`invite-${i}`}>
                  <TableCell width={200}>
                    <UserBasicInfo user={conn?.otherUser} />
                  </TableCell>
                  {!isRecieved && (
                    <TableCell width={200}>
                      <StyledStatusIdicator
                        label={conn.status === ConnectionStatus.Rejected ? 'Declined' : 'Requested'}
                        color={
                          conn.status !== ConnectionStatus.Rejected
                            ? conn.direction === ConnectionDirection.Send
                              ? 'warning'
                              : 'info'
                            : 'default'
                        }
                        size="small"
                      />
                    </TableCell>
                  )}
                  <TableCell width={isRecieved ? 605 : 805}>
                    <Typography variant="body2" color={theme.palette.grey[600]}>
                      {getLimitedWords(conn.description ?? '', 10)}
                    </Typography>
                  </TableCell>
                  <TableCell width={200}>
                    <Typography variant="body2" color={theme.palette.grey[600]}>
                      {conn.firstContact == ConnectionFirstContact.Package &&
                        conn.package != null &&
                        `Package: ${PackageType[conn.package]}`}
                      {conn.firstContact == ConnectionFirstContact.Quote && 'Hourly'}
                      {conn.firstContact == ConnectionFirstContact.Message && '-'}
                    </Typography>
                  </TableCell>
                  <TableCell width={200}>
                    {conn.direction === ConnectionDirection.Receive && conn.status !== ConnectionStatus.Rejected && (
                      <QuoteOptionButtons
                        connectionId={conn.connectionId}
                        setRefresh={setRefresh}
                        otherUserId={conn.otherUser?.userId}
                      />
                    )}
                    {conn.direction === ConnectionDirection.Send && conn.status === ConnectionStatus.Invitation && (
                      <WithdrawButton connectionId={conn.connectionId ?? ''} setRefresh={setRefresh} />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1" color={theme.palette.grey[500]}>
          {isRecieved ? 'You don’t have any invites yet.' : 'You don’t have any sent invites'}
        </Typography>
      )}
    </>
  );
};

const MobileQuotesistoryView: React.FC<ViewContractQuoteProps> = ({ invites, setRefresh, isRecieved }) => {
  const theme = useTheme();

  return (
    <Grid>
      {invites && invites.length > 0 ? (
        <Grid container spacing={5}>
          {invites?.map((conn, i) => {
            const isInvitationRecieved = conn.direction === ConnectionDirection.Receive;

            return (
              <StyledQuoteContentGrid key={`invite-${i}`} container item justifyContent="space-between">
                <StyledContentTitleGrid container xs={12} item>
                  <UserBasicInfo user={conn.otherUser} />
                </StyledContentTitleGrid>

                <Grid item mb={1} xs={6}>
                  <Typography variant="subtitle2">Enquiry</Typography>
                </Grid>

                <Grid item mb={1} xs={6} container justifyContent="flex-end">
                  <Typography variant="body2">
                    {conn.package !== undefined && conn.package !== null ? PackageType[conn.package] : 'Hourly'}
                  </Typography>
                </Grid>

                <Stack direction="row" width="100%" alignItems="center">
                  {!isInvitationRecieved && (
                    <Grid item mb={1} xs={6}>
                      <Typography variant="subtitle2">Status</Typography>
                    </Grid>
                  )}

                  {!isInvitationRecieved && (
                    <Grid item mb={1} xs={6} container justifyContent="flex-end" alignItems="center">
                      <StyledStatusIdicator
                        label={conn.status === ConnectionStatus.Rejected ? 'Declined' : 'Requested'}
                        color={
                          conn.status !== ConnectionStatus.Rejected
                            ? conn.direction === ConnectionDirection.Send
                              ? 'warning'
                              : 'info'
                            : 'default'
                        }
                        size="small"
                      />
                    </Grid>
                  )}
                </Stack>

                <Grid item xs={12}>
                  <Typography variant="subtitle2">Description</Typography>
                </Grid>

                <Grid item xs={12} mb={isInvitationRecieved ? 2 : 0}>
                  <Typography variant="body2">{getLimitedWords(conn.description ?? '-')}</Typography>
                </Grid>

                {isInvitationRecieved && (
                  <QuoteOptionButtons
                    connectionId={conn.connectionId}
                    setRefresh={() => setRefresh}
                    otherUserId={conn.otherUser?.userId}
                  />
                )}
              </StyledQuoteContentGrid>
            );
          })}
        </Grid>
      ) : (
        <Typography variant="body1" color={theme.palette.grey[500]}>
          {isRecieved ? 'You don’t have any invites yet.' : 'You don’t have any sent invites'}
        </Typography>
      )}
    </Grid>
  );
};
