import { SvgIcon, SvgIconProps } from '@mui/material';

export const BriefIcon = (props: SvgIconProps) => (
  <SvgIcon width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
    <path
      d="M16.334 7V6.2C16.334 5.0799 16.334 4.51984 16.116 4.09202C15.9243 3.71569 15.6183 3.40973 15.242 3.21799C14.8141 3 14.2541 3 13.134 3H11.534C10.4139 3 9.85383 3 9.426 3.21799C9.04968 3.40973 8.74372 3.71569 8.55197 4.09202C8.33398 4.51984 8.33398 5.0799 8.33398 6.2V7M3.3612 10.0263C3.72174 10.3719 7.6197 14 12.334 14C17.0483 14 20.9462 10.3719 21.3068 10.0263M3.3612 10.0263C3.33398 10.493 3.33398 11.0665 3.33398 11.8V16.2C3.33398 17.8802 3.33398 18.7202 3.66096 19.362C3.94858 19.9265 4.40753 20.3854 4.97201 20.673C5.61375 21 6.45383 21 8.13398 21H16.534C18.2141 21 19.0542 21 19.696 20.673C20.2604 20.3854 20.7194 19.9265 21.007 19.362C21.334 18.7202 21.334 17.8802 21.334 16.2V11.8C21.334 11.0665 21.334 10.493 21.3068 10.0263M3.3612 10.0263C3.39631 9.4241 3.47674 8.99959 3.66096 8.63803C3.94858 8.07354 4.40753 7.6146 4.97201 7.32698C5.61375 7 6.45383 7 8.13398 7H16.534C18.2141 7 19.0542 7 19.696 7.32698C20.2604 7.6146 20.7194 8.07354 21.007 8.63803C21.1912 8.99959 21.2717 9.4241 21.3068 10.0263"
      stroke={props.htmlColor ?? '#CD6F2A'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
