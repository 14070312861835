import { Card, Chip, Grid, styled, Typography, useTheme } from '@mui/material';
import { AuthContext } from 'contexts/AuthContext';
import { useContext, useEffect } from 'react';
import ActiveSubscription from './activeSubscription';
import InactiveSubscription from './inactiveSubscription';

export const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const StyledSubsciprionTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

export default function BasicPlan() {
  const authContext = useContext(AuthContext);

  useEffect(() => {
    authContext.refresh();
  }, []);

  if (authContext.user?.subscriptionActive) {
    return <ActiveSubscription />;
  }

  return <InactiveSubscription />;
}

export function SubHeader({ text, active, activeText }: { text: string; active: boolean; activeText?: string }) {
  const theme = useTheme();
  return (
    <Grid container justifyContent="space-between">
      <Typography variant="subtitle2" color="grey.500">
        {text}
      </Typography>
      <Chip
        label={
          active ? (
            <Typography variant="caption" color={theme.palette.common.white}>
              {activeText}
            </Typography>
          ) : (
            <Typography variant="caption">Inactive</Typography>
          )
        }
        size="small"
        color={active ? 'success' : undefined}
      />
    </Grid>
  );
}
