import { IEmailInvitationForm } from 'global/interfaces/emailInvitation';
import { SchemaOf, object, string } from 'yup';

export const emailInvitationValidationSchema: SchemaOf<IEmailInvitationForm> = object({
  email: string()
    .required('Email is required')
    .trim()
    .max(150, 'Maximum of 150 characters allowed')
    .email('Invalid email address'),
  message: string()
    .required('Message is required')
    .min(20, 'Minimum of 2 characters needed')
    .max(500, 'Maximum of 200 characters allowed'),
});
