import { Box, IconButton } from '@mui/material';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import { amber } from '@mui/material/colors';

export default function RatingComponent({ rating }: { rating: number | undefined }) {
  return (
    <Box sx={{ marginLeft: '-3px' }}>
      {rating
        ? [...Array(5)].map((_, i) => (
            <IconButton key={i} sx={{ padding: 0 }}>
              {rating && rating >= i + 1 ? <StarIcon sx={{ fill: amber[600] }} /> : <StarOutlineIcon />}
            </IconButton>
          ))
        : null}
    </Box>
  );
}
