import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { DetailContainer, CenteredBox } from 'components/terms/Containers';
import Template from 'components/terms/Template';
import { useTitle } from 'utils/router';
import im from 'assets/images/lps/Hero_submit_opportunity_min.webp';

interface MobileProps {
  isMobile: boolean;
  isTablet: boolean;
}

const BannerContent = ({ isMobile, isTablet }: MobileProps): JSX.Element => (
  <CenteredBox>
    <Typography variant={isTablet ? (isMobile ? 'h5' : 'h3') : 'h3'}>About Us</Typography>
  </CenteredBox>
);

export default function About(): JSX.Element {
  useTitle('About Us');
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <>
      <Template
        backgroundImage={im}
        bannerContent={<BannerContent isMobile={isMobileScreen} isTablet={isTabletScreen} />}
      >
        <DetailContainer>
          <Typography variant={isMobileScreen ? 'h6' : 'h5'} mb={3}>
            No race to the bottom. No hefty commissions. A lot less noise.
          </Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            We’re a small team of engineers and marketers from the UK that wanted to do something better as a result of
            our own experiences and frustrations.
          </Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            We help freelancers and businesses by simplifying the way they collaborate, encouraging strong relationships
            to be built, rather than just pushing ‘gigs’.
          </Typography>

          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            Everything is vetted — both talent and opportunities — so less time is wasted and the needle moves faster.
            Everything is designed with quality and simplicity in mind.
          </Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            Our payment and contract tools encourage clarity and help to avoid headaches down the road. And when you
            want to bypass the platform and work directly, you can.
          </Typography>
          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            We’re always adding new features to help you work smarter, with AI tools in the works to help both
            businesses and freelancers grow faster.
          </Typography>

          <Typography variant={isMobileScreen ? 'body2' : 'body1'}>
            With a free option and premium features available for an affordable monthly fee, Shoutt makes quality work
            happen without the fuss.
          </Typography>
        </DetailContainer>
      </Template>
    </>
  );
}
