import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import React, { useEffect, useState } from 'react';
import { getPayout } from 'services/payoutService';
import { IStripeConnect } from 'global/interfaces/payout';
import { CircularProgress, styled, useMediaQuery, useTheme } from '@mui/material';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { showError } from 'utils/errorHandler';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import { useTitle } from 'utils/router';
import { PayoutHandler } from 'components/payouts/payoutHandler';
import { useSearchParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { saveBusinessIcon, saveLogo } from 'services/storageService';
import IApiError from 'global/interfaces/api';
import { BrandingImageUploader } from 'components/payouts/brandingImageUploader';
import useProgressBar from 'global/hooks/useProgressBar';
import CancelIcon from '@mui/icons-material/Cancel';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[200]}`,
  backgroundColor: theme.palette.common.white,
  padding: '16px',
  borderRadius: '12px',
  maxWidth: '400px',

  [theme.breakpoints.down('sm')]: {
    padding: '8px',
  },
}));

const StyledListItemAvatar = styled(ListItemAvatar)(({ theme }) => ({
  minWidth: theme.spacing(7),

  [theme.breakpoints.down('sm')]: {
    minWidth: theme.spacing(5.5),
  },
}));

export const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary[100],
  color: theme.palette.primary.main,
  width: '48px',
  height: '48px',
  marginRight: '12px',
  [theme.breakpoints.down('sm')]: {
    height: '40px',
    width: '40px',
  },
}));

export const StyledListItemText = styled(ListItemText)(() => ({
  margin: 0,
}));

const StyledBankDetailsList = styled(List)(() => ({
  display: 'flex',
}));

const successRedirect = 'success';

function PayoutDetails(): JSX.Element {
  useTitle('Payout Details / Branding');

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchParams] = useSearchParams();
  const [progress, showProgress] = useProgressBar();
  const [loaded, setLoaded] = useState<boolean>(false);

  const [stripeAccount, setStripeAccount] = React.useState<IStripeConnect>({
    ordersEnabled: false,
    payoutsEnabled: false,
    detailsSubmitted: false,
    moreInformationRequired: false,
    payoutMethods: [],
  });

  const retrivePayoutAndUpdateUI = (): void => {
    getPayout()
      .then((res: IStripeConnect) => {
        showProgress(false);
        setLoaded(true);
        setStripeAccount(res);
      })
      .catch(showError);
  };

  const handleLogoChange = (file: File): void => {
    if (file !== null) {
      showProgress(true);
      saveLogo(file)
        .then(() => {
          retrivePayoutAndUpdateUI();
        })
        .catch((err: IApiError) => {
          showError(err);
        });
    }
  };

  const handleBusinessIconChange = (file: File): void => {
    if (file !== null) {
      showProgress(true);
      saveBusinessIcon(file)
        .then(() => {
          retrivePayoutAndUpdateUI();
        })
        .catch((err: IApiError) => {
          showError(err);
        });
    }
  };

  useEffect(() => {
    retrivePayoutAndUpdateUI();
  }, []);

  return (
    <React.Fragment>
      <Container maxWidth={searchParams.has(successRedirect) ? 'sm' : 'md'}>
        <Box mt={isMobileScreen ? '32px' : '72px'}>
          <Typography variant={isMobileScreen ? 'h5' : 'h4'} mb={4}>
            {searchParams.has(successRedirect) ? 'Payout Details Updated' : 'Payout Details / Branding'}
          </Typography>
          {!searchParams.has(successRedirect) && (
            <Typography variant="h6" mt={2}>
              Payout Details
            </Typography>
          )}
          {stripeAccount.detailsSubmitted && (
            <Typography variant={!searchParams.has(successRedirect) ? 'body2' : 'body1'} color="grey.600" mt={1}>
              {stripeAccount.payoutsEnabled
                ? 'Payouts are enabled on your account. You can now create contracts as a seller.'
                : 'Payouts are disabled on your account. Please update your payout details.'}
            </Typography>
          )}

          <Grid container mt={'24px'}>
            {!stripeAccount.detailsSubmitted && loaded && (
              <Grid item container xs={12}>
                <PayoutHandler btnText="Add Payout Details" />
              </Grid>
            )}
            {!loaded && <CircularProgress />}

            {stripeAccount.detailsSubmitted && (
              <React.Fragment>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <StyledBankDetailsList>
                      {stripeAccount.payoutMethods.map((method, index) => (
                        <StyledListItem key={index}>
                          <StyledListItemAvatar>
                            <StyledAvatar
                              sx={
                                stripeAccount.payoutsEnabled
                                  ? {
                                      backgroundColor: theme.palette.success.light,
                                      color: theme.palette.success.main,
                                    }
                                  : { backgroundColor: theme.palette.error.light, color: theme.palette.error.main }
                              }
                            >
                              {stripeAccount.payoutsEnabled ? <CheckCircleIcon /> : <CancelIcon />}
                            </StyledAvatar>
                          </StyledListItemAvatar>
                          <StyledListItemText
                            primary={<Typography variant="subtitle1">{method.name}</Typography>}
                            secondary={
                              <Typography color="grey.600" variant="caption">
                                {'****' + method.last4}{' '}
                              </Typography>
                            }
                          />
                        </StyledListItem>
                      ))}
                    </StyledBankDetailsList>
                  </Grid>
                  {(!searchParams.has(successRedirect) || (!stripeAccount.payoutsEnabled && loaded)) && (
                    <Grid item container xs={12} mt={4}>
                      <PayoutHandler btnText="Update Payout Details" />
                    </Grid>
                  )}
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Box>

        {stripeAccount.detailsSubmitted && loaded && !searchParams.has(successRedirect) && (
          <>
            <Stack mt={isMobileScreen ? '32px' : '48px'} gap={0.5} pb="24px">
              <Typography variant="h6">Logo</Typography>
              <Typography variant="body2">
                Optional: The full sized version of your logo. This will be used in invoices and email receipts.
              </Typography>
              <BrandingImageUploader url={stripeAccount.logoUrl} handleFileChange={handleLogoChange} />
            </Stack>
            <Stack gap={0.5} pb="56px" mt={1}>
              <Typography variant="h6">Icon</Typography>
              <Typography variant="body2">
                Optional: A smaller representation of your logo (like a favicon) to be used in the invoice payment page.
                Must be square and at least 128px x 128px. This should not contain the company name.
              </Typography>
              <BrandingImageUploader url={stripeAccount.iconUrl} handleFileChange={handleBusinessIconChange} />
            </Stack>
          </>
        )}
        {!stripeAccount.detailsSubmitted && loaded && !searchParams.has(successRedirect) && (
          <>
            <Stack mt={isMobileScreen ? '32px' : '48px'} gap={0.5} pb="24px">
              <Typography variant="h6">Logo</Typography>
              <Typography variant="body2">
                You will be able to upload your business logo and icon after you have added your payout details.
              </Typography>
            </Stack>
          </>
        )}
      </Container>
      {progress}
    </React.Fragment>
  );
}

export default PayoutDetails;
