import { StyledPaper } from './PortfolioFilesStyles';
import MediaProgress from '../MediaProgress';

interface IPortfolioMediaProgressProps {
  message: string;
}

export default function PortfolioMediaProgress(props: IPortfolioMediaProgressProps) {
  return (
    <StyledPaper>
      <MediaProgress message={props.message} fullWidth />
    </StyledPaper>
  );
}
