import { Grid, Typography } from '@mui/material';

export default function OppNotFound() {
  return (
    <Grid item xs={12} md={6} mt={4}>
      <Typography textAlign="center" variant="h5">
        Opportunity Not Found
      </Typography>
      <Typography textAlign="center" variant="body1" pt={2}>
        We could not find this opportunity. It may have been expired or removed.
      </Typography>
    </Grid>
  );
}
