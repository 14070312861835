import {
  Box,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Chip,
  ListSubheader,
  styled,
} from '@mui/material';
import { IDeliverableForApproval } from 'global/interfaces/contract';
import { useEffect, useRef, useState } from 'react';

const StyledListItemText = styled(ListItemText)`
  overflow: hidden;
  & .MuiTypography-root {
    overflow-wrap: anywhere;
    text-wrap: wrap;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  align-items: flex-start;
`;

const StyledCheckbox = styled(Checkbox)`
  padding-top: 3px;
`;

interface IDeliverablesSelectProps {
  onChange: (selectedDeliverables: IDeliverableForApproval[]) => void;
  deliverables: IDeliverableForApproval[];
}

export default function DeliverablesSelect({ deliverables, onChange }: IDeliverablesSelectProps) {
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputWidth, setInputWidth] = useState(0);

  useEffect(() => {
    if (inputRef.current) {
      setInputWidth(inputRef.current.offsetWidth);
    }
  }, []);

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value },
    } = event;

    const selectedDeliverablesForApproval: IDeliverableForApproval[] = [];

    if (typeof value === 'string') {
      setSelectedIndexes([]);
    } else {
      setSelectedIndexes(value);
      for (const index of value) {
        selectedDeliverablesForApproval.push(deliverables[index]);
      }
    }

    onChange(selectedDeliverablesForApproval);
  };

  if (deliverables.length == 0) {
    return <></>;
  }

  return (
    <FormControl sx={{ mr: 1, mt: 1 }} fullWidth>
      <Select
        multiple
        size="small"
        value={selectedIndexes}
        onChange={handleChange}
        input={<OutlinedInput ref={inputRef} />}
        renderValue={selected => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map(value => (
              <Chip key={value} label={deliverables[value].contractName + ': ' + deliverables[value].name} />
            ))}
          </Box>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              // Set dropdown max width to the width of the input
              maxWidth: inputWidth,
            },
          },
        }}
      >
        {deliverables.map((item, index) => {
          const MenuItemComponent = (
            <StyledMenuItem key={index} value={index}>
              <StyledCheckbox checked={selectedIndexes.indexOf(index) >= 0} />
              <StyledListItemText primary={item.name} />
            </StyledMenuItem>
          );

          if (item.first) {
            return [<ListSubheader key={index + 'head'}>{item.contractName}</ListSubheader>, MenuItemComponent];
          }
          return [MenuItemComponent];
        })}
      </Select>
    </FormControl>
  );
}
