import { Tab, Tabs, styled } from '@mui/material';
import { StyledTabProps, StyledTabsProps } from './TabStyles';

export const PillStyledTabs = styled((props: StyledTabsProps) => (
  <>{props.value !== -1 ? <Tabs {...props} /> : <></>}</>
))(({ theme }) => ({
  backgroundColor: theme.palette.blue['200'],

  borderRadius: '16px',
  border: '1px solid',
  borderColor: theme.palette.grey[200],
  minHeight: '44px',

  '.MuiTabs-flexContainer': {
    gap: '8px',
    borderRadius: '12px',
    padding: '6px',
  },

  '.MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },

  '.Mui-selected': {
    backgroundColor: theme.palette.common.white,
    minHeight: '44px',
  },

  '.MuiTabScrollButton-root.Mui-disabled': {
    opacity: 0.3,
  },

  [theme.breakpoints.down('md')]: {
    maxHeight: '44px',
    '.MuiTabs-flexContainer': {
      gap: '4px',
      borderRadius: '12px',
      padding: '3px',
      height: '44px',
      boxSizing: 'border-box',
    },

    '.MuiTab-root': {
      minHeight: 30,
      height: 36,
    },

    '.Mui-selected': {
      backgroundColor: theme.palette.common.white,
      minHeight: '30px',
      height: '36px',
    },
  },
}));

export const PillStyledTab = styled((props: StyledTabProps) => <Tab {...props} />)(({ theme }) => ({
  textTransform: 'none',
  padding: '10px 14px',
  borderRadius: '12px',
  color: theme.palette.grey[500],
}));
