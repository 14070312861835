import { Box, Grid, IconButton, styled, Typography, useTheme } from '@mui/material';
import { IFrontUserBase } from 'global/interfaces/user';
import useMediaQuery from '@mui/material/useMediaQuery';
import VerticalUserCard from './VerticalUserCard';
import { StyledCarousel } from 'components/common/Carousel/StyledCarousel';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface IUserSectionProps {
  users: IFrontUserBase[];
  title: string;
  onInViewUserCard: (id: string) => void;
  muted: boolean;
  setMuted: React.Dispatch<React.SetStateAction<boolean>>;
  activeUserId: string;
  setActiveUser: (userId: string, active: boolean) => void;
}

const StyledGrid = styled(Grid)(() => ({
  marginBottom: '28px',
}));

const StyledArrowIconButton = styled(IconButton)(({ theme }) => ({
  padding: '4px',
  boxShadow: '0 3px 9px rgba(0,0,0,0.3)',
  backgroundColor: theme.palette.grey[800],

  '&:hover': {
    backgroundColor: theme.palette.grey[800],
    opacity: '0.6',
  },
}));

const RightBtnContainer = styled(Box)(() => ({
  position: 'absolute',
  right: '-20px',
  top: 'unset',
  bottom: '190px',
  zIndex: '999',
}));

const LeftBtnContainer = styled(Box)(() => ({
  position: 'absolute',
  left: '-20px',
  top: '230px',
  zIndex: '999',
}));

const itemsPerRow = 4;

const UserSection = React.memo(
  ({ title, onInViewUserCard, users, muted, setMuted, activeUserId, setActiveUser }: IUserSectionProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(itemsPerRow);
    const [hasNext, setHasNext] = useState(true);
    const [hasPrev, setHasPrev] = useState(false);
    const carouselRef = useRef<any>(null);

    const handleCarouselChanged = (nextSlide: number) => {
      setStartIndex(nextSlide);
      setEndIndex(prev => prev + itemsPerRow);
    };

    useEffect(() => {
      if (carouselRef.current) {
        const { currentSlide, totalItems } = carouselRef.current.state;
        setHasNext(currentSlide + itemsPerRow < totalItems);
        setHasPrev(currentSlide + itemsPerRow > itemsPerRow);
      }
    }, [startIndex]);

    const setActiveUserCallback = useCallback((userId: string, active: boolean) => {
      setActiveUser(userId, active);
    }, []);

    const onInViewCallback = useCallback((userId: string) => {
      onInViewUserCard(userId);
    }, []);

    return (
      <>
        <Grid item xs={12}>
          <StyledGrid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12}>
              <Typography variant="h5">{title}</Typography>
            </Grid>
          </StyledGrid>
        </Grid>
        <Box mb={isMobile ? '36px' : '48px'}>
          {hasPrev && !isMobile && (
            <Box sx={{ position: 'relative' }}>
              <LeftBtnContainer>
                <StyledArrowIconButton onClick={() => carouselRef.current?.previous()}>
                  <ArrowBackIcon sx={{ color: theme.palette.common.white }} />
                </StyledArrowIconButton>
              </LeftBtnContainer>
            </Box>
          )}

          <StyledCarousel
            innerRef={carouselRef}
            sx={{
              '&.small-poistion-react-multiple-carousel__arrow .react-multiple-carousel__arrow': {
                top: 'unset',
                bottom: isMobile ? '158px' : '139px',
              },
            }}
            className="small-react-multiple-carousel__arrow small-poistion-react-multiple-carousel__arrow"
            beforeChange={handleCarouselChanged}
            arrows={false}
            partialVisible={true}
            draggable={true}
          >
            {users.map((user, i) => {
              if (i >= startIndex - 1 && i < endIndex) {
                return (
                  <VerticalUserCard
                    key={i}
                    user={user}
                    onInView={onInViewCallback}
                    muted={muted}
                    setMuted={setMuted}
                    data-index={i}
                    isUserSection={true}
                    isActiveUser={user.id == activeUserId}
                    setActiveUser={setActiveUserCallback}
                  />
                );
              }

              return <div key={i}></div>;
            })}
          </StyledCarousel>
          {hasNext && !isMobile && (
            <Box sx={{ position: 'relative' }}>
              <RightBtnContainer>
                <StyledArrowIconButton onClick={() => carouselRef.current?.next()}>
                  <ArrowForwardIcon sx={{ color: theme.palette.grey[100] }} />
                </StyledArrowIconButton>
              </RightBtnContainer>
            </Box>
          )}
        </Box>
      </>
    );
  },
);

UserSection.displayName = 'UserSection';

export default UserSection;
