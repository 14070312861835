import { IChatDeliverable, IChatMessageDisplay } from 'global/interfaces/chatMessage';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { ContractDeliverableStatus } from 'global/interfaces/contract';

export default function ChatMessageReviewComplete(props: IChatMessageDisplay): JSX.Element {
  const numDeliverables: number = props.deliverables !== undefined ? props.deliverables.length : 0;
  const approvedDeliverables: IChatDeliverable[] =
    props.deliverables !== undefined
      ? props.deliverables.filter(p => p.status === ContractDeliverableStatus.Approved)
      : [];
  const requestedRevisionDeliverables: IChatDeliverable[] =
    props.deliverables !== undefined
      ? props.deliverables.filter(p => p.status === ContractDeliverableStatus.RevisionRequired)
      : [];
  const disputedDeliverables: IChatDeliverable[] =
    props.deliverables !== undefined
      ? props.deliverables.filter(p => p.status === ContractDeliverableStatus.Disputed)
      : [];

  const isSeller: boolean = props.userId !== props.currentUserId;
  const feedbackText =
    props.deliverables !== undefined && isSeller
      ? 'The buyer will share feedback directly in the chat below.'
      : 'Please add your feedback directly into the chat.';

  return (
    <React.Fragment>
      <Box>
        {numDeliverables === 1 &&
          props.deliverables !== undefined &&
          props.deliverables[0].status === ContractDeliverableStatus.RevisionRequired && (
            <>
              {' '}
              <Typography variant="body2" color="grey.900">
                {isSeller
                  ? 'A revision has been requested for deliverable'
                  : 'You requested a revision for deliverable'}{' '}
                {props.deliverables[0].contractName}:&nbsp;
                {props.deliverables[0].name}.{' '}
              </Typography>
              <Typography variant="caption" color="grey.500" fontStyle="italic" fontWeight={600}>
                {feedbackText}
              </Typography>
            </>
          )}
        {numDeliverables === 1 &&
          props.deliverables !== undefined &&
          props.deliverables[0].status === ContractDeliverableStatus.Approved && (
            <Typography variant="body2" color="grey.900">
              Deliverable {props.deliverables[0].contractName}:&nbsp;{props.deliverables[0].name} has been approved.
            </Typography>
          )}
        {numDeliverables === 1 &&
          props.deliverables !== undefined &&
          props.deliverables[0].status === ContractDeliverableStatus.Disputed && (
            <Typography variant="body2" color="grey.900">
              Deliverable {props.deliverables[0].contractName}:&nbsp;{props.deliverables[0].name} has been disputed.
            </Typography>
          )}
        {numDeliverables > 1 && approvedDeliverables.length > 0 && (
          <Box sx={{ mb: '6px' }}>
            <Typography variant="body2" color="grey.900">
              The following deliverables have been approved:
            </Typography>
            {approvedDeliverables.map((item: IChatDeliverable, i: number) => (
              <Typography variant="body2" key={i} color="grey.900">
                {item.contractName}:&nbsp;{item.name}
              </Typography>
            ))}
          </Box>
        )}
        {numDeliverables > 1 && requestedRevisionDeliverables.length > 0 && (
          <Box sx={{ mb: '6px' }}>
            <Typography variant="body2" color="grey.900">
              The following deliverables need a revision:
            </Typography>
            {requestedRevisionDeliverables.map((item: IChatDeliverable, i: number) => (
              <Typography variant="body2" key={i} color="grey.900">
                {item.contractName}:&nbsp;{item.name}
              </Typography>
            ))}
            <Typography variant="caption" color="grey.500" fontStyle="italic" fontWeight={600}>
              {feedbackText}
            </Typography>
          </Box>
        )}
        {numDeliverables > 1 && disputedDeliverables.length > 0 && (
          <Box sx={{ mb: '6px' }}>
            <Typography variant="body2" color="grey.900">
              The following deliverables have been disputed:
            </Typography>
            {disputedDeliverables.map((item: IChatDeliverable, i: number) => (
              <Typography variant="body2" key={i} color="grey.900">
                {item.contractName}:&nbsp;{item.name}
              </Typography>
            ))}
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
}
