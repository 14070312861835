import { ConnectionOption } from 'global/enums/contractConnectionOptions';
import { IFormSelectContractConnection } from 'global/interfaces/selectContractConnection';
import { object, string, SchemaOf, number } from 'yup';

export const selectContractConnectionValidation: SchemaOf<IFormSelectContractConnection> = object({
  option: number().nullable().required('Select a connection option'),
  selectedThreadId: string()
    .notRequired()
    .when('option', {
      is: ConnectionOption.Connect,
      then: string().nullable().required('Select a connection'),
    }),
  selectedTeamThreadId: string().required(),
});
