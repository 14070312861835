const missEntryTtl = 1000 * 60 * 5; //5min
const makeMissEntryKey = (userId: string) => `avatarMissEntry_${userId}`;
const makeAvatarUpdatedAtKey = (userId: string) => `avatarUpdatedAt_${userId}`;

export const isAvatarMissing = (userId: string) => {
  const key = makeMissEntryKey(userId);
  const entry: string | null = localStorage.getItem(key);
  if (!entry) {
    return false;
  }

  const expirationTime = parseInt(entry);
  if (new Date().getTime() > expirationTime) {
    localStorage.removeItem(key);
    return false;
  } else {
    return true;
  }
};

export const setMissingAvatar = (userId: string) => {
  const expirationTime = new Date().getTime() + missEntryTtl;
  localStorage.setItem(makeMissEntryKey(userId), expirationTime.toString());
};

export const setNonMissingAvatar = (userId: string) => {
  const key = makeMissEntryKey(userId);
  localStorage.removeItem(key);
};

export const refreshAvatarUpdateTime = (userId: string) => {
  localStorage.setItem(makeAvatarUpdatedAtKey(userId), new Date().toISOString());
};

export const getAvatarUpdateTime = (userId: string): string | null =>
  localStorage.getItem(makeAvatarUpdatedAtKey(userId));

export const refreshAvatar = (userId: string) => {
  refreshAvatarUpdateTime(userId);
  setNonMissingAvatar(userId);
};
