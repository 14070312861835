import { Dialog, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Dispatch } from 'react';
import { FormikProps, useFormik } from 'formik';
import { RoundButton } from 'components/common/Button/RoundButton';
import { StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'components/common/Dialog';
import { Close } from '@mui/icons-material';

interface IRejectConnectionDialogProps {
  id: string | undefined;
  open: boolean;
  setOpen: Dispatch<boolean>;
  confirmReject: (id: string, reason: string) => void;
  progress: JSX.Element | undefined;
}

interface IReasonFormProps {
  reason: string;
}

export const RejectConnectionDialog = ({
  id,
  open,
  setOpen,
  confirmReject,
  progress,
}: IRejectConnectionDialogProps) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleReject = (values: IReasonFormProps) => {
    if (id !== undefined) {
      confirmReject(id, values.reason);
    }
  };

  const reasonForm: FormikProps<IReasonFormProps> = useFormik<IReasonFormProps>({
    initialValues: {
      reason: '',
    },
    onSubmit: handleReject,
  });

  return (
    <Dialog open={open} fullWidth fullScreen={isMobileScreen} onClose={() => setOpen(false)}>
      <StyledDialogTitle>
        <Typography variant="h6" color={theme.palette.grey[900]}>
          Decline this invitation
        </Typography>
        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </StyledDialogTitle>

      <form onSubmit={reasonForm.handleSubmit} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <StyledDialogContent dividers>
          <Grid pb={5}>
            <Typography>Are you sure you want to decline this invitation?</Typography>
          </Grid>
        </StyledDialogContent>

        <StyledDialogActions>
          <RoundButton fullWidth={isMobileScreen} onClick={() => setOpen(false)} variant="outlined">
            No
          </RoundButton>
          <RoundButton fullWidth={isMobileScreen} type="submit" variant="contained">
            Yes
          </RoundButton>
        </StyledDialogActions>
      </form>
      {progress}
    </Dialog>
  );
};
