import { Grid, Typography, styled } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { IConnection } from 'global/interfaces/connection';
import { LoadingButton } from '@mui/lab';
import { Dispatch, useState } from 'react';
import { acceptConnectionRequest, rejectConnectionRequest } from 'services/connectionService';
import { showError } from 'utils/errorHandler';
import { RejectConnectionDialog } from 'components/viewContracts/RejectConnectionDialog';
import useProgressBar from 'global/hooks/useProgressBar';
import { useNavigate } from 'react-router-dom';

interface IUserReceiveConnectProps {
  connection: IConnection;
  onReject: Dispatch<void>;
}

const StyledLoadingButton = styled(LoadingButton)(() => ({
  borderRadius: '32px',
}));

export const UserReceiveConnect = ({ connection, onReject }: IUserReceiveConnectProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [rejectDialogOpen, setRejectDialogOpen] = useState<boolean>(false);
  const [progress, showProgress] = useProgressBar();
  const navigate = useNavigate();

  const handleAccept = () => {
    if (connection.connectionId) {
      setLoading(true);
      acceptConnectionRequest(connection.connectionId)
        .then(acceptedConnection => {
          navigate(`/workroom/${acceptedConnection.chatThreadId}`);
          setLoading(false);
        })
        .catch(showError);
    }
  };

  const confirmReject = (connectionId: string, reason: string) => {
    showProgress(true);
    rejectConnectionRequest(connectionId, reason)
      .then(() => {
        showProgress(false);
        setRejectDialogOpen(false);
        onReject();
      })
      .catch(showError);
  };

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12}>
        <Typography variant="subtitle1" textAlign="center">
          Connection request
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ my: 1 }}>
        <Typography variant="body2" textAlign="center">
          {connection?.description}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <StyledLoadingButton
          loading={loading}
          startIcon={<CloseIcon />}
          variant="outlined"
          size="large"
          fullWidth
          onClick={() => setRejectDialogOpen(true)}
        >
          Decline
        </StyledLoadingButton>
      </Grid>
      <Grid item xs={6}>
        <StyledLoadingButton
          loading={loading}
          color="primary"
          startIcon={<CheckIcon />}
          variant="contained"
          size="large"
          fullWidth
          onClick={handleAccept}
        >
          Accept
        </StyledLoadingButton>
      </Grid>

      <RejectConnectionDialog
        open={rejectDialogOpen}
        setOpen={setRejectDialogOpen}
        confirmReject={confirmReject}
        id={connection.connectionId}
        progress={progress}
      />
    </Grid>
  );
};
