import { useEffect, useRef } from 'react';
import _ from 'lodash';

interface BlobAudioProps {
  url?: string;
  isActive: boolean;
}

export default function BlobAudio({ url, isActive }: BlobAudioProps): JSX.Element {
  const audioElRef = useRef<HTMLAudioElement>(null);

  const playAudio = (): void => {
    if (audioElRef?.current && isActive) {
      audioElRef.current.play();
    }
  };

  useEffect(() => {
    const debouncedPlayAudio = _.debounce(playAudio, 300);
    debouncedPlayAudio();
  }, []);

  return (
    <audio
      style={{ width: '80%' }}
      ref={audioElRef}
      controls
      src={url}
      controlsList="nodownload"
      crossOrigin="anonymous"
    >
      Your browser does not support the audio tag.
    </audio>
  );
}
