import { ListItemButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[200]}`,
  backgroundColor: theme.palette.common.white,
  padding: '16px',
  borderRadius: '12px',
  '&.Mui-selected': {
    border: `1px solid ${theme.palette.primary[300]}`,
    backgroundColor: '#F7F8FD',
  },
  '&.Mui-selected:hover': {
    border: `1px solid ${theme.palette.primary[400]}`,
    backgroundColor: '#F7F8FD',
  },
  '&:hover': {
    border: `1px solid ${theme.palette.primary[300]}`,
    backgroundColor: '#F7F8FD',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '16px',
  },
}));
