import { IContractDisplay } from 'global/interfaces/contract';
import { StyledDrawer, StyledDrawerGrid, StyledGrid } from './drawerStyles';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Close from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { ResolveDisputeStatus } from 'global/enums/resolveDisputeStatus';
import { resolveDipute } from 'services/contractService';
import { showError } from 'utils/errorHandler';

interface ResolveDisputeDrawerProps {
  setOpen: React.Dispatch<boolean>;
  open: boolean;
  selectedContract: IContractDisplay;
}

export const ResolveDisputeDrawer: React.FC<ResolveDisputeDrawerProps> = ({ setOpen, open, selectedContract }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const form = useFormik<{ resolutionType: string }>({
    initialValues: {
      resolutionType: '',
    },
    onSubmit: values => {
      resolveDipute({ resolutionType: values.resolutionType, contractId: selectedContract.contractId })
        .then(() => setOpen(false))
        .catch(showError);
    },
    validationSchema: object({
      resolutionType: string().required('Enter the resolution type'),
    }),
  });

  return (
    <StyledDrawer
      sx={{ width: '100%' }}
      anchor={isMobile ? 'bottom' : 'right'}
      open={open}
      onClose={() => setOpen(false)}
    >
      <StyledDrawerGrid container>
        <Grid item xs={12} sx={{ marginBottom: '16px' }} container justifyContent="space-between">
          <Typography variant="h6" color={theme.palette.grey[900]}>
            Resolve dispute
          </Typography>
          {isMobile && (
            <IconButton onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          )}
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={form.handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Select
                    name="resolutionType"
                    value={form.values.resolutionType}
                    onChange={e => {
                      form.handleChange(e);
                    }}
                    error={form.touched.resolutionType && form.errors.resolutionType !== undefined}
                    fullWidth
                  >
                    <MenuItem value={ResolveDisputeStatus.InProgress}>Cancel dispute</MenuItem>
                    <MenuItem value={ResolveDisputeStatus.Payout}>Complete contract and pay seller</MenuItem>
                    <MenuItem value={ResolveDisputeStatus.Refund}>Issue full refund to buyer </MenuItem>
                  </Select>
                  <FormHelperText error>
                    {form.touched.resolutionType !== undefined &&
                    form.errors.resolutionType !== undefined &&
                    Boolean(form.errors.resolutionType)
                      ? form.errors.resolutionType
                      : ''}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <StyledGrid item container justifyContent="flex-end">
              <Button variant="contained" type="submit">
                Submit
              </Button>
            </StyledGrid>
          </form>
        </Grid>
      </StyledDrawerGrid>
    </StyledDrawer>
  );
};
