import { AuthContext } from 'contexts/AuthContext';
import { useContext } from 'react';
import Dashboard from './dashboard/Dashboard';

import Info from './info/info';

export default function Home() {
  const authContext = useContext(AuthContext);
  return <>{authContext.user?.id !== undefined ? <Dashboard /> : <Info />}</>;
}
