import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';

export const RoundButton = styled(LoadingButton)(({ theme }) => ({
  padding: '18px 36px',
  borderRadius: theme.spacing(6),

  [theme.breakpoints.down('sm')]: {
    padding: '12px 22px',
  },
}));
