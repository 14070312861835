import { Box, Stack, Typography, styled } from '@mui/material';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import EmptyData from '../EmptyData';

const StyledPermMediaIcon = styled(PermMediaIcon)(() => ({
  height: '32px',
  width: '32px',
}));

interface NoFilesIconProps {
  isSellingServices: boolean;
}

export default function NoFilesIcon({ isSellingServices }: NoFilesIconProps): JSX.Element {
  const isSellerSignup: boolean = isSellingServices;
  return (
    <Box
      sx={{
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        width: {
          xs: '60%',
          md: '20%',
        },
        margin: 'auto',
      }}
    >
      <Stack gap={2} mt={5}>
        <EmptyData icon={<StyledPermMediaIcon color={'disabled'} />} />
        <Typography
          color={'grey'}
          sx={{
            textAlign: 'center',
          }}
        >
          {!isSellerSignup ? 'Gallery' : 'Portfolio'} is empty
        </Typography>
      </Stack>
    </Box>
  );
}
