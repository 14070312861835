const faviconMain = process.env.REACT_APP_FAVICON_MAIN ?? '';
const faviconNotification = process.env.REACT_APP_FAVICON_NOTIFICATION ?? '';

export enum FaviconType {
  Main = 0,
  Notification = 1,
}

const getFaviconElement = () => document.getElementById('favicon');

export const getFaviconType = () => {
  const favicon = getFaviconElement();
  return favicon?.getAttribute('href') == faviconMain ? FaviconType.Main : FaviconType.Notification;
};

export const changeFaviconType = (newType: FaviconType) => {
  const currentType = getFaviconType();

  if (currentType !== newType) {
    const favicon = getFaviconElement();
    if (newType == FaviconType.Main) {
      favicon?.setAttribute('href', faviconMain);
    } else if (newType == FaviconType.Notification) {
      favicon?.setAttribute('href', faviconNotification);
    }
  }
};
