import { SvgIcon, SvgIconProps } from '@mui/material';

export const ExclusiveIcon = (props: SvgIconProps) => (
  <SvgIcon width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
    <path
      d="M21.666 21H8.46602C6.78586 21 5.94578 21 5.30404 20.673C4.73956 20.3854 4.28062 19.9265 3.993 19.362C3.66602 18.7202 3.66602 17.8802 3.66602 16.2V3M6.66602 15L10.666 11L14.666 15L20.666 9M20.666 9V13M20.666 9H16.666"
      stroke={props.htmlColor ?? '#CD6F2A'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
