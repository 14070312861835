import { SvgIconProps, SvgIcon, useTheme } from '@mui/material';

export const UsersIcon = (props: SvgIconProps) => {
  const theme = useTheme();
  return (
    <SvgIcon width="20" height="18" viewBox="0 0 20 18" fill="none" {...props}>
      <path
        d="M18.3333 16.5V14.8333C18.3333 13.2801 17.271 11.9751 15.8333 11.605M12.9166 1.7423C14.1382 2.23679 15 3.43443 15 4.83333C15 6.23224 14.1382 7.42988 12.9166 7.92437M14.1666 16.5C14.1666 14.9469 14.1666 14.1703 13.9129 13.5577C13.5746 12.741 12.9257 12.092 12.1089 11.7537C11.4963 11.5 10.7198 11.5 9.16663 11.5H6.66663C5.11349 11.5 4.33692 11.5 3.72435 11.7537C2.90759 12.092 2.25867 12.741 1.92036 13.5577C1.66663 14.1703 1.66663 14.9469 1.66663 16.5M11.25 4.83333C11.25 6.67428 9.75758 8.16667 7.91663 8.16667C6.07568 8.16667 4.58329 6.67428 4.58329 4.83333C4.58329 2.99238 6.07568 1.5 7.91663 1.5C9.75758 1.5 11.25 2.99238 11.25 4.83333Z"
        stroke={props.color ?? theme.palette.grey[500]}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
};
