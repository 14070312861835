import { IChatMessageDisplay } from 'global/interfaces/chatMessage';
import * as React from 'react';
import Typography from '@mui/material/Typography';

interface IChatMessageConnectionAutoAcceptedProps extends IChatMessageDisplay {
  currentUserId?: string;
}

export default function ChatMessageConnectionAutoAccepted(props: IChatMessageConnectionAutoAcceptedProps): JSX.Element {
  return (
    <React.Fragment>
      {props.connection?.actionUserId == props.currentUserId && (
        <>
          <Typography variant="body2" color="grey.900" whiteSpace="break-spaces">
            You are now connected with {props.connection?.otherUserDisplayName} and can talk to them directly in this
            chat, send them a contract or add them to a Team. As a reminder Shoutt is <b>100% commission free.</b>
          </Typography>
        </>
      )}
      {props.connection?.actionUserId !== props.currentUserId && (
        <>
          <Typography variant="body2" color="grey.900" whiteSpace="break-spaces">
            {props.connection?.actionUserDisplayName} is interested in working with you. You can talk to them directly
            in this chat, send them a contract or add them to a Team. As a reminder Shoutt is{' '}
            <b>100% commission free.</b>
          </Typography>
        </>
      )}
    </React.Fragment>
  );
}
