import { IChatMessageDisplay } from 'global/interfaces/chatMessage';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import {
  Box,
  Dialog,
  DialogContent,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { StyledDialogActions, StyledDialogTitle } from 'components/common/Dialog';
import Close from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/system';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { amber } from '@mui/material/colors';
import StarIcon from '@mui/icons-material/Star';
import { useFormik } from 'formik';
import { number, object, string } from 'yup';
import { createRating } from 'services/reviewService';
import { showError } from 'utils/errorHandler';
import { RoundButton } from 'components/common/Button/RoundButton';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import { AuthContext } from 'contexts/AuthContext';
import { ContractType } from 'global/interfaces/contract';
import { addDays } from 'date-fns';

interface FeedbackForm {
  comment?: string;
  rating: number;
}

const StyledCustomDialogContent = styled(DialogContent)({
  padding: '12px 24px 26px 24px !important',
  display: 'flex',
  flexDirection: 'column',
});

export default function ChatMessageContractComplete(props: IChatMessageDisplay): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const authContext = React.useContext(AuthContext);

  const form = useFormik<FeedbackForm>({
    initialValues: {
      comment: '',
      rating: 0,
    },
    onSubmit: values => {
      if (!props.contract?.contractId) {
        throw 'Contract id undefined';
      }
      setLoading(true);
      createRating({
        contractId: props.contract?.contractId,
        rating: values.rating,
        comment: values.comment,
        requestFeedbackMessage: props.id,
      })
        .then(() => {
          setLoading(false);
          setOpen(false);
        })
        .catch(showError);
    },
    validationSchema: object({
      rating: number().min(1, 'You must choose a rating'),
      comment: string().notRequired().max(500, 'Comment must be at most 500 characters'),
    }),
  });

  const canLeaveFeedback: boolean = props.contract?.completedOn
    ? addDays(new Date(props.contract?.completedOn), 3) > new Date()
    : false;

  const isBuyer = props.contract?.buyerUserId === authContext.user?.id;

  const hasRating: boolean =
    (isBuyer && props.contract?.sellerRating != undefined) || (!isBuyer && props.contract?.buyerRating != undefined);

  return (
    <React.Fragment>
      <Box>
        <Typography variant="body2" color="grey.900">
          {props.contract?.contractType == ContractType.FixedPrice && 'Fixed price '}
          {props.contract?.contractType == ContractType.Retainer && 'Retainer '}
          contract &quot;{props.contract?.name}&quot; is complete.&nbsp;
          {!hasRating && canLeaveFeedback
            ? "You have 72 hours to leave feedback. It only takes 30 seconds! Feedback given will be visible on the user's profile."
            : ''}
        </Typography>
        {!hasRating && canLeaveFeedback ? (
          <RoundButton
            onClick={() => setOpen(true)}
            variant="contained"
            sx={{ marginTop: '8px' }}
            startIcon={<RateReviewOutlinedIcon />}
          >
            Leave feedback
          </RoundButton>
        ) : hasRating && canLeaveFeedback ? (
          <Typography variant="caption" fontStyle={'italic'} color="grey.500">
            You have already left feedback for this contract
          </Typography>
        ) : (
          <Typography variant="caption" fontStyle={'italic'} color="grey.500">
            You can no longer leave feedback as it has been more than 72 hours since contract completion.
          </Typography>
        )}
      </Box>
      <Dialog open={open} fullWidth fullScreen={isMobile} onClose={() => setOpen(false)}>
        <form onSubmit={form.handleSubmit}>
          <StyledDialogTitle>
            <Typography variant="h6" color="grey.900">
              Leave Feedback
            </Typography>
            <IconButton onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </StyledDialogTitle>
          <StyledCustomDialogContent dividers>
            <Box marginBottom={2}>
              <Grid container columnSpacing={2}>
                {[...Array(5)].map((_, i) => (
                  <Grid item key={i}>
                    <IconButton onClick={() => form.setFieldValue('rating', i + 1)} sx={{ padding: 0 }}>
                      {form.values.rating && form.values.rating >= i + 1 ? (
                        <StarIcon sx={{ fill: amber[600] }} fontSize="small" />
                      ) : (
                        <StarOutlineIcon fontSize="small" />
                      )}
                    </IconButton>
                  </Grid>
                ))}
              </Grid>
              {form.touched.rating && form.errors.rating && <FormHelperText error>{form.errors.rating}</FormHelperText>}
            </Box>
            <TextField
              multiline
              rows={8}
              value={form.values.comment}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              name="comment"
              placeholder="Optional comment"
              label="Optional comment"
              helperText={form.errors.comment ? form.errors.comment : ''}
              error={form.touched.comment && Boolean(form.errors.comment)}
              InputProps={{
                style: { overflow: 'hidden' },
                endAdornment: (
                  <InputAdornment position="end" style={{ alignSelf: 'flex-end' }}>
                    <FormHelperText
                      style={{
                        color: theme.palette.primary.dark,
                        fontSize: '10px',
                      }}
                    >
                      {form.values.comment?.length}
                    </FormHelperText>
                  </InputAdornment>
                ),
              }}
            />
          </StyledCustomDialogContent>
          <StyledDialogActions>
            <LoadingButton loading={loading} color="primary" variant="contained" type="submit">
              Send feedback
            </LoadingButton>
          </StyledDialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
