import { Avatar, AvatarProps, styled } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { getAvatarUpdateTime, isAvatarMissing, setMissingAvatar } from './avatarCache';
import { useNavigate } from 'react-router-dom';
import { makeBlobUrlFromPath } from 'utils/file';

interface IUserAvatarProps extends AvatarProps {
  userId?: string;
  displayName?: string;
  children?: ReactNode;
  noLink?: boolean;
  isTemp?: boolean;
  profileImageId?: string;
}

const StyledAvatar = styled(Avatar, {
  shouldForwardProp: prop => prop !== 'noLink',
})<IUserAvatarProps>(({ noLink }) => ({
  height: '40px',
  width: '40px',
  cursor: noLink ? 'inherit' : 'pointer',
}));

const extractInitials = (displayName: string) => {
  const parts = displayName.split(' ').filter(e => e);
  if (parts.length > 1) {
    return `${parts[0][0]}${parts[1][0]}`;
  }
  if (parts.length > 0) {
    return parts[0][0];
  }

  return 'N/A';
};

export const UserAvatar = ({
  userId,
  displayName,
  children,
  noLink,
  isTemp,
  profileImageId,
  ...props
}: IUserAvatarProps) => {
  const [profileSrc, setProfileSrc] = useState<string>();
  const navigate = useNavigate();
  const noLinkInternal = noLink || !userId;

  useEffect(() => {
    if (userId) {
      if (!isAvatarMissing(userId)) {
        const updatedAt = getAvatarUpdateTime(userId);
        const postFix = updatedAt ? `?${updatedAt}` : '';
        const fileName = isTemp ? 'temp_avatar.jpg' : 'avatar.jpg';
        setProfileSrc(makeBlobUrlFromPath(`${userId}/${fileName}${postFix}`));
      }
    } else {
      setProfileSrc(undefined);
    }
  }, [userId, profileImageId]);

  const onImageError = () => {
    if (userId) {
      setMissingAvatar(userId);
    }
  };

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!noLinkInternal) {
      e.stopPropagation();
      navigate(`/users/${userId}`);
    }
  };

  return (
    <StyledAvatar
      alt={displayName}
      src={profileSrc}
      onClick={onClick}
      {...props}
      noLink={noLinkInternal}
      imgProps={{ onError: onImageError, loading: 'lazy' }}
    >
      {children ? children : extractInitials(displayName ?? '')}
    </StyledAvatar>
  );
};
