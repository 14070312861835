import { styled } from '@mui/material';
import { useWindowSize } from 'global/hooks/useWindowSize';
import { FC, useEffect, useRef } from 'react';
import {
  AutoSizerProps,
  Grid as _Grid,
  GridCellProps,
  GridProps,
  WindowScroller as _WindowScroller,
  AutoSizer as _AutoSizer,
  WindowScrollerProps,
} from 'react-virtualized';

const Grid = _Grid as unknown as FC<GridProps>;
const WindowScroller = _WindowScroller as unknown as FC<WindowScrollerProps>;
const AutoSizer = _AutoSizer as unknown as FC<AutoSizerProps>;

/**
 * Interfaces
 */

interface VirtualizedGridProps<ItemType> {
  items: ItemType[];
  itemHeight: number;
  itemMinWidth: number;
  renderItem: (props: VirtualizedGridItemProps<ItemType>) => JSX.Element;
  numColumns?: number; // explicitly set number of columns
  activeUserId: string;
  muted: boolean;
}

export interface VirtualizedGridItemProps<ItemType> extends GridCellProps {
  items: ItemType[];
  columnCount: number;
}

/**
 * Styles
 */

const Container = styled('div')(() => ({
  flex: 1,

  '> div': {
    height: `unset !important`,
  },

  '.ReactVirtualized__Grid, .ReactVirtualized__Grid__innerScrollContainer': {
    overflow: 'visible !important',
  },
}));

/**
 * Component
 */

export function VirtualizedGrid<ItemType>({
  items,
  renderItem,
  itemMinWidth,
  itemHeight,
  numColumns,
  activeUserId,
  muted,
}: VirtualizedGridProps<ItemType>): JSX.Element {
  const gridRef = useRef<any>(null);
  const containerRef = useRef<any>(null);
  const containerWidth = containerRef?.current?.clientWidth;

  const windowSize = useWindowSize();

  useEffect(() => {
    gridRef.current?.recomputeGridSize();
  }, [windowSize, activeUserId, muted]);

  function calculateColumnCount(width: number) {
    return Math.floor(width / itemMinWidth);
  }

  function calculateItemWidth(width: number, columnCount: number) {
    return width / columnCount;
  }

  return (
    <Container ref={containerRef}>
      <WindowScroller>
        {({ height, isScrolling, onChildScroll, scrollTop }: any) => (
          <AutoSizer disableHeight disableWidth>
            {() => {
              const columnCount = numColumns ?? calculateColumnCount(containerWidth);
              const rowCount = Math.ceil(items.length / columnCount);
              const itemWidth = calculateItemWidth(containerWidth, columnCount);
              const newItemHeight = (itemWidth / 4) * 3 + itemHeight;

              return (
                <Grid
                  ref={gridRef}
                  autoHeight
                  columnCount={columnCount}
                  columnWidth={itemWidth}
                  width={containerWidth}
                  height={height}
                  rowCount={rowCount}
                  rowHeight={newItemHeight}
                  isScrolling={isScrolling}
                  scrollTop={scrollTop}
                  onScroll={onChildScroll}
                  isScrollingOptOut={true}
                  cellRenderer={(props: GridCellProps) => {
                    const fullProps: VirtualizedGridItemProps<ItemType> = {
                      ...props,
                      items,
                      columnCount: columnCount,
                    };
                    return renderItem(fullProps);
                  }}
                  overscanIndicesGetter={({ cellCount, startIndex, stopIndex }) => ({
                    overscanStartIndex: Math.max(0, startIndex - 8),
                    overscanStopIndex: Math.min(cellCount - 1, stopIndex + 8),
                  })}
                />
              );
            }}
          </AutoSizer>
        )}
      </WindowScroller>
    </Container>
  );
}
