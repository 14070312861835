import React, { useEffect, useState } from 'react';
import { DialogTitle, Divider, Grid, Stack, styled, Typography, useTheme } from '@mui/material';
import { CompleteProfileForm } from '../CompleteProfileForm';
import { IUser } from 'global/interfaces/user';

export const StyledTypography = styled(Typography)(() => ({
  marginLeft: '20px',
  alignSelf: 'center',
  paddingBottom: '0px',
}));

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: '16px 8px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    padding: '16px 0px',
  },
}));

export default function AddProfileDetails({
  handleClose,
  handleComplete,
  currentUser,
  title,
}: {
  handleClose: () => void;
  handleComplete: () => void;
  currentUser: IUser | null;
  title: string;
}) {
  const theme = useTheme();
  const [isProfileDetailsInValid, setProfileDetailsInValid] = useState(true);

  useEffect(() => {
    if (currentUser?.title && currentUser?.profileImage) {
      setProfileDetailsInValid(false);
    }
  }, [currentUser]);

  return (
    <React.Fragment>
      <StyledDialogTitle>
        <Stack direction="row">
          <StyledTypography variant="h6" color={theme.palette.grey[900]}>
            {title}
          </StyledTypography>
        </Stack>
      </StyledDialogTitle>
      <Divider sx={{ marginBottom: '24px' }} />
      <div>
        <Grid container item xs={12} justifyContent="center" sx={{ marginBottom: '1px' }}>
          <Grid item xs={10.8}>
            <Typography variant="body2">
              Add a few details so users know who they are talking to! Can be edited anytime.
            </Typography>
          </Grid>
        </Grid>
      </div>
      <CompleteProfileForm
        handleClose={handleClose}
        handleComplete={handleComplete}
        currentUser={currentUser}
        isProfileDetailsInValid={isProfileDetailsInValid}
        setProfileDetailsInValid={setProfileDetailsInValid}
      ></CompleteProfileForm>
    </React.Fragment>
  );
}
