import { Container, IconButton, styled, useMediaQuery, useTheme } from '@mui/material';
import AudiotrackOutlinedIcon from '@mui/icons-material/AudiotrackOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import MusicImage from '../../../assets/images/musicImg.webp';

const CenteredAudioElem = styled('div')(({ theme }) => ({
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  lineHeight: '1.5',
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
  display: 'block',
  paddingTop: '60%',
  paddingBottom: '60%',

  [theme.breakpoints.down('xl')]: {
    paddingBottom: '60%',
    paddingTop: '60%',
  },
  [theme.breakpoints.down('lg')]: {
    paddingBottom: '31%',
    paddingTop: '31%',
  },
  [theme.breakpoints.down('md')]: {
    paddingBottom: '25%',
    paddingTop: '25%',
  },
}));

const IconContainer = styled('div')(() => ({
  position: 'absolute',
  top: '17%',
  right: '0',
}));

interface PortfolioAudioProps {
  disableIcon?: boolean;
}

export function PortfolioAudio({ disableIcon }: PortfolioAudioProps): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

  return (
    <Container
      sx={{
        backgroundImage: `url(${MusicImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        borderRadius: '13px',
      }}
    >
      <CenteredAudioElem>
        <IconButton>
          <PlayArrowIcon style={{ fontSize: isMobile ? '40px' : '60px' }} />
        </IconButton>
      </CenteredAudioElem>
      {!disableIcon && (
        <IconContainer>
          <IconButton>
            <AudiotrackOutlinedIcon style={{ fontSize: isMobile ? '20px' : '25px' }} />
          </IconButton>
        </IconContainer>
      )}
    </Container>
  );
}
