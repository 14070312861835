import { IconButton, Paper, Popover, styled } from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
import { useState } from 'react';
import ShareIcon from '@mui/icons-material/Share';
import { isStableShareDevice } from 'utils/device';

const StyledPaper = styled(Paper)(() => ({
  padding: '10px 0px',
  backgroundColor: 'black',
  color: 'white',
  width: '130px',
  textAlign: 'center',
}));

interface IShareBtnProps {
  url: string;
  eventFunc?: () => void;
}

export default function ShareButton({ url, eventFunc }: IShareBtnProps) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const shareProfileFallback = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
    navigator.clipboard.writeText(url).then(() => {
      setOpen(true);
      eventFunc && eventFunc();
      setTimeout(() => {
        setOpen(false);
      }, 1000);
    });
  };

  const shareProfile = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    navigator
      .share({
        title: document.title,
        url: url,
      })
      .then(() => eventFunc && eventFunc())
      .catch(() => {
        shareProfileFallback(e);
      });
  };
  return (
    <IconButton
      style={{
        marginLeft: '3px',
        padding: '3px',
      }}
      onClick={isStableShareDevice() ? e => shareProfile(e) : e => shareProfileFallback(e)}
    >
      {isStableShareDevice() ? <IosShareIcon /> : <ShareIcon />}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <StyledPaper>Link copied</StyledPaper>
      </Popover>
    </IconButton>
  );
}
