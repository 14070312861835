import {
  Box,
  Button,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { RoundButton } from 'components/common/Button/RoundButton';
import { useFormik } from 'formik';
import { IContactUsForm } from 'global/interfaces/IContactUsForm';
import { contactUsValidationSchema } from 'global/validations/contactUs';
import { Dispatch, useContext, useEffect, useState } from 'react';
import { sendContactDetails } from 'services/contactUsService';
import { nameof } from 'ts-simple-nameof';
import { showError } from 'utils/errorHandler';
import ReCAPTCHA from 'react-google-recaptcha';
import { AuthContext } from 'contexts/AuthContext';
import ContactUsBackground from '../../assets/images/contactusBackground.webp';

const StyledFormGrid = styled(Grid)(() => ({
  marginTop: '25px',
}));
const StyledInputGrid = styled(Grid)(() => ({
  marginTop: '20px',
}));
const StyledButtonGrid = styled(Grid)(() => ({
  marginTop: '20px',
  marginBottom: '40px',
}));
const StyledHelperText = styled(FormHelperText)(({ theme }) => ({
  marginLeft: '14px',
  color: theme.palette.error.main,
}));
const TitleGrid = styled(Grid)(() => ({
  paddingTop: '112px',
  paddingBottom: '112px',
  backgroundImage: `url(${ContactUsBackground})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

export default function ContactUsForm({ setSubmitted }: { setSubmitted: Dispatch<boolean> }) {
  const [selectedFile, setSelectedFile] = useState<File>();
  const authContext = useContext(AuthContext);
  const isLoggedIn = authContext.user !== null;
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  const form = useFormik<IContactUsForm>({
    initialValues: {
      name: '',
      description: '',
      email: '',
      subject: '',
      file: null,
      token: '',
    },
    onSubmit: values => {
      if (isLoggedIn || (form.values.token !== '' && form.values.token !== null)) {
        setLoading(true);
        sendContactDetails(values)
          .then(() => setSubmitted(true))
          .catch(showError);
      }
    },
    validationSchema: contactUsValidationSchema(!isLoggedIn),
  });

  useEffect(() => {
    if (isLoggedIn) {
      form.setFieldValue(
        nameof<IContactUsForm>(x => x.name),
        authContext.user?.firstName,
      );
      form.setFieldValue(
        nameof<IContactUsForm>(x => x.email),
        authContext.user?.email,
      );
    }
  }, [authContext.user]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      setSelectedFile(e.target.files[0]);
      form.setFieldValue(
        nameof<IContactUsForm>(x => x.file),
        e.target.files[0],
      );
    }
  };

  return (
    <Grid container item xs={12} justifyContent="center">
      <TitleGrid container item xs={12} justifyContent="center">
        <Typography variant={isMobileScreen ? 'h5' : 'h4'}>Contact Us</Typography>
      </TitleGrid>
      <Box maxWidth={600} width="100%" marginTop={5}>
        <Grid container item xs={12} justifyContent="center">
          <StyledFormGrid item xs={11}>
            <form onSubmit={form.handleSubmit}>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControl>
                    <TextField
                      name={nameof<IContactUsForm>(x => x.name)}
                      label="Name *"
                      value={form.values.name}
                      variant="outlined"
                      fullWidth
                      onChange={form.handleChange}
                      error={form.touched.name !== undefined && Boolean(form.errors.name)}
                      helperText={form.touched.name !== undefined ? form.errors.name : ''}
                      disabled={isLoggedIn}
                    />
                  </FormControl>
                </FormGroup>
              </Grid>
              <StyledInputGrid item xs={12}>
                <FormGroup>
                  <FormControl>
                    <TextField
                      name={nameof<IContactUsForm>(x => x.email)}
                      label="Email *"
                      variant="outlined"
                      fullWidth
                      value={form.values.email}
                      onChange={form.handleChange}
                      error={form.touched.email !== undefined && Boolean(form.errors.email)}
                      helperText={form.touched.email !== undefined ? form.errors.email : ''}
                      disabled={isLoggedIn}
                    />
                  </FormControl>
                </FormGroup>
              </StyledInputGrid>
              <StyledInputGrid item xs={12}>
                <FormGroup>
                  <FormControl>
                    <TextField
                      name={nameof<IContactUsForm>(x => x.subject)}
                      label="Subject *"
                      variant="outlined"
                      fullWidth
                      onChange={form.handleChange}
                      error={form.touched.subject !== undefined && Boolean(form.errors.subject)}
                      helperText={form.touched.subject !== undefined ? form.errors.subject : ''}
                    />
                  </FormControl>
                </FormGroup>
              </StyledInputGrid>
              <StyledInputGrid item xs={12}>
                <FormGroup>
                  <FormControl>
                    <TextField
                      name={nameof<IContactUsForm>(x => x.description)}
                      label="How can we help? *"
                      variant="outlined"
                      fullWidth
                      rows={10}
                      multiline
                      onChange={form.handleChange}
                      error={form.touched.description !== undefined && Boolean(form.errors.description)}
                      helperText={form.touched.description !== undefined ? form.errors.description : ''}
                    />
                  </FormControl>
                </FormGroup>
              </StyledInputGrid>
              <StyledInputGrid container alignItems="center">
                <Button
                  variant="outlined"
                  component="label"
                  sx={{
                    padding: '18px 36px',
                    borderRadius: theme.spacing(6),
                    [theme.breakpoints.down('sm')]: {
                      padding: '12px 22px',
                    },
                    marginRight: '10px',
                  }}
                >
                  Upload File
                  <input
                    type="file"
                    hidden
                    name={nameof<IContactUsForm>(x => x.file)}
                    onChange={e => handleFileChange(e)}
                  />
                </Button>
                <label htmlFor={nameof<IContactUsForm>(x => x.file)}>
                  <Typography variant="body2">{selectedFile?.name ?? ''}</Typography>
                </label>
                {form.errors && form.errors.file && (
                  <Grid item xs={12}>
                    <StyledHelperText>{form.errors.file}</StyledHelperText>
                  </Grid>
                )}
              </StyledInputGrid>
              {!isLoggedIn && (
                <StyledInputGrid>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_KEY ?? ''}
                    onChange={(value: string | null) => {
                      form.setFieldValue(
                        nameof<IContactUsForm>(x => x.token),
                        value,
                      );
                    }}
                    onErrored={() => console.error('reCAPTCHA initialization error')}
                  />
                  {form.errors.token && form.submitCount > 0 && (
                    <StyledHelperText>{form.errors.token}</StyledHelperText>
                  )}
                </StyledInputGrid>
              )}
              <StyledButtonGrid container item xs={12} justifyContent="center">
                <RoundButton loading={loading} variant="contained" type="submit">
                  Submit
                </RoundButton>
              </StyledButtonGrid>
            </form>
          </StyledFormGrid>
        </Grid>
      </Box>
    </Grid>
  );
}
