import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';

export const UserNotFound: React.FC = () => (
  <Grid item xs={12} md={6}>
    <Typography textAlign="center" variant="h5">
      Profile Not Found
    </Typography>
    <Typography textAlign="center" variant="body1" pt={2}>
      We could not find the user&apos;s profile. The user may have deleted their account.
    </Typography>
  </Grid>
);
