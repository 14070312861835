import { Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import NewFAQ from 'components/common/FAQs/NewFAQ';
import BasicPlan from 'components/subscriptions/basicPlan';

import { billingFaqOptions as faqs } from 'components/common/FAQs/allData';

import { useTitle } from 'utils/router';

export default function Subscriptions() {
  useTitle('Your Subscription');
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box style={{ backgroundColor: '#FFFFFF', paddingTop: isMobileScreen ? '32px' : '48px' }}>
      <Container maxWidth="lg">
        <Box>
          <Typography variant={isMobileScreen ? 'h5' : 'h4'}>Your Subscription</Typography>
          <BasicPlan />
        </Box>
      </Container>
      <NewFAQ description="Everything you need to know about Shoutt." options={faqs} />
    </Box>
  );
}
