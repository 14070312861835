import { FormikProps } from 'formik';
import { IEditableUser } from 'global/interfaces/user';
import { EditWorkHistorySection } from './EditWorkHistorySection';
import { EditNotableClientSection } from './EditNotableClientSection';
import { isAdminEdit } from 'utils/adminUtil';

interface IEditWorkHistorySectionProps {
  form: FormikProps<IEditableUser>;
}

export const EditWorkHistoryIndex = ({ form }: IEditWorkHistorySectionProps) => (
  <>
    {isAdminEdit() && <EditWorkHistorySection form={form} />}
    <EditNotableClientSection form={form} />
  </>
);
