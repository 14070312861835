export enum NetworkTabValue {
  InvitationsSent = 0,
  InvitationsRecieved = 1,
}

export enum EmailInvitationResponseType {
  Success,
  ExistingUser,
  ExistingConnection,
}

export enum EmailInviteStep {
  Form,
  ExistingUser,
  ExistingConnection,
  InvitationSent,
  Failure,
}
