import { memo, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material';
import ReactPlayer from 'react-player/file';
import { isiOS } from 'utils/device';

interface AudioPlayerProps {
  url: string;
  playing: boolean;
  muted: boolean;
  className?: string;
  thumbnailUrl?: string;
  hideAudioControl?: boolean;
  thumbnailAspectRatio?: string;
  controls: boolean;
  onPlay?: () => void;
  renderImmedatiely?: boolean;
}

interface StyledMediaProps {
  hideAudioControl?: boolean;
}

const StyledAudio = styled('audio', {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'hideAudioControl',
})<StyledMediaProps>(({ hideAudioControl }) => ({
  display: hideAudioControl ? 'none' : 'inline',
  width: '80%',
  height: '54px',
}));

const StyledPlayer = styled(ReactPlayer, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'hideAudioControl',
})<StyledMediaProps>(({ hideAudioControl }) => ({
  display: hideAudioControl ? 'none' : 'inline',
}));

interface StyledImageProps {
  aspectRatio?: string;
}

const StyledImage = styled('img', {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: prop => prop !== 'aspectRatio',
})<StyledImageProps>(({ aspectRatio, theme }) => ({
  width: '100%',
  display: 'block',
  backgroundColor: theme.palette.common.black,
  objectFit: 'contain',
  aspectRatio: aspectRatio,
  height: !aspectRatio ? '100%' : undefined,
}));

const MemoizedStyledImage = memo(StyledImage);
const MemoizedStyledAudio = memo(StyledAudio);

export default function AudioPlayer({
  url,
  playing,
  muted,
  className,
  thumbnailUrl,
  hideAudioControl,
  thumbnailAspectRatio,
  controls,
  onPlay,
  renderImmedatiely,
}: AudioPlayerProps): JSX.Element {
  const audioElRef = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<ReactPlayer | null>(null);
  const [firstPlay, setFirstPlay] = useState(renderImmedatiely !== undefined ? renderImmedatiely : false);
  const [hasHlsLoaded, setHasHlsLoaded] = useState<boolean>(false);

  const playPause = (): void => {
    if (audioElRef?.current && playing && audioElRef?.current.paused) {
      audioElRef.current.play();
    } else if (audioElRef?.current && !playing && !audioElRef?.current.paused) {
      audioElRef.current.pause();
    }
  };

  const handlePlay = () => {
    if (playerRef.current !== null && !hasHlsLoaded) {
      const hlsPlayer = playerRef.current.getInternalPlayer('hls')?.startLoad(-1);

      if (!hlsPlayer) return;

      setHasHlsLoaded(true);
    }
    if (onPlay) {
      onPlay();
    }
  };

  useEffect(() => {
    if (playing) {
      setFirstPlay(true);
    }
    playPause();
  }, [playing]);

  useEffect(() => {
    playPause();
  }, [firstPlay]);

  return (
    <>
      {thumbnailUrl && (
        <MemoizedStyledImage
          className={className}
          alt="User image"
          src={thumbnailUrl}
          loading="eager"
          style={{ backgroundColor: 'transparent' }}
          aspectRatio={thumbnailAspectRatio}
        />
      )}
      {url.startsWith(process.env.REACT_APP_BUNNY_VIDEO_CDN_URL ?? '') && !isiOS() ? (
        <StyledPlayer
          url={url}
          ref={playerRef}
          controls={controls}
          muted={muted}
          loop
          width="80%"
          height="54px"
          playing={playing}
          onPlay={() => handlePlay()}
          hideAudioControl={hideAudioControl}
          config={{
            forceAudio: true,
            attributes: {
              preload: 'metadata',
              className: className,
              playsInline: true,
              autoPlay: false,
            },
            forceHLS: true,
            hlsOptions: {
              autoStartLoad: false,
              maxLoadingDelay: 0.25,
              startLevel: 1,
              maxBufferSize: 10 * 1000 * 1000,
            },
          }}
        />
      ) : (
        <>
          {firstPlay && (
            <MemoizedStyledAudio
              ref={audioElRef}
              muted={muted}
              loop
              src={url}
              controlsList="nodownload"
              controls={controls}
              className={className}
              preload="metadata"
              hideAudioControl={hideAudioControl}
              onPlay={() => handlePlay()}
            />
          )}
        </>
      )}
    </>
  );
}
