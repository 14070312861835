import { Close } from '@mui/icons-material';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import ImageViewer from 'components/common/ImageViewer/ImageViewer';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: '16px',
  top: '16px',
  zIndex: theme.zIndex.modal + 2,
  backgroundColor: theme.palette.common.white,
  boxShadow: '0 3px 9px rgba(0,0,0,0.3)',

  '&:hover': {
    backgroundColor: theme.palette.grey[300],
  },
}));

const StyledChildrenContainer = styled(Box)(() => ({
  cursor: 'pointer',
  position: 'relative',
  height: '100%',
  display: 'flex',
  verticalAlign: 'middle',
}));

interface IImageDialogProps {
  children: React.ReactNode;
  imageUrl: string;
}

export default function ImageDialog({ children, imageUrl }: IImageDialogProps) {
  const [openDialog, setOpenDialog] = useState(false);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  const closeDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <StyledChildrenContainer onClick={() => setOpenDialog(true)}>{children}</StyledChildrenContainer>

      <Dialog
        hideBackdrop
        PaperProps={{ sx: { bgcolor: 'transparent' } }}
        onClose={closeDialog}
        open={openDialog}
        fullWidth
        maxWidth="lg"
        fullScreen={isMobileScreen}
      >
        <Box position="relative" height={isMobileScreen ? '100vh' : '90vh'}>
          <Box
            position="absolute"
            left={0}
            right={0}
            top={0}
            bottom={0}
            bgcolor="rgba(0, 0, 0, 0.9)"
            boxShadow="inset 0 0 500px rgba(255, 255, 255, .4)"
          />

          <ImageViewer url={imageUrl} name="" />

          <StyledIconButton onClick={closeDialog}>
            <Close />
          </StyledIconButton>
        </Box>
      </Dialog>
    </>
  );
}
