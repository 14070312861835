import { styled } from '@mui/material';
import BlobImage from 'components/common/BlobFiles/BlobImage';
import { getThumbnailId, makeBlobUrl, makeBunnyThumbnailUrl } from 'utils/file';

const StyledBlobImage = styled(BlobImage)(() => ({
  width: '100%',
  height: 'auto',
  objectFit: 'cover',
  display: 'block',
  aspectRatio: '4/3',
}));

interface IPortfolioImageProps {
  fileName: string;
  extension: string;
  id: string;
  directory: string;
}

export default function PortfolioImage(props: IPortfolioImageProps): JSX.Element {
  return (
    <StyledBlobImage
      alt={props.fileName}
      url={
        props.id.startsWith('bunv_')
          ? makeBunnyThumbnailUrl(props.id)
          : makeBlobUrl(props.directory, getThumbnailId(props.id), props.extension)
      }
    />
  );
}
