import { DialogActions, DialogContent, DialogTitle, Grow, styled, Typography } from '@mui/material';

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  padding: '20px 24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    padding: '20px 16px',
  },
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: '36px 24px !important',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',

  [theme.breakpoints.down('sm')]: {
    padding: '20px 16px !important',
  },
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: '16px 24px',

  [theme.breakpoints.down('sm')]: {
    padding: '16px',
  },
}));

export const WrappedTypography = styled(Typography)(() => ({
  overflowWrap: 'break-word',
  overflow: 'hidden',
}));

export const StyledGrow = styled(Grow)(() => ({
  marginBottom: '20px',
}));
