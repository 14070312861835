import { Avatar, Box, Stack, styled, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { INotableClient } from 'global/interfaces/INotableClient';
import { PaddedBox } from './UserProfileHeadSection/UserProfileHeadSection';

import { StyledCarousel } from 'components/common/Carousel/StyledCarousel';
import { EllipsisTypography } from 'components/common/Typography/EllipsisTypography';
import { Fragment } from 'react';

const StyledTooltip = styled(Tooltip)(() => ({
  '&:hover': {
    cursor: 'pointer',
  },
}));

const CarouselWrapper = styled(Box)(({ theme }) => ({
  padding: '24px',
  paddingInline: '12px',
  paddingBottom: '0px',
  marginInline: '-24px',

  [theme.breakpoints.down('md')]: {
    paddingInline: '8px',
    marginInline: 0,
    paddingBottom: 0,
  },
}));

const StyledNotableClientBox = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  padding: '16px 24px',
  justifyContent: 'flex-start',
  alignItems: 'center',
  borderColor: theme.palette.grey[200],
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: '8px',
  marginInline: '10px',
  maxHeight: '86px',

  [theme.breakpoints.down('md')]: {
    marginInline: '12px',
  },
}));

export default function UserNotableClientSection({ notableClients }: { notableClients: INotableClient[] }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const responsiveProps = {
    desktop: {
      breakpoint: { max: window.screen.width, min: 1440 },
      items: 2,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: theme.breakpoints.values.lg, min: theme.breakpoints.values.sm },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: theme.breakpoints.values.sm, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <Box mt={4}>
      <PaddedBox>
        <Typography variant="h6">Notable Experience</Typography>{' '}
      </PaddedBox>

      <CarouselWrapper>
        <StyledCarousel className="work-history-react-multiple-carousel" responsive={responsiveProps}>
          {notableClients.map((client, index) => (
            <Fragment key={index}>
              {!isMobile ? (
                <StyledTooltip key={index} title={client.description} enterTouchDelay={0}>
                  <StyledNotableClientBox>
                    <Avatar src={client.logo} sx={{ width: 50, height: 50 }} variant="square"></Avatar>
                    <Stack direction="column" spacing={1} ml={2}>
                      <Typography variant="subtitle1">{client.name}</Typography>
                      <EllipsisTypography variant="body2">{client.role}</EllipsisTypography>
                    </Stack>
                  </StyledNotableClientBox>
                </StyledTooltip>
              ) : (
                <StyledNotableClientBox>
                  <Avatar src={client.logo} sx={{ width: 50, height: 50 }} variant="square"></Avatar>
                  <Stack direction="column" spacing={1} ml={2}>
                    <Typography variant="subtitle1">{client.name}</Typography>
                    <EllipsisTypography variant="body2">{client.role}</EllipsisTypography>
                  </Stack>
                </StyledNotableClientBox>
              )}
            </Fragment>
          ))}
        </StyledCarousel>
      </CarouselWrapper>
    </Box>
  );
}
