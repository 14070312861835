import { styled } from '@mui/material';
import { RoundButton } from './RoundButton';

export const LowPriorityButton = styled(RoundButton)(({ theme }) => ({
  borderColor: theme.palette.grey[300],
  color: theme.palette.grey[900],
  '&:hover': {
    borderColor: theme.palette.grey[300],
    backgroundColor: theme.palette.grey[100],
  },
}));
