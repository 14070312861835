import { IWorkHistory } from 'global/interfaces/user';

export function compareWorkHistories(a: IWorkHistory, b: IWorkHistory): number {
  // Convert end dates to Date objects, setting a high value for present positions
  const aEndDate = a.isPresentPosition ? new Date('3000-01-01') : new Date(a.toYear || 0, a.toMonth || 0);
  const bEndDate = b.isPresentPosition ? new Date('3000-01-01') : new Date(b.toYear || 0, b.toMonth || 0);

  if (aEndDate > bEndDate) {
    return -1;
  } else if (aEndDate < bEndDate) {
    return 1;
  }

  const aStartDate = a.isPresentPosition ? new Date('3000-01-01') : new Date(a.fromYear || 0, a.fromMonth || 0);
  const bStartDate = b.isPresentPosition ? new Date('3000-01-01') : new Date(b.fromYear || 0, b.fromMonth || 0);

  if (aStartDate > bStartDate) {
    return -1;
  } else if (aStartDate < bStartDate) {
    return 1;
  }

  return 0;
}
