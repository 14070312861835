import { INotableClient } from 'global/interfaces/INotableClient';
import { number, object, SchemaOf, string } from 'yup';

export const notableClientValidation: SchemaOf<INotableClient> = object({
  name: string().required('You must select a notable company'),
  domain: string().optional(),
  id: number().optional(),
  logo: string().optional(),
  description: string().max(2500, 'What did you do there must be at less than or equal to 2500 characters').optional(),
  role: string().max(50, 'Role can only be less than or equal to 50 characters').required('Role is required'),
});
