import { useEffect, useState } from 'react';
import { Dialog, useTheme, useMediaQuery } from '@mui/material';
import { IUser } from 'global/interfaces/user';
import IApiError from 'global/interfaces/api';
import { showError } from 'utils/errorHandler';
import { DashboardButton } from 'components/dashboard/DashboardButton';
import { EmailInviteStep } from 'global/enums/networkEnums';
import AddProfileDetails from 'components/common/Contract/AddProfileDetails';
import { getUser } from 'services/userService';
import EmailInviteForm from './EmailInviteForm';

const EmailInviteButton: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState<IUser | null>(null);
  const [isProfileDetailsValid, setIsProfileDetailsValid] = useState(false);
  const [emailInviteStep, setEmailInviteStep] = useState<EmailInviteStep>(EmailInviteStep.Form);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (open) getUserFromAPI();
  }, [open]);

  useEffect(() => {
    getUserFromAPI();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCompleteProfilePic = (): void => {
    getUserFromAPI();
  };

  const getUserFromAPI = (): void => {
    getUser()
      .then((res: IUser) => {
        setUser(res);
        if (res?.title && res?.profileImage) {
          setIsProfileDetailsValid(true);
        }
      })
      .catch((err: IApiError) => {
        if (err.status !== 404) {
          showError(err);
        }
      });
  };

  return (
    <div>
      <DashboardButton variant="contained" onClick={handleClickOpen}>
        Send Invite
      </DashboardButton>
      <Dialog open={open} onClose={handleClose} fullWidth fullScreen={isMobileScreen}>
        {!isProfileDetailsValid && (
          <AddProfileDetails
            handleClose={() => handleClose()}
            handleComplete={handleCompleteProfilePic}
            currentUser={user}
            title={'Send Invite'}
          />
        )}
        {isProfileDetailsValid && (
          <EmailInviteForm
            user={user}
            emailInviteStep={emailInviteStep}
            setEmailInviteStep={setEmailInviteStep}
            handleClose={handleClose}
            setLoading={setLoading}
            loading={loading}
          />
        )}
      </Dialog>
    </div>
  );
};

export default EmailInviteButton;
