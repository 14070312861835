import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import { showError } from 'utils/errorHandler';
import IApiError from 'global/interfaces/api';
import useProgressBar from 'global/hooks/useProgressBar';
import { useNavigate } from 'react-router-dom';
import { IChangePasswordForm } from 'global/interfaces/changePassword';
import { changePasswordValidation } from 'global/validations/changePassword';
import { changePassword } from 'services/authService';
import { useTitle } from 'utils/router';
import { Container, Stack, useMediaQuery, useTheme } from '@mui/material';

function ChangePassword(): JSX.Element {
  useTitle('Change Password');
  const [progress, showProgress, successRedirect] = useProgressBar();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const form = useFormik<IChangePasswordForm>({
    initialValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: changePasswordValidation,
    onSubmit: (values: IChangePasswordForm) => {
      showProgress();
      changePassword(values)
        .then((res: boolean) => {
          if (res) {
            successRedirect('/');
          }
        })
        .catch((err: IApiError) => {
          showProgress(false);
          showError(err);
        });
    },
  });

  const handelCancelClick = (): void => {
    navigate('/');
  };

  return (
    <section>
      <Container maxWidth="md">
        <Stack alignItems="center" mt={isMobile ? '32px' : '72px'} gap={3} pb={3}>
          <form onSubmit={form.handleSubmit}>
            <Typography textAlign="center" variant={isMobile ? 'h5' : 'h4'} paddingTop="24px">
              Change Password
            </Typography>
            <Grid container spacing={3} marginTop={{ xs: 2, sm: 3 }}>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControl variant="standard">
                    <TextField
                      size={isMobile ? 'small' : 'medium'}
                      name="newPassword"
                      label="New password"
                      variant="outlined"
                      type="password"
                      onChange={form.handleChange}
                      error={form.touched.newPassword !== undefined && Boolean(form.errors.newPassword)}
                      helperText={form.touched.newPassword !== undefined ? form.errors.newPassword : ''}
                    />
                  </FormControl>
                </FormGroup>
              </Grid>
              <Grid item xs={12}>
                <FormGroup>
                  <FormControl variant="standard">
                    <TextField
                      size={isMobile ? 'small' : 'medium'}
                      name="confirmNewPassword"
                      label="Confirm new password"
                      variant="outlined"
                      type="password"
                      onChange={form.handleChange}
                      error={form.touched.confirmNewPassword !== undefined && Boolean(form.errors.confirmNewPassword)}
                      helperText={form.touched.confirmNewPassword !== undefined ? form.errors.confirmNewPassword : ''}
                    />
                  </FormControl>
                </FormGroup>
              </Grid>
            </Grid>
            <Box sx={{ m: 1, mt: 4 }}>
              <Stack direction={'row'} spacing={1} justifyContent="center">
                <Button
                  fullWidth
                  sx={{ p: { xs: '18px 0', sm: '18px 36px' }, borderRadius: 6 }}
                  variant="outlined"
                  size={isMobile ? 'medium' : 'large'}
                  onClick={handelCancelClick}
                >
                  Cancel
                </Button>
                <Button
                  fullWidth
                  sx={{ p: { xs: '18px 0', sm: '18px 36px' }, borderRadius: 6 }}
                  type="submit"
                  variant="contained"
                  size={isMobile ? 'medium' : 'large'}
                >
                  Save
                </Button>
              </Stack>
            </Box>
          </form>
        </Stack>
      </Container>
      {progress}
    </section>
  );
}

export default ChangePassword;
